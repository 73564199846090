import Component from 'ShopUi/models/component';

export default class AbstractSystemSelectionTab extends Component {
    protected boxes: HTMLElement[];

    protected isEditMode: HTMLElement;

    protected changeTrackAlert: HTMLElement;

    protected activeTrack: string;

    /**
     * @inheritDoc
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    public initialize(): void {
        this.boxes = <HTMLElement[]>Array.from(this.querySelectorAll('.js-abstract-system-selection-box'));
        this.isEditMode = <HTMLElement> document.querySelector('.js-modal-window-edit-mode');
        this.changeTrackAlert = <HTMLElement> document.querySelector('.js-tab-tracktype__warning');

        if (this.isEditMode) {
            this.activeTrack = this.isEditMode.getAttribute('data-active-form-type');
            this.selectDefaultFormValue();
        }

        this.mapEvents();
    }

    private mapEvents(): void {
        this.boxes.forEach((box) => {
            box.addEventListener('click', event => this.selectBoxValue(box, event));
        });
    }

    private selectBoxValue(box, event): void {
        if (this.isSystemStep !== null) {
            if (event.target.matches('.tooltip-button--track-configurator *')) {
                return;
            }

            this.resetBoxSelection();
            box.classList.add('is--selected');

            if (this.isEditMode) {
                const boxValue = box.getAttribute('data-box-value');
                const hiddenField = <HTMLInputElement> document.querySelector('.js-tab-tracktype__hidden-systemform');
                hiddenField.value = boxValue;

                if (this.activeTrack === boxValue) {
                    this.changeTrackAlert.classList.add('tab-tracktype__is-hidden');
                } else {
                    this.changeTrackAlert.classList.remove('tab-tracktype__is-hidden');
                }
            } else {
                const nextStepSelector = `[data-content='${this.nextStep}']`;
                const nextStep = <HTMLElement> this.closest(
                    '.configurator-tabs-element'
                ).querySelector(nextStepSelector);
                this.setHiddenField(box);

                if (nextStep) {
                    setTimeout(() => {
                        nextStep.classList.remove('is--disabled');
                        nextStep.click();
                    },
                    500);
                }
                this.enableNextButton();
            }

            /**
             * Set hidden field value with box value
             * change tab
             */
        } else {
            /**
             * Do things to set box value in session (call of controller action per ajax)
             * page reload
             */
        }
    }

    // eslint-disable-next-line class-methods-use-this
    private enableNextButton(): void {
        const hiddenInputFields = <HTMLElement[]>Array.from(
            document.querySelectorAll('.js-system-step .input--hidden')
        );
        const nextStepButton = <HTMLElement>document.querySelector('.system-step__next-step-button');
        let enableButton = true;

        if (typeof hiddenInputFields !== 'undefined' && hiddenInputFields !== null && hiddenInputFields.length !== 0) {
            hiddenInputFields.forEach((hiddenInputField: HTMLInputElement) => {
                const hiddenInputFieldValue = hiddenInputField.value;

                if (hiddenInputFieldValue === '') {
                    enableButton = false;
                }
            });
        }

        enableButton === true ?
            nextStepButton.classList.remove('is--disabled') :
            nextStepButton.classList.add('is--disabled');
    }

    // eslint-disable-next-line class-methods-use-this
    private setHiddenField(box): void {
        const boxType = <string>box.getAttribute('data-box-type');
        const boxValue = <string>box.getAttribute('data-box-value');
        const boxName = <string>box.getAttribute('data-box-name');
        const hiddenInputField = <HTMLInputElement>document.querySelector(`input[name="${boxType}"]`);

        hiddenInputField.value = boxValue;
        hiddenInputField.setAttribute('data-name', boxName);
    }

    private resetBoxSelection(): void {
        this.boxes.forEach((box) => {
            box.classList.remove('is--selected');
        });
    }

    private selectDefaultFormValue(): void {
        this.boxes.forEach((box) => {
            const boxName = box.getAttribute('data-box-value');
            if (this.activeTrack === boxName) {
                box.classList.add('is--selected');
            }
        });
    }

    get nextStep(): string | null {
        return this.getAttribute('data-next-step') ? this.getAttribute('data-next-step') : null;
    }

    get isSystemStep(): string | boolean {
        return this.getAttribute('data-system-selection') ? this.getAttribute('data-system-selection') : false;
    }
}
