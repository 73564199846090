/* eslint-disable no-param-reassign */
import Component from 'ShopUi/models/component';

export default class FilterSelectionModal extends Component {
    private selectAllButton : HTMLButtonElement;

    private selectNoneButton : HTMLButtonElement;

    private filterCheckboxes : HTMLInputElement[];

    protected readyCallback(): void {
        this.initializeElements();
        this.mapEvents();
    }

    private initializeElements() {
        this.selectNoneButton = document.querySelector(`.${this.name}__select-none-button`);
        this.filterCheckboxes = Array.from(document.querySelectorAll(`.${this.name}__filter-checkbox input`));
    }

    private mapEvents() {
        if (this.selectNoneButton) {
            this.selectNoneButton.addEventListener('click', () => {
                this.changeAllCheckboxes({ checked: false });
            });
        }
    }

    private changeAllCheckboxes({ checked } : {checked : boolean}) {
        this.filterCheckboxes.forEach((checkbox : HTMLInputElement) => {
            checkbox.checked = checked;
        });
    }
}
