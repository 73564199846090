import Component from 'ShopUi/models/component';
import OverlayBlock from '../../atoms/overlay-block/overlay-block';

export default class CountrySelection extends Component {
    protected overlay: OverlayBlock;

    protected triggers: HTMLElement[];

    protected closeButton: HTMLElement;

    protected countrySelectContainer: HTMLElement;

    protected footerCountryListWrapperContainer: HTMLElement;

    protected closeButtonTarget: HTMLElement;

    protected targets: HTMLElement[];

    protected countryLinks: HTMLAnchorElement[];

    constructor() {
        super();
        this.overlay = <OverlayBlock> document.querySelector(this.overlaySelector);
        this.triggers = <HTMLElement[]> Array.from(this.querySelectorAll(this.triggerSelector));
        this.closeButton = <HTMLElement> document.querySelector(this.closeButtonSelector);
        this.closeButtonTarget = <HTMLElement> this.querySelector(this.getCloseButtonTargetSelector);
        this.countrySelectContainer = <HTMLElement> document.querySelector(
            this.footerCountrySelectContainerSelector,
        );
        this.footerCountryListWrapperContainer = <HTMLElement> document.querySelector(
            this.footerCountryListWrapperContainerSelector,
        );
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    protected init(): void {
        this.countryLinks = <HTMLAnchorElement[]> Array.from(
            this.querySelectorAll('.country-list__link'),
        );

        if (this.isInFooter && this.countrySelectContainer) {
            this.moveFooterCountrySelectionContainer();
        }

        this.mapEvents();
    }

    protected mapEvents(): void {
        this.triggers.forEach((trigger: HTMLElement) => trigger.addEventListener(
            'click',
            () => this.onTriggerClick(),
        ));

        if (this.closeButton !== null) {
            this.closeButton.addEventListener('click', () => this.closeContainer());
        }

        window.addEventListener('resize', () => this.onResize());

        this.countryLinks.forEach((countryLink) => {
            countryLink.addEventListener('click', () => {
                sessionStorage.setItem('hideStoreRedirectDialog', 'true');
            });
        });
    }

    protected addClass(trigger: HTMLElement): void {
        trigger.classList.add(this.classToToggle);
    }

    protected removeClass(trigger: HTMLElement): void {
        trigger.classList.remove(this.classToToggle);
    }

    protected closeContainer(): void {
        if (typeof this.closeButtonTarget !== 'undefined' && this.closeButtonTarget !== null) {
            this.removeClass(this.closeButtonTarget);
        }
    }

    protected onTriggerClick(): void {
        const targets = <HTMLElement[]>Array.from(document.querySelectorAll(this.targetSelector));

        if (this.isWidthMoreThanAvailableBreakpoint()) {
            targets.forEach((target) => {
                target.classList.toggle(this.classToToggle);
            });
        } else {
            targets.forEach((target) => {
                this.removeClass(target);
            });
        }
    }

    protected onResize(): void {
        const targets = <HTMLElement[]>Array.from(document.querySelectorAll(this.targetSelector));

        if (!this.isWidthMoreThanAvailableBreakpoint()) {
            targets.forEach((target) => {
                target.classList.remove(this.classToToggle);
            });
        }
    }

    protected isWidthMoreThanAvailableBreakpoint(): boolean {
        return window.innerWidth >= this.availableBreakpoint;
    }

    // eslint-disable-next-line class-methods-use-this
    protected getDataAttribute(block: HTMLElement, attr: string): string {
        return block.getAttribute(attr);
    }

    public openCountrySelection(trigger: HTMLElement) {
        if (this.isWidthMoreThanAvailableBreakpoint() && !trigger.classList.contains(this.classToToggle)) {
            this.addClass(trigger);
        }
    }

    private moveFooterCountrySelectionContainer(): void {
        const footerCountrySelectElement = document.querySelector(this.footerCountrySelectContainerSelector);

        this.footerCountryListWrapperContainer.appendChild(this.countrySelectContainer);

        if (footerCountrySelectElement) {
            footerCountrySelectElement.remove();
        }
    }

    get targetSelector(): string {
        return this.getAttribute('target-selector');
    }

    get classToToggle(): string {
        return this.getAttribute('class-to-toggle');
    }

    get getCloseButtonTargetSelector(): string {
        return this.getAttribute('close-button-target-selector');
    }

    get availableBreakpoint(): number {
        return Number(this.getAttribute('available-breakpoint'));
    }

    get isInFooter(): boolean {
        return this.dataset.inFooter === 'true';
    }

    // eslint-disable-next-line class-methods-use-this
    get overlaySelector(): string {
        return '.js-overlay-block';
    }

    get triggerSelector(): string {
        return `.${this.jsName}__trigger`;
    }

    // eslint-disable-next-line class-methods-use-this
    get closeButtonSelector(): string {
        return '.js-country-list__close-button button';
    }

    // eslint-disable-next-line class-methods-use-this
    get footerCountrySelectContainerSelector(): string {
        return '.internationalization--footer .country-list--footer';
    }

    // eslint-disable-next-line class-methods-use-this
    get footerCountryListWrapperContainerSelector(): string {
        return '.footer .country-list-footer-wrapper';
    }
}
