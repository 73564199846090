import CustomSubtypeInterface from './custom-subtype-interface';

/* eslint-disable camelcase */
export interface NewsletterSubscribeFailDataInterface {
    topics: string;
    error_type: string;
}

export default class NewsletterSubscribeFail implements CustomSubtypeInterface {
    topics: string;
    error_type: string;

    constructor(data: NewsletterSubscribeFailDataInterface) {
        this.topics = data.topics && data.topics.length > 100 ? data.topics.slice(0, 100) : data.topics;
        this.error_type = data.error_type && data.error_type.length > 100 ?
            data.error_type.slice(0, 100) :
            data.error_type;
    }
}
/* eslint-enable camelcase */
