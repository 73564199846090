import EventDataTransfer from '../event-data-transfer';
import Ga4EcommerceEvent from '../events/ecommerce-types/ga4-ecommerce-event';
import Ga4EcommerceEventWithProductItemMapper from './ga4-ecommerce-event-with-product-item-mapper';
import Ga4EcommerceViewCartEvent, {
    Ga4EcommerceViewCartEventDataInterface
} from '../events/ecommerce-types/ga4-ecommerce-view-cart-event';
import EcommerceWithCartSum, {
    EcommerceWithCartSumDataInterface
} from '../events/ecommerce-subtypes/ecommerce-with-cart-sum';
import Product from '../events/ecommerce-subtypes/product';

/* eslint-disable camelcase */
export default class Ga4EcommerceViewCartEventMapper extends Ga4EcommerceEventWithProductItemMapper {
    public map(transfer: EventDataTransfer): Ga4EcommerceEvent {
        const event = this.mapCustomSubtype({
            event_name: transfer.eventName,
            cart_type: transfer.data.cart_type,
            ecommerce: this.mapEcommerceSubtype(transfer.data.ecommerce)
        });

        return event;
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapEcommerceSubtype(data: EcommerceWithCartSumDataInterface): EcommerceWithCartSum {
        data.items = data.items.map((item): Product => new Product(item));
        data.currency = data.items[0].currency;

        return new EcommerceWithCartSum(data);
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(data: Ga4EcommerceViewCartEventDataInterface): Ga4EcommerceEvent {
        return new Ga4EcommerceViewCartEvent(data);
    }
}
/* eslint-enable camelcase */
