import Component from 'ShopUi/models/component';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';

export default class ViewSwitcher extends Component {
    // eslint-disable-next-line no-undef
    private buttons: NodeListOf<HTMLButtonElement>;

    // eslint-disable-next-line no-undef
    private activeButtons: NodeListOf<HTMLButtonElement>;

    private explosionGraphicContainer: HTMLElement;

    private roomViewContainer: HTMLElement;

    private hiddenViewModeFilter: HTMLElement;

    // eslint-disable-next-line no-undef
    private viewSwitcherContainers: NodeListOf<HTMLElement>;

    private gtmEventHandler: GoogleTagEvents;

    protected readyCallback(): void {
        // eslint-disable-next-line no-undef
        this.buttons = <NodeListOf<HTMLButtonElement>> document.querySelectorAll(`.${this.jsName}__button`);
        // eslint-disable-next-line no-undef
        this.activeButtons = <NodeListOf<HTMLButtonElement>> this.querySelectorAll(`.${this.jsName}__button`);
        this.explosionGraphicContainer = <HTMLElement> document.querySelector('.explosion-graphic');
        this.roomViewContainer = <HTMLElement> document.querySelector('.room-view');
        this.hiddenViewModeFilter = <HTMLElement> document.querySelector('.attribute-filter-view-mode');
        // eslint-disable-next-line no-undef
        this.viewSwitcherContainers = <NodeListOf<HTMLElement>> document.querySelectorAll('.view-switcher');
        this.gtmEventHandler = this.getGoogleTagEventHandler;

        this.setDefaultViewMode();

        this.mapEvents();
    }

    private mapEvents(): void {
        if (typeof this.buttons === 'undefined' || this.buttons === null) {
            return;
        }

        this.buttons.forEach((button) => {
            button.addEventListener('click', () => this.switchToView(this.getViewName(button)));
        });
        this.activeButtons.forEach((button) => {
            button.addEventListener('click', () => this.fireEvent(button, this.getViewName(button)));
        });
    }

    // eslint-disable-next-line class-methods-use-this
    private getViewName(button: HTMLButtonElement) {
        let viewName = null;

        if (button.hasAttribute('data-view')) {
            viewName = button.getAttribute('data-view');
        }

        return viewName;
    }

    private switchToView(viewName: string): void {
        this.viewSwitcherContainers.forEach((container) => {
            container.setAttribute('data-active-view', viewName);
        });

        this.removeSelected(viewName);
        this.setSelectedButton(viewName);

        switch (viewName) {
            case 'detailView':
                this.explosionGraphicContainer.classList.remove('is-hidden');
                this.roomViewContainer.classList.add('is-hidden');
                sessionStorage.setItem('product-configurator-view-mode', 'detailView');
                break;
            case 'roomView':
            default:
                this.explosionGraphicContainer.classList.add('is-hidden');
                this.roomViewContainer.classList.remove('is-hidden');
                sessionStorage.setItem('product-configurator-view-mode', 'roomView');
                break;
        }
    }

    private setSelectedButton(viewName): void {
        this.buttons.forEach((button: HTMLButtonElement) => {
            if (button.getAttribute('data-view') === viewName) {
                button.classList.add('selected');
            }
        });
    }

    private removeSelected(viewName): void {
        this.buttons.forEach((button: HTMLButtonElement) => {
            if (button.getAttribute('data-view') !== viewName) {
                button.classList.remove('selected');
            }
        });
    }

    private fireEvent(button: HTMLButtonElement, viewName: string): void {
        if (typeof this.gtmEventHandler !== 'undefined' && this.gtmEventHandler !== null) {
            let eventData = this.gtmEventHandler.getGtmEventData(button);
            const gtmStepName = this.capitalize(button.getAttribute('data-step-name'));
            const gtmViewName = this.capitalize(viewName);
            eventData = {
                ...eventData,
                eventLabel: `${gtmStepName}: ${gtmViewName}`,
            };
            const event = this.gtmEventHandler.createGaEvent(eventData);
            this.gtmEventHandler.pushEvent(<any> event);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    private capitalize(str: string): string {
        if (typeof str[0] !== 'undefined' && str[0] !== null) {
            return str[0].toUpperCase() + str.slice(1);
        }
        return str;
    }

    private setDefaultViewMode(): void {
        const viewMode = sessionStorage.getItem('product-configurator-view-mode');

        this.viewSwitcherContainers.forEach((container) => {
            container.setAttribute('data-active-view', viewMode);
        });

        this.removeSelected(viewMode);
        this.setSelectedButton(viewMode);

        if (viewMode === 'roomView') {
            this.roomViewContainer.classList.remove('is-hidden');
            this.explosionGraphicContainer.classList.add('is-hidden');
            return;
        }
        this.roomViewContainer.classList.add('is-hidden');
        this.explosionGraphicContainer.classList.remove('is-hidden');
    }

    // eslint-disable-next-line class-methods-use-this
    get getGoogleTagEventHandler(): GoogleTagEvents {
        return <GoogleTagEvents> document.querySelector('.google-tag-events');
    }
}
