import AbstractBasicForm from 'FormPage/components/organisms/basic-form/basic-form';

export default class DealerRegistrationForm extends AbstractBasicForm {
    /**
     * @inheritDoc
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /* eslint-disable no-empty-function */
    }

    /**
     * @inheritDoc
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    public removeEvents(): void {
        /* eslint-disable no-empty-function */
    }

    /**
     * @inheritDoc
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    public initialize(): void {
        /* eslint-disable no-empty-function */
    }
}
