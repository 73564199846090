import Component from 'ShopUi/models/component';

export default class ProductLandingPageExplosionGraphics extends Component {
    /**
     * The explosion graphic container
     */
    private graphics: HTMLElement;

    /**
     * We cache the lastScrollPosition to work with our rAF debouncing mechanism
     */
    private lastScrollPosition = 0;

    /**
     * Helper variable for debouncing with rAF
     */
    private tick = false;

    /**
     * Flag if the animation of the explosion graphic was triggered already
     */
    private animationTriggered = false;

    /**
     * The scroll position on which the graphic animation should get triggered
     */
    private graphicsAnimationThreshold = 0;

    protected init(): void {
        this.initializeElements();
        this.mapEvents();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    private initializeElements() {
        this.graphics = document.querySelector('.product-landing-page-explosion-graphics');
        this.graphicsAnimationThreshold = this.graphics.offsetTop - (window.innerHeight / 3);
    }

    private mapEvents() {
        if (this.graphics) {
            document.addEventListener('scroll', this.onScroll.bind(this));

            const detailTriggers = this.graphics.querySelectorAll(
                '.product-landing-page-explosion-graphic__show-details-trigger'
            );
            detailTriggers.forEach((detailTrigger:HTMLElement) => {
                detailTrigger.addEventListener('click', () => {
                    this.showDetails(detailTrigger);
                });
            });
        }
    }

    /**
     * Event handler for the on scroll event
     * Based on https://www.afasterweb.com/2017/09/26/performance-basics-throttling/
     */
    private onScroll = () => {
        /* eslint-disable no-invalid-this */
        /**
         * Cache the current scroll position
         * NOTE: It is IMPORTANT to use pageYOffset for cross browser compatibility
         */
        this.lastScrollPosition = window.pageYOffset;

        if (!this.tick) {
            /**
             * With this, we essentially tell the browser to handle the scroll
             * in the next possible frame, which is up to the browser
             * It will only request a new frame when the old one is done
             * rAF polyfilled by src/Pyz/Yves/ShopUi/Theme/default/requestAnimationFramePolyfill.ts
             */
            window.requestAnimationFrame(() => {
                this.handleScroll(this.lastScrollPosition);

                // Scroll is handled, release tick lock
                this.tick = false;
            });

            // Mark as ticked
            this.tick = true;
        }
    };

    private handleScroll(scrollPosition): void {
        if (this.graphicsAnimationThreshold <= scrollPosition && !this.animationTriggered) {
            this.graphics.classList.add('loaded');
            this.animationTriggered = true;
        }
    }

    // eslint-disable-next-line class-methods-use-this
    private showDetails(detailTrigger: HTMLElement): void {
        const icons = detailTrigger.querySelectorAll('.icon');
        icons.forEach((icon:HTMLElement) => {
            icon.classList.toggle('is-hidden');
        });

        const container = detailTrigger.parentElement.querySelector(
            '.product-landing-page-explosion-graphic__container'
        );

        if (container) {
            container.classList.toggle('showDetails');
        }
    }
}
