/* eslint-disable camelcase */

export default class NewsletterSubscribeSoiEvent {
    event: string;
    event_name: string;
    newsletter_subscribe_soi: {};

    constructor(data: NewsletterSubscribeSoiEvent) {
        this.event = data.event;
        this.event_name = data.event_name;
        this.newsletter_subscribe_soi = data.newsletter_subscribe_soi;
    }
}
/* eslint-enable camelcase */
