import CustomSubtypeInterface from './custom-subtype-interface';
import MaxLengthStringValue from '../validator-types/max-length-string-value';

/* eslint-disable camelcase */
export interface ShowMoreDataInterface {
    page_number: string;
    trigger: 'manually'|'automatically';
}

export default class ShowMore implements CustomSubtypeInterface {
    page_number: string;
    trigger: 'manually'|'automatically';

    constructor(data: ShowMoreDataInterface) {
        this.page_number = new MaxLengthStringValue(data.page_number).value;
        this.trigger = data.trigger;
    }
}
/* eslint-enable camelcase */
