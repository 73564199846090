import AjaxProvider from 'ShopUi/components/molecules/ajax-provider/ajax-provider';
import Component from 'ShopUi/models/component';
import { CheckoutOption } from 'GTMEventTracking/types/google-tag-events';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';

// eslint-disable-next-line no-var
declare var trakkenCheckoutData: any;

export default class ShipmentForm extends Component {
    private datePicker: HTMLElement;

    private datePickerValueHolder: string = null;

    private googleEventTagHandler: GoogleTagEvents;

    private trakkenCheckoutData: any;

    private selectedTypeRadio: HTMLInputElement;

    private selectedScopeRadio: HTMLInputElement;

    private form: HTMLFormElement;

    private ajaxProvider: AjaxProvider[];

    protected readyCallback() {
        this.form = <HTMLFormElement> this.querySelector('[name=shipmentCollectionForm]');
        this.datePicker = this.querySelector('.date-picker');
        this.trakkenCheckoutData = trakkenCheckoutData;
        this.googleEventTagHandler = document.querySelector('.google-tag-events');

        this.selectedTypeRadio = this.querySelector(`${this.typeRadios}[checked]`) as HTMLInputElement;
        if (this.querySelector(this.scopeRadios)) {
            this.selectedScopeRadio = this.querySelector(`${this.scopeRadios}[checked]`) as HTMLInputElement;
        }

        this.ajaxProvider = Array.from(this.querySelectorAll(`.${this.jsName}__provider`));
        this.loadShipmentPrices();

        this.initShipmentForm();
        this.mapEvents();

        this.checkDatePickerMinimumDate(this.selectedScopeRadio ? this.selectedScopeRadio : this.selectedTypeRadio);
    }

    private initShipmentForm() {
        this.setEECcheckoutOptionValue();

        if (this.googleEventTagHandler !== null) {
            const checkoutEvent = this.googleEventTagHandler.createCheckout(this.trakkenCheckoutData.ecommerce);
            this.pushTrakkenEvent(this.trakkenCheckoutData.event, checkoutEvent, null, 'load');
        }
    }

    private mapEvents() {
        const typeRadios = this.querySelectorAll(this.typeRadios);
        typeRadios.forEach((radio) => {
            radio.addEventListener('change', (event: Event) => {
                const target = <HTMLInputElement>event.target;
                this.checkDatePickerMinimumDate(target);
                this.selectedTypeRadio = target;
                this.setEECcheckoutOptionValue();
            });
        });

        const scopeRadios = this.querySelectorAll(this.scopeRadios);
        scopeRadios.forEach((radio) => {
            radio.addEventListener('change', (event: Event) => {
                const target = <HTMLInputElement>event.target;
                this.checkDatePickerMinimumDate(target);
                this.selectedScopeRadio = target;
                this.toggleExpressShipping(target);
            });
        });

        this.form.addEventListener('submit', (event: Event) => this.onFormSubmit(event));

        this.datePicker.addEventListener('changeDate', (event: CustomEvent) => this.onChangeDate(event));
    }

    private changeDatePickerMinimumDate(date) {
        this.datePicker.dispatchEvent(new CustomEvent('changeMinimumDate', { detail: { date } }));
    }

    private checkDatePickerMinimumDate(element: HTMLInputElement) {
        const earliestDeliveryDateString = element.dataset.earliestAvailabilityDate;
        if (earliestDeliveryDateString) {
            const earliestDeliveryDate = new Date(earliestDeliveryDateString);
            this.changeDatePickerMinimumDate(earliestDeliveryDate);
        }
    }

    // eslint-disable-next-line max-params
    private pushTrakkenEvent(eventName: string, eventData: any, callback?: Function, eventType?: string) {
        if (this.eventTrackingIsActive) {
            const ecommerce = this.googleEventTagHandler.createEnhancedEcommerce(eventName, eventData, callback);
            ecommerce.cartType = this.trakkenCheckoutData.cartType;
            this.googleEventTagHandler.pushEvent(<any> ecommerce, eventType);
        }
    }

    get eventLabel() {
        return this.selectedTypeRadio.getAttribute('data-trakken-label');
    }

    get hasDesiredDeliveryDate() {
        const selectedDate = this.datePicker.getAttribute('data-selected-date');
        return selectedDate !== '' || this.datePickerValueHolder !== null ? 'yes' : 'no';
    }

    private onFormSubmit(event: Event) {
        this.setSelectedShipmentMethodPrice();
        if (this.eventTrackingIsActive) {
            this.setEECcheckoutOptionValue();
            const actionField = this.googleEventTagHandler.createActionField(
                this.trakkenCheckoutData.ecommerce.checkout.actionField,
            );
            const checkoutOptionField = this.googleEventTagHandler.createCheckoutOptionField(actionField);
            const checkoutOption = new CheckoutOption(checkoutOptionField);
            const callback = () => {
                const triggerElement = <HTMLElement> event.target;
                triggerElement.closest('form').submit();
            };

            this.pushTrakkenEvent(GoogleTagEvents.EVENT_EEC_CHECKOUT_OPTION, checkoutOption, callback);
        } else {
            this.form.submit();
        }
    }

    get eventTrackingIsActive(): boolean {
        return this.googleEventTagHandler !== null && typeof this.googleEventTagHandler !== 'undefined';
    }

    private setEECcheckoutOptionValue() {
        let optionValue = this.eventLabel;
        optionValue = `${optionValue} : Desired delivery date `;
        optionValue = optionValue + this.hasDesiredDeliveryDate;

        this.trakkenCheckoutData.ecommerce.checkout.actionField.option = optionValue;
    }

    private setSelectedShipmentMethodPrice(): void {
        const shipmentPriceHiddenField = this.form.querySelector('[id=shipmentCollectionForm_shipmentPrice]');
        const shipmentSelectionInputName = 'shipmentCollectionForm[shipmentGroups][0][shipment][shipmentSelection]';
        const shipmentSelectionInput = `input[name='${shipmentSelectionInputName}']:checked`;
        const selectedShipmentMethodId = document.querySelector(shipmentSelectionInput).getAttribute('value');
        const selectedShipmentMethodPrice = document.querySelector(
            `.shipment-price-${selectedShipmentMethodId}`
        ).getAttribute('shipment-price-value');
        shipmentPriceHiddenField.setAttribute('value', selectedShipmentMethodPrice);
    }

    private toggleExpressShipping(element: HTMLInputElement): void {
        const typeRadios = this.querySelectorAll(this.typeRadios);
        if (element.getAttribute('value') === 'complete-delivery') {
            typeRadios.forEach((radio) => {
                if (radio.getAttribute('value') === '1') {
                    radio.setAttribute('checked', 'checked');
                }
                if (radio.getAttribute('value') === '2') {
                    radio.removeAttribute('checked');
                    radio.setAttribute('disabled', 'disabled');
                }
            });
        } else {
            typeRadios.forEach((radio) => {
                if (radio.getAttribute('value') === '2') {
                    radio.removeAttribute('disabled');
                }
            });
        }
    }

    private loadShipmentPrices(): void {
        if (this.ajaxProvider === null) {
            return;
        }
        this.ajaxProvider.forEach(async(provider) => {
            const shipmentId = provider.getAttribute('data-shipment-id');
            const priceContainer = this.querySelector(`.shipment-price-${shipmentId}`);
            try {
                const response = await provider.fetch();
                if (response && priceContainer) {
                    this.fillShipmentPrice(response, priceContainer);
                }
            } catch (error) {
                priceContainer.innerHTML = 'Error, please reload';
            }
        });
    }

    private fillShipmentPrice(response, priceContainer: Element): void {
        const shipmentData = JSON.parse(response);
        if (shipmentData.text !== null) {
            priceContainer.setAttribute('shipment-price-value', shipmentData.price);
            priceContainer.innerHTML = shipmentData.text;
            priceContainer.nextElementSibling.innerHTML = shipmentData.additionalText;
            if (shipmentData.noExpressShipping === true) {
                const expressRadio = this.querySelector(`.${this.name}__type-container input[type=radio][value="2"]`);
                expressRadio.removeAttribute('checked');
                expressRadio.setAttribute('disabled', 'disabled');
            }
        } else {
            priceContainer.innerHTML = 'Error, please reload';
        }
    }

    get typeRadios(): string {
        return `.${this.name}__type-container input[type=radio]`;
    }

    get scopeRadios(): string {
        return `.${this.name}__scope-container input[type=radio]`;
    }

    private onChangeDate(event: CustomEvent) {
        this.datePickerValueHolder = event.detail.date;
    }
}
