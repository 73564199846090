import Component from 'ShopUi/models/component';

export default class DealerSearchRegistrationForm extends Component {
    private submitButton: HTMLButtonElement;

    protected readyCallback(): void {
        this.submitButton = <HTMLButtonElement> this.querySelector('button[type="submit"]');

        DealerSearchRegistrationForm.setWeekdays().forEach((day) => {
            const checkbox = <HTMLInputElement> document.querySelector(`#dealer_search_form_${day}Checkbox`);
            const openDatePicker = <HTMLInputElement> document.querySelector(`#dealer_search_form_${day}OpenTime`);
            const closeDatePicker = <HTMLInputElement> document.querySelector(`#dealer_search_form_${day}CloseTime`);

            this.mapEvents(checkbox, openDatePicker, closeDatePicker);
        });

        this.submitEvent();
    }

    // eslint-disable-next-line class-methods-use-this
    private mapEvents(checkbox:HTMLInputElement, openDate:HTMLInputElement, closeDate:HTMLInputElement): void {
        checkbox.addEventListener('click', (event: Event) => DealerSearchRegistrationForm.toggleSelect(
            event,
            openDate,
            closeDate
        ));
    }

    private submitEvent(): void {
        this.submitButton.addEventListener('click', () => DealerSearchRegistrationForm.enableFieldsOnSubmit());
    }

    private static toggleSelect(event, openDate:HTMLInputElement, closeDate:HTMLInputElement): void {
        if (event.target.checked) {
            openDate.disabled = false;
            closeDate.disabled = false;
            openDate.required = true;
            closeDate.required = true;
        } else {
            openDate.disabled = true;
            closeDate.disabled = true;
            openDate.required = false;
            closeDate.required = false;
        }
    }

    private static setWeekdays(): string[] {
        return [
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday',
            'sunday',
        ];
    }

    private static enableFieldsOnSubmit(): void {
        let inputElements = null;

        inputElements = <HTMLInputElement[]> Array.from(document.querySelectorAll('input'));

        if (inputElements !== null) {
            let missingRequiredField = false;
            const companyPhone = <HTMLInputElement> document.querySelector('input[name="dealer_search_form[phone]"]');
            const companyMail = <HTMLInputElement> document.querySelector(
                'input[name="dealer_search_form[companyEmail]"]'
            );
            const tos = <HTMLInputElement> document.querySelector('input[name="dealer_search_form[tos]"]');
            if (companyPhone.value === '' || companyMail.value === '' || !tos.checked) {
                missingRequiredField = true;
            }

            if (missingRequiredField === false) {
                inputElements.forEach((inputField) => {
                    if (inputField.hasAttribute('disabled') &&
                        !inputField.parentElement.parentElement.classList.contains(
                            'dealer-search-registration-form__time-picker'
                        )
                    ) {
                        inputField.disabled = false;
                    }
                });
            }
        }
    }
}
