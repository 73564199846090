import Component from 'ShopUi/models/component';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        UC_UI: any;
        UC_UI_IS_RENDERED: boolean;
        dataLayer: any;
        dataLayerCollection: any;
    }
}

export default class UserCentrics extends Component {
    private findDealerUserNavButton: HTMLElement;

    private findDealerDetailPageButton: HTMLElement;

    private findDealerPage: HTMLElement;

    private userCentricsMessages: HTMLElement[];

    private userCentricsAcceptButtons: HTMLElement[];

    private facebookPixel: HTMLElement;

    private getHeader: HTMLElement;

    private getFooter: HTMLElement;

    private localStoresContainers: HTMLElement[];

    private googleTagManagerId: string;

    private googleAnalyticsId: string;

    private language: string;

    private ucVisible: string;

    private smartMonUrl: string;

    private defaultStoreName: string;

    private templateIds: any;

    private googleAnalyticsConsentStatus: boolean;

    private gtmEventHandler: GoogleTagEvents;

    private ucBannerTrigger: HTMLElement[];

    public googleMapsConsentStatus: boolean;

    public marketingModalWindowConsentStatus: boolean;

    /**
     * @inheritDoc
     * @protected
     */
    protected init(): void {
        window.UC_UI = window.UC_UI || {};
        this.setTemplateIds();

        if (Object.keys(window.UC_UI).length !== 0 && window.UC_UI.isInitialized()) {
            this.initialize();
        } else {
            window.addEventListener('UC_UI_INITIALIZED', () => {
                this.initialize();
            });
        }
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * The init function of the component
     *
     * @protected
     */
    protected initialize(): void {
        this.defaultStoreName = 'en';
        this.findDealerUserNavButton = <HTMLElement>document.querySelector('.user-navigation__item--dealer');
        this.findDealerDetailPageButton = <HTMLElement>document.querySelector('.find-dealer__trigger-button');
        this.findDealerPage = <HTMLElement>document.querySelector('.find-dealer-page');
        this.setLocalStoresContainers();
        this.setUserCentricsMessages();
        this.userCentricsAcceptButtons = <HTMLElement[]>Array.from(
            document.querySelectorAll('.user-centrics-googlemaps-overlay__accept-button'),
        );
        this.googleTagManagerId = this.getAttribute('js-google-tag-manager-id');
        this.googleAnalyticsId = this.getAttribute('js-google-analytics-id');
        this.language = this.getAttribute('js-language-name');
        this.ucVisible = this.getAttribute('uc-visible');
        this.smartMonUrl = this.getAttribute('js-smartmon-url');
        this.facebookPixel = <HTMLElement>document.querySelector('.social-share__facebook');
        this.getHeader = <HTMLElement>document.querySelector('slv-header');
        this.getFooter = <HTMLElement>document.querySelector('footer');
        this.gtmEventHandler = <GoogleTagEvents> document.querySelector('.google-tag-events');
        this.ucBannerTrigger = <HTMLElement[]> Array.from(document.querySelectorAll('.uc-banner-trigger'));
        this.checkForHidingBanner();
        this.setLanguage();
        this.checkFacebookPixel();
        this.checkGoogleAnalytics();
        this.checkMarketingModalWindowConsent();
        this.checkSalesFeed();
        this.mapEvents();

        this.dispatchCustomEvent('consentManagementInitialized');
    }

    /**
     * Setter for the local stores container of the CommerceConnector
     */
    public setLocalStoresContainers(): void {
        this.localStoresContainers = <HTMLElement[]>Array.from(
            document.querySelectorAll('.local-stores__container'),
        );
    }

    /**
     * Setter for the UserCentrics message for the Google Maps overlay
     */
    public setUserCentricsMessages(): void {
        this.userCentricsMessages = <HTMLElement[]>Array.from(
            document.querySelectorAll('.user-centrics-googlemaps-overlay'),
        );
    }

    /**
     * Setter for the UserCentrics consent templateIds
     */
    public setTemplateIds(): void {
        this.templateIds = [
            {
                name: 'googleMaps',
                value: 'S1pcEj_jZX',
            },
        ];
    }

    /**
     * Mapper function for the component events
     * @protected
     */
    protected mapEvents(): void {
        // Validate cookie settings on find dealer button click user-nav header
        if (this.findDealerUserNavButton !== null) {
            this.findDealerUserNavButton.addEventListener('click', () => this.checkGoogleMapStatus());
        }

        // Validate cookie settings on find dealer button click pdp
        if (this.findDealerDetailPageButton !== null) {
            this.findDealerDetailPageButton.addEventListener('click', () => this.checkGoogleMapStatus());
        }

        // Accept google maps cookie button event
        if (this.userCentricsAcceptButtons !== null) {
            this.userCentricsAcceptButtons.forEach((button) => {
                button.addEventListener('click', () => this.acceptGoogleMaps());
            });
        }

        // Reload if an event changes
        window.addEventListener('ucevents', () => {
            this.checkGoogleAnalytics();
            window.location.reload();
        });

        // Reload if an event changes
        this.ucBannerTrigger.forEach((trigger) => {
            trigger.addEventListener('click', (event: Event) => {
                event.preventDefault();
                window.UC_UI.showSecondLayer();
            });
        });
    }

    /**
     * Validation for the Facebook pixel consent status, if the consent is given we display the share button.
     * When we do not have the consent we remove the share button.
     *
     * @protected
     */
    protected checkFacebookPixel(): void {
        window.UC_UI.getServicesFullInfo().then((fullService) => {
            fullService.forEach((service) => {
                if (service.name === 'Facebook Pixel' && this.facebookPixel !== null) {
                    if (!service.consent.status) {
                        /**
                         * IE 11 can not use ChildNode.remove()
                         * (see https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove)
                         */
                        this.facebookPixel.parentElement.removeChild(this.facebookPixel);
                    }
                }
            });
        }).catch(() => {
            // Do nothing
        });
    }

    /**
     * Validation of the Google Analytics consent status
     */
    public checkGoogleAnalytics(): void {
        let googleAnalyticsService = null;

        window.UC_UI.getServicesFullInfo().then((fullService) => {
            fullService.forEach((service) => {
                if (service.name === 'Google Analytics') {
                    googleAnalyticsService = service;
                    const analyticsId = `ga-disable-UA-${this.googleAnalyticsId}`;
                    window[analyticsId] = !googleAnalyticsService.consent.status;
                    this.googleAnalyticsConsentStatus = googleAnalyticsService.consent.status;

                    if (typeof this.gtmEventHandler !== 'undefined' &&
                        this.gtmEventHandler !== null &&
                        window.dataLayerCollection.length > 0 &&
                        this.googleAnalyticsConsentStatus
                    ) {
                        this.gtmEventHandler.pushDataLayerCollection();
                    }
                }
            });
        }).catch(() => {
            // Do nothing
        }).finally(() => {
            if (typeof googleAnalyticsService !== 'undefined' && googleAnalyticsService !== null) {
                this.googleAnalyticsConsentStatus = googleAnalyticsService.consent.status;
            }

            this.dispatchCustomEvent('googleAnalyticsConsentStatusLoaded');
        });
    }

    /**
     * Getter for the Google Analytics consent status
     */
    public getGoogleAnalyticsConsentStatus(): boolean {
        return this.googleAnalyticsConsentStatus;
    }

    /**
     * Validation for the consent status for the SalesFeed functionality
     * @protected
     */
    protected checkSalesFeed(): void {
        window.UC_UI.getServicesFullInfo().then((fullService) => {
            fullService.forEach((service) => {
                if (service.name === 'SalesFeed' &&
                    service.consent.status &&
                    this.smartMonUrl !== null) {
                    const smartMonCookie = document.createElement('script');
                    smartMonCookie.type = 'text/javascript';
                    smartMonCookie.src = this.smartMonUrl;
                    document.head.appendChild(smartMonCookie);
                }
            });
        }).catch(() => {
            // Do nothing
        });
    }

    /**
     * Validation of the consent status for the Google Maps functionality
     */
    public checkGoogleMapStatus(): boolean {
        // eslint-disable-next-line arrow-body-style
        const googleMapConsent = this.templateIds.find(({ name }) => {
            return name === 'googleMaps';
        });

        let googleMapsService = null;
        window.UC_UI.getServicesFullInfo().then((fullService) => {
            fullService.forEach((service) => {
                if (service.id === googleMapConsent.value.toString()) {
                    googleMapsService = service;
                }
            });
        }).catch(() => {
            // Do nothing
        }).finally(() => {
            if (typeof googleMapsService !== 'undefined' && googleMapsService !== null) {
                this.userCentricsMessages.forEach((message) => {
                    if (googleMapsService.consent.status === false && message.classList.contains('is-hidden')) {
                        this.localStoresContainers.forEach((container) => {
                            /**
                             * IE 11 can not use ChildNode.remove()
                             * (see https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove)
                             */
                            if (container.parentElement !== null) {
                                container.parentElement.removeChild(container);
                            }
                        });
                        message.classList.remove('is-hidden');
                    } else {
                        message.classList.add('is-hidden');
                    }
                });

                this.googleMapsConsentStatus = googleMapsService.consent.status;
            }

            this.dispatchCustomEvent('googleMapConsentStatusLoaded');

            return this.googleMapsConsentStatus;
        });

        return this.googleMapsConsentStatus;
    }

    /**
     * Action for accepting cookies for the google maps functionality
     * @protected
     */
    protected acceptGoogleMaps(): void {
        window.UC_UI.getServicesFullInfo().then((fullService) => {
            fullService.forEach((service) => {
                if (typeof service.name !== 'undefined' &&
                    service.name !== null &&
                    service.name === 'Google Maps'
                ) {
                    this.userCentricsMessages.forEach(() => {
                        window.UC_UI.acceptService(service.id);
                    });
                }
            });
        }).catch(() => {
            // Do nothing
        });
    }

    /**
     * Setter of the language from the current user
     * @protected
     */
    protected setLanguage(): void {
        if (typeof this.language !== 'undefined' || this.language !== null) {
            window.UC_UI.updateLanguage(this.language);
        } else {
            window.UC_UI.updateLanguage(this.defaultStoreName);
        }
    }

    /**
     * Validates if banner, header and footer should be hidden, when on specific page
     *
     * @protected
     */
    protected checkForHidingBanner(): void {
        window.UC_UI.getServicesFullInfo().then((fullService) => {
            fullService.forEach((service) => {
                if (service.name === 'Usercentrics Consent Management Platform' &&
                    service.consent.status === true &&
                    service.consent.history[service.consent.history.length - 1] === 'implicit' &&
                    this.getHeader !== null &&
                    this.ucVisible === '0'
                ) {
                    /**
                     * IE 11 can not use ChildNode.remove()
                     * (see https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove)
                     */
                    this.getHeader.parentElement.removeChild(this.getHeader.nextElementSibling);
                    this.getHeader.classList.add('is-hidden');
                    this.getFooter.classList.add('is-hidden');
                    window.UC_UI.showSecondLayer(false);
                }
            });
        }).catch(() => {
            // Do nothing
        });
    }

    /**
     * Checks if marketing modal window consent is set in usercentrics
     */
    public checkMarketingModalWindowConsent(): void {
        let marketingModalWindowService = null;

        window.UC_UI.getServicesFullInfo().then((fullService) => {
            fullService.forEach((service) => {
                if (service.name === 'Informationsmodal') {
                    marketingModalWindowService = service;
                    this.marketingModalWindowConsentStatus = marketingModalWindowService.consent.status;
                }
            });
        }).catch(() => {
            // Do nothing
        }).finally(() => {
            if (typeof marketingModalWindowService !== 'undefined' && marketingModalWindowService !== null) {
                this.marketingModalWindowConsentStatus = marketingModalWindowService.consent.status;
            }

            this.dispatchCustomEvent('marketingModalWindowConsentStatusLoaded');
        });
    }

    /**
     * Getter for the constent status for the marketing modal window
     */
    public getMarketingModalWindowConsentStatus(): boolean {
        return this.marketingModalWindowConsentStatus;
    }

    /**
     * Function to dispatch an event, when the user centrics tool is initialized
     *
     * @param name
     * @param detail
     *
     * @protected
     */
    protected dispatchCustomEvent = (name: string, detail: any = {}) => {
        const customEvent = new CustomEvent(name, { detail });
        document.dispatchEvent(customEvent);
    }
}
