import Component from 'ShopUi/models/component';
import AjaxProvider from 'ShopUi/components/molecules/ajax-provider/ajax-provider';
import TextField from 'src/BestIt/ShopUi/components/molecules/text-field/text-field';
import LoadingIndicator from 'src/BestIt/ShopUi/components/molecules/loading-indicator/loading-indicator';
import Icon from 'src/BestIt/ShopUi/components/atoms/icon/icon';
import SvgIcon from 'src/BestIt/ShopUi/components/atoms/svg-icon/svg-icon';
import { Iconly } from '@best-it/iconly';
import CustomSelect from 'src/BestIt/ShopUi/components/molecules/custom-select/custom-select';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import AbstractBasicForm from 'FormPage/components/organisms/basic-form/basic-form';
import ScrollToInvalidFieldOnSubmit
    from 'FormPage/components/atoms/scroll-to-invalid-field-on-submit/scroll-to-invalid-field-on-submit';
/* eslint-disable no-unused-vars */
import CatalogBasicForm
    from 'FormPage/components/organisms/catalog-basic-form/catalog-basic-form';
import CatalogCustomerForm from 'FormPage/components/organisms/catalog-customer-form/catalog-customer-form';
import CatalogPartnerForm from 'FormPage/components/organisms/catalog-partner-form/catalog-partner-form';
import CatalogRetailerForm from 'FormPage/components/organisms/catalog-retailer-form/catalog-retailer-form';
import ContactForm from 'FormPage/components/organisms/contact-form/contact-form';
import DealerRegistrationForm from 'FormPage/components/organisms/dealer-registration-form/dealer-registration-form';
/* eslint-enable no-unused-vars */
import { mount } from 'ShopUi/app';

export default class FormElement extends Component {
    /**
     * The header to calculate the scroll position
     *
     * @private
     */
    private header: HTMLElement;

    /**
     * The loading indicator for the times in which we load the form per AJAX
     *
     * @private
     */
    private loadingIndicator: LoadingIndicator;

    /**
     * The ajax provider which loads the form from the ajax url
     *
     * @private
     */
    private ajaxProvider: AjaxProvider;

    /**
     * The loaded organism component
     *
     * @private
     */
    private loadedComponent: AbstractBasicForm;

    /**
     * The container in which we load the form into
     *
     * @private
     */
    private formContainer: HTMLDivElement;

    /**
     * The form name as selector for the input fields
     *
     * @private
     */
    private form: HTMLFormElement;

    /**
     * The form name as selector for the input fields
     *
     * @private
     */
    private formSelector: string;

    /**
     * The input elements of the form
     *
     * @private
     */
    private honeyPotField: HTMLInputElement;

    /**
     * The text-field components which get loaded to register the events for them
     *
     * @private
     */
    private textFieldComponents: TextField[];

    /**
     * The input elements of the form
     *
     * @private
     */
    private inputFields: HTMLInputElement[];

    /**
     * The select elements of the form
     *
     * @private
     */
    private selectFields: HTMLSelectElement[];

    /**
     * The textarea elements of the form
     *
     * @private
     */
    private textAreaFields: HTMLTextAreaElement[];

    /**
     * The textarea elements of the form
     *
     * @private
     */
    private submitButton: HTMLElement;

    /**
     * A threshold for the calculation of the scroll position
     *
     * @private
     */
    private threshold = 50;

    /**
     * The icon
     *
     * @private
     */
    private icons: Icon[];

    /**
     * The scrollToInvalidField
     *
     * @private
     */
    private scrollToInvalidField: ScrollToInvalidFieldOnSubmit;

    /**
     * The svgIconHandler
     *
     * @private
     */
    private svgIconHandler: SvgIcon;

    /**
     * The customSelcets
     *
     * @private
     */
    private customSelects: CustomSelect[];

    /**
     * The google tag events handler
     *
     * @private
     */
    private googleEventHandler: GoogleTagEvents;

    /**
     * The form response message container
     *
     * @private
     */
    private formResponse: HTMLElement

    protected FORM_CAST_MAP: any = {
        'catalog-basic-form': 'CatalogBasicForm',
        'catalog-customer-form': 'CatalogCustomerForm',
        'catalog-partner-form': 'CatalogPartnerForm',
        'catalog-retailer-form': 'CatalogRetailerForm',
        'contact-form': 'ContactForm',
        'dealer-registration-form': 'DealerRegistrationForm',
    }

    /**
     * @inheritDoc
     *
     * @protected
     */
    protected init(): void {
        this.header = <HTMLElement>document.querySelector('.header');
        this.loadingIndicator = this.querySelector('loading-indicator') as LoadingIndicator;
        this.ajaxProvider = <AjaxProvider> this.querySelector(`.${this.jsName}__provider`);
        this.formContainer = <HTMLDivElement> this.querySelector(`.${this.jsName}__form-container`);
        this.googleEventHandler = <GoogleTagEvents> document.querySelector('.google-tag-events');

        this.loadingIndicator.openLoadingIndicator(false);
        this.mapEvents();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Mapper function for the window load event to fetch the form
     *
     * @private
     */
    private mapEvents(): void {
        window.addEventListener('load', () => this.loadForm());
    }

    /**
     * Mapper functino for the sub-component events of the form
     *
     * @private
     */
    private mapFormEvents() {
        if (this.submitButton !== null) {
            this.submitButton.addEventListener('click', (event: Event) => this.submitForm(event));
        }

        if (this.textFieldComponents !== null) {
            this.textFieldComponents.forEach((textFieldComponent) => {
                textFieldComponent.initialize();
                textFieldComponent.setModifierClasses();
            });
        }
    }

    /**
     * Asynchronous function to fetch the requested form
     *
     * @param formData
     *
     * @private
     */
    private async loadForm(formData?: FormData): Promise<void> {
        try {
            this.dispatchCustomEvent('fetchForm');
            const response = await this.ajaxProvider.fetch(formData);

            if (response) {
                this.formContainer.innerHTML = response;
                await mount();

                this.loadedComponent = <AbstractBasicForm> this.querySelector(
                    `.${this.jsName}__form-container > .custom-element`,
                );
                this.form = <HTMLFormElement> this.querySelector('form');
                this.formSelector = this.form.name;
                this.honeyPotField = document.querySelector(
                    `input[name="${this.formSelector}[check]"]`,
                );
                this.inputFields = <HTMLInputElement[]>Array.from(
                    this.form.querySelectorAll(`form[name=${this.formSelector}] input`),
                );
                this.selectFields = <HTMLSelectElement[]>Array.from(
                    this.form.querySelectorAll(`form[name=${this.formSelector}] select`),
                );
                this.textAreaFields = <HTMLTextAreaElement[]>Array.from(
                    this.form.querySelectorAll(`form[name=${this.formSelector}] textarea`),
                );
                this.textFieldComponents = <TextField[]>Array.from(
                    this.form.querySelectorAll(`form[name=${this.formSelector}] .text-field`),
                );
                this.submitButton = document.querySelector(
                    `form[name=${this.formSelector}] [type=submit]`,
                );
                this.formResponse = this.form.querySelector('.form-response');

                this.icons = <Icon[]> Array.from(
                    this.form.querySelectorAll('.icon'),
                );
                this.svgIconHandler = <SvgIcon> document.querySelector('.svg-icon');

                if (typeof this.icons !== 'undefined' &&
                    this.icons !== null &&
                    typeof this.svgIconHandler !== 'undefined' &&
                    this.svgIconHandler !== null
                ) {
                    const { iconElementSelector } = this.svgIconHandler;

                    if (iconElementSelector !== '') {
                        this.icons.forEach((icon: Icon) => {
                            icon.initializeCustomElement(iconElementSelector, {
                                fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
                                createIntersectionObserver: false,
                            }, {}, false);
                        });

                        Iconly.registerIntersectionObserver(iconElementSelector, {
                            fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
                        });
                    }
                }

                this.customSelects = <CustomSelect[]> Array.from(this.form.querySelectorAll('.custom-select'));

                if (typeof this.customSelects !== 'undefined' && this.customSelects !== null) {
                    this.customSelects.forEach((customSelect: CustomSelect) => {
                        customSelect.initialize();
                    });
                }

                this.scrollToInvalidField = <ScrollToInvalidFieldOnSubmit> this.loadedComponent.querySelector(
                    '.scroll-to-invalid-field-on-submit',
                );

                if (typeof this.scrollToInvalidField !== 'undefined' && this.scrollToInvalidField !== null) {
                    this.scrollToInvalidField.initSubmitListener();
                }

                this.mapFormEvents();

                this.classList.remove(`${this.name}--error`);

                this.triggerGoogleTagEvents();
            }
        } catch (error) {
            this.classList.add(`${this.name}--error`);
            this.resetForm();
        } finally {
            this.loadingIndicator.closeLoadingIndicator();
            this.dispatchCustomEvent('fetchedForm');
        }
    }

    /**
     * Submit form event handler
     *
     * @param event
     *
     * @private
     */
    private submitForm(event: Event): void {
        event.preventDefault();
        const formData = new FormData(this.form);

        if (this.scrollToInvalidField.getInvalidFields().length === 0) {
            this.triggerSklikEvent();
        }

        this.loadingIndicator.openLoadingIndicator(false);
        this.resetForm();
        this.loadForm(formData);

        this.scrollToTopOfForm();
    }

    /**
     * Getter function to get the field values for the async request
     *
     * @param field
     *
     * @private
     */
    private static getFieldValue(field: any): any {
        return field.value instanceof String ? field.value.trim() : field.value;
    }

    /**
     * Resets the form and all sub-components
     *
     * @private
     */
    private resetForm(): void {
        if (typeof this.honeyPotField !== 'undefined' && this.honeyPotField.value === '') {
            this.honeyPotField.removeAttribute('required');
        }

        if (typeof this.inputFields !== 'undefined') {
            this.inputFields.forEach((input) => {
                this.ajaxProvider.queryParams.set(input.name, FormElement.getFieldValue(input));
            });
        }

        if (typeof this.selectFields !== 'undefined') {
            this.selectFields.forEach((select) => {
                this.ajaxProvider.queryParams.set(select.name, FormElement.getFieldValue(select));
            });
        }

        if (typeof this.textAreaFields !== 'undefined') {
            this.textAreaFields.forEach((textArea) => {
                this.ajaxProvider.queryParams.set(textArea.name, FormElement.getFieldValue(textArea));
            });
        }

        if (typeof this.loadedComponent !== 'undefined' &&
            this.loadedComponent !== null &&
            typeof this.loadedComponent.removeEvents !== 'undefined' &&
            typeof this.loadedComponent.removeEvents === 'function'
        ) {
            this.loadedComponent.removeEvents();
        }

        this.formContainer.innerHTML = '';
        this.honeyPotField = null;
        this.inputFields = [];
        this.selectFields = [];
        this.textAreaFields = [];
        this.icons = [];
        this.svgIconHandler = null;
        this.customSelects = [];
        this.scrollToInvalidField = null;
        this.formResponse = null;
    }

    /**
     * A function to scroll to the top of the form automatically after form submit to see the form response message
     *
     * @private
     */
    private scrollToTopOfForm(): void {
        const scrollPosition = this.offsetTop;
        const scrollTo = scrollPosition - this.header.offsetHeight - this.threshold;
        window.scroll(0, scrollTo);
    }

    /**
     * Event handler function to trigger the Google tag event
     *
     * @private
     */
    private triggerGoogleTagEvents() {
        if (typeof this.formResponse !== 'undefined' && this.formResponse !== null &&
            typeof this.googleEventHandler !== 'undefined' && this.googleEventHandler !== null
        ) {
            const formEventData = this.googleEventHandler.getGtmEventData(this.formResponse);

            if (typeof formEventData !== 'undefined' && formEventData !== null) {
                const event = this.googleEventHandler.createGaEvent(formEventData);
                this.googleEventHandler.pushEvent(<any>event);
            }
        }
    }

    /**
     * Function to dispatch the add and remove product events
     *
     * @param name
     * @param detail
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected dispatchCustomEvent(name: string, detail: any = {}): void {
        const customEvent = new CustomEvent(name, { detail });
        document.dispatchEvent(customEvent);
    }

    /**
     * Event handler function to trigger the Sklik conversion event
     *
     * @param event
     *
     * @protected
     */
    public triggerSklikEvent() {
        if (this.sklikConversionId) {
            window.rc.conversionHit({
                id: this.sklikConversionId,
                // eslint-disable-next-line camelcase
                seznam_value: null,
            });
        }
    }

    /**
     * Getter for the data attribute data-sklik-conversion-id
     *
     * @public
     */
    get sklikConversionId() {
        const sklikConversionId = this.loadedComponent.dataset.sklikConversionId || null;
        return sklikConversionId !== null ? parseInt(sklikConversionId, 10) : null;
    }
}
