import AbstractGa4CustomEventWithDataMapper from './abstract-ga4-custom-event-with-data-mapper';
import OpenFastcalcTool, {
    OpenFastcalcToolDataInterface
} from 'GA4EventTracking/types/events/custom-subtypes/open-fastcalc-tool';
import CustomSubtypeInterface from 'GA4EventTracking/types/events/custom-subtypes/custom-subtype-interface';

export default class Ga4CustomOpenFastcalcEventMapper extends AbstractGa4CustomEventWithDataMapper {
    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(data: OpenFastcalcToolDataInterface): CustomSubtypeInterface {
        return new OpenFastcalcTool(data);
    }
}
