import Component from 'ShopUi/models/component';
import LoadingIndicator from 'src/BestIt/ShopUi/components/molecules/loading-indicator/loading-indicator';

export default class MiniCartDetail extends Component {
    protected setDefaultQuoteTriggers: HTMLElement[];

    protected loadingIndicator: LoadingIndicator;

    /**
     * @inheritDoc
     *
     * @protected
     *
     * @return void
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * The initialize function
     *
     * @public
     *
     * @return void
     */
    protected initialize(): void {
        this.setDefaultQuoteTriggers = <HTMLElement[]> Array.from(this.querySelectorAll('[data-form-selector]'));
        this.loadingIndicator = document.querySelector('loading-indicator') as LoadingIndicator;

        this.mapEvents();
    }

    private mapEvents(): void {
        if (typeof this.setDefaultQuoteTriggers !== 'undefined' && this.setDefaultQuoteTriggers !== null) {
            this.setDefaultQuoteTriggers.forEach((setDefaultQuoteTrigger) => {
                if (setDefaultQuoteTrigger.dataset.formSelector !== '') {
                    setDefaultQuoteTrigger.addEventListener('click', () => {
                        const form = <HTMLFormElement> this.querySelector(setDefaultQuoteTrigger.dataset.formSelector);

                        if (typeof this.loadingIndicator !== 'undefined' && this.loadingIndicator !== null) {
                            this.loadingIndicator.openLoadingIndicator();
                        }
                        if (typeof form !== 'undefined' && form !== null) {
                            form.submit();
                        }
                    });
                }
            });
        }
    }
}
