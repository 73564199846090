import Ga4EcommerceEventWithProductItemMapper
    from 'GA4EventTracking/types/event-mappers/ga4-ecommerce-event-with-product-item-mapper';
import EventDataTransfer from 'GA4EventTracking/types/event-data-transfer';
import Ga4EcommerceAddShippingInfoEvent, {
    Ga4EcommerceAddShippingInfoEventDataInterface
} from 'GA4EventTracking/types/events/ecommerce-types/ga4-ecommerce-add-shipping-info-event';
import EcommerceWithCartSumAndCoupon, {
    EcommerceWithCartSumAndCouponDataInterface
} from 'GA4EventTracking/types/events/ecommerce-subtypes/ecommerce-with-cart-sum-and-coupon';
import Product from 'GA4EventTracking/types/events/ecommerce-subtypes/product';

/* eslint-disable camelcase */
export default class Ga4EcommerceEventAddShippingInfoMapper extends Ga4EcommerceEventWithProductItemMapper {
    public map(transfer: EventDataTransfer): Ga4EcommerceAddShippingInfoEvent {
        return this.mapCustomSubtype({
            event_name: transfer.eventName,
            cart_type: transfer.data.cart_type,
            shipping_tier: transfer.data.shipping_tier,
            ecommerce: this.mapEcommerceSubtype(transfer.data.ecommerce)
        });
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(
        data: Ga4EcommerceAddShippingInfoEventDataInterface
    ): Ga4EcommerceAddShippingInfoEvent {
        return new Ga4EcommerceAddShippingInfoEvent(data);
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapEcommerceSubtype(
        data: EcommerceWithCartSumAndCouponDataInterface
    ): EcommerceWithCartSumAndCoupon {
        data.items = data.items.map((item): Product => new Product(item));

        return new EcommerceWithCartSumAndCoupon(data);
    }
}
/* eslint-enable camelcase */
