import AbstractStep from '../abstract-step/abstract-step';

export default class TechnicalStep extends AbstractStep {
    protected infoBox: HTMLElement;

    protected readyCallback(): void {
        this.initialize();
    }

    protected initStepFunctionality(): void {
        this.registerAbstractSkuFilterEvent();
        this.hideFiltersIfNoAbstractSkuIsSelected();

        this.initInfoBox();

        this.explosionGraphic.turnLightOff();
    }

    protected registerAbstractSkuFilterEvent(): void {
        // eslint-disable-next-line no-undef
        const filterOptions = <NodeListOf<HTMLElement>> this.querySelectorAll(this.cssSelectors.abstractSkuFilter);

        if (filterOptions === null || filterOptions.length === 0) {
            return;
        }

        filterOptions.forEach((filterOption: HTMLElement) => {
            filterOption.addEventListener('click', (event) => {
                event.preventDefault();
                const abstractSku = filterOption.getAttribute('data-abstract_sku');
                this.reloadPageWithAbstractSku(abstractSku);
            });
        });
    }

    // eslint-disable-next-line class-methods-use-this
    protected reloadPageWithAbstractSku(abstractSku: string): void {
        const currentUrl = document.location.href.split('?')[0];
        document.location.href = `${currentUrl}?abstract_sku=${abstractSku}`;
    }

    protected hideFiltersIfNoAbstractSkuIsSelected(): void {
        if (this.abstractSku !== null && this.abstractSku !== 'null') {
            return;
        }

        this.filters.forEach((filter: HTMLElement) => {
            if (!this.filterHasSkuDataAttribute(filter)) {
                this.setFilterInvisible(filter);
            }
        });
    }

    protected filterHasSkuDataAttribute(filterOption: HTMLElement): boolean {
        const elementOrNull = filterOption.querySelector(this.cssSelectors.abstractSkuFilter);

        if (elementOrNull === null) {
            return false;
        }
        return true;
    }

    protected setFilterInvisible(filter: HTMLElement) {
        filter.closest(this.cssSelectors.filterBox).classList.add(this.cssClasses.invisible);
    }

    protected hideInfoBox(): void {
        if (this.infoBox) {
            sessionStorage.setItem('product-configurator-view-switcher-disabled', '1');
            this.infoBox.classList.add('is-hidden');
        }
    }

    protected initInfoBox(): void {
        this.infoBox = this.querySelector('.technical-step__info-box');

        this.querySelector('.js-info-box__close-button').addEventListener('click', (event) => {
            event.preventDefault();
            this.hideInfoBox();
        });

        if (this.infoBoxShouldBeHidden()) {
            this.hideInfoBox();
        }
    }

    // eslint-disable-next-line class-methods-use-this
    protected infoBoxShouldBeHidden(): boolean {
        const viewSwitcherDisabled = sessionStorage.getItem('product-configurator-view-switcher-disabled');

        if (viewSwitcherDisabled === '1') {
            return true;
        }

        return false;
    }
}
