import Component from 'ShopUi/models/component';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import KeenSlider from 'keen-slider';
import { ResizeObserver } from 'resize-observer';
import { ResizeObserverEntry } from 'resize-observer/lib/ResizeObserverEntry';

export default class KeenSliderCarousel extends Component {
    /**
     * The KeenSlider object
     *
     * @protected
     */
    public slider: KeenSlider;

    /**
     * The container of the KeenSlider object
     *
     * @protected
     */
    public sliderContainer: HTMLElement;

    /**
     * The Google event handler
     *
     * @protected
     */
    protected gtmEventHandler: GoogleTagEvents;

    /**
     * The arrow controls of the slider
     *
     * @protected
     */
    protected arrowControls: HTMLElement[];

    /**
     * The arrow controls of the slider
     *
     * @protected
     */
    protected prevArrowControl: HTMLElement;

    /**
     * The arrow controls of the slider
     *
     * @protected
     */
    protected nextArrowControl: HTMLElement;

    /**
     * The dot controls container of the slider
     *
     * @protected
     */
    protected dotControlsContainer: HTMLElement;

    /**
     * The dot controls of the slider
     *
     * @protected
     */
    protected dotControls: HTMLElement[] = [];

    /**
     * The resize observer for the different viewports to reinit the slider
     *
     * @protected
     */
    protected resizeObserver: ResizeObserver;

    /**
     * Base function for the components
     *
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Init function for the component
     *
     * @public
     */
    public initialize() {
        this.sliderContainer = <HTMLElement> this.querySelector(this.sliderSelector);
        this.gtmEventHandler = this.getGoogleTagEventHandler;
        let resetEvents = false;

        if (typeof this.resizeObserver !== 'undefined' && this.resizeObserver !== null) {
            resetEvents = true;
        } else {
            this.resizeObserver = new ResizeObserver(
                (entries: ResizeObserverEntry[]) => this.resizeSlider(entries),
            );
        }

        if (this.arrowControlsSelector !== '') {
            this.setArrowControls();
        }
        if (this.dotControlsSelector !== '') {
            this.setDotControlContainer();
        }

        this.mapEvents(resetEvents);

        if (typeof this.gtmEventHandler !== 'undefined' && this.gtmEventHandler !== null) {
            this.mapGtmEvents();
        }
    }

    /**
     * Register the events for the component (resize of the window, arrow controls)
     *
     * @protected
     */
    protected mapEvents(resetEvents: boolean = false): void {
        if (resetEvents) {
            this.resizeObserver.unobserve(document.body);
        }
        this.resizeObserver.observe(document.body);

        if (typeof this.arrowControls !== 'undefined' && this.arrowControls !== null && this.arrowControls.length > 0) {
            this.arrowControls.forEach((arrowControl) => {
                if (resetEvents) {
                    arrowControl.removeEventListener('click', (event: Event) => this.changeSlide(event));
                }
                arrowControl.addEventListener('click', (event: Event) => this.changeSlide(event));
            });
        }
    }

    /**
     * Register the GTM events for the slider
     *
     * @public
     */
    // eslint-disable-next-line class-methods-use-this
    protected mapGtmEvents(): void {
        /* eslint-disable capitalized-comments,multiline-comment-style */
        // ToDo: Add when needed
        // if (typeof this.gtmEventHandler !== 'undefined'
        //     && this.gtmEventHandler !== null
        //     && typeof this.prevArrow !== 'undefined'
        //     && this.prevArrow !== null
        //     && typeof this.nextArrow !== 'undefined'
        //     && this.nextArrow !== null) {
        //     $(this.prevArrow).on('click', () => this.triggerGaEvent());
        //
        //     $(this.nextArrow).on('click', () => this.triggerGaEvent());
        // }
    }

    /**
     * Remove the GTM events before the slider get destroyed
     *
     * @public
     */
    // eslint-disable-next-line class-methods-use-this
    protected removeGtmEvents(): void {
        // ToDo: remove events on reinit
    }

    /**
     * Initialize the slider
     *
     * @public
     */
    public sliderInit(): void {
        const container = <HTMLElement> document.querySelector(this.sliderSelector);
        this.slider = new KeenSlider(container, this.sliderConfig);
        this.slider.resize();
    }

    /**
     * Show slider container after slider got created or mounted
     *
     * @protected
     */
    protected showSlider(slider: KeenSlider): void {
        this.sliderContainer.classList.remove('is-hidden');

        if (!this.sliderContainer.classList.contains(`${this.name}--is-inited`)) {
            this.sliderContainer.classList.add(`${this.name}--is-inited`);
        }

        if (this.arrowControlsSelector !== '') {
            this.handleVisibilityOfArrowControls(slider);
        }

        if (this.dotControlsSelector !== '') {
            this.addDots(slider);
        }
    }

    /**
     * Hide slider container after slider got destroyed
     *
     * @protected
     */
    protected hideSlider(): void {
        this.sliderContainer.classList.remove(`${this.name}--is-inited`);

        if (!this.sliderContainer.classList.contains('is-hidden')) {
            this.sliderContainer.classList.add('is-hidden');
        }
    }

    /**
     * Reinit slider (sometimes needed if multiple sliders are nested in each other)
     *
     * @public
     */
    public sliderReinit(): void {
        if (typeof this.slider !== 'undefined' && this.slider !== null) {
            const gtmEventHandlerActive = typeof this.gtmEventHandler !== 'undefined' && this.gtmEventHandler !== null;

            if (gtmEventHandlerActive) {
                this.removeGtmEvents();
            }

            if (this.dotControlsSelector !== '') {
                this.removeDotEvent(this.slider);
            }

            this.slider.refresh(this.sliderConfig);
            this.slider.resize();

            if (gtmEventHandlerActive) {
                this.mapGtmEvents();
            }
        }
    }

    /**
     * Destroy slider if not needed
     *
     * @public
     */
    public sliderTerminate(): void {
        this.removeGtmEvents();
        this.slider.destroy();
    }

    /**
     * Resize slider on window size change
     *
     * @public
     */
    public resizeSlider(entries: ResizeObserverEntry[]): void {
        if (this.sliderContainer.classList.contains(`${this.name}--is-inited`)) {
            entries.forEach(() => {
                this.sliderReinit();
            });
        } else {
            entries.forEach(() => {
                this.sliderInit();
            });
        }
    }

    /**
     * Getter for the selector of the element which should be initialized as slider
     *
     * @public
     */
    get sliderSelector(): string {
        return this.dataset.sliderSelector || '';
    }

    /**
     * Getter for the selector of the element which should be initialized as slider
     *
     * @return Object
     *
     * @public
     */
    get sliderConfig(): Object {
        const sliderProperties = JSON.parse(this.dataset.sliderConfig) || {};
        const sliderEvents = {
            mounted: (slider: KeenSlider) => {
                this.showSlider(slider);
            },
            destroyed: () => {
                this.hideSlider();
            },
            slideChanged: (slider: KeenSlider) => {
                this.handleSlideChanged(slider);
            },
        };

        return Object.assign({}, sliderProperties, sliderEvents);
    }

    /**
     * Getter for the selector of the arrow controls
     */
    get arrowControlsSelector(): string {
        return this.dataset.arrowControlsSelector || '';
    }

    /**
     * Getter for the selector of the dot controls
     */
    get dotControlsSelector(): string {
        return this.dataset.dotControlsSelector || '';
    }

    /**
     * Getter for the Google event handler
     *
     * @public
     */
    // eslint-disable-next-line class-methods-use-this
    get getGoogleTagEventHandler(): GoogleTagEvents {
        return <GoogleTagEvents> document.querySelector('.google-tag-events');
    }

    /**
     * Getter for the Google event data
     *
     * @protected
     */
    get gtmEventData(): object {
        const gtmEventData = JSON.parse(this.sliderContainer.dataset.eventData);

        return gtmEventData || undefined;
    }

    /**
     * Trigger function to push the Google event into the data layer
     *
     * @protected
     */
    protected triggerGaEvent(): void {
        if (typeof this.gtmEventData !== 'undefined' &&
            this.gtmEventData !== null
        ) {
            const slickCarouselGaEvent = this.gtmEventHandler.createGaEvent(this.gtmEventData);
            this.gtmEventHandler.pushEvent(slickCarouselGaEvent);
        }
    }

    /**
     * Handler for the arrow controls
     *
     * @param event
     *
     * @protected
     */
    protected changeSlide(event: Event): void {
        let target = <HTMLElement> event.target;
        const arrowControlsSelector = this.arrowControlsSelector.substring(1);

        if (!target.classList.contains(`${arrowControlsSelector}`)) {
            do {
                target = target.parentElement;
            } while (!target.classList.contains(`${arrowControlsSelector}`));
        }

        if (target.classList.contains(`${arrowControlsSelector}--prev`)) {
            this.slider.prev();
        } else if (target.classList.contains(`${arrowControlsSelector}--next`)) {
            this.slider.next();
        }
    }

    /**
     * Handle the slideChanged event to show and hide the controls if activated
     *
     * @protected
     */
    protected handleSlideChanged(slider: KeenSlider): void {
        if (this.arrowControlsSelector !== '') {
            this.handleVisibilityOfArrowControls(slider);
        }
        if (this.dotControlsSelector !== '') {
            this.handleStatusOfControls(slider);
        }
    }

    /**
     * Getter for the breakpoint on which the hero teaser should be removed from the slider
     *
     * @public
     */
    get viewportBreakpoint(): number {
        return parseInt(this.dataset.breakpoint || '0', 10);
    }

    /**
     * Hide or display arrow controls
     *
     * @param slider
     *
     * @protected
     */
    protected handleVisibilityOfArrowControls(slider: KeenSlider) {
        if (typeof slider !== 'undefined' && slider !== null && window.innerWidth > this.viewportBreakpoint) {
            const details = slider.details();

            if (details.size === details.slidesPerView) {
                this.prevArrowControl.classList.add('is-invisible');
                this.nextArrowControl.classList.add('is-invisible');
            } else {
                const sliderProperties = JSON.parse(this.dataset.sliderConfig) || {};
                const isLoop = sliderProperties.loop;

                if (details.relativeSlide === 0 && isLoop === false) {
                    this.prevArrowControl.classList.add('is-invisible');
                } else {
                    this.prevArrowControl.classList.remove('is-invisible');
                }

                if ((details.relativeSlide === (details.size - (Math.round(details.slidesPerView * 100) / 100)) ||
                    details.size < details.slidesPerView) && isLoop === false
                ) {
                    this.nextArrowControl.classList.add('is-invisible');
                } else {
                    this.nextArrowControl.classList.remove('is-invisible');
                }
            }
        } else {
            if (!this.prevArrowControl.classList.contains('is-invisible')) {
                this.prevArrowControl.classList.add('is-invisible');
            }

            if (!this.nextArrowControl.classList.contains('is-invisible')) {
                this.nextArrowControl.classList.add('is-invisible');
            }
        }
    }

    /**
     * Adds the dot controls to the slider per slide
     *
     * @param slider
     *
     * @protected
     */
    protected addDots(slider: KeenSlider) {
        if (typeof slider !== 'undefined' &&
            slider !== null &&
            slider.details().size > 1
        ) {
            const { slides } = slider.options();
            const sliderSlides = <HTMLElement[]>Array.from(this.querySelectorAll(slides));
            this.dotControls = [];

            this.dotControlsContainer.innerHTML = '';

            sliderSlides.forEach(() => {
                const dot = document.createElement('li');
                dot.classList.add(`${this.name}__dot`);
                this.dotControlsContainer.appendChild(dot);
                this.dotControls.push(dot);
            });

            this.mapDotEvent(slider);
            this.handleStatusOfControls(slider);
        }
    }

    /**
     * Register the events for the dot controls
     *
     * @protected
     */
    private mapDotEvent(slider: KeenSlider) {
        this.dotControls.forEach((dot: HTMLElement, index: number) => {
            dot.addEventListener('click', () => {
                slider.moveToSlide(index);
            });
        });
    }

    /**
     * Remove the events for the dot controls
     *
     * @protected
     */
    private removeDotEvent(slider: KeenSlider) {
        this.dotControls.forEach((dot: HTMLElement, index: number) => {
            dot.removeEventListener('click', () => {
                slider.moveToSlide(index);
            });
        });
    }

    /**
     * Handle the status of the dot controls
     *
     * @protected
     */
    private handleStatusOfControls(slider: KeenSlider) {
        const activeSlide = slider.details().relativeSlide;

        if (window.innerWidth > this.viewportBreakpoint) {
            if (!this.dotControlsContainer.classList.contains('is-hidden')) {
                this.dotControlsContainer.classList.add('is-hidden');
            }
        } else if (this.dotControlsContainer.classList.contains('is-hidden')) {
            this.dotControlsContainer.classList.remove('is-hidden');
        }

        this.dotControls.forEach((dot: HTMLElement, index: number) => {
            if (dot.classList.contains('active') && index !== activeSlide) {
                dot.classList.remove('active');
            } else if (!dot.classList.contains('active') && index === activeSlide) {
                dot.classList.add('active');
            }
        });
    }

    /**
     * Move to slide with the given absolute position in the slider
     * @param position
     */
    public moveToSlide(position: string|number): void {
        const absolutePosition = typeof position === 'string' ? parseInt(position, 10) : position;
        this.slider.moveToSlide(absolutePosition);
    }

    /**
     * Setter for the arrow controlls
     *
     * @protected
     */
    protected setArrowControls(): void {
        this.arrowControls = <HTMLElement[]> Array.from(this.querySelectorAll(this.arrowControlsSelector));
        this.prevArrowControl = <HTMLElement> this.querySelector(`${this.arrowControlsSelector}--prev`);
        this.nextArrowControl = <HTMLElement> this.querySelector(`${this.arrowControlsSelector}--next`);

        if (typeof this.arrowControls === 'undefined' ||
            this.arrowControls === null ||
            this.arrowControls.length === 0
        ) {
            this.arrowControls = <HTMLElement[]> Array.from(document.querySelectorAll(this.arrowControlsSelector));
        }

        if (typeof this.prevArrowControl === 'undefined' || this.prevArrowControl === null) {
            this.prevArrowControl = <HTMLElement> document.querySelector(`${this.arrowControlsSelector}--prev`);
        }

        if (typeof this.nextArrowControl === 'undefined' || this.nextArrowControl === null) {
            this.nextArrowControl = <HTMLElement> document.querySelector(`${this.arrowControlsSelector}--next`);
        }
    }

    /**
     * Setter for the dot control container
     *
     * @protected
     */
    protected setDotControlContainer(): void {
        this.dotControlsContainer = <HTMLElement> this.querySelector(this.dotControlsSelector);

        if (typeof this.dotControlsContainer === 'undefined' || this.dotControlsContainer === null) {
            this.dotControlsContainer = <HTMLElement> document.querySelector(this.dotControlsSelector);
        }
    }
}
