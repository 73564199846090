import Component from 'ShopUi/models/component';
import GraphicScale from 'src/BestIt/ShopUi/components/molecules/graphic-scale/graphic-scale';
import ProductConfiguratorConfig, { ConfiguratorOption } from '../abstract-step/product-configurator-config';

export default class ExplosionGraphic extends Component {
    private container: HTMLElement;

    // eslint-disable-next-line no-undef
    private partsContainer: NodeListOf<HTMLElement>;

    private productConfiguratorConfig: ProductConfiguratorConfig;

    private scale: GraphicScale;

    private filepath: string = '';

    private stepName: string = 'technical';

    private stepLabel: string = 'Technische Konfiguration';

    private series: string = 'numinos';

    private productFamily: string = 'downlight';

    private productType: string = 'downlight';

    private options: ConfiguratorOption;

    public setProductFamily(value: string): void {
        this.productFamily = value;
    }

    public setProductType(value: string): void {
        this.productType = value;
    }

    public setSeries(value: string): void {
        this.series = value;
    }

    public setFilepath(value: string): void {
        this.filepath = value;
    }

    public setOptions(value: ConfiguratorOption): void {
        this.options = value;
    }

    // eslint-disable-next-line class-methods-use-this
    protected readyCallback() {
        /* eslint-disable no-empty-function */
    }

    public initGraphic(productFamily: string, productType: string, options: any): void {
        this.container = <HTMLElement> this.querySelector(`.${this.name}__container`);

        this.setProductFamily(productFamily);
        this.setProductType(productType);
        this.setOptions(options);

        this.productConfiguratorConfig = new ProductConfiguratorConfig();

        if (this.container.hasAttribute('data-step-name')) {
            this.stepName = this.container.getAttribute('data-step-name');
        }

        if (this.container.hasAttribute('data-step-name')) {
            this.stepLabel = this.container.getAttribute('data-step-label');
        }

        this.initScale();

        this.generateParts();
    }

    protected initScale(): void {
        this.scale = <GraphicScale>(
            this.querySelector('.graphic-scale')
        );
        this.scale.initScale();

        if (this.options.abstract_sku) {
            const ratio = this.productConfiguratorConfig.getRatio(this.options.abstract_sku);
            this.scale.setRatio(ratio);
        }

        this.scale.setDiameter(this.getDiameterFromOptions());
        this.scale.reloadScale();
    }

    protected generateParts(): void {
        const parts = this.productConfiguratorConfig.getSchema(this.productType);
        const activeParts = this.productConfiguratorConfig.getActiveParts(this.productType, this.stepName);

        let activeContainerOpened = false;
        let activePartsDiv = this.createActivePartsDiv();

        parts.forEach((partConfig) => {
            const partDiv = this.generateSinglePart(partConfig);
            partDiv.setAttribute('data-loaded', '0');

            if (activeParts.indexOf(partConfig.type) !== -1) {
                activePartsDiv.appendChild(partDiv);

                if (activeContainerOpened === false) {
                    activeContainerOpened = true;
                }
            } else {
                // Flushing activePartsDiv
                if (activeContainerOpened) {
                    this.container.appendChild(activePartsDiv);
                }
                activeContainerOpened = false;
                this.container.appendChild(partDiv);

                // Reinitialize activeParts container after appending it to Main Container
                activePartsDiv = this.createActivePartsDiv();
            }
        });

        // Flushing activePartsDiv
        if (activeContainerOpened) {
            this.container.appendChild(activePartsDiv);
        }

        // eslint-disable-next-line no-undef
        this.partsContainer = <NodeListOf<HTMLElement>> this.container.querySelectorAll(`.${this.name}__part`);
    }

    protected createActivePartsDiv(): HTMLDivElement {
        const divElement = document.createElement('div');
        divElement.classList.add(`${this.name}__parts-active`);
        const labelElement = document.createElement('label');
        labelElement.classList.add(`${this.name}__label`);
        labelElement.innerHTML = this.stepLabel;
        divElement.appendChild(labelElement);

        return divElement;
    }

    protected generateSinglePart(partConfig): HTMLDivElement {
        const activeParts = this.productConfiguratorConfig.getActiveParts(this.productType, this.stepName);
        const abstractSku = this.options.abstract_sku;

        const partDiv = document.createElement('div');
        partDiv.classList.add(`${this.name}__part`);
        partDiv.classList.add(`${this.name}__part_${partConfig.type}`);
        partDiv.setAttribute('data-type', partConfig.type);
        if (activeParts.length === 0 || activeParts.indexOf(partConfig.type) !== -1) {
            partDiv.setAttribute('data-active', '1');
        }

        if (partConfig.options) {
            partConfig.options.forEach((option) => {
                partDiv.setAttribute(`data-${option}`, this.getOption(option));
            });
        }

        if (partConfig.optionsForSku && Object.keys(partConfig.optionsForSku).includes(abstractSku)) {
            partConfig.optionsForSku[abstractSku].forEach((option) => {
                partDiv.setAttribute(`data-${option}`, this.getOption(option));
            });
        }

        partDiv.appendChild(this.generateImg(partDiv, partConfig));

        if (partConfig.lightcone) {
            partDiv.appendChild(this.generateSinglePart(partConfig.lightcone));
        }
        if (partConfig.chip) {
            partDiv.appendChild(this.generateSinglePart(partConfig.chip));
        }

        return partDiv;
    }

    protected generateImg(part: HTMLDivElement, partConfig): HTMLImageElement {
        const imgElement = document.createElement('img');
        const imgSrc = this.getImage(part);

        imgElement.setAttribute('src', imgSrc);

        imgElement.onload = function() {
            part.setAttribute('data-loaded', '1');
        };

        if (partConfig.name) {
            imgElement.setAttribute('alt', partConfig.name);
        }
        imgElement.classList.add(`${this.name}__part_image`);

        return imgElement;
    }

    public turnLightOff(): void {
        const lightcone = this.querySelector(`.${this.name}__part_lightcone`);
        if (!lightcone) {
            return;
        }
        lightcone.classList.add('is-hidden');
    }

    public turnLightOn(): void {
        const lightcone = this.querySelector(`.${this.name}__part_lightcone`);
        if (!lightcone) {
            return;
        }
        lightcone.classList.remove('is-hidden');
    }

    public change(options: {}): void {
        Object.keys(options).forEach((optionKey) => {
            this.changeSingleProperty(optionKey, options[optionKey]);
        });
    }

    protected changeSingleProperty(optionKey: string, value: string): void {
        if (this.options[optionKey]) {
            this.options[optionKey] = value;
        }

        // Change Scale if Needed
        if (optionKey === 'diameter_cm_sp_dimension' || optionKey === 'cut_out_diameter_cm_sp_dimension') {
            this.scale.setDiameter(this.getValueInMM(value));
            this.scale.reloadScale();
        }

        this.partsContainer.forEach((part: HTMLElement) => {
            if (part.hasAttribute(`data-${optionKey}`)) {
                value = value.split('.')[0];

                part.setAttribute(`data-${optionKey}`, value);

                // Preload Image Source
                this.preloadImage(part);

                // Change Scale if Needed
                if (optionKey === 'abstract_sku') {
                    this.scale.setRatio(this.productConfiguratorConfig.getRatio(value));
                    this.scale.reloadScale();
                }

                // eslint-disable-next-line consistent-this
                const self = this;
                part.setAttribute('data-changing', '1');
                setTimeout(() => {
                    self.reloadPart(part);
                    part.removeAttribute('data-changing');
                }, 1000);
            }
        });

        this.turnLightOn();
    }

    protected preloadImage(part: HTMLElement): void {
        const preloadedImage = new Image();
        preloadedImage.src = this.getImage(part);
    }

    protected setDataOptions(part: HTMLElement): void {
        // eslint-disable-next-line consistent-this
        const self = this;

        Object.keys(this.options)
            .forEach((optionKey) => {
                if (part.hasAttribute(`data-${optionKey}`) && part.getAttribute(`data-${optionKey}`) === '0') {
                    part.setAttribute(`data-${optionKey}`, self.options[optionKey]);
                }
            });
    }

    protected reloadPart(part: HTMLElement): void {
        part.querySelector('img').setAttribute('src', this.getImage(part));
    }

    protected getOption(optionName: string): string {
        if (!this.options[optionName]) {
            return '';
        }
        return this.options[optionName];
    }

    protected getImage(part: HTMLElement): string {
        const type = part.getAttribute('data-type');
        // eslint-disable-next-line consistent-this
        const self = this;
        let fileExtension = 'png';

        if (type === 'housing') {
            fileExtension = 'jpg';
        }

        let optionString = '';
        Object.keys(this.options).forEach((option) => {
            optionString += self.getOptionValue(part, option);
        });

        return `${this.filepath}/${this.productFamily}/${this.productType}/${type}${optionString}.${fileExtension}`;
    }

    // eslint-disable-next-line class-methods-use-this
    protected getOptionValue(part: HTMLElement, option: string) {
        if (!part.hasAttribute(`data-${option}`)) {
            return '';
        }

        let value = part.getAttribute(`data-${option}`);

        if (option === 'variant_wattage') {
            value = value.split('/')[0].trim();
            value = value.split('.')[0];
        }

        return `_${value}`;
    }

    protected getDiameterFromOptions(): string {
        if (!this.options) {
            return '0';
        }

        if (this.options.diameter_cm_sp_dimension) {
            return this.getValueInMM(this.options.diameter_cm_sp_dimension);
        }

        if (this.options.cut_out_diameter_cm_sp_dimension) {
            return this.getValueInMM(this.options.cut_out_diameter_cm_sp_dimension);
        }

        return '0';
    }

    // eslint-disable-next-line class-methods-use-this
    protected getValueInMM(valueinCM: string): string {
        return Math.floor(parseFloat(valueinCM) * 10).toString();
    }
}
