import Component from 'ShopUi/models/component';

export default class ScrollLock extends Component {
    private breakpoints = ['sm', 'sp', 'md', 'lg', 'xl', 'xxl', 'xxxl'];

    protected readyCallback(): void {
        this.mapEvents();
    }

    private mapEvents() {
        const triggers = Array.from(document.querySelectorAll(this.triggerSelector));

        triggers.map(trigger => trigger.addEventListener('click', () => {
            const element = document.querySelector(this.targetSelector);
            element.classList.toggle('overflow-hidden');
            if (this.unsetFromBreakpoint && window.innerHeight >= this.minHeight) {
                element.classList.toggle(`overflow-unset-${this.unsetFromBreakpoint}`);
            }
        }));
    }

    get triggerSelector(): string {
        return this.getAttribute('trigger-selector');
    }

    get targetSelector(): string {
        return this.getAttribute('target-selector') || 'body';
    }

    get unsetFromBreakpoint(): string {
        const unsetFromBreakpoint = this.getAttribute('unset-from-breakpoint');
        return this.breakpoints.includes(unsetFromBreakpoint) ? unsetFromBreakpoint : null;
    }

    get minHeight(): Number {
        return parseInt(this.getAttribute('min-height') || '0', 10);
    }
}
