/* eslint camelcase: "warn" */
/* eslint-disable max-classes-per-file */
/* eslint-disable no-use-before-define */

export class PageMeta {
    event: string;

    country: string;

    language: string;

    pageType: string;

    breadcrumb: string;

    contentId: string;

    environment: string;

    loginStatus: string;

    trafficType: string;

    searchResults: string;

    showMyPrices: string;

    customerType: string;

    customerCountryArea: string;

    currentCartValueEuro: string;

    currentWatchListValueEuro: string;

    userId: string;
}

export class GaEvent {
    event: string;

    eventCategory: string;

    eventAction: string;

    eventLabel: string;

    eventValue: string | undefined;

    userInfo: string | undefined;

    loginStatus: string | undefined;

    constructor(options: any) {
        this.event = 'gaEvent';
        this.eventCategory = options.eventCategory;
        this.eventAction = options.eventAction;
        this.eventLabel = options.eventLabel;
        this.eventValue = options.eventValue;
        this.userInfo = options.userInfo;
        this.loginStatus = options.loginStatus;
    }
}

export class EnhancedEcommerce {
    event: string;

    eventTimeout: number;

    eventCallback: Object;

    ecommerce: Ecommerce;

    cartType: string | undefined;

    // eslint-disable-next-line max-params
    constructor(event: string, ecommerce: any, callback?: Function, cartType?: string) {
        this.event = event;
        if (typeof callback === 'function') {
            this.eventCallback = callback;
            this.eventTimeout = 2000;
        }
        if (typeof cartType === 'string') {
            this.cartType = cartType;
        }
        this.ecommerce = ecommerce;
    }
}

export class EnhancedEcommerceExtended extends EnhancedEcommerce {
    eventCategory: string;

    eventAction: string;

    eventLabel: string;

    eventValue: string | undefined;

    // eslint-disable-next-line max-params
    constructor(event: string, eventData: any, ecommerce: any, callback?: Function, cartType?: string) {
        super(event, ecommerce, callback, cartType);
        this.eventCategory = eventData.eventCategory;
        this.eventAction = eventData.eventAction;
        this.eventLabel = eventData.eventLabel;
        this.eventValue = eventData.eventValue;
    }
}

export class PromotionView implements Ecommerce {
    promoView: PromoView | undefined;

    constructor(promoView: PromoView) {
        this.promoView = promoView;
    }
}

// eslint-disable-next-line no-unused-vars
interface PromotionClick extends Ecommerce {
    promoClick: PromoClick | undefined;
}

export class ProductImpression implements Ecommerce {
    currencyCode: string;

    impressions: Impression[];

    constructor(currencyCode: string, impressions: Impression[]) {
        this.currencyCode = currencyCode;
        this.impressions = impressions;
    }
}

export class ProductClick implements Ecommerce {
    currencyCode: string;

    click: Detail;

    constructor(currencyCode: string, click: Detail) {
        this.currencyCode = currencyCode;
        this.click = click;
    }
}

export class ProductDetail implements Ecommerce {
    currencyCode: string;

    detail: Detail;

    constructor(currencyCode: string, detail: Detail) {
        this.currencyCode = currencyCode;
        this.detail = detail;
    }
}

export class AddToCart implements Ecommerce {
    currencyCode: string;

    add: ProductCollection;

    constructor(currencyCode: string, add: ProductCollection) {
        this.currencyCode = currencyCode;
        this.add = add;
    }
}

export class RemoveFromCart implements Ecommerce {
    currencyCode: string;

    remove: ProductCollection;

    constructor(currencyCode: string, remove: ProductCollection) {
        this.currencyCode = currencyCode;
        this.remove = remove;
    }
}

export class CheckoutOption implements Ecommerce {
    // eslint-disable-next-line camelcase
    checkout_option: CheckoutOptionField;

    constructor(checkoutOption: CheckoutOptionField) {
        // eslint-disable-next-line camelcase
        this.checkout_option = checkoutOption;
    }
}

export class CheckoutOptionField {
    actionField: ActionField;

    constructor(actionField: ActionField) {
        this.actionField = actionField;
    }
}

export class Checkout implements Ecommerce {
    currencyCode: string;

    checkout: CheckoutStep;

    constructor(currencyCode: string, checkout: CheckoutStep) {
        this.currencyCode = currencyCode;
        this.checkout = checkout;
    }
}

export class Purchase implements Ecommerce {
    currencyCode: string;

    purchase: Order;

    constructor(currencyCode: string, purchase: Order) {
        this.currencyCode = currencyCode;
        this.purchase = purchase;
    }
}

// eslint-disable-next-line no-unused-vars
interface Refund extends Ecommerce {
    refund: RefundItem;
}

interface Ecommerce { }

export class Promotion {
    id: string;

    name: string;

    creative: string;

    position: string;

    constructor(options: any) {
        this.id = options.id;
        this.name = options.name;
        this.creative = options.creative;
        this.position = options.position;
    }
}

export class PromoView {
    promotions: Promotion[];

    constructor(promotions: Promotion[]) {
        this.promotions = promotions;
    }
}

interface PromoClick {
    promotions: Promotion[];
}

export class Product {
    name: string;

    id: string;

    price: string;

    brand: string;

    category: string;

    variant: string;

    position: number | undefined;

    dimension17: string;

    dimension18: string | undefined;

    dimension19: string;

    constructor(options: any) {
        this.name = options.name;
        this.id = options.id;
        if (typeof options.price !== 'undefined' && options.price !== null) {
            this.price = options.price;
        } else {
            this.price = undefined;
        }
        if (typeof options.brand !== 'undefined' && options.brand !== null) {
            this.brand = options.brand;
        } else {
            this.brand = undefined;
        }
        this.category = options.category;
        this.variant = options.variant;
        if (typeof options.position !== 'undefined' && options.position !== null) {
            this.position = options.position;
        }
        this.dimension17 = options.dimension17;
        if (typeof options.dimension18 !== 'undefined' && options.dimension18 !== null) {
            this.dimension18 = options.dimension18;
        } else {
            this.dimension18 = undefined;
        }
        this.dimension19 = options.dimension19;
    }
}

export class CartProduct extends Product {
    quantity: number | undefined;

    constructor(options: any) {
        super(options);
        if (typeof options.quantity !== 'undefined' && options.quantity !== null) {
            this.quantity = options.quantity;
        } else {
            this.quantity = undefined;
        }
    }

    public setQuantity(quantity: number) {
        this.quantity = quantity;
    }
}

export class ProductCollection {
    actionField: ActionField | undefined;

    products: Product[];

    constructor(actionField: ActionField, products: Product[]) {
        if (actionField !== undefined) {
            this.actionField = actionField;
        }
        this.products = products;
    }
}

export class Impression extends Product {
    list: string;

    constructor(options: any) {
        super(options);
        this.list = options.list;
    }
}

export class ActionField {
    list: string | undefined;

    step: string | undefined;

    option: string | undefined;

    id: string | undefined;

    affiliation: string | undefined;

    revenue: string | undefined;

    tax: string | undefined;

    shipping: string | undefined;

    coupon: string | undefined;
}

export class Detail {
    actionField: ActionField | undefined;

    products: Product[];

    constructor(actionField: ActionField, products: Product[]) {
        this.actionField = actionField;
        this.products = products;
    }
}

export class CheckoutStep {
    actionField: ActionField;

    products: Product[];

    constructor(actionField: ActionField, products: Product[]) {
        this.actionField = actionField;
        this.products = products;
    }
}

export class Order {
    actionField: ActionField;

    products: OrderItem[];

    constructor(actionField: ActionField, products: OrderItem[]) {
        this.actionField = actionField;
        this.products = products;
    }
}

export class OrderItem extends CartProduct {
    coupon: string | undefined;

    constructor(options: any) {
        super(options);
        this.coupon = options.coupon;
        if (typeof options.coupon !== 'undefined' && options.coupon !== null) {
            this.coupon = options.coupon;
        } else {
            this.coupon = undefined;
        }
    }
}

interface RefundItem {
    actionField: ActionField;
    products: RefundProductItem[];
}

interface RefundProductItem {
    id: string;
    quantity: number;
}
