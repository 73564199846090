import AbstractGa4CustomEventWithDataMapper
    from 'GA4EventTracking/types/event-mappers/abstract-ga4-custom-event-with-data-mapper';
import CustomSubtypeInterface from 'GA4EventTracking/types/events/custom-subtypes/custom-subtype-interface';
import NewsletterSubscribeFail, {
    NewsletterSubscribeFailDataInterface
} from 'GA4EventTracking/types/events/custom-subtypes/newsletter-subscribe-fail';

export default class Ga4CustomNewsletterSubscribeFailEventMapper extends AbstractGa4CustomEventWithDataMapper {
    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(data: NewsletterSubscribeFailDataInterface): CustomSubtypeInterface {
        return new NewsletterSubscribeFail(data);
    }
}
