import Component from 'ShopUi/models/component';
import AutocompleteForm, { Events as AutocompleteEvents }
    from 'src/BestIt/ShopUi/components/molecules/autocomplete-form/autocomplete-form';
import AjaxProvider from 'ShopUi/components/molecules/ajax-provider/ajax-provider';
import debounce from 'lodash-es/debounce';
import QuantityInputSelect from 'src/BestIt/ShopUi/components/molecules/quantity-input-select/quantity-input-select';
import Icon from 'src/BestIt/ShopUi/components/atoms/icon/icon';
import { Iconly } from '@best-it/iconly';
import SvgIcon from 'src/BestIt/ShopUi/components/atoms/svg-icon/svg-icon';

const ERROR_MESSAGE_CLASS = 'quick-order-row__error--show';
const ERROR_PARTIAL_MESSAGE_CLASS = 'quick-order-row-partial__error--show';

export default class QuickOrderRow extends Component {
    ajaxProvider: AjaxProvider;

    autocompleteInput: AutocompleteForm;

    quantityInput: QuantityInputSelect;

    errorMessage: HTMLElement;

    timer: number;

    timeout: number = 3000;

    incrementButton: HTMLButtonElement;

    decrementButton: HTMLButtonElement;

    protected readyCallback(): void {
        this.initialize();
    }

    public initialize(): void {
        this.ajaxProvider = <AjaxProvider> this.querySelector(`.${this.jsName}__provider`);
        this.autocompleteInput = <AutocompleteForm> this.querySelector(this.autocompleteFormSelector);
        this.registerQuantityInput();
        this.mapEvents();
    }

    protected registerQuantityInput(): void {
        this.quantityInput = <QuantityInputSelect> this.querySelector(
            `.${this.jsName}__quantity, .${this.jsName}-partial__quantity`,
        );
        this.errorMessage = <HTMLElement> this.querySelector(
            `.${this.name}__error, .${this.name}-partial__error`,
        );
    }

    protected mapEvents(): void {
        this.autocompleteInput.addEventListener(AutocompleteEvents.SET, () => this.onAutocompleteSet());
        this.autocompleteInput.addEventListener(AutocompleteEvents.UNSET, () => this.onAutocompleteUnset());
        this.mapQuantityInputChange();
    }

    protected mapQuantityInputChange(): void {
        this.quantityInput.addEventListener('change', debounce(
            () => this.onQuantityChange(),
            this.autocompleteInput.debounceDelay,
        ));
    }

    protected onAutocompleteSet(): void {
        this.reloadField(this.autocompleteInput.inputValue);
    }

    protected onAutocompleteUnset(): void {
        this.reloadField();
    }

    protected onQuantityChange(): void {
        this.reloadField(this.autocompleteInput.inputValue);
    }

    protected hideErrorMessage(): void {
        if (!this.errorMessage) {
            return;
        }

        this.errorMessage.classList.remove(ERROR_MESSAGE_CLASS, ERROR_PARTIAL_MESSAGE_CLASS);
    }

    // eslint-disable-next-line class-methods-use-this
    protected initInHouseIcon(container: HTMLElement): void {
        const inHouseIcon = <Icon> container.querySelector(
            '.quick-order-row-partial__data-holder .icon--inhouse-production'
        );
        const svgIconHandler = <SvgIcon> document.querySelector('.svg-icon');
        if (typeof inHouseIcon !== 'undefined' &&
            inHouseIcon !== null &&
            typeof svgIconHandler !== 'undefined' &&
            svgIconHandler !== null
        ) {
            const { iconElementSelector } = svgIconHandler;

            if (iconElementSelector !== '') {
                inHouseIcon.initializeCustomElement(iconElementSelector, {
                    fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
                    createIntersectionObserver: false,
                }, {}, false);

                Iconly.registerIntersectionObserver(iconElementSelector, {
                    fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
                });
            }
        }
    }

    /**
     * @public
     *
     * @return void
     */
    public initDeleteIcon(): void {
        const deleteIcon = <Icon> this.querySelector(
            '.quick-order-row__delete .icon--delete'
        );
        const svgIconHandler = <SvgIcon> document.querySelector('.svg-icon');

        if (typeof deleteIcon !== 'undefined' &&
            deleteIcon !== null &&
            typeof svgIconHandler !== 'undefined' &&
            svgIconHandler !== null
        ) {
            const { iconElementSelector } = svgIconHandler;

            if (iconElementSelector !== '') {
                deleteIcon.initializeCustomElement(iconElementSelector, {
                    fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
                    createIntersectionObserver: false,
                }, {}, false);

                Iconly.registerIntersectionObserver(iconElementSelector, {
                    fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
                });
            }
        }
    }

    async reloadField(sku: string = '') {
        this.classList.add('loading');
        clearTimeout(this.timer);
        const quantityInputValue = this.quantityValue;

        this.ajaxProvider.queryParams.set('sku', sku);
        this.ajaxProvider.queryParams.set(
            'index',
            this.ajaxProvider.getAttribute('class').split('-').pop().trim()
        );

        if (quantityInputValue > 0) {
            this.ajaxProvider.queryParams.set('quantity', `${quantityInputValue}`);
        }

        await this.ajaxProvider.fetch();
        this.registerQuantityInput();
        this.quantityInput.initialize();
        this.mapQuantityInputChange();

        this.initInHouseIcon(this);

        this.timer = <any>setTimeout(() => this.hideErrorMessage(), this.timeout);
        this.classList.remove('loading');
    }

    get quantityValue(): Number {
        return this.quantityInput.currentValue;
    }

    get autocompleteFormSelector(): string {
        return this.getAttribute('autocomplete-form');
    }

    get minQuantity(): number {
        return Number(this.quantityInput.getAttribute('min'));
    }

    get maxQuantity(): number {
        const max = Number(this.quantityInput.getAttribute('max'));
        return max > 0 && max > this.minQuantity ? max : Infinity;
    }

    get step(): number {
        const step = Number(this.quantityInput.getAttribute('step'));
        return step > 0 ? step : 1;
    }
}
