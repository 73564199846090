import Component from 'ShopUi/models/component';
import ModalWindow from 'src/BestIt/ShopUi/components/molecules/modal-window/modal-window';
import KeenSliderCarousel from 'src/BestIt/ShopUi/components/molecules/keen-slider-carousel/keen-slider-carousel';

export default class EnergyLabel extends Component {
    /**
     * The modal window which contains the EEC label slider
     *
     * @public
     */
    public modalWindow: ModalWindow;

    /**
     * The slider for the EEC labels
     *
     * @protected
     */
    protected labelSlider: KeenSliderCarousel;

    /**
     * @inheritDoc
     *
     * @protected
     */
    protected readyCallback(): void {
        this.initialize();
    }

    /**
     * Init function for the component
     *
     * @public
     */
    public initialize() {
        this.modalWindow = <ModalWindow> document.querySelector(`.${this.name}__modal-window-${this.randomId}`);
        if (typeof this.modalWindow !== 'undefined' && this.modalWindow !== null) {
            this.labelSlider = <KeenSliderCarousel> this.modalWindow.querySelector(
                `.keen-slider-carousel-${this.randomId}`,
            );

            if (typeof this.labelSlider !== 'undefined' && this.labelSlider !== null) {
                this.mapEvents();
            }
        }
    }

    /**
     * The event registration function for this component
     *
     * @private
     */
    private mapEvents() {
        this.modalWindow.addEventListener('opened', () => this.reinitSlider());
        window.addEventListener('resize', () => this.reinitSlider());
    }

    /**
     * The event handling function to reint the slider on modal open and window resize
     * @private
     */
    private reinitSlider() {
        this.labelSlider.sliderReinit();
    }

    /**
     * Getter function to get the random id for the component
     */
    get randomId(): string {
        return this.dataset.key || '';
    }
}
