import DefaultAbstractStep
    from 'AbstractProductConfigurator/components/organisms/default-abstract-step/default-abstract-step';
import ExplosionGraphic from '../explosion-graphic/explosion-graphic';
import RoomView from '../room-view/room-view';
import ProductConfiguratorConfig from './product-configurator-config';

export default abstract class AbstractStep extends DefaultAbstractStep {
    protected explosionGraphic: ExplosionGraphic;

    protected roomView: RoomView;

    protected productConfiguratorConfig: ProductConfiguratorConfig;

    /* eslint-disable no-invalid-this */
    protected cssSelectors: any = {
        container: `.${this.jsName}[data-product-type]`,
        nextStepButton: `.${this.name}__next-step-button`,
        priceContainer: `.${this.name}__price-container`,
        filterBox: '.attribute-filter',
        filterValues: '.attribute-filter__values',
        hiddenField: '.attribute-filter-hidden',
        filterOption: '.attribute-filter-option',
        validationMessage: '.attribute-filter__validation-message',
        selectedFilterOption: '.attribute-filter__selected',
        abstractSkuFilter: '.attribute-filter-option[data-abstract_sku]',
        concreteSkuFilter: '.attribute-filter-option[data-concrete_sku]',
        opticalFrontring: '.attribute-filter-option[data-optical_frontring]',
        diffusor: '.attribute-filter-option[data-sku_accessory_diffusor]',
        productBox: '.product-accessory-box',
        tabElement: `.${this.jsName}__tab-element`,
        tabContainerElement: `.${this.jsName}__tab-container`,
        tabListContainer: `.${this.jsName}__tab-list-container`,
        currentStepName: '.js-product-configurator__step',
        active: '.active',
        header: '.header',
    };
    /* eslint-enable no-invalid-this */

    protected productType: string = 'downlight';

    protected productFamily: string = 'numinos';

    protected abstractSku: string;

    protected options: any;

    protected initialize(): void {
        this.setHeader();
        this.setContainer();
        this.setFilters();
        this.setHiddenFields();
        this.setForm();

        this.initConfiguration();
        this.initNextButton();
        this.initProductProperties();
        this.initTabAreas();

        this.initOptions();
        this.initFilters();

        this.initExplosionGraphic();
        this.initRoomView();

        this.initStepFunctionality();

        this.initGoogleTagEvents();
        this.initTempParams();
        this.fireEvent();

        if (this.getShowSelectedOnLoad()) {
            this.updateDependencies(this.options);
        }
    }

    protected initConfiguration(): void {
        this.productConfiguratorConfig = new ProductConfiguratorConfig();
    }

    protected validateStep(): boolean {
        const errors = [];
        const requiredFields = this.productConfiguratorConfig.getRequiredFields(
            this.productType,
            this.getCurrentStep(),
            this.abstractSku,
        );

        this.hideAllValidationMessages();

        this.hiddenFields.forEach((hiddenField: HTMLElement) => {
            const name = hiddenField.getAttribute('name');
            let isFieldInvalid = false;

            if (requiredFields.includes(name)) {
                if (!hiddenField.hasAttribute('value')) {
                    errors.push(name);
                    this.resetTabsOnMobile();
                    this.showValidationMessage(name);
                    isFieldInvalid = true;
                }
                if (hiddenField.getAttribute('value') === '') {
                    errors.push(name);
                    this.resetTabsOnMobile();
                    this.showValidationMessage(name);
                    isFieldInvalid = true;
                }
                if (hiddenField.getAttribute('value') === 'null') {
                    errors.push(name);
                    this.resetTabsOnMobile();
                    this.showValidationMessage(name);
                    isFieldInvalid = true;
                }

                if (isFieldInvalid === true) {
                    this.invalidFields.push(hiddenField);
                }
            }
        });

        return errors.length === 0;
    }

    protected getShowSelectedOnLoad(): boolean {
        const stepContainer = <HTMLElement> document.querySelector(
            this.cssSelectors.currentStepName,
        );

        return stepContainer.getAttribute('data-show-selected-on-load') === '1';
    }

    protected hideAllValidationMessages(): void {
        const validationMessages = this.querySelectorAll(this.cssSelectors.validationMessage);

        validationMessages.forEach((validationMessage: HTMLElement) => {
            validationMessage.classList.add(this.cssClasses.hidden);
        });
    }

    protected showValidationMessage(errorField: string): void {
        const validationMessage = this.querySelector(`[data-validation-${errorField}]`);
        validationMessage.classList.remove(this.cssClasses.hidden);
    }

    protected initProductProperties(): void {
        this.setAbstractSku();
        this.setProductType();
        this.setProductFamily();

        if (this.isNuminosDownlight()) {
            this.optionsWithSpecialEvent = ['abstract_sku'];
        }
    }

    protected setAbstractSku(): void {
        this.abstractSku = this.getAbstractSku();
    }

    protected getAbstractSku(): string {
        let abstractSku = this.getAbstractSkuFromHiddenField();

        if (!abstractSku) {
            abstractSku = this.getAbstractSkuFromContainer();
        }

        return abstractSku;
    }

    protected getAbstractSkuFromHiddenField(): string {
        const hiddenField = this.querySelector('input.attribute-filter-hidden[name=abstract_sku]');

        if (!hiddenField || !hiddenField.hasAttribute('value')) {
            return null;
        }

        if (hiddenField.getAttribute('value') === '') {
            return null;
        }

        return hiddenField.getAttribute('value');
    }

    protected getAbstractSkuFromContainer(): string {
        if (this.container.hasAttribute('data-abstract_sku')) {
            return this.container.getAttribute('data-abstract_sku');
        }
        return null;
    }

    protected setProductType(): void {
        this.productType = this.container.getAttribute('data-product-type');
    }

    protected setProductFamily(): void {
        this.productFamily = this.container.getAttribute('data-product-family');
    }

    protected initOptions(): void {
        this.options = this.productConfiguratorConfig.getOptions(this.productType, this.abstractSku);

        this.initOptionsFromTransferObject();
        this.initOptionsFromQueryParams();
        this.initOptionsFromContainer();
    }

    protected initOptionsFromContainer(): void {
        Object.keys(this.options).forEach((optionKey) => {
            const selectedFilter = <HTMLElement> this.container.querySelector(
                `.attribute-filter__selected[data-${optionKey}]`,
            );

            if (typeof selectedFilter !== 'undefined' && selectedFilter !== null) {
                this.options[optionKey] = selectedFilter.getAttribute(`data-${optionKey}`);
            }
        });
    }

    protected initOptionsFromTransferObject(): void {
        Object.keys(this.options).forEach((optionKey) => {
            const viewData = this.getViewData(optionKey);
            if (typeof viewData !== 'undefined' && viewData !== null) {
                this.options[optionKey] = viewData;
            }
        });
    }

    protected initOptionsFromQueryParams(): void {
        const queryParams = new URLSearchParams(document.location.search);

        Object.keys(this.options).forEach((optionKey) => {
            if (queryParams.get(`${optionKey}`) !== null) {
                this.options[optionKey] = queryParams.get(`${optionKey}`);
            }
        });
    }

    protected initFilters(): void {
        // eslint-disable-next-line consistent-this
        const self = this;

        if (this.filters.length === 0) {
            return;
        }

        this.filters.forEach((filter: HTMLElement) => {
            self.registerFilterButtonEvent(filter);
        });
    }

    protected registerFilterButtonEvent(filter: HTMLElement): void {
        // eslint-disable-next-line consistent-this
        const self = this;

        // eslint-disable-next-line no-undef
        const options = <NodeListOf<HTMLElement>> filter.querySelectorAll(this.cssSelectors.filterOption);

        options.forEach((option: HTMLElement) => {
            option.addEventListener('click', (event) => {
                event.preventDefault();
                self.setSelected(option, options);
                self.changeValues(option);
            });
        });
    }

    protected changeValues(filterOption: HTMLElement): void {
        const changingOptions = [];
        let updateDependencies = true;

        Object.keys(this.options).forEach((optionKey) => {
            if (filterOption.hasAttribute(`data-${optionKey}`)) {
                const newValue = filterOption.getAttribute(`data-${optionKey}`);
                changingOptions[optionKey] = newValue;
                this.options[optionKey] = newValue;

                if (this.optionsWithSpecialEvent.includes(optionKey)) {
                    updateDependencies = false;
                }
            }
        });

        if (updateDependencies) {
            this.updateDependencies(changingOptions);
        }
    }

    protected updateDependencies(changingOptions): void {
        this.changeHiddenFields(changingOptions);
        this.explosionGraphic.change(changingOptions);
        this.roomView.change(changingOptions);
    }

    // eslint-disable-next-line class-methods-use-this
    protected changeHiddenFields(changingOptions): void {
        Object.keys(changingOptions).forEach((optionKey) => {
            const hiddenField = document.querySelector(`input.attribute-filter-hidden[name=${optionKey}]`);

            if (typeof hiddenField !== 'undefined' && hiddenField !== null) {
                hiddenField.setAttribute('value', changingOptions[optionKey]);
            }
        });
    }

    protected showSelectedProductBox(option: HTMLElement) {
        this.hideAllProductBoxesInFilterGroup(option);

        const sku = option.getAttribute('data-sku');
        const productBoxes = option.closest(this.cssSelectors.filterBox).querySelectorAll(this.cssSelectors.productBox);

        productBoxes.forEach((productBox: HTMLElement) => {
            if (productBox.getAttribute('data-sku') === sku) {
                productBox.classList.remove(this.cssClasses.hidden);
            }
        });
    }

    protected hideAllProductBoxesInFilterGroup(option: HTMLElement) {
        const productBoxes = option.closest(this.cssSelectors.filterBox).querySelectorAll(this.cssSelectors.productBox);

        if (productBoxes === null || productBoxes.length === 0) {
            return;
        }

        productBoxes.forEach((productBox) => {
            productBox.classList.add(this.cssClasses.hidden);
        });
    }

    protected initExplosionGraphic(): void {
        this.explosionGraphic = <ExplosionGraphic>(
            document.querySelector('.explosion-graphic')
        );

        this.explosionGraphic.initGraphic(this.productFamily, this.productType, this.options);
    }

    protected initRoomView(): void {
        this.roomView = <RoomView>(
            document.querySelector('.room-view')
        );

        this.roomView.initGraphic(this.productFamily, this.productType, this.options);
    }

    protected isNuminosDownlight(): boolean {
        return this.productFamily === 'numinos' && this.productType === 'downlight';
    }

    protected getCurrency(selector: string): string {
        const currencyContainer: Element = this.querySelector(selector);

        if (!currencyContainer) {
            return '';
        }

        const currencyRegex = '[^0-9., ]+';
        const match = currencyContainer.innerHTML.match(currencyRegex);

        return match ? match[0] : '';
    }

    protected getPriceOfSingleAccessory(accessoryFilter: HTMLElement): number {
        const accessoryOption = accessoryFilter.querySelector(this.cssSelectors.selectedFilterOption);

        if (!accessoryOption || !accessoryOption.hasAttribute('data-price')) {
            return 0;
        }

        if (accessoryOption.getAttribute('data-price') === '') {
            return 0;
        }

        return parseInt(accessoryOption.getAttribute('data-price'), 10);
    }
}
