import Component from 'ShopUi/models/component';

export default class PackagingUnitQuantitySelector extends Component {
    qtyInSalesUnitInput: HTMLInputElement;

    qtyInBaseUnitInput: HTMLInputElement;

    measurementUnitInput: HTMLSelectElement;

    addToCartButton: HTMLButtonElement;

    leadSalesUnitSelect: HTMLSelectElement;

    baseUnit: any;

    salesUnits: any;

    currentSalesUnit: any;

    productQuantityStorage: any;

    currentValue: Number;

    translations: any;

    leadSalesUnits: any;

    productPackagingUnitStorage: any;

    amountInSalesUnitInput: any;

    amountDefaultInBaseUnitInput: any;

    packagingUnitAmountInput: any;

    itemBasePriceInput: any;

    itemMoneySymbolInput: any;

    amountInBaseUnitInput: any;

    isAmountBlockEnabled: boolean;

    isAddToCartDisabled: boolean;

    currentLeadSalesUnit: any;

    defaultAmount: any;

    productPackagingNewPriceBlock: any;

    productPackagingNewPriceValueBlock: any;

    quantityBetweenElement: HTMLDivElement;

    quantityMinElement: HTMLDivElement;

    quantityMaxElement: HTMLDivElement;

    muChoiceNotificationElement: HTMLDivElement;

    muBetweenNotificationElement: HTMLDivElement;

    muMinNotificationElement: HTMLDivElement;

    muMaxNotificationElement: HTMLDivElement;

    muTranslationsElement: HTMLScriptElement;

    muChoiceListElement: HTMLUListElement;

    muCurrentChoiceElement: HTMLSpanElement;

    puChoiceElement: HTMLDivElement;

    puMinNotificationElement: HTMLDivElement;

    puMaxNotificationElement: HTMLDivElement;

    puIntervalNotificationElement: HTMLDivElement;

    puChoiceListElement: HTMLUListElement;

    puCurrentChoiceElement: HTMLSpanElement;

    muError: boolean;

    puError: boolean;

    // eslint-disable-next-line no-unused-vars
    protected readyCallback(event?: Event): void {
        this.qtyInSalesUnitInput = <HTMLInputElement>document.querySelector('#sales-unit-quantity');
        this.qtyInBaseUnitInput = <HTMLInputElement>document.querySelector('#base-unit-quantity');
        this.measurementUnitInput = <HTMLSelectElement>document.querySelector('.select-measurement-unit');
        this.addToCartButton = <HTMLButtonElement>document.getElementById('add-to-cart-button');
        this.leadSalesUnitSelect = <HTMLSelectElement>document.querySelector('.select-lead-measurement-unit');
        this.amountInSalesUnitInput = <HTMLInputElement>document.querySelector('#user-amount');
        this.amountDefaultInBaseUnitInput = <HTMLInputElement>document.querySelector('#default-amount');
        this.amountInBaseUnitInput = <HTMLInputElement>document.querySelector('#amount');
        this.packagingUnitAmountInput = <HTMLInputElement>document.querySelector('#packaging-unit-amount');
        this.productPackagingNewPriceBlock = <HTMLInputElement>document.querySelector(
            '#product-packaging-new-price-block'
        );
        this.productPackagingNewPriceValueBlock = <HTMLInputElement>document.querySelector(
            '#product-packaging-new-price-value-block'
        );
        this.itemBasePriceInput = <HTMLInputElement>document.querySelector('#item-base-price');
        this.itemMoneySymbolInput = <HTMLInputElement>document.querySelector('#item-money-symbol');
        this.quantityBetweenElement = <HTMLDivElement>document.getElementById('quantity-between-units');
        this.quantityMinElement = <HTMLDivElement>document.getElementById('minimum-quantity');
        this.quantityMaxElement = <HTMLDivElement>document.getElementById('maximum-quantity');
        this.muChoiceNotificationElement = <HTMLDivElement>document.querySelector('.measurement-unit-choice');
        this.muBetweenNotificationElement = <HTMLDivElement>document.getElementById('quantity-between-units');
        this.muMinNotificationElement = <HTMLDivElement>document.getElementById('minimum-quantity');
        this.muMaxNotificationElement = <HTMLDivElement>document.getElementById('maximum-quantity');
        this.muTranslationsElement = <HTMLScriptElement>document.getElementById('measurement-unit-translation');
        this.muChoiceListElement = <HTMLUListElement>document.querySelector('#measurement-unit-choices .list');
        this.muCurrentChoiceElement = <HTMLSpanElement>document.querySelector(
            '.measurement-unit-choice #current-choice'
        );
        this.puChoiceElement = <HTMLDivElement>document.querySelector('.packaging-unit-choice');
        this.puMinNotificationElement = <HTMLDivElement>document.getElementById('packaging-amount-min');
        this.puMaxNotificationElement = <HTMLDivElement>document.getElementById('packaging-amount-max');
        this.puIntervalNotificationElement = <HTMLDivElement>document.getElementById('packaging-amount-interval');
        this.puChoiceListElement = <HTMLUListElement>document.querySelector('#packaging-unit-choices .list');
        this.puCurrentChoiceElement = <HTMLSpanElement>document.querySelector(
            '.packaging-unit-choice #amount-current-choice'
        );
        this.puError = false;
        this.muError = false;

        this.initJson();
        this.initTranslations();
        this.initCurrentSalesUnit();
        this.initCurrentLeadSalesUnit();
        this.initFormDefaultValues();
        this.mapEvents();
        if (this.amountInBaseUnitInput) {
            this.amountInputChange();
        }
    }

    private initJson() {
        const jsonSchemaContainer = document.getElementsByClassName(`${this.name}__json`)[0];
        if (jsonSchemaContainer.hasAttribute('json')) {
            const jsonString = jsonSchemaContainer.getAttribute('json');
            const jsonData = JSON.parse(jsonString);

            // eslint-disable-next-line no-prototype-builtins
            if (jsonData.hasOwnProperty('baseUnit')) {
                this.baseUnit = jsonData.baseUnit;
            }

            // eslint-disable-next-line no-prototype-builtins
            if (jsonData.hasOwnProperty('salesUnits')) {
                this.salesUnits = jsonData.salesUnits;
            }

            // eslint-disable-next-line no-prototype-builtins
            if (jsonData.hasOwnProperty('leadSalesUnits')) {
                this.leadSalesUnits = jsonData.leadSalesUnits;
            }

            // eslint-disable-next-line no-prototype-builtins
            if (jsonData.hasOwnProperty('isAmountBlockEnabled')) {
                this.isAmountBlockEnabled = jsonData.isAmountBlockEnabled;
            }

            // eslint-disable-next-line no-prototype-builtins
            if (jsonData.hasOwnProperty('isAddToCartDisabled')) {
                this.isAddToCartDisabled = jsonData.isAddToCartDisabled;
            }

            // eslint-disable-next-line no-prototype-builtins
            if (jsonData.hasOwnProperty('productPackagingUnitStorage')) {
                this.productPackagingUnitStorage = jsonData.productPackagingUnitStorage;
            }

            // eslint-disable-next-line no-prototype-builtins
            if (jsonData.hasOwnProperty('productQuantityStorage')) {
                this.productQuantityStorage = jsonData.productQuantityStorage;
            }
        }
    }

    private initFormDefaultValues() {
        if (this.amountInBaseUnitInput) {
            this.qtyInSalesUnitInput.value = this.getMinQuantity().toString();
            this.amountInSalesUnitInput.value = this.getDefaultAmount();
            this.amountDefaultInBaseUnitInput.value = this.getDefaultAmount();
            this.amountInBaseUnitInput.value = this.getDefaultAmount();
            this.leadSalesUnitSelect.value = this.currentLeadSalesUnit.id_product_measurement_sales_unit;
            this.measurementUnitInput.value = this.currentSalesUnit.id_product_measurement_sales_unit;
        }
    }

    private initTranslations() {
        this.translations = JSON.parse(this.muTranslationsElement.innerHTML);
    }

    private initCurrentSalesUnit() {
        for (const key in this.salesUnits) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.salesUnits.hasOwnProperty(key)) {
                if (this.salesUnits[key].is_default) {
                    this.currentSalesUnit = this.salesUnits[key];
                }
            }
        }
    }

    private mapEvents() {
        this.qtyInSalesUnitInput.addEventListener('input', () => this.qtyInputChange());
        this.qtyInSalesUnitInput.addEventListener('change', () => this.qtyInputChange());
        this.measurementUnitInput.addEventListener('change', (event: Event) => this.measurementUnitInputChange(event));

        if (this.isAmountBlockEnabled) {
            this.amountInSalesUnitInput.addEventListener('change', () => this.amountInputChange());
            this.leadSalesUnitSelect.addEventListener(
                'change',
                (event: Event) => this.leadSalesUnitSelectChange(event)
            );
        }
    }

    private qtyInputChange(qtyInSalesUnits?: number) {
        if (typeof qtyInSalesUnits === 'undefined') {
            qtyInSalesUnits = Number(this.qtyInSalesUnitInput.value);
        }

        this.muError = false;
        const qtyInBaseUnits = this.multiply(qtyInSalesUnits, Number(this.currentSalesUnit.conversion));

        if (qtyInBaseUnits < this.getMinQuantity()) {
            this.muError = true;
            this.hideNotifications();
            this.quantityMinElement.classList.remove('is-hidden');
        } else if ((qtyInBaseUnits - this.getMinQuantity()) % this.getQuantityInterval() !== 0) {
            this.muError = true;
            this.hideNotifications();
            this.quantityBetweenElement.classList.remove('is-hidden');
        } else if (this.getMaxQuantity() > 0 && qtyInBaseUnits > this.getMaxQuantity()) {
            this.muError = true;
            this.hideNotifications();
            this.quantityMaxElement.classList.remove('is-hidden');
        }

        if (this.muError || this.puError || this.isAddToCartDisabled) {
            this.addToCartButton.setAttribute('disabled', 'disabled');
            this.askCustomerForCorrectInput(qtyInSalesUnits);
            return;
        }

        this.qtyInBaseUnitInput.value = qtyInBaseUnits.toString();

        if (this.amountInBaseUnitInput) {
            this.amountInputChange();
        }

        this.addToCartButton.removeAttribute('disabled');
        this.hideNotifications();
    }

    private hideNotifications() {
        this.muChoiceNotificationElement.classList.add('is-hidden');
        this.muBetweenNotificationElement.classList.add('is-hidden');
        this.muMinNotificationElement.classList.add('is-hidden');
        this.muMaxNotificationElement.classList.add('is-hidden');
    }

    private askCustomerForCorrectInput(qtyInSalesUnits: number) {
        if (this.muError) {
            const minChoice = this.getMinChoice(qtyInSalesUnits);
            const maxChoice = this.getMaxChoice(qtyInSalesUnits, minChoice);

            this.muChoiceListElement.innerHTML = '';
            this.muCurrentChoiceElement.innerHTML = '';
            this.muCurrentChoiceElement.textContent = `${this.round(qtyInSalesUnits, 4)}
                ${this.getUnitName(this.currentSalesUnit.product_measurement_unit.code)}`;

            const choiceElements = [];
            choiceElements.push(this.createChoiceElement(minChoice));

            if (maxChoice !== minChoice) {
                choiceElements.push(this.createChoiceElement(maxChoice));
            }

            // eslint-disable-next-line no-extra-parens,max-len
            choiceElements.forEach(element => (element !== null ? this.muChoiceListElement.appendChild(element) : null));

            this.muChoiceNotificationElement.classList.remove('is-hidden');
        }
    }

    private createChoiceElement(qtyInBaseUnits: number) {
        if (qtyInBaseUnits > 0) {
            const choiceElem = document.createElement('span');
            const qtyInSalesUnits = this.convertBaseUnitsAmountToCurrentSalesUnitsAmount(qtyInBaseUnits);
            const measurementSalesUnitName = this.getUnitName(this.currentSalesUnit.product_measurement_unit.code);
            const measurementBaseUnitName = this.getUnitName(this.baseUnit.code);

            choiceElem.classList.add('link');
            choiceElem.setAttribute('data-base-unit-qty', qtyInBaseUnits.toString());
            choiceElem.setAttribute('data-sales-unit-qty', qtyInSalesUnits.toString());
            choiceElem.textContent = `(${this.round(qtyInSalesUnits, 4).toString().toString()}
                ${measurementSalesUnitName}) = (${qtyInBaseUnits} ${measurementBaseUnitName})`;
            choiceElem.onclick = function(event: Event) {
                const element = event.srcElement as HTMLSelectElement;
                // eslint-disable-next-line no-shadow
                const qtyInBaseUnits = parseFloat(element.dataset.baseUnitQty);
                // eslint-disable-next-line no-shadow
                const qtyInSalesUnits = parseFloat(element.dataset.salesUnitQty);
                this.muError = false;
                this.selectQty(qtyInBaseUnits, qtyInSalesUnits);
            }.bind(this);

            choiceElem.style.display = 'block';

            return choiceElem;
        }

        return null;
    }

    private selectQty(qtyInBaseUnits: number, qtyInSalesUnits: number) {
        this.qtyInBaseUnitInput.value = qtyInBaseUnits.toString();
        this.qtyInSalesUnitInput.value = this.round(qtyInSalesUnits, 4).toString().toString();
        if (!this.puError && !this.isAddToCartDisabled) {
            this.addToCartButton.removeAttribute('disabled');
        }
        this.muChoiceNotificationElement.classList.add('is-hidden');
    }

    private getMinChoice(qtyInSalesUnits: number) {
        const qtyInBaseUnits = this.floor(this.multiply(qtyInSalesUnits, this.currentSalesUnit.conversion));

        if (qtyInBaseUnits < this.getMinQuantity()) {
            return this.getMinQuantity();
        }

        if ((qtyInBaseUnits - this.getMinQuantity()) % this.getQuantityInterval() !== 0 ||
            (this.getMaxQuantity() > 0 && qtyInBaseUnits > this.getMaxQuantity())
        ) {
            return this.getMinChoice(this.convertBaseUnitsAmountToCurrentSalesUnitsAmount(qtyInBaseUnits - 1));
        }

        return qtyInBaseUnits;
    }

    private convertBaseUnitsAmountToCurrentSalesUnitsAmount(qtyInBaseUnits: number) {
        return Math.round(qtyInBaseUnits / this.currentSalesUnit.conversion * this.currentSalesUnit.precision) /
            this.currentSalesUnit.precision;
    }

    private getMaxChoice(qtyInSalesUnits: number, minChoice: number) {
        let qtyInBaseUnits = this.ceil(this.multiply(qtyInSalesUnits, this.currentSalesUnit.conversion));

        if (this.getMaxQuantity() > 0 && qtyInBaseUnits > this.getMaxQuantity()) {
            qtyInBaseUnits = this.getMaxQuantity();

            if ((qtyInBaseUnits - this.getMinQuantity()) % this.getQuantityInterval() !== 0) {
                qtyInBaseUnits -= (qtyInBaseUnits - this.getMinQuantity()) % this.getQuantityInterval();
            }

            return qtyInBaseUnits;
        }

        if ((qtyInBaseUnits - this.getMinQuantity()) % this.getQuantityInterval() !== 0) {
            return this.getMaxChoice(
                this.convertBaseUnitsAmountToCurrentSalesUnitsAmount(
                    (qtyInBaseUnits + 1) / this.currentSalesUnit.conversion
                ),
                minChoice
            );
        }

        return qtyInBaseUnits;
    }

    // eslint-disable-next-line class-methods-use-this
    private floor(value: number): number {
        if (Math.floor(value) > 0) {
            return Math.floor(value);
        }

        return Math.ceil(value);
    }

    // eslint-disable-next-line class-methods-use-this
    private ceil(value: number): number {
        return Math.ceil(value);
    }

    // eslint-disable-next-line class-methods-use-this
    private round(value: number, decimals: number): number {
        return Number(`${Math.round(parseFloat(`${value}e${decimals}`))}e-${decimals}`);
    }

    // eslint-disable-next-line class-methods-use-this
    private multiply(value1: number, value2: number): number {
        const result = ((value1 * 10) * (value2 * 10)) / 100;

        return Math.round(result * 1000) / 1000;
    }

    private getMinQuantity() {
        if (typeof this.productQuantityStorage !== 'undefined' &&
            // eslint-disable-next-line no-prototype-builtins
            this.productQuantityStorage.hasOwnProperty('quantity_min')
        ) {
            return this.productQuantityStorage.quantity_min;
        }

        return 1;
    }

    private getMaxQuantity() {
        if (typeof this.productQuantityStorage !== 'undefined' &&
            // eslint-disable-next-line no-prototype-builtins
            this.productQuantityStorage.hasOwnProperty('quantity_max') &&
            this.productQuantityStorage.quantity_max !== null
        ) {
            return this.productQuantityStorage.quantity_max;
        }

        return 0;
    }

    private getQuantityInterval() {
        if (typeof this.productQuantityStorage !== 'undefined' &&
            // eslint-disable-next-line no-prototype-builtins
            this.productQuantityStorage.hasOwnProperty('quantity_interval')
        ) {
            return this.productQuantityStorage.quantity_interval;
        }

        return 1;
    }

    private measurementUnitInputChange(event: Event) {
        // eslint-disable-next-line no-extra-parens
        const salesUnitId = parseInt((event.srcElement as HTMLSelectElement).value, 10);
        const salesUnit = this.getSalesUnitById(salesUnitId);
        let qtyInSalesUnits = Number(this.qtyInSalesUnitInput.value);
        const qtyInBaseUnits = this.multiply(qtyInSalesUnits, this.currentSalesUnit.conversion);
        this.currentSalesUnit = salesUnit;
        qtyInSalesUnits = this.convertBaseUnitsAmountToCurrentSalesUnitsAmount(qtyInBaseUnits);
        this.qtyInSalesUnitInput.value = this.round(qtyInSalesUnits, 4).toString();
        this.qtyInputChange(qtyInSalesUnits);
    }

    private getSalesUnitById(salesUnitId: number) {
        for (const key in this.salesUnits) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.salesUnits.hasOwnProperty(key)) {
                if (salesUnitId === this.salesUnits[key].id_product_measurement_sales_unit) {
                    return this.salesUnits[key];
                }
            }
        }

        return null;
    }

    private getUnitName(key) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.translations.hasOwnProperty(key)) {
            return this.translations[key];
        }

        return key;
    }

    private amountInputChange(amountInSalesUnitInput?: number) {
        if (typeof amountInSalesUnitInput === 'undefined') {
            amountInSalesUnitInput = Number(this.amountInSalesUnitInput.value);
        }
        this.productPackagingNewPriceBlock.classList.add('is-hidden');
        this.puError = false;
        let amountInBaseUnits = this.multiply(amountInSalesUnitInput, Number(this.currentLeadSalesUnit.conversion));
        amountInBaseUnits = Math.round(amountInBaseUnits);

        if ((amountInBaseUnits - this.getMinAmount()) % this.getAmountInterval() !== 0) {
            this.puError = true;
            this.puIntervalNotificationElement.classList.remove('is-hidden');
        } else if (amountInBaseUnits < this.getMinAmount()) {
            this.puError = true;
            this.puMinNotificationElement.classList.remove('is-hidden');
        } else if (this.getMaxAmount() > 0 && amountInBaseUnits > this.getMaxAmount()) {
            this.puError = true;
            this.puMaxNotificationElement.classList.remove('is-hidden');
        }

        if (this.puError || this.muError || this.isAddToCartDisabled) {
            this.askCustomerForCorrectAmountInput(amountInSalesUnitInput);
            this.addToCartButton.setAttribute('disabled', 'disabled');

            return;
        }

        const quantity = Number(this.qtyInBaseUnitInput.value);
        const totalAmount = amountInBaseUnits * quantity;

        this.amountInBaseUnitInput.value = totalAmount.toString();
        this.addToCartButton.removeAttribute('disabled');
        this.hidePackagingUnitRestrictionNotifications();

        if (this.amountDefaultInBaseUnitInput.value !== amountInBaseUnits) {
            let newPrice = (amountInBaseUnits / this.amountDefaultInBaseUnitInput.value) *
                this.itemBasePriceInput.value;
            newPrice /= 100;
            this.productPackagingNewPriceValueBlock.innerHTML = this.itemMoneySymbolInput.value + newPrice.toFixed(2);

            this.productPackagingNewPriceBlock.classList.remove('is-hidden');
        }
    }

    private askCustomerForCorrectAmountInput(amountInSalesUnits) {
        if (this.puError) {
            const minChoice = this.getMinAmountChoice(amountInSalesUnits);
            const maxChoice = this.getMaxAmountChoice(amountInSalesUnits, minChoice);

            this.puChoiceListElement.innerHTML = '';
            this.puCurrentChoiceElement.innerHTML = '';
            this.puCurrentChoiceElement.textContent = `${this.round(amountInSalesUnits, 4)}
                ${this.getUnitName(this.currentLeadSalesUnit.product_measurement_unit.code)}`;

            const choiceElements = [];

            if (minChoice) {
                choiceElements.push(this.createAmountChoiceElement(minChoice));
            }

            if (maxChoice !== minChoice) {
                choiceElements.push(this.createAmountChoiceElement(maxChoice));
            }

            // eslint-disable-next-line no-extra-parens,max-len
            choiceElements.forEach(element => (element !== null ? this.puChoiceListElement.appendChild(element) : null));

            this.puChoiceElement.classList.remove('is-hidden');
        }
    }

    private initCurrentLeadSalesUnit() {
        for (const key in this.leadSalesUnits) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.leadSalesUnits.hasOwnProperty(key)) {
                if (this.leadSalesUnits[key].is_default) {
                    this.currentLeadSalesUnit = this.leadSalesUnits[key];
                }
            }
        }
    }

    private createAmountChoiceElement(amountInBaseUnits: number) {
        if (amountInBaseUnits > 0) {
            const choiceElem = document.createElement('span');
            const amountInSalesUnits = this.convertBaseUnitsAmountToCurrentSalesUnitsAmount(amountInBaseUnits);
            const measurementSalesUnitName = this.getUnitName(this.currentLeadSalesUnit.product_measurement_unit.code);
            const measurementBaseUnitName = this.getUnitName(this.baseUnit.code);

            choiceElem.classList.add('link');
            choiceElem.setAttribute('data-base-unit-amount', amountInBaseUnits.toString());
            choiceElem.setAttribute('data-sales-unit-amount', amountInSalesUnits.toString());
            choiceElem.textContent = `(${this.round(amountInSalesUnits, 4).toString().toString()}
                ${measurementSalesUnitName}) = (${amountInBaseUnits} ${measurementBaseUnitName})`;
            choiceElem.onclick = function(event: Event) {
                const element = event.srcElement as HTMLSelectElement;
                // eslint-disable-next-line no-shadow
                const amountInBaseUnits = parseFloat(element.dataset.baseUnitAmount);
                // eslint-disable-next-line no-shadow
                const amountInSalesUnits = parseFloat(element.dataset.salesUnitAmount);
                this.puError = false;
                this.selectAmount(amountInBaseUnits, amountInSalesUnits);
            }.bind(this);

            choiceElem.style.display = 'block';

            return choiceElem;
        }

        return null;
    }

    private selectAmount(amountInBaseUnits: number, amountInSalesUnits: number) {
        this.amountInSalesUnitInput.value = amountInSalesUnits.toString();
        this.amountInBaseUnitInput.value = this.round(amountInBaseUnits, 4).toString().toString();
        if (!this.muError && !this.isAddToCartDisabled) {
            this.addToCartButton.removeAttribute('disabled');
        }
        this.puChoiceElement.classList.add('is-hidden');
        this.amountInputChange();
    }

    private leadSalesUnitSelectChange(event: Event) {
        // eslint-disable-next-line no-extra-parens
        const salesUnitId = parseInt((event.srcElement as HTMLSelectElement).value, 10);
        const salesUnit = this.getLeadSalesUnitById(salesUnitId);
        let amountInSalesUnits = Number(this.amountInSalesUnitInput.value);
        const amountInBaseUnits = this.multiply(amountInSalesUnits, this.currentLeadSalesUnit.conversion);
        amountInSalesUnits = amountInBaseUnits / salesUnit.conversion;
        this.currentLeadSalesUnit = salesUnit;
        this.amountInSalesUnitInput.value = this.round(amountInSalesUnits, 4).toString();
        this.amountInputChange(amountInSalesUnits);
    }

    private hidePackagingUnitRestrictionNotifications() {
        this.puChoiceElement.classList.add('is-hidden');
        this.puMinNotificationElement.classList.add('is-hidden');
        this.puMaxNotificationElement.classList.add('is-hidden');
        this.puIntervalNotificationElement.classList.add('is-hidden');
    }

    private getLeadSalesUnitById(salesUnitId: number) {
        for (const key in this.leadSalesUnits) {
            // eslint-disable-next-line no-prototype-builtins
            if (this.leadSalesUnits.hasOwnProperty(key)) {
                if (salesUnitId === this.leadSalesUnits[key].id_product_measurement_sales_unit) {
                    return this.leadSalesUnits[key];
                }
            }
        }

        return null;
    }

    private getMinAmount() {
        if (typeof this.productPackagingUnitStorage !== 'undefined' &&
            // eslint-disable-next-line no-prototype-builtins
            this.productPackagingUnitStorage.hasOwnProperty('amount_min') &&
            this.productPackagingUnitStorage.amount_min !== null
        ) {
            return this.productPackagingUnitStorage.amount_min;
        }

        return 1;
    }

    private getMaxAmount() {
        if (typeof this.productPackagingUnitStorage !== 'undefined' &&
            // eslint-disable-next-line no-prototype-builtins
            this.productPackagingUnitStorage.hasOwnProperty('amount_max') &&
            this.productPackagingUnitStorage.amount_max !== null
        ) {
            return this.productPackagingUnitStorage.amount_max;
        }

        return 0;
    }

    private getAmountInterval() {
        if (typeof this.productPackagingUnitStorage !== 'undefined' &&
            // eslint-disable-next-line no-prototype-builtins
            this.productPackagingUnitStorage.hasOwnProperty('amount_interval') &&
            this.productPackagingUnitStorage.amount_interval !== null
        ) {
            return this.productPackagingUnitStorage.amount_interval;
        }

        return 1;
    }

    private getDefaultAmount() {
        if (typeof this.productPackagingUnitStorage !== 'undefined' &&
            // eslint-disable-next-line no-prototype-builtins
            this.productPackagingUnitStorage.hasOwnProperty('default_amount') &&
            this.productPackagingUnitStorage.default_amount !== null
        ) {
            return this.productPackagingUnitStorage.default_amount;
        }

        return 0;
    }

    private getMinAmountChoice(amountInSalesUnits: number) {
        const amountInBaseUnits = this.floor(this.multiply(amountInSalesUnits, this.currentLeadSalesUnit.conversion));

        if (amountInBaseUnits < this.getMinAmount()) {
            return this.getMinAmount();
        }

        if (this.isAmountGreaterThanMaxAmount(amountInBaseUnits)) {
            return 0;
        }

        if (this.isAmountMultipleToInterval(amountInBaseUnits)) {
            return this.getMinAmountChoice((amountInBaseUnits - 1) / this.currentLeadSalesUnit.conversion);
        }

        return amountInBaseUnits;
    }

    private getMaxAmountChoice(amountInSalesUnits: number, minChoice: number) {
        let amountInBaseUnits = this.ceil(this.multiply(amountInSalesUnits, this.currentLeadSalesUnit.conversion));

        if (this.isAmountGreaterThanMaxAmount(amountInBaseUnits)) {
            amountInBaseUnits = this.getMaxAmount();

            if (this.isAmountMultipleToInterval(amountInBaseUnits)) {
                amountInBaseUnits -= (amountInBaseUnits - this.getMinAmount()) % this.getAmountInterval();
            }

            return amountInBaseUnits;
        }

        if (amountInBaseUnits <= minChoice) {
            return 0;
        }

        if (this.isAmountMultipleToInterval(amountInBaseUnits)) {
            return minChoice + this.getAmountInterval();
        }

        return amountInBaseUnits;
    }

    private isAmountGreaterThanMaxAmount(amountInBaseUnits: number) {
        return this.getMaxAmount() > 0 && amountInBaseUnits > this.getMaxAmount();
    }

    private isAmountMultipleToInterval(amountInBaseUnits: number) {
        return (amountInBaseUnits - this.getMinAmount()) % this.getAmountInterval() !== 0;
    }
}
