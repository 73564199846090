import CustomSubtypeInterface from './custom-subtype-interface';
import MaxLengthStringValue from '../validator-types/max-length-string-value';

/* eslint-disable camelcase */
export interface FilterDataInterface {
    filter_name: string;
    filter_element: string;
}

export default class Filter implements CustomSubtypeInterface {
    filter_name: string;
    filter_element: string;

    constructor(data: FilterDataInterface) {
        this.filter_name = new MaxLengthStringValue(data.filter_name).value;
        this.filter_element = new MaxLengthStringValue(data.filter_element).value;
    }
}
/* eslint-enable camelcase */
