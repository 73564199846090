import CartType from '../ecommerce-subtypes/cart-type';
import Ga4EcommerceEvent from './ga4-ecommerce-event';
import Ga4EcommerceEventWithProductDataInterface from '../ga4-ecommerce-event-with-product-data-interface';

/* eslint-disable camelcase */
export interface Ga4EcommerceAddToCartEventDataInterface extends Ga4EcommerceEventWithProductDataInterface{
    cart_type: CartType;
    add_to_cart_type: 'PDP'|'FastCalc'|'Quick Add'|'SRP'|'Product Category Page'|'Shopping List';
    product_selection: 'Whole Watch List'|'Selected Articles';
}
export default class Ga4EcommerceAddToCartEvent extends Ga4EcommerceEvent {
    cart_type: CartType;
    add_to_cart_type: 'PDP'|'FastCalc'|'Quick Add'|'SRP'|'Product Category Page'|'Shopping List';
    product_selection: 'Whole Watch List'|'Selected Articles';

    constructor(data: Ga4EcommerceAddToCartEventDataInterface) {
        super(data);

        this.cart_type = data.cart_type;
        this.add_to_cart_type = data.add_to_cart_type;
        this.product_selection = data.product_selection;
    }
}
/* eslint-enable camelcase */
