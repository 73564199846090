import AjaxProvider from 'src/BestIt/ShopUi/components/molecules/ajax-provider/ajax-provider';
import AbstractStep from '../abstract-step/abstract-step';
import ConfiguratorProductItem from '../../molecules/configurator-product-item/configurator-product-item';
import SelectedProductItem from '../../molecules/selected-product-item/selected-product-item';
import { mount } from 'ShopUi/app';

export default class DefaultProductSelectionStep extends AbstractStep {
    private configuratorProductItems: ConfiguratorProductItem[];

    private ajaxProviderAdd: AjaxProvider;

    private ajaxProviderRemove: AjaxProvider;

    private selectedProductList: HTMLElement;

    private selectedProductContainer: HTMLElement;

    private selectedProductCounter: HTMLElement;

    /**
     * Init function for the component
     *
     * @protected
     */
    protected initialize() {
        this.setItemCount();
        super.initialize();
        this.setAjaxProvider();
        this.setConfiguratorProductItems();
        this.setSelectedProductList();
        this.registerConfiguratorProductItemEvent();
    }

    /**
     * Setter for the next step buttons
     *
     * @protected
     */
    protected setNextButton(): void {
        // eslint-disable-next-line no-undef
        this.nextStepButtons = <NodeListOf<HTMLElement>> this.querySelectorAll(this.cssSelectors.nextStepButton);

        if (this.itemCount > 0 || this.isOptionalStep) {
            this.nextStepButtons.forEach((nextStepButton) => {
                nextStepButton.removeAttribute('disabled');
            });
        }
    }

    /**
     * Setter for the configurator product items of the product list
     *
     * @private
     */
    private async setConfiguratorProductItems() {
        this.configuratorProductItems = <ConfiguratorProductItem[]> Array.from(
            this.querySelectorAll('.configurator-product-item'),
        );

        await mount();

        this.configuratorProductItems.forEach((listItem: ConfiguratorProductItem) => {
            listItem.quantitySelect.markAsMounted();
        });
    }

    /**
     * Registration of the global events addProduct, removeProduct and filterChanged
     *
     * @private
     */
    private registerConfiguratorProductItemEvent() {
        document.addEventListener('addProduct', (event: CustomEvent) => {
            const details = event.detail;
            this.addConfiguratorItems(details.sku, details.step, details.quantity);

            if (!details.isUpdate) {
                this.itemCount++;

                if (this.itemCount > 0 && !this.isOptionalStep) {
                    this.nextStepButtons.forEach((nextStepButton) => {
                        nextStepButton.removeAttribute('disabled');
                    });
                }
            }
        });

        document.addEventListener('removeProduct', (event: CustomEvent) => {
            const details = event.detail;

            this.removeConfiguratorItems(details.sku);
            this.itemCount--;

            if (this.itemCount <= 0 && !this.isOptionalStep) {
                this.nextStepButtons.forEach((nextStepButton) => {
                    nextStepButton.setAttribute('disabled', 'disabled');
                });
            }
        });

        document.addEventListener('filterChanged', () => this.reinitProductList());
    }

    /**
     * Setter for the ajax provider to add and remove products
     *
     * @private
     */
    private setAjaxProvider() {
        this.ajaxProviderAdd = this.querySelector(`.${this.jsName}__provider--add`);
        this.ajaxProviderRemove = this.querySelector(`.${this.jsName}__provider--remove`);
    }

    /**
     * The event handler for the addProduct event
     *
     * @param sku
     * @param step
     * @param quantity
     *
     * @private
     */
    private async addConfiguratorItems(sku: string, step: string, quantity: number) {
        const configuratorProductItem = <ConfiguratorProductItem> this.querySelector(
            `.configurator-product-item[data-sku="${sku}"]`,
        );

        if (typeof configuratorProductItem !== 'undefined' && configuratorProductItem !== null) {
            configuratorProductItem.classList.add('loading');
        }

        this.ajaxProviderAdd.queryParams.set('sku', sku);
        this.ajaxProviderAdd.queryParams.set('step', step);
        this.ajaxProviderAdd.queryParams.set('quantity', quantity.toString());
        const response = await this.ajaxProviderAdd.fetch();

        const productItem = <SelectedProductItem> this.selectedProductContainer.querySelector(
            `.selected-product-item[data-sku="${sku}"]`,
        );
        if (productItem !== null) {
            this.selectedProductContainer.removeChild(productItem);
        }

        this.selectedProductContainer.insertAdjacentHTML('beforeend', response);
        await mount();

        if (typeof this.selectedProductCounter !== 'undefined' && this.selectedProductCounter !== null) {
            this.selectedProductCounter.innerText = this.selectedProductContainer.children.length.toString();
        }
        if (this.selectedProductList.classList.contains('is-hidden')) {
            this.selectedProductList.classList.remove(this.cssClasses.hidden);
        }

        if (typeof configuratorProductItem !== 'undefined' && configuratorProductItem !== null) {
            configuratorProductItem.classList.remove('loading');
        }

        this.itemCount = this.itemCount + 1;
    }

    /**
     * The event handler for the removeProduct event
     *
     * @param sku
     *
     * @private
     */
    private async removeConfiguratorItems(sku: string) {
        const configuratorProductItem = <ConfiguratorProductItem> this.querySelector(
            `.configurator-product-item[data-sku="${sku}"]`,
        );

        if (typeof configuratorProductItem !== 'undefined' && configuratorProductItem !== null) {
            configuratorProductItem.classList.add('loading');
        }

        this.ajaxProviderRemove.queryParams.set('sku', sku);
        await this.ajaxProviderRemove.fetch();

        const productItem = <SelectedProductItem> this.selectedProductContainer.querySelector(
            `.selected-product-item[data-sku="${sku}"]`,
        );
        if (typeof productItem !== 'undefined' && productItem !== null) {
            productItem.removeEvents();
            this.selectedProductContainer.removeChild(productItem);
        }

        if (typeof this.selectedProductCounter !== 'undefined' && this.selectedProductCounter !== null) {
            this.selectedProductCounter.innerText = this.selectedProductContainer.children.length.toString();
        }
        if (this.selectedProductContainer.children.length === 0) {
            this.selectedProductList.classList.add(this.cssClasses.hidden);
        }

        if (typeof configuratorProductItem !== 'undefined' && configuratorProductItem !== null) {
            configuratorProductItem.resetConfiguratorProductItem();
            configuratorProductItem.classList.remove('loading');
        }

        this.itemCount = this.itemCount - 1;
    }

    /**
     * Setter for the selected product item count
     * @private
     */
    private setItemCount() {
        this.itemCount = this.selectedItemCount;
    }

    /**
     * Getter to verify if the step is optional or mandatory
     */
    get isOptionalStep() {
        return this.dataset.optional === 'true';
    }

    /**
     * Setter for selected product list box elements
     *
     * @private
     */
    private setSelectedProductList() {
        this.selectedProductList = this.querySelector(
            '.js-selected-product-list',
        );

        this.selectedProductContainer = this.selectedProductList.querySelector(
            '.js-selected-product-list__products',
        );

        this.selectedProductCounter = this.selectedProductList.querySelector(
            '.js-selected-product-list__counter',
        );
    }

    private async reinitProductList() {
        await mount();
        this.setConfiguratorProductItems();
        this.setSelectedProductList();
    }
}
