import Component from 'ShopUi/models/component';

export default class GraphicScale extends Component {
    private scaleContainer: HTMLElement;

    private rulerContainer: HTMLElement;

    private labelContainer: HTMLElement;

    private diameter: number;

    private ratio: number;

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    public initScale(): void {
        this.scaleContainer = <HTMLElement> document.querySelector(`.${this.name}__container`);
        this.rulerContainer = <HTMLElement> this.scaleContainer.querySelector(`.${this.name}__ruler`);
        this.labelContainer = <HTMLElement> this.scaleContainer.querySelector(`.${this.name}__value`);

        this.createRuler();
    }

    public reloadScale(): void {
        this.drawRuler();
    }

    public setDiameter(diameter: string): void {
        this.scaleContainer.setAttribute('data-diameter', diameter);
        this.diameter = parseInt(diameter, 10);
    }

    public setRatio(ratio: string): void {
        this.scaleContainer.setAttribute('data-ratio', ratio);
        this.ratio = parseFloat(ratio);
    }

    private drawRuler(): void {
        this.deleteRuler();
        this.createRuler();
    }

    private getDiameter(): number {
        if (!this.scaleContainer) {
            return 0;
        }
        if (!this.scaleContainer.hasAttribute('data-diameter')) {
            return 0;
        }

        return parseInt(this.scaleContainer.getAttribute('data-diameter'), 10);
    }

    private getRatio(): number {
        if (!this.scaleContainer) {
            return 0;
        }
        if (!this.scaleContainer.hasAttribute('data-ratio')) {
            return 0;
        }

        return parseInt(this.scaleContainer.getAttribute('data-ratio'), 10);
    }

    private getOffset(): number {
        return 100 - this.getRatio();
    }

    private getLeftOffset(): number {
        return this.getOffset() / 2;
    }

    private getRightOffset(): number {
        return 100 - (this.getOffset() / 2);
    }

    private deleteRuler(): void {
        this.rulerContainer.innerHTML = '';
    }

    private createRuler(): void {
        // Label
        this.setLabel();
        // Baseline
        this.createRulerBaseElement();
        // Start
        this.createRulerElement(this.getLeftOffset());
        // End
        this.createRulerElement(this.getRightOffset());
        // Steps
        this.createRulerStepsElements();
    }

    private setLabel(): void {
        if (!this.labelContainer) {
            return;
        }
        // TODO: Replace mm with a given Unit
        this.labelContainer.innerHTML = `${this.getDiameter()}mm`;
    }

    private createRulerBaseElement(): void {
        const divContainer = document.createElement('div');
        divContainer.classList.add(`${this.name}__line-on-ruler-base`);
        divContainer.style.left = `${this.getLeftOffset()}%`;
        divContainer.style.width = `${this.getRatio()}%`;
        this.rulerContainer.appendChild(divContainer);
    }

    private createRulerElement(position: number, size = 'big'): void {
        const divContainer = document.createElement('div');
        divContainer.classList.add(`${this.name}__line-on-ruler-${size}`);
        divContainer.style.left = `${position}%`;

        const labelContainer = document.createElement('label');
        divContainer.appendChild(labelContainer);
        this.rulerContainer.appendChild(divContainer);
    }

    private createRulerStepsElements(): void {
        const stepCount = Math.floor(this.getDiameter() / 10);
        const stepWidth = this.getRatio() / this.getDiameter() * 10;

        for (let step = 1; step <= stepCount; step++) {
            let size = 'small';

            if (step % 5 === 0) {
                size = 'big';
            }

            // ToDo: check calculation to set the parentheses on the right position
            // eslint-disable-next-line no-mixed-operators
            this.createRulerElement(this.getLeftOffset() + step * stepWidth, size);
        }
    }
}
