import EcommerceItemInterface from './ecommerce-item-interface';
import IntegerValue from '../validator-types/integer-value';
import NumberValue from '../validator-types/number-value';

/* eslint-disable camelcase */
export interface ProductDataInterface extends EcommerceItemInterface {
    item_name: string;
    item_id: string;
    affiliation: string|null;
    coupon: string|null;
    currency: string|null;
    discount: number|null;
    item_brand: string|null;
    item_category: string|null;
    item_category2: string|null;
    item_category3: string|null;
    item_category4: string|null;
    item_category5: string|null;
    item_variant: string|null;
    item_list_name: string|null;
    item_list_id: string|null;
    index: number|null;
    location_id: string|null;
    quantity: number|null;
    price: number|null;
}

export default class Product implements EcommerceItemInterface {
    item_name: string;
    item_id: string;
    affiliation: string|null;
    coupon: string|null;
    currency: string|null;
    discount: number|null;
    item_brand: string|null;
    item_category: string|null;
    item_category2: string|null;
    item_category3: string|null;
    item_category4: string|null;
    item_category5: string|null;
    item_variant: string|null;
    item_list_name: string|null;
    item_list_id: string|null;
    index: number|null;
    location_id: string|null;
    quantity: number|null;
    price: number|null;

    constructor(data: ProductDataInterface) {
        this.item_name = data.item_name;
        this.item_id = data.item_id;
        this.affiliation = data.affiliation;
        this.coupon = data.coupon;
        this.currency = data.currency;
        this.discount = data.discount;
        this.item_brand = data.item_brand;
        this.item_category = data.item_category;
        this.item_category2 = data.item_category2;
        this.item_category3 = data.item_category3;
        this.item_category4 = data.item_category4;
        this.item_category5 = data.item_category5;
        this.item_variant = data.item_variant;
        this.item_list_name = data.item_list_name;
        this.item_list_id = data.item_list_id;
        this.index = data.index === null ? null : new IntegerValue(data.index).value;
        this.location_id = data.location_id;
        this.quantity = data.quantity === null ? null : new IntegerValue(data.quantity).value;
        this.price = data.price === null ? null : new NumberValue(data.price).value;
    }
}
/* eslint-enable camelcase */
