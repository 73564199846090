import Ecommerce from '../ecommerce-subtypes/ecommerce';
import Ga4EcommerceEvent from './ga4-ecommerce-event';
import Product from '../ecommerce-subtypes/product';
import Ga4EcommerceEventWithProductDataInterface from '../ga4-ecommerce-event-with-product-data-interface';

/* eslint-disable camelcase */
interface Ga4EcommerceSelectItemEventDataInterface extends Ga4EcommerceEventWithProductDataInterface{
    click_location: string;
}
export default class Ga4EcommerceSelectItemEvent extends Ga4EcommerceEvent {
    ecommerce: Ecommerce<Product>;
    click_location: string;

    constructor(data: Ga4EcommerceSelectItemEventDataInterface) {
        super(data);

        this.ecommerce = data.ecommerce;
        this.click_location = data.click_location;
    }
}
/* eslint-enable camelcase */
