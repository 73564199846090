import Component from 'ShopUi/models/component';
import { CustomElement } from '@best-it/iconly/src/classes/custom-element';
import { Iconly } from '@best-it/iconly';

export default class SvgIcon extends Component {
    protected readyCallback(): void {
        this.initialize();
    }

    public initialize(): void {
        if (this.iconElementSelector !== '') {
            const icons = <CustomElement[]>Array.from(document.querySelectorAll(this.iconElementSelector));

            if (typeof icons !== 'undefined' && icons !== null) {
                icons.forEach((icon) => {
                    icon.initializeCustomElement(this.iconElementSelector, {
                        fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
                        createIntersectionObserver: false,
                    }, {}, false);
                });
            }

            Iconly.registerIntersectionObserver(this.iconElementSelector, {
                fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
            });
        }
    }

    get iconElementSelector(): string {
        return this.dataset.iconElementSelector || '';
    }
}
