import EventDataTransfer from '../event-data-transfer';
import Ga4EventInterface from '../events/ga4-event-interface';
import Ga4EventMapperInterface from './ga4-event-mapper-interface';

export default class AbstractGa4EventMapper implements Ga4EventMapperInterface {
    // eslint-disable-next-line class-methods-use-this
    public map(transfer: EventDataTransfer): Ga4EventInterface {
        return {
            event: transfer.event,
        };
    }
}
