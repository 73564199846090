import Component from 'ShopUi/models/component';
import axios from 'axios';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import ModalWindow from '../modal-window/modal-window';
import LoadingIndicator from '../loading-indicator/loading-indicator';
import { mount } from 'ShopUi/app';

export default class AjaxModalWindow extends Component {
    private modalWindow : ModalWindow;

    private contentContainer : HTMLElement;

    private loadingIndicator: LoadingIndicator;

    private googleEventTagHandler: GoogleTagEvents;

    private loaded: boolean = false;

    public isInitialized: boolean = false;

    protected readyCallback(): void {
        this.initialize();
    }

    public initialize(): void {
        this.modalWindow = this.querySelector('modal-window');
        this.contentContainer = this.querySelector(`.${this.name}__content-container`);
        this.loadingIndicator = this.querySelector('loading-indicator');
        this.googleEventTagHandler = document.querySelector('.google-tag-events');

        if (this.modalWindow) {
            this.modalWindow.addEventListener('opened', this.handleModalOpened.bind(this));
        }

        this.isInitialized = true;
    }

    private async handleModalOpened() {
        if (!this.loaded && this.contentContainer) {
            this.contentContainer.classList.remove(`${this.name}__content-container--has-error`);
            this.loadingIndicator.openLoadingIndicator();
            if (this.dataset.url && this.contentContainer) {
                try {
                    const result = await axios.get(this.dataset.url);
                    this.contentContainer.innerHTML = result.data;
                    await mount();
                    this.loaded = true;
                } catch (error) {
                    this.contentContainer.classList.add(`${this.name}__content-container--has-error`);
                    this.contentContainer.innerHTML = this.dataset.genericErrorMessage;
                } finally {
                    if (this.googleEventTagHandler !== null) {
                        this.googleEventTagHandler.mapEvents(
                            Array.from(
                                this.contentContainer.querySelectorAll(
                                    '[data-event-name][data-event-data]:not([data-event-type="impression"])',
                                ),
                            ),
                        );
                    }

                    this.loadingIndicator.closeLoadingIndicator();
                }
            }
        }
    }
}
