import Component from 'ShopUi/models/component';
import $ from 'jquery/dist/jquery';
import 'slick-carousel';

export default class ImageMap extends Component {
    /**
     * The image map image
     *
     * @private
     */
    private imageMapImage;

    /**
     * The circle areas in the image map which should get the mouseover event
     *
     * @private
     */
    private imageMapCircleAreas;

    /**
     * The rect areas in the image map which should get the mouseover event
     *
     * @private
     */
    private imageMapRectAreas;

    /**
     * The polygon areas in the image map which should get the mouseover event
     *
     * @private
     */
    private imageMapPolygonAreas;

    /**
     * The tooltips to the image map areas
     *
     * @private
     */
    private imageMapAreaTooltips = [];

    /**
     * The target image in the mobile Image Map that should adjust its position
     *
     * @private
     */
    private imageMapTargetImage: HTMLElement;

    /**
     * The container for the slides in the mobile view
     *
     * @private
     */
    private imageMapSlideContainer: HTMLElement;

    /**
     * The slides for the mobile view
     *
     * @private
     */
    // eslint-disable-next-line no-undef
    private imageMapSlides: NodeListOf<HTMLElement>;

    /**
     * The area of the currently opened tooltip
     *
     * @protected
     */
    protected openArea: SVGGraphicsElement = null;

    /**
     * The height of the headline including margin, padding and border
     *
     * @protected
     */
    protected headlineHeight: number;

    /**
     * Base function for the components
     *
     * @protected
     */
    protected init(): void {
        // The setTimeout is needed here, else the browser will not be able to initialize more than 5 image-maps
        setTimeout(() => {
            this.imageMapImage = <HTMLElement> this.querySelector(`.${this.name}__container`);
            this.imageMapCircleAreas = <HTMLElement[]> Array.from(this.querySelectorAll(`.${this.name}-area--circle`));
            this.imageMapRectAreas = <HTMLElement[]> Array.from(this.querySelectorAll(`.${this.name}-area--rect`));
            this.imageMapPolygonAreas = <HTMLElement[]> Array.from(
                this.querySelectorAll(`.${this.name}-area--polygon`)
            );

            this.imageMapTargetImage = <HTMLElement> this.querySelector(`.${this.name}-slider__target-image-container`);
            this.imageMapSlideContainer = <HTMLElement> this.querySelector(`.${this.name}-slider__slides`);
            // eslint-disable-next-line no-undef
            this.imageMapSlides = <NodeListOf<HTMLElement>> this.querySelectorAll(`.${this.name}-slider__slide`);

            this.headlineHeight = <number> this.getAbsoluteElHeight(
                this.querySelector(`.${this.jsName}__headlines .headline`)
            );

            this.initSlickSlider();

            this.mapEvents();
        });
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Mapper for the events
     *
     * @private
     */
    private mapEvents(): void {
        this.imageMapCircleAreas.forEach((area) => {
            this.addAreaEvent(area);
        });

        this.imageMapRectAreas.forEach((area) => {
            this.addAreaEvent(area);
        });

        this.imageMapPolygonAreas.forEach((area) => {
            this.addAreaEvent(area);
        });

        // eslint-disable-next-line max-params
        $(this.imageMapSlideContainer).on('beforeChange', (event, slick, currentSlide, nextSlide) => {
            const nextSlideElement = <HTMLElement> slick.$slides[nextSlide];
            const slide = <HTMLElement> nextSlideElement.querySelector('.image-map-slider__slide');
            const coordinates = <string> slide.getAttribute('data-coordinates');
            const areaType = <string> slide.getAttribute('data-area-type');

            this.setTargetImageCenter(coordinates, areaType);
        });
    }

    /**
     * Mapper for the image map area events
     *
     * @private
     */
    protected addAreaEvent(area) {
        const openEvent = area.dataset.eventOpen || 'click';
        const closeEvent = area.dataset.eventClose || null;
        const closeButton = <HTMLElement> document.querySelector(
            `.image-map-area-tooltip[data-area-selector="#${area.id}"] .icon-button`,
        );

        if (typeof closeButton !== 'undefined' && closeButton !== null) {
            closeButton.addEventListener('click', (event: Event) => this.closeTooltipOnButtonClick(event));
        }

        area.addEventListener(openEvent, () => this.openTooltip(area));

        if (closeEvent !== null) {
            area.addEventListener(closeEvent, () => this.closeTooltip(area));
        }
    }

    /**
     * Init function for the slick slider
     *
     * @private
     */
    private initSlickSlider() {
        $(this.imageMapSlideContainer).slick({
            slidesToShow: 1,
            autoplay: true,
            autoplaySpeed: 10000,
            dots: true,
            infinite: true,
            arrows: false,
            adaptiveHeight: true,
        });

        const slide = <HTMLElement> this.querySelector('.image-map-slider__slide-1');

        if (typeof slide !== 'undefined' && slide !== null) {
            const coordinates = <string>slide.getAttribute('data-coordinates');
            const areaType = <string>slide.getAttribute('data-area-type');

            this.setTargetImageCenter(coordinates, areaType);
        }
    }

    /**
     * The event handler function for the open event on the areas
     *
     * @param area The event target
     *
     * @private
     */
    private openTooltip(area: SVGGraphicsElement): void {
        const areaId = <string> area.getAttribute('id');
        const tooltip = <HTMLElement> this.getTooltipToArea(areaId);
        const tooltipMarginTop = <number> parseInt(
            window.getComputedStyle(tooltip).getPropertyValue('margin-top').replace('px', ''),
            10
        );
        const tooltipAfterElHeight = <number> parseInt(
            window.getComputedStyle(tooltip, ':after').height.replace('px', ''),
            10
        );
        const areaHeight = <number> area.getBoundingClientRect().height;
        const tooltipPointerMarginTop = <number> (tooltipMarginTop + areaHeight) / 2;

        if (this.openArea !== null) {
            this.closeTooltip(this.openArea);
        }

        if (typeof tooltip !== 'undefined' && tooltip !== null) {
            const tooltipPositionX = this.getTooltipLeftPosition(area);
            const tooltipPositionY = this.getTooltipTopPosition(area);

            if (tooltipPositionX < 50) {
                tooltip.style.left = `${tooltipPositionX}%`;
                tooltip.classList.add('has-left-pointer');
            } else {
                tooltip.style.right = `${100 - tooltipPositionX}%`;
                tooltip.classList.add('has-right-pointer');
            }

            if (tooltipPositionY < 66) {
                tooltip.style.top = `calc(${tooltipPositionY}% + ${this.headlineHeight}px)`;
                tooltip.classList.add('has-down-pointer');
                tooltip.style.setProperty('--down-pointer-margin', `-${tooltipPointerMarginTop}px`);
                tooltip.style.setProperty(
                    '--down-pointer-translate-y',
                    `-${tooltipPointerMarginTop + (tooltipAfterElHeight / 2)}px`
                );
            } else {
                tooltip.style.bottom = `calc(${100 - tooltipPositionY}% + ${area.getBoundingClientRect().height}px` +
                    ` - ${this.headlineHeight}px)`;
                tooltip.classList.add('has-up-pointer');
                tooltip.style.setProperty(
                    '--up-pointer-margin',
                    `${tooltipPointerMarginTop + tooltipMarginTop}px`
                );
                tooltip.style.setProperty(
                    '--up-pointer-translate-y',
                    `${tooltipPointerMarginTop + tooltipMarginTop + (tooltipAfterElHeight / 2)}px`
                );
            }

            tooltip.classList.remove('is-hidden');

            this.openArea = area;
        }
    }

    /**
     * The event handler function for the close event on the areas
     *
     * @param area The event target
     *
     * @private
     */
    private closeTooltip(area: SVGGraphicsElement): void {
        const areaId = area.getAttribute('id');
        const tooltip = this.getTooltipToArea(areaId);

        if (typeof tooltip !== 'undefined' && tooltip !== null) {
            tooltip.classList.add('is-hidden');
            this.openArea = null;
        }
    }

    /**
     * The event handler function for the close event on the areas
     *
     * @param area The event target
     * @param event The event object
     *
     * @private
     */
    private closeTooltipOnButtonClick(event: Event): void {
        let closeButton = <HTMLElement> event.target;
        let areaSelector = '';

        if (!closeButton.getAttribute('data-area-to-close-selector')) {
            do {
                closeButton = closeButton.parentElement;
            } while (!closeButton.getAttribute('data-area-to-close-selector'));

            areaSelector = closeButton.getAttribute('data-area-to-close-selector');
        }

        const areaToClose = <SVGGraphicsElement> document.querySelector(areaSelector);

        if (areaToClose !== null) {
            this.closeTooltip(areaToClose);
        }
    }

    /**
     * Getter for the tooltips to the given area
     *
     * @private
     */
    private getTooltipToArea(areaId: string): HTMLElement {
        if (!(areaId in this.imageMapAreaTooltips)) {
            this.imageMapAreaTooltips[areaId] = <HTMLElement> this.querySelector(
                `.image-map-area-tooltip[data-area-selector='#${areaId}']`,
            );
        }

        return this.imageMapAreaTooltips[areaId];
    }

    /**
     * Getter for the left positon of the tooltip
     *
     * @param area
     *
     * @private
     */
    private getTooltipLeftPosition(area: SVGGraphicsElement): number {
        const viewWidth = parseFloat(this.imageMapImage.getAttribute('data-view_width'));
        let positionLeft = ((area.getBBox().x.valueOf() + (area.getBBox().width.valueOf() / 2)) / viewWidth) * 100;

        if (positionLeft < 0) {
            positionLeft = 0;
        }

        return positionLeft;
    }

    /**
     * Getter for the top positon of the tooltip
     *
     * @param area
     *
     * @private
     */
    private getTooltipTopPosition(area: any): number {
        const viewHeight = parseFloat(this.imageMapImage.getAttribute('data-view_height')) + this.headlineHeight;
        const positionTop = ((area.getBBox().y.valueOf() + area.getBoundingClientRect().height) / viewHeight) * 100;

        return positionTop;
    }

    /**
     * Getter for the center positon of the tooltip
     *
     *
     * @private
     * @param imageMapAreaCoordinates
     * @param imageMapAreaType
     */
    private setTargetImageCenter(imageMapAreaCoordinates: string, imageMapAreaType: string) {
        const coordinateArray = imageMapAreaCoordinates.split(',');
        let minX = 0;
        let minY = 0;
        let maxX = 0;
        let maxY = 0;
        let width = 0;
        let height = 0;
        let centerX = 0;
        let centerY = 0;

        switch (imageMapAreaType) {
            case 'circle':
                centerX = parseFloat(coordinateArray[0]);
                centerY = parseFloat(coordinateArray[1]);
                break;
            case 'rect':
                minX = parseFloat(coordinateArray[0]);
                minY = parseFloat(coordinateArray[1]);
                maxX = parseFloat(coordinateArray[2]);
                maxY = parseFloat(coordinateArray[3]);
                width = maxX - minX;
                height = maxY - minY;
                centerX = minX + (width / 2);
                centerY = minY + (height / 2);
                break;
            case 'polygon':
                centerX = parseFloat(coordinateArray[0]);
                centerY = parseFloat(coordinateArray[1]);
                break;
            default:
                break;
        }

        const pointer = <HTMLElement> this.imageMapTargetImage.querySelector('.pointer');
        pointer.style.left = `${centerX}px`;
        pointer.style.top = `${centerY}px`;

        const targetImageWidth = window.outerWidth;

        let offsetLeft = centerX - (targetImageWidth / 2);

        if (offsetLeft < 0) {
            offsetLeft = 0;
        }

        if ((offsetLeft + targetImageWidth) > this.imageMapTargetImage.offsetWidth) {
            offsetLeft = this.imageMapTargetImage.offsetWidth - targetImageWidth;
        }

        this.imageMapTargetImage.style.left = `-${offsetLeft.toString()}px`;
    }

    /**
     * Get the height for an element including margin, padding and border
     *
     * @private
     *
     * @param el HTMLElement
     */
    // eslint-disable-next-line class-methods-use-this
    private getAbsoluteElHeight(el: HTMLElement) {
        if (typeof el === 'undefined' || el === null) {
            return 0;
        }

        // Get the DOM Node if you pass in a string
        el = typeof el === 'string' ? document.querySelector(el) : el;

        const styles = window.getComputedStyle(el);
        const margin = parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);

        return Math.ceil(el.offsetHeight + margin);
    }
}
