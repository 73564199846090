import Component from 'ShopUi/models/component';
import Vue from 'vue';
import VueDatepicker from 'vuejs-datepicker';
import {
    en, de, it, cs, fr, nbNO, nl, ptBR, slSI,
} from 'vuejs-datepicker/dist/locale';
import { CombinedVueInstance } from 'vue/types/vue';
import { format } from 'date-fns';

interface VmData {
    minimumDate: Date,
    language: string
}

export default class DatePicker extends Component {
    private vueComponent: CombinedVueInstance<Vue, VmData, any, any, any>;

    protected init() {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    public initialize() {
        const { earliestAvailabilityDate, selectedDate } = this.dataset;

        const minimumDate = earliestAvailabilityDate ? new Date(earliestAvailabilityDate) : new Date();

        this.vueComponent = new Vue({
            el: this,
            data: {
                date: selectedDate !== format(Date.now(), 'YYYY-MM-DD') ? selectedDate : null,
                minimumDate,
                language: this.getLanguage(),
                component: this,
            },
            computed: {
                isoDate() {
                    if (this.date) {
                        const date = format(this.date, 'YYYY-MM-DD');
                        this.component.dispatchChangeEvent(date);
                        return date;
                    }

                    return null;
                },
                disabledDates() {
                    return {
                        // eslint-disable-next-line id-length
                        to: this.minimumDate,
                        days: [0],
                    };
                },
            },
            methods: {
                showCalendar() {
                    this.$refs.datePicker.showCalendar();
                },
            },
            mounted() {
                const calendar = this.$el.querySelector('.vdp-datepicker__calendar');
                this.$el.appendChild(calendar);
                const overlayBlock = <HTMLElement> document.querySelector('overlay-block');

                this.$refs.datePicker.$watch('isOpen', (isOpen) => {
                    if (isOpen) {
                        overlayBlock.style.opacity = '1';
                        overlayBlock.style.visibility = 'visible';
                    } else {
                        overlayBlock.style.opacity = '0';
                        overlayBlock.style.visibility = 'hidden';
                    }
                });
            },
            components: { VueDatepicker },
        });

        this.mapEvents();
    }

    private getLanguage() {
        /* eslint-disable id-length */
        const languages = {
            en,
            de,
            fr,
            nl,
            it,
            pt: ptBR,
            cs,
            nb: nbNO,
            sl: slSI,
        };
        /* eslint-enable id-length */

        const language = this.getAttribute('data-lang');

        return languages[language] || en;
    }

    private mapEvents() {
        this.addEventListener('changeMinimumDate', (event : CustomEvent) => {
            this.vueComponent.minimumDate = event.detail.date;
            if (this.vueComponent.date && this.vueComponent.date < this.vueComponent.minimumDate) {
                this.vueComponent.date = this.vueComponent.minimumDate;
            }
        });
    }

    public dispatchChangeEvent(date) {
        this.dispatchEvent(new CustomEvent('changeDate', { detail: { date } }));
    }
}
