import Component from 'ShopUi/models/component';
import ZoomOverlay from '../../molecules/zoom-overlay/zoom-overlay';

export default class ImageTextTeaserGrid extends Component {
    /**
     * The images of the grid slider
     *
     * @protected
     */
    protected images: HTMLElement[];

    /**
     * The overlay in which opens after a click on the gallery
     * @protected
     */
    protected overlay: ZoomOverlay;

    /**
     * Base function for the components
     *
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Init function for the component
     *
     * @public
     */
    public initialize() {
        if (this.activateZoom) {
            this.images = <HTMLElement[]> Array.from(this.querySelectorAll('.teaser-box__image'));
            this.overlay = <ZoomOverlay> this.querySelector(`.${this.name}__overlay`);

            this.mapEvents();
        }
    }

    /**
     * Adds the events for the images and the overlay
     *
     * @protected
     */
    protected mapEvents(): void {
        this.images.forEach((image: HTMLElement) => {
            image.addEventListener('click', this.openSlideOnSelectedPosition.bind(this, image));
        });
    }

    /**
     * Handler to open the overlay and initialize the slider if not already done
     * The slider will start on the image position which got clicked
     *
     * @param image
     *
     * @protected
     */
    protected openSlideOnSelectedPosition(image: HTMLElement): void {
        const position = image.dataset.imagePosition || '0';
        this.overlay.classList.remove('is-hidden');
        this.overlay.openSlideOnSelectedPosition(position);
    }

    /**
     * Get the activateZoom dataset
     */
    get activateZoom(): boolean {
        return this.dataset.activateZoom === 'true';
    }
}
