import ModalWindow from 'src/BestIt/ShopUi/components/molecules/modal-window/modal-window';
import DefaultProductSelectionStep from '../default-product-selection-step/default-product-selection-step';

export default class BaseTrackStep extends DefaultProductSelectionStep {
    /**
     * The modal window with the compatibility note
     *
     * @var ModalWindow
     *
     * @protected
     */
    protected baseTrackWindow: ModalWindow;

    /**
     * The checkbox to deactivate the compatibility note modal window for the current session and tab
     *
     * @protected
     */
    protected deactivateModalWindowCheckbox: HTMLInputElement;

    /**
     * Init function for the component
     *
     * @protected
     */
    protected initialize(): void {
        super.initialize();
    }

    /**
     * Init functionality for this concrete step
     *
     * @protected
     */
    protected initStepFunctionality(): void {
        this.registerBaseTrackModalWindowEvents();
        this.registerBaseTrackModalWindowDeactivationEvents();
    }

    /**
     * Get value of the session storage variable showBaseTrackModalWindow
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected getShowBaseTrackModalWindowSessionValue(): string {
        return sessionStorage.getItem('showBaseTrackModalWindow');
    }

    /**
     * Set value of the session storage variable showBaseTrackModalWindow
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected setShowBaseTrackModalWindowSessionValue(value: string): void {
        sessionStorage.setItem('showBaseTrackModalWindow', value);
    }

    /**
     * Get value of the session storage variable baseTrackModalWindowDeactivated
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected getShowBaseTrackModalWindowDeactivatedSessionValue(): string {
        return sessionStorage.getItem('baseTrackModalWindowDeactivated');
    }

    /**
     * Set value of the session storage variable baseTrackModalWindowDeactivated
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected setShowBaseTrackModalWindowDeactivatedSessionValue(value: string): void {
        sessionStorage.setItem('baseTrackModalWindowDeactivated', value);
    }

    /**
     * Sets the modal window variable and set the session storage value to true, to display the modal on the first visit
     * of the step
     *
     * @protected
     */
    protected setBaseTrackModalWindow(): void {
        this.baseTrackWindow = <ModalWindow> document.querySelector('.modal-window--base-track');

        if (typeof this.baseTrackWindow !== 'undefined' &&
            this.baseTrackWindow !== null &&
            this.getShowBaseTrackModalWindowSessionValue() === null &&
            this.getShowBaseTrackModalWindowDeactivatedSessionValue() !== 'true'
        ) {
            this.setShowBaseTrackModalWindowSessionValue('true');
        }
    }

    /**
     * Register the events which are connected to the modal window
     *
     * @protected
     */
    protected registerBaseTrackModalWindowEvents(): void {
        window.addEventListener('load', () => {
            this.setBaseTrackModalWindow();

            if (typeof this.baseTrackWindow !== 'undefined' && this.baseTrackWindow !== null) {
                this.baseTrackWindow.addEventListener('closed', () => {
                    this.setShowBaseTrackModalWindowSessionValue('false');
                });

                if (this.getShowBaseTrackModalWindowSessionValue() === 'true' &&
                    this.getShowBaseTrackModalWindowDeactivatedSessionValue() !== 'true'
                ) {
                    this.baseTrackWindow.openModal();
                }
            }
        });
    }

    private registerBaseTrackModalWindowDeactivationEvents(): void {
        this.deactivateModalWindowCheckbox = <HTMLInputElement> document.querySelector(
            `.${this.name}__deactivate-modal input[type=checkbox]`,
        );

        if (typeof this.deactivateModalWindowCheckbox !== 'undefined' &&
            this.deactivateModalWindowCheckbox !== null
        ) {
            this.deactivateModalWindowCheckbox.addEventListener('change', () => {
                if (this.deactivateModalWindowCheckbox.checked) {
                    this.setShowBaseTrackModalWindowDeactivatedSessionValue('true');
                } else {
                    this.setShowBaseTrackModalWindowDeactivatedSessionValue('false');
                }
            });
        }
    }
}
