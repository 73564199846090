import AbstractGa4CustomEventWithDataMapper from './abstract-ga4-custom-event-with-data-mapper';
import CustomSubtypeInterface from '../events/custom-subtypes/custom-subtype-interface';
import PriceCalculation, { PriceCalculationDataInterface } from '../events/custom-subtypes/price-calculation';

export default class Ga4CustomPriceCalculationEventMapper extends AbstractGa4CustomEventWithDataMapper {
    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(data: PriceCalculationDataInterface): CustomSubtypeInterface {
        return new PriceCalculation(data);
    }
}
