import EcommerceWithCartSumAndCoupon, {
    EcommerceWithCartSumAndCouponDataInterface
} from './ecommerce-with-cart-sum-and-coupon';

/* eslint-disable camelcase */
export interface EcommerceWithPaymentInfoDataInterface extends EcommerceWithCartSumAndCouponDataInterface {
    payment_type: string;
}

export default class EcommerceWithPaymentInfo extends EcommerceWithCartSumAndCoupon {
    payment_type: string;

    constructor(data: EcommerceWithPaymentInfoDataInterface) {
        super(data);

        this.payment_type = data.payment_type;
    }
}
/* eslint-enable camelcase */
