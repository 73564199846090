import EventDataTransfer from '../event-data-transfer';
import Ga4EventInterface from '../events/ga4-event-interface';
import Ga4EventMakerInterface from './ga4-event-maker-interface';
import Ga4EventMapperInterface from '../event-mappers/ga4-event-mapper-interface';

export default class Ga4EventMaker implements Ga4EventMakerInterface {
    protected dataClass: typeof Ga4EventInterface;
    protected mapper: Ga4EventMapperInterface;

    constructor(dataClass: typeof Ga4EventInterface, mapper?: Ga4EventMapperInterface) {
        this.dataClass = dataClass;
        this.mapper = mapper;
    }

    public createEventFromElement(element: HTMLElement): Ga4EventInterface {
        const transfer = new EventDataTransfer();
        this.collectData(element, transfer);

        const mappedData = this.mapData(transfer);
        // eslint-disable-next-line new-cap
        return new this.dataClass(mappedData);
    }

    // eslint-disable-next-line class-methods-use-this
    protected collectData(element: HTMLElement, transfer: EventDataTransfer): void {
        transfer.event = Ga4EventMaker.getElementAttribute(element, 'data-ga4-event');
        transfer.eventName = Ga4EventMaker.getElementAttribute(element, 'data-ga4-event-name');
        transfer.data = Ga4EventMaker.resolveData(element);
    }

    protected static getElementAttribute(element: HTMLElement, attributeName: string, defaultValue = undefined) {
        return element.getAttribute(`${attributeName}`) ?? defaultValue;
    }

    protected static resolveData(element: HTMLElement) {
        let attributeValue = Ga4EventMaker.getElementAttribute(element, 'data-ga4-event-data');

        if (attributeValue !== undefined) {
            attributeValue = JSON.parse(attributeValue);
        }

        return attributeValue;
    }

    // eslint-disable-next-line no-unused-vars
    protected mapData(transfer): object {
        if (this.mapper === undefined) {
            return {};
        }

        return this.mapper.map(transfer);
    }
}
