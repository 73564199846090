import Component from 'ShopUi/models/component';
import debounce from 'lodash-es/debounce';

export default class LandingPageOverlay extends Component {
    protected readyCallback() {
        window.addEventListener(
            'load',
            debounce(
                () => this.hideOverlay(),
                this.getFadeOutDebounce(),
            ),
        );
    }

    public hideOverlay(): void {
        this.classList.add('hidden');
    }

    protected getFadeOutDebounce(): number {
        return parseInt(this.getAttribute('fade-out-debounce'), 10);
    }
}
