import AutocompleteForm from 'src/BestIt/ShopUi/components/molecules/autocomplete-form/autocomplete-form';
import Icon from 'src/BestIt/ShopUi/components/atoms/icon/icon';
import SvgIcon from 'src/BestIt/ShopUi/components/atoms/svg-icon/svg-icon';
import { Iconly } from '@best-it/iconly';

/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-shadow
export enum Events {
    FETCHING = 'fetching',
    FETCHED = 'fetched',
    CHANGE = 'change',
    SET = 'set',
    UNSET = 'unset'
}
/* eslint-enable no-unused-vars */

interface keyCodes {
    [key: string]: number;
}

// eslint-disable-next-line no-redeclare
const keyCodes = {
    arrowUp: 'ArrowUp',
    arrowDown: 'ArrowDown',
    enter: 'Enter',
};

export default class ProductSearchAutocompleteForm extends AutocompleteForm {
    widgetSuggestionsContainer: HTMLElement;

    suggestionItems: HTMLElement[];

    lastSelectedItem: HTMLElement;

    quantityInput: HTMLInputElement;

    protected readyCallback(): void {
        this.widgetSuggestionsContainer = <HTMLElement> this.querySelector(`.${this.jsName}__suggestions`);
        this.quantityInput = <HTMLInputElement>document.querySelector(`.${this.jsName}__quantity-field`);
        super.readyCallback();
        this.plugKeydownEvent();
    }

    protected plugKeydownEvent(): void {
        this.inputElement.addEventListener('keydown', event => this.onKeyDown(event));
    }

    protected showSuggestions(): void {
        this.widgetSuggestionsContainer.classList.remove('is-hidden');
    }

    protected hideSuggestions(): void {
        this.widgetSuggestionsContainer.classList.add('is-hidden');
    }

    protected mapItemEvents(): void {
        // eslint-disable-next-line consistent-this
        const self = this;
        const items = <HTMLElement[]>Array.from(this.widgetSuggestionsContainer.querySelectorAll(this.itemSelector));
        items.forEach((item: HTMLElement) => item.addEventListener('click', () => self.onItemClick(item)));
    }

    protected onKeyDown(event: KeyboardEvent): void {
        if (!this.suggestionItems && this.inputText.length < this.minLetters) {
            return;
        }

        switch (event.key) {
            case keyCodes.arrowUp:
                event.preventDefault();
                this.onKeyDownArrowUp();
                break;
            case keyCodes.arrowDown:
                event.preventDefault();
                this.onKeyDownArrowDown();
                break;
            case keyCodes.enter:
                event.preventDefault();
                this.onKeyDownEnter();
                break;
            default:
                break;
        }
    }

    protected onKeyDownArrowUp(): void {
        const lastSelectedItemIndex = this.suggestionItems.indexOf(this.lastSelectedItem);
        const elementIndex = lastSelectedItemIndex - 1;
        const lastSuggestionItemIndex = this.suggestionItems.length - 1;
        const item = this.suggestionItems[elementIndex < 0 ? lastSuggestionItemIndex : elementIndex];

        this.changeSelectedItem(item);
    }

    protected onKeyDownArrowDown(): void {
        const lastSelectedItemIndex = this.suggestionItems.indexOf(this.lastSelectedItem);
        const elementIndex = lastSelectedItemIndex + 1;
        const lastSuggestionItemIndex = this.suggestionItems.length - 1;
        const item = this.suggestionItems[elementIndex > lastSuggestionItemIndex ? 0 : elementIndex];

        this.changeSelectedItem(item);
    }

    protected onKeyDownEnter(): void {
        this.lastSelectedItem.click();
    }

    protected changeSelectedItem(item: HTMLElement): void {
        this.lastSelectedItem.classList.remove(this.selectedInputClass);
        item.classList.add(this.selectedInputClass);
        this.lastSelectedItem = item;
    }

    async loadSuggestions(): Promise<void> {
        this.dispatchCustomEvent(Events.FETCHING);
        this.showSuggestions();
        this.ajaxProvider.queryParams.set(this.queryParamName, this.inputText);
        await this.ajaxProvider.fetch();
        this.suggestionItems = <HTMLElement[]>Array.from(
            this.widgetSuggestionsContainer.querySelectorAll(this.itemSelector),
        );

        this.initInHouseIcon();

        this.lastSelectedItem = this.suggestionItems[0];
        this.mapItemEvents();
    }

    protected initInHouseIcon(): void {
        const inHouseIcons = <Icon[]>Array.from(
            this.widgetSuggestionsContainer.querySelectorAll('.icon--inhouse-production'),
        );
        const svgIconHandler = <SvgIcon> document.querySelector('.svg-icon');

        if (typeof inHouseIcons !== 'undefined' &&
            inHouseIcons !== null &&
            inHouseIcons.length > 0 &&
            typeof svgIconHandler !== 'undefined' &&
            svgIconHandler !== null
        ) {
            const { iconElementSelector } = svgIconHandler;

            if (iconElementSelector !== '') {
                inHouseIcons.forEach((inHouseIcon) => {
                    inHouseIcon.initializeCustomElement(iconElementSelector, {
                        fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
                        createIntersectionObserver: false,
                    }, {}, false);
                });

                Iconly.registerIntersectionObserver(iconElementSelector, {
                    fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
                });
            }
        }
    }

    setInputs(data: string, text: string): void {
        this.inputText = text;
        this.inputValue = data;

        const changeEvent = new CustomEvent('change');
        this.hiddenInputElement.dispatchEvent(changeEvent);

        this.dispatchCustomEvent(Events.SET, {
            text: this.inputText,
            value: this.inputValue,
        });

        if (this.quantityInput) {
            this.quantityInput.focus();
        }
    }

    protected onBlur(): void {
        this.hideSuggestions();
    }

    protected onFocus(): void {
        if (this.inputText.length >= this.minLetters) {
            this.showSuggestions();
        }
    }

    get selectedInputClass(): string {
        return `${this.itemSelector}--selected`.substr(1);
    }
}
