import Ga4EcommerceEventWithProductItemMapper
    from 'GA4EventTracking/types/event-mappers/ga4-ecommerce-event-with-product-item-mapper';
import EventDataTransfer from 'GA4EventTracking/types/event-data-transfer';
import Ga4EcommerceAddToWishlistEvent, {
    Ga4EcommerceAddToWishlistEventDataInterface
} from 'GA4EventTracking/types/events/ecommerce-types/ga4-ecommerce-add-to-wishlist-event';
import EcommerceWithCartSum, {
    EcommerceWithCartSumDataInterface
} from 'GA4EventTracking/types/events/ecommerce-subtypes/ecommerce-with-cart-sum';
import Product from 'GA4EventTracking/types/events/ecommerce-subtypes/product';

/* eslint-disable camelcase */
export default class Ga4EcommerceEventAddToWishlistMapper extends Ga4EcommerceEventWithProductItemMapper {
    public map(transfer: EventDataTransfer): Ga4EcommerceAddToWishlistEvent {
        return this.mapCustomSubtype({
            event_name: transfer.eventName,
            add_to_wishlist_type: transfer.data.add_to_wishlist_type,
            ecommerce: this.mapEcommerceSubtype(transfer.data)
        });
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(data: Ga4EcommerceAddToWishlistEventDataInterface): Ga4EcommerceAddToWishlistEvent {
        return new Ga4EcommerceAddToWishlistEvent(data);
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapEcommerceSubtype(data: EcommerceWithCartSumDataInterface): EcommerceWithCartSum {
        let value = 0;
        data.items = data.items.map((item): Product => {
            value += item.price * item.quantity;
            return new Product(item);
        });
        data.currency = data.items[0].currency;
        data.value = value;

        return new EcommerceWithCartSum(data);
    }
}
/* eslint-enable camelcase */
