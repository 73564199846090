import Component from 'ShopUi/models/component';
import OverlayBlock from '../../atoms/overlay-block/overlay-block';

export default class NavigationMultilevel extends Component {
    readonly overlay: OverlayBlock;

    readonly triggers: HTMLElement[];

    readonly touchTriggers: HTMLElement[];

    readonly targets: HTMLElement[];

    private closeButtons: HTMLElement[];

    private lastTouchedElement: HTMLElement;

    private isTouch: boolean;

    constructor() {
        super();
        this.overlay = <OverlayBlock>document.querySelector(this.overlaySelector);
        this.triggers = <HTMLElement[]>Array.from(this.querySelectorAll(this.triggerSelector));
        this.touchTriggers = <HTMLElement[]>Array.from(this.querySelectorAll(this.touchSelector));
        this.targets = <HTMLElement[]>Array.from(document.querySelectorAll(this.targetSelector));
        this.closeButtons = <HTMLElement[]>Array.from(document.querySelectorAll(this.closeSelector));
        this.isTouch = 'ontouchstart' in window;
    }

    protected init(): void {
        if (this.isTouch) {
            this.closeButtons.forEach((closeButton:HTMLElement) => closeButton.classList.remove('is-hidden'));
        }

        this.mapEvents();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    protected mapEvents(): void {
        if (this.isTouch) {
            this.touchTriggers.forEach((trigger: HTMLElement) => trigger.addEventListener(
                'touchend',
                (event: Event) => this.onTriggerTouch(event)
            ));
            this.closeButtons.forEach((trigger: HTMLElement) => trigger.addEventListener(
                'touchend',
                () => this.closeNavigation(),
                true
            ));
        } else {
            this.triggers.forEach((trigger: HTMLElement) => trigger.addEventListener(
                'mouseover',
                (event: Event) => this.onTriggerOver(event)
            ));
            this.touchTriggers.forEach((trigger: HTMLElement) => trigger.addEventListener(
                'click',
                (event: Event) => this.onTriggerClick(event)
            ));
        }
        this.triggers.forEach((trigger: HTMLElement) => trigger.addEventListener(
            'mouseout',
            (event: Event) => this.onTriggerOut(event)
        ));
    }

    protected onTriggerOver(event: Event): void {
        if (!this.isTouch) {
            event.preventDefault();
            const trigger = <HTMLElement>event.currentTarget;
            this.overlay.showOverlay();
            this.addClass(<HTMLElement>trigger.closest(`.${this.jsName}__item`));
        }
    }

    protected addClass(trigger: HTMLElement): void {
        trigger.classList.add(this.classToToggle);
    }

    protected onTriggerOut(event: Event): void {
        if (!this.isTouch) {
            const trigger = <HTMLElement>event.currentTarget;
            event.preventDefault();
            this.overlay.hideOverlay();
            this.removeClass(<HTMLElement>trigger.closest(`.${this.jsName}__item`));
        }
    }

    protected removeClass(trigger: HTMLElement): void {
        trigger.classList.remove(this.classToToggle);
    }

    protected onTriggerClick(event: Event): void {
        if (!this.isWidthMoreThanAvailableBreakpoint()) {
            const trigger = <HTMLElement>event.currentTarget;
            const contentToShowSelector = this.getDataAttribute(trigger, 'data-toggle-target');
            const contentToggleClass = this.getDataAttribute(trigger, 'data-class-to-toggle');
            const closestParentNode = trigger.closest(`.${this.jsName}__item`);
            const contentToShow = closestParentNode.querySelector(contentToShowSelector);

            contentToShow.classList.toggle(contentToggleClass);
            trigger.classList.toggle('is-active');
        }
    }

    protected onTriggerTouch(event: Event): void {
        const trigger = <HTMLElement>event.currentTarget;
        const triggerParent = trigger.closest(`.${this.jsName}__item`);

        if (this.isTouch &&
            (this.lastTouchedElement !== event.currentTarget) &&
            !triggerParent.closest(`.${this.jsName}__item`).classList.contains(`${this.jsName}__touch-inactive`)
        ) {
            if (typeof this.lastTouchedElement !== 'undefined') {
                this.removeClass(<HTMLElement> this.lastTouchedElement.closest(`.${this.jsName}__item`));
            }

            event.preventDefault();
            this.overlay.showOverlay();
            this.addClass(<HTMLElement>trigger.closest(`.${this.jsName}__item`));

            this.lastTouchedElement = <HTMLElement>event.currentTarget;
        } else if (this.isTouch &&
            triggerParent.classList.contains(`${this.jsName}__touch-inactive`)
        ) {
            if (typeof this.lastTouchedElement !== 'undefined') {
                this.removeClass(<HTMLElement> this.lastTouchedElement.closest(`.${this.jsName}__item`));
            }

            this.lastTouchedElement = <HTMLElement>event.currentTarget;
        }
    }

    protected isWidthMoreThanAvailableBreakpoint(): boolean {
        return window.innerWidth >= this.availableBreakpoint;
    }

    // eslint-disable-next-line class-methods-use-this
    protected getDataAttribute(block: HTMLElement, attr: string): string {
        return block.getAttribute(attr);
    }

    get targetSelector(): string {
        return this.getAttribute('target-selector');
    }

    get classToToggle(): string {
        return this.getAttribute('class-to-toggle');
    }

    get availableBreakpoint(): number {
        return Number(this.getAttribute('available-breakpoint'));
    }

    // eslint-disable-next-line class-methods-use-this
    get overlaySelector(): string {
        return '.js-overlay-block';
    }

    get triggerSelector(): string {
        return `.${this.jsName}__trigger`;
    }

    get touchSelector(): string {
        return `.${this.jsName}__touch-trigger`;
    }

    get closeSelector(): string {
        return `.${this.jsName}__close`;
    }

    private closeNavigation() {
        this.removeClass(<HTMLElement> this.lastTouchedElement.closest(`.${this.jsName}__item`));
        this.lastTouchedElement = undefined;
        this.overlay.hideOverlay();
    }
}
