import Component from 'ShopUi/models/component';

export default class ShoppingListAddItem extends Component {
    /**
     * The select element with the shopping-list list
     */
    protected shoppingListSelect: HTMLSelectElement;

    /**
     * The form where the select is integrated
     */
    protected shoppingListForm: HTMLElement;

    /**
     * The submit button of the form
     */
    protected formSubmitButton: HTMLElement;

    protected readyCallback(): void {
        this.shoppingListSelect = this.querySelector('[name=idShoppingList]');
        this.shoppingListForm = this.querySelector('.js-shopping-list__form');
        this.formSubmitButton = this.querySelector('.js-add-to-shopping-list');

        this.initShoppingListAddItem();
        this.mapEvents();
    }

    protected initShoppingListAddItem() {
        if (this.shoppingListSelect) {
            this.formSubmitButton.setAttribute('disabled', 'disabled');
        }
    }

    protected mapEvents() {
        this.shoppingListForm.addEventListener('submit', () => this.onFormSubmit());
        if (this.shoppingListSelect) {
            this.shoppingListSelect.addEventListener('change', () => this.onSelect());
        }
    }

    private onFormSubmit(): boolean {
        if (this.shoppingListSelect.value === null || this.shoppingListSelect.value === '') {
            return false;
        }

        return true;
    }

    private onSelect() {
        if (this.shoppingListSelect.value === null || this.shoppingListSelect.value === '') {
            this.formSubmitButton.setAttribute('disabled', 'disabled');
        } else {
            this.formSubmitButton.removeAttribute('disabled');
        }
    }
}
