import Component from 'ShopUi/models/component';
// eslint-disable-next-line no-unused-vars
import LoadingIndicator from 'src/BestIt/ShopUi/components/molecules/loading-indicator/loading-indicator';

export default class BimIframeLoader extends Component {
    protected readyCallback(): void {
        const iframe = this.querySelector('iframe');
        const loadingIndicator = this.querySelector('loading-indicator') as LoadingIndicator;

        iframe.addEventListener('load', () => {
            loadingIndicator.closeLoadingIndicator();
        });

        this.modalWindow.addEventListener('opened', () => {
            iframe.setAttribute('src', this.iframeUrl);
        });
    }

    private get iframeUrl(): string {
        return this.getAttribute('iframe-url');
    }

    private get modalWindow(): Element {
        const selector = this.getAttribute('modal-selector');
        return document.querySelector(selector);
    }
}
