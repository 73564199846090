import AbstractGa4CustomEventWithDataMapper from './abstract-ga4-custom-event-with-data-mapper';
import CustomSubtypeInterface from '../events/custom-subtypes/custom-subtype-interface';
import Search, { SearchDataInterface } from '../events/custom-subtypes/search';

/* eslint-disable camelcase */
export default class Ga4CustomSearchEventMapper extends AbstractGa4CustomEventWithDataMapper {
    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(data: SearchDataInterface): CustomSubtypeInterface {
        // If search recommendation is empty, set it to null
        data.search_recommendation.length === 0 && (data.search_recommendation = null);

        return new Search(data);
    }
}
/* eslint-enable camelcase */
