import CustomSubtypeInterface from '../events/custom-subtypes/custom-subtype-interface';
import EventDataTransfer from '../event-data-transfer';
import Ga4CustomEventMapper from './ga4-custom-event-mapper';
import Ga4CustomEventWithData from '../events/ga4-custom-event-with-data';

export default abstract class AbstractGa4CustomEventWithDataMapper extends Ga4CustomEventMapper {
    customSubtype: typeof CustomSubtypeInterface;

    constructor(customSubtype: typeof CustomSubtypeInterface) {
        super();

        this.customSubtype = customSubtype;
    }

    public map(transfer: EventDataTransfer): Ga4CustomEventWithData {
        const event: Ga4CustomEventWithData = <Ga4CustomEventWithData>Object.assign(
            super.map(transfer),
            {
                [transfer.eventName]: this.mapCustomSubtype(transfer.data),
            }
        );
        return event;
    }

    // eslint-disable-next-line no-unused-vars
    protected abstract mapCustomSubtype(data: object): CustomSubtypeInterface;
}
