import ModalWindow from 'src/BestIt/ShopUi/components/molecules/modal-window/modal-window';
import AbstractStep from '../abstract-step/abstract-step';

export default class AccessoriesStep extends AbstractStep {
    // eslint-disable-next-line no-undef
    protected priceBoxContainers: NodeListOf<HTMLElement>;

    protected noDriverWarning: HTMLElement;

    protected noDriverModalWindow: ModalWindow;

    protected readyCallback(): void {
        this.initialize();
    }

    protected initStepFunctionality(): void {
        this.setPriceBoxContainer();
        this.setNoDriverWarning();
        this.setNoDriverModalWindow();
        this.registerFilterEvents();
        this.registerNoDriverModalWindowEvents();

        this.updatePriceBox();
        this.showOrHideNoDriverWarning();
    }

    protected setPriceBoxContainer() {
        // eslint-disable-next-line no-undef
        this.priceBoxContainers = <NodeListOf<HTMLElement>> this.querySelectorAll(this.cssSelectors.priceContainer);
    }

    protected setNoDriverWarning() {
        this.noDriverWarning = <HTMLElement> this.querySelector('.no-driver-selected-warning-message');
    }

    protected setNoDriverModalWindow() {
        this.noDriverModalWindow = <ModalWindow> this.querySelector(`.${this.jsName}__modal--no-driver`);

        if (typeof this.noDriverModalWindow !== 'undefined' && this.noDriverModalWindow !== null) {
            if (this.getShowNoDriverModalWindowSessionValue() === null) {
                this.setShowNoDriverModalWindowSessionValue('true');
            }

            if (this.getShowNoDriverModalWindowSessionValue() === 'true') {
                this.noDriverModalWindow.triggerButtons = <HTMLElement[]> Array.from(this.nextStepButtons);
            }
        }
    }

    protected registerFilterEvents(): void {
        // eslint-disable-next-line no-undef
        const filterOptions = <NodeListOf<HTMLElement>> this.querySelectorAll(this.cssSelectors.filterOption);

        filterOptions.forEach((filterOption: HTMLElement) => {
            filterOption.addEventListener('click', (event) => {
                event.preventDefault();

                this.showWarningsForFilter(filterOption);
                this.showSelectedProductBox(filterOption);
                this.updatePriceBox();
            });
        });
    }

    // eslint-disable-next-line class-methods-use-this
    protected getShowNoDriverModalWindowSessionValue() {
        return sessionStorage.getItem('showNoDriverModalWindow');
    }

    // eslint-disable-next-line class-methods-use-this
    protected setShowNoDriverModalWindowSessionValue(value: string): void {
        sessionStorage.setItem('showNoDriverModalWindow', value);
    }

    protected registerNoDriverModalWindowEvents(): void {
        if (typeof this.noDriverModalWindow !== 'undefined' && this.noDriverModalWindow !== null) {
            const moveForwardButton = <HTMLElement> this.noDriverModalWindow.querySelector('.move-forward');

            // eslint-disable-next-line arrow-body-style
            this.noDriverModalWindow.addEventListener('open', () => {
                return this.getShowNoDriverModalWindowSessionValue() === 'true';
            });

            this.noDriverModalWindow.addEventListener('closed', () => {
                this.setShowNoDriverModalWindowSessionValue('false');
                this.disableNoDriverModalWindow();
            });

            if (moveForwardButton) {
                moveForwardButton.addEventListener('click', () => {
                    this.setShowNoDriverModalWindowSessionValue('false');
                    this.disableNoDriverModalWindow();

                    /**
                     * Have to use a click event on one of the next buttons because of the IE
                     * The IE doesn't recognize the form for the submit function
                     */
                    this.nextStepButtons.forEach((button) => {
                        button.click();
                    });
                });
            }
        }
    }

    protected enableNoDriverModalWindow(): void {
        this.noDriverModalWindow.registerOpeningTrigger();

        this.nextStepButtons.forEach((button) => {
            button.setAttribute('onclick', 'return false;');
        });
    }

    protected disableNoDriverModalWindow(): void {
        this.noDriverModalWindow.removeOpeningTrigger();

        this.nextStepButtons.forEach((button) => {
            button.removeAttribute('onclick');
        });
    }

    protected showWarningsForFilter(filterOption: HTMLElement): void {
        if (filterOption.hasAttribute('data-sku_accessory_driver')) {
            this.showOrHideNoDriverWarning(filterOption);
        }
    }

    protected showOrHideNoDriverWarning(filterOption: HTMLElement = null): void {
        if (filterOption === null) {
            filterOption = <HTMLElement> this.querySelector('[data-sku_accessory_driver]');
        }

        if (filterOption.getAttribute('data-sku_accessory_driver') === '0') {
            this.showNoDriverWarning();

            if (typeof this.noDriverModalWindow !== 'undefined' &&
                this.noDriverModalWindow !== null &&
                this.getShowNoDriverModalWindowSessionValue() === 'true'
            ) {
                this.enableNoDriverModalWindow();
            }
        } else {
            this.hideNoDriverWarning();

            if (typeof this.noDriverModalWindow !== 'undefined' && this.noDriverModalWindow !== null) {
                this.disableNoDriverModalWindow();
            }
        }
    }

    protected showNoDriverWarning(): void {
        this.noDriverWarning.classList.remove(this.cssClasses.hidden);
    }

    protected hideNoDriverWarning(): void {
        this.noDriverWarning.classList.add(this.cssClasses.hidden);
    }

    protected updatePriceBox(): void {
        if (this.priceBoxContainers !== null) {
            this.priceBoxContainers.forEach((container) => {
                const priceContainer = container.querySelector(`.${this.name}--selected-price`);
                const price = this.convertPriceToMoney();

                if (!price) {
                    return;
                }

                if (priceContainer) {
                    priceContainer.innerHTML = price;
                }
            });
        }
    }

    protected convertPriceToMoney(): string {
        const convertedPrice = (this.calculatePrice() / 100).toFixed(2).replace('.', ',');
        const currency = this.getCurrency('.price__amount-value');
        if (!currency) {
            return convertedPrice;
        }

        return `${convertedPrice} ${currency}`;
    }

    protected calculatePrice(): number {
        return this.getProductPrice() + this.getPriceOfAccessories();
    }

    protected getProductPrice(): number {
        const productBox = this.querySelector('.accessories-step__product-container');

        if (!productBox || !productBox.hasAttribute('data-price')) {
            return 0;
        }

        if (productBox.getAttribute('data-price') === '') {
            return 0;
        }

        return parseInt(productBox.getAttribute('data-price'), 10);
    }

    protected getPriceOfAccessories(): number {
        let cumulatedPrice = 0;

        this.filters.forEach((accessoryFilter: HTMLElement) => {
            cumulatedPrice += this.getPriceOfSingleAccessory(accessoryFilter);
        });

        return cumulatedPrice;
    }
}
