import Component from 'ShopUi/models/component';
import axios from 'axios';
import QuantityInputSelect from 'src/BestIt/ShopUi/components/molecules/quantity-input-select/quantity-input-select';
import TempParams from 'src/BestIt/ShopUi/components/molecules/temp-params/temp-params';

export default class BuyBox extends Component {
    private customPriceButton: HTMLButtonElement | null;

    private customPrice: HTMLElement;

    private officialPrice: HTMLElement;

    private errorContainer: HTMLDivElement;

    private maintenanceContainer: HTMLDivElement;

    private customPriceValueElement: HTMLSpanElement;

    private alternativeProductsModal: HTMLElement;

    private addToCartButton: HTMLButtonElement;

    private quantityInputSelect: QuantityInputSelect;

    private tempParams: TempParams;

    protected readyCallback(): void {
        this.initButton();
        this.customPrice = this.querySelector(`.${this.name}__custom-price`);
        this.officialPrice = this.querySelector(`.${this.name}__official-price`);
        this.errorContainer = this.querySelector(`.${this.name}__custom-price-error-container`);
        this.maintenanceContainer = this.querySelector(`.${this.name}__custom-price-maintenance-container`);
        if (this.customPrice) {
            this.customPriceValueElement = this.customPrice.querySelector('.price__amount-value');
        }
        this.tempParams = <TempParams> document.querySelector('.temp-params');

        this.alternativeProductsModal = this.queryModal();

        if (this.shouldOpenModal) {
            this.openModal();
        }
    }

    private queryModal() : HTMLElement {
        const modalSelector = `modal-window[data-trigger=".${this.name}__show-similar-products"]`;
        return document.querySelector(modalSelector);
    }

    get shouldOpenModal() {
        if (typeof this.tempParams !== 'undefined' && this.tempParams !== null) {
            return this.tempParams.get('openAlternativeProductsModal') === 'true';
        }
        return false;
    }

    private openModal() {
        if (this.alternativeProductsModal) {
            // eslint-disable-next-line no-extra-parens
            (this.alternativeProductsModal as any).openModal();
        }
    }

    private initButton() {
        this.customPriceButton = this.querySelector(`.${this.name}__custom-price-button`);
        this.addToCartButton = this.querySelector(`.${this.name}__add-to-cart-button`);
        this.quantityInputSelect = this.querySelector('.quantity-input-select');

        if (this.customPriceButton) {
            this.customPriceButton.disabled = false;
            this.customPriceButton.addEventListener('click', this.handleCustomPriceButtonClick.bind(this));
        }

        if (this.addToCartButton) {
            this.addToCartButton.addEventListener('click', (event: Event) => this.onAddToCartButtonClick(event));
        }
    }

    private async handleCustomPriceButtonClick() {
        this.customPriceButton.disabled = true;
        this.customPriceButton.classList.add('loading');
        const result = await this.calculateCustomPrice();
        const { success, formattedPrice, isMaintenance } = result.data;

        if (success) {
            this.customPriceValueElement.innerText = formattedPrice;
            this.customPrice.classList.remove('is-hidden');
            this.officialPrice.classList.add(`${this.name}__official-price--reduced`);
        } else if (isMaintenance) {
            this.maintenanceContainer.classList.remove('is-hidden');
        } else {
            this.errorContainer.classList.remove('is-hidden');
        }

        this.customPriceButton.disabled = false;
        this.customPriceButton.classList.remove('loading');
        this.customPriceButton.style.display = 'none';
    }

    private calculateCustomPrice() {
        return axios.get(this.myPricePath, { withCredentials: true });
    }

    get myPricePath() {
        return this.dataset.myPricePath;
    }

    private onAddToCartButtonClick(event: Event) {
        event.preventDefault();
        const triggerElement = <HTMLElement> event.target;
        triggerElement.closest('button').disabled = true;

        let quantity = this.quantityInputSelect.getAttribute('data-new-value');
        if (quantity === '0' || quantity === '') {
            quantity = '1';
        }

        const callback = () => {
            triggerElement.closest('form').submit();
        };

        this.quantityInputSelect.pushCartEvents(quantity, callback);
    }
}
