import Component from 'ShopUi/models/component';
import noUiSlider from 'nouislider';

export default class RangeSlider extends Component {
    sliderContainer: HTMLElement;

    rangeInputs: HTMLInputElement[];

    protected init(): void {
        this.sliderContainer = document.querySelector(this.wrapSelector);
        this.rangeInputs = Array.from(document.querySelectorAll(this.targetSelector));

        this.initUiSlider();
        this.mapEvents();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    protected mapEvents(): void {
        this.rangeInputs.forEach((input, index) => {
            input.addEventListener('change', (event: Event) => {
                // eslint-disable-next-line no-extra-parens
                this.setInputValueToSlider(index, (<HTMLInputElement>event.currentTarget).value);
            });
        });

        this.valueUpdate();
    }

    protected initUiSlider(): void {
        noUiSlider.create(this.sliderContainer, this.sliderConfig);
    }

    protected setInputValueToSlider(index, value) {
        const inputsValue = [null, null];
        inputsValue[index] = value;
        // eslint-disable-next-line no-extra-parens
        (<noUiSlider> this.sliderContainer).noUiSlider.set(inputsValue);
    }

    protected valueUpdate(): void {
        // eslint-disable-next-line no-extra-parens
        (<noUiSlider> this.sliderContainer).noUiSlider.on('update', (values, handle) => {
            this.rangeInputs[handle].setAttribute('value', String(values[handle]));
            this.rangeInputs[handle].value = String(values[handle]);
        });
    }

    get wrapSelector(): string {
        return this.getAttribute('wrap-selector');
    }

    get targetSelector(): string {
        return this.getAttribute('target-selector');
    }

    get sliderConfig(): object {
        return Object.assign(JSON.parse(this.getAttribute('slider-config')), {
            format: {
                // eslint-disable-next-line id-length
                to(value) {
                    value = (value.toFixed(2) % 1) === 0 ? Math.floor(value) : value.toFixed(2);
                    return value;
                },
                from(value) {
                    return value;
                },
            },
        });
    }
}
