import Ecommerce from '../ecommerce-subtypes/ecommerce';
import Ga4EventInterface from '../ga4-event-interface';
import Ga4EcommerceEventWithProductDataInterface from '../ga4-ecommerce-event-with-product-data-interface';
import Product from '../ecommerce-subtypes/product';
import Return from '../ecommerce-subtypes/return';
import Promotion from '../ecommerce-subtypes/promotion';

/* eslint-disable camelcase */
export default class Ga4EcommerceEvent implements Ga4EventInterface {
    event: string;
    event_name: string;
    ecommerce: Ecommerce<Product|Return|Promotion>;

    constructor(data: Ga4EcommerceEventWithProductDataInterface) {
        this.event = `Ecommerce - ${data.event_name}`;
        this.event_name = data.event_name;
        this.ecommerce = data.ecommerce;
    }
}
/* eslint-enable camelcase */
