import Component from 'ShopUi/models/component';

export default class FilterCategoryAccordion extends Component {
    private triggers: HTMLElement[];

    protected init(): void {
        this.triggers = Array.from<HTMLElement>(document.querySelectorAll(this.triggerSelector));
        this.mapEvents();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    protected mapEvents(): void {
        this.triggers.forEach(item => item.addEventListener('click', event => this.onTriggerClick(event, item)));
    }

    protected onTriggerClick(event: Event, item: HTMLElement): void {
        this.toggle(item);
    }

    protected toggle(activeTrigger: HTMLElement): void {
        const isTriggerActive = activeTrigger.classList.contains(this.triggerActiveClass);
        const triggerPair = this.getTriggerPair(activeTrigger);
        const branchContainer = this.getBranchContainer(activeTrigger);

        triggerPair.forEach((trigger: HTMLElement) => {
            trigger.classList.toggle(this.triggerActiveClass, !isTriggerActive);
        });

        if (branchContainer !== null) {
            branchContainer.classList.toggle(this.getBranchContainerToogleClass(activeTrigger));
        }

        this.targetToggle(activeTrigger);
    }

    protected targetToggle(trigger: HTMLElement): void {
        const targets = <HTMLElement[]>Array.from(document.querySelectorAll(trigger.dataset.toggleTarget));
        targets.forEach((target: HTMLElement) => {
            const isTargetActive = !target.classList.contains(this.classToToggle);
            target.classList.toggle(this.classToToggle, isTargetActive);
        });
    }

    get triggerSelector(): string {
        return this.getAttribute('trigger-selector');
    }

    // eslint-disable-next-line class-methods-use-this
    protected getTriggerPair(eventTarget: HTMLElement): HTMLElement[] {
        return <HTMLElement[]>Array.from(
            document.querySelectorAll(eventTarget.getAttribute('data-trigger-pair-selector')),
        );
    }

    get classToToggle(): string {
        return this.getAttribute('class-to-toggle');
    }

    get triggerActiveClass(): string {
        return this.getAttribute('active-class');
    }

    // eslint-disable-next-line class-methods-use-this
    protected getBranchContainer(eventTarget: HTMLElement): HTMLElement {
        return <HTMLElement> document.querySelector(
            eventTarget.getAttribute('data-branch-container-selector'),
        );
    }

    // eslint-disable-next-line class-methods-use-this
    protected getBranchContainerToogleClass(eventTarget: HTMLElement): string {
        return eventTarget.getAttribute('data-branch-container-toggle-class');
    }
}
