import ModalWindow from 'src/BestIt/ShopUi/components/molecules/modal-window/modal-window';
import DefaultProductSelectionStep from '../default-product-selection-step/default-product-selection-step';

export default class DetailConnectorStep extends DefaultProductSelectionStep {
    /**
     * @var ModalWindow
     *
     * @protected
     */
    protected connectorWindow: ModalWindow;

    /**
     * Init function for the component
     *
     * @protected
     */
    protected initialize(): void {
        super.initialize();
    }

    /**
     * Init functionality for this concrete step
     *
     * @protected
     */
    protected initStepFunctionality(): void {
        this.registerConnectorModalWindowEvents();
    }

    /**
     * Get value of the session storage variable showConnectorModalWindow
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected getShowConnectorModalWindowSessionValue(): string {
        return sessionStorage.getItem('showConnectorModalWindow');
    }

    /**
     * Set value of the session storage variable showConnectorModalWindow
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected setShowConnectorModalWindowSessionValue(value: string): void {
        sessionStorage.setItem('showConnectorModalWindow', value);
    }

    /**
     * Sets the modal window variable and set the session storage value to true, to display the modal on the first visit
     * of the step
     *
     * @protected
     */
    protected setConnectorModalWindow(): void {
        this.connectorWindow = <ModalWindow> document.querySelector('.modal-window--track-connector');

        if (typeof this.connectorWindow !== 'undefined' &&
            this.connectorWindow !== null &&
            this.getShowConnectorModalWindowSessionValue() === null
        ) {
            this.setShowConnectorModalWindowSessionValue('true');
        }
    }

    /**
     * Register the events which are connected to the modal window
     *
     * @protected
     */
    protected registerConnectorModalWindowEvents(): void {
        window.addEventListener('load', () => {
            this.setConnectorModalWindow();

            if (typeof this.connectorWindow !== 'undefined' && this.connectorWindow !== null) {
                this.connectorWindow.addEventListener('closed', () => {
                    this.setShowConnectorModalWindowSessionValue('false');
                });

                if (this.getShowConnectorModalWindowSessionValue() === 'true' &&
                    this.connectorWindow.triggerButtons.length > 0
                ) {
                    this.connectorWindow.triggerButtons[0].click();
                }
            }
        });
    }
}
