import UserCentrics from 'src/BestIt/ShopUi/components/molecules/user-centrics/user-centrics';

export default class UserCentricsWrapper {
    public readonly EVENT_GOOGLE_ANALYTICS_CONSENT_STATUS_LOADED: string = 'googleAnalyticsConsentStatusLoaded';

    protected userCentrics: UserCentrics;

    constructor() {
        this.userCentrics = <UserCentrics> document.querySelector('.user-centrics');
    }

    public isUserCentricsAvailable(): boolean {
        return typeof this.userCentrics !== 'undefined' && this.userCentrics !== null;
    }

    public isGoogleAnalyticsConsented(): boolean {
        return this.userCentrics.getGoogleAnalyticsConsentStatus();
    }
}
