import Component from 'ShopUi/models/component';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';

export default class ScrollToTop extends Component {
    /**
     * Helper variable for debouncing with rAF
     */
    protected tick = false;

    protected lastScrollPosition: number;

    protected scrollToTopButton: HTMLElement;

    protected scrollToTopButtonVisibilityThreshold: number = 300;

    protected googleEventHandler: GoogleTagEvents;

    protected init() {
        this.scrollToTopButton = <HTMLElement> this.querySelector(`.${this.jsName}__scroll-up`);
        this.googleEventHandler = <GoogleTagEvents> document.querySelector('.google-tag-events');

        this.mapEvents();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    protected mapEvents() {
        this.scrollToTopButton.addEventListener('click', () => this.scrollToTop());
        document.addEventListener('scroll', this.onScroll.bind(this));
        window.addEventListener('load', () => this.handleScroll(window.pageYOffset));
    }

    protected scrollToTop() {
        window.scroll({ top: 0, behavior: 'smooth' });

        if (typeof this.googleEventHandler !== 'undefined' && this.googleEventHandler !== null) {
            let eventData = this.googleEventHandler.getGtmEventData(this.scrollToTopButton);
            if (typeof eventData !== 'undefined' && eventData !== null) {
                const { search } = window.location;
                const regexp = /\bpage=\b\d+/;
                const pageMatch = search.match(regexp);
                const pageParam = pageMatch !== null && pageMatch.length > 0 ? pageMatch[0].split('=') : null;
                const page = pageParam !== null && pageParam.length > 0 ? pageParam[1] : '1';

                eventData = {
                    ...eventData,
                    eventLabel: page,
                };
                const event = this.googleEventHandler.createGaEvent(eventData);
                this.googleEventHandler.pushEvent(<any>event);
            }
        }
    }

    /**
     * Event handler for the on scroll event
     * Based on https://www.afasterweb.com/2017/09/26/performance-basics-throttling/
     */
    protected onScroll = () => {
        /* eslint-disable no-invalid-this */
        /**
         * Cache the current scroll position
         * NOTE: It is IMPORTANT to use pageYOffset for cross browser compatibility
         */
        this.lastScrollPosition = window.pageYOffset;

        if (!this.tick) {
            /**
             * With this, we essentially tell the browser to handle the scroll
             * in the next possible frame, which is up to the browser
             * It will only request a new frame when the old one is done
             * rAF polyfilled by src/Pyz/Yves/ShopUi/Theme/default/requestAnimationFramePolyfill.ts
             */
            window.requestAnimationFrame(() => {
                this.handleScroll(this.lastScrollPosition);

                // Scroll is handled, release tick lock
                this.tick = false;
            });

            // Mark as ticked
            this.tick = true;
        }
    };

    protected handleScroll(scrollPosition: number) {
        if (scrollPosition >= this.scrollToTopButtonVisibilityThreshold) {
            this.classList.remove('is-hidden');
        } else {
            this.classList.add('is-hidden');
        }
    }
}
