import Ecommerce, { EcommerceDataInterface } from './ecommerce';
import Product from './product';

/* eslint-disable camelcase */
export interface EcommerceWithCartSumDataInterface extends EcommerceDataInterface<Product> {
    currency: string;
    value: number;
}

export default class EcommerceWithCartSum extends Ecommerce<Product> {
    currency: string;
    value: number;

    constructor(data: EcommerceWithCartSumDataInterface) {
        super(data);

        this.currency = data.currency;
        this.value = data.value;
    }
}
/* eslint-enable camelcase */
