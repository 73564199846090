import Component from 'ShopUi/models/component';

export default class ScrollToInvalidFieldOnSubmit extends Component {
    private header: HTMLElement;

    private container: HTMLElement;

    protected readyCallback(): void {
        this.initSubmitListener();
    }

    public initSubmitListener() {
        this.header = <HTMLElement>document.querySelector('.header');
        this.container = document.querySelector(this.dataset.containerSelector);
        if (!this.container) {
            return;
        }
        const submitButton = this.container.querySelector('button[type="submit"]');
        const honeyPotField = <HTMLInputElement> this.container.querySelector(
            `input[name="${this.formSelector}[check]"]`,
        );

        if (!submitButton) {
            return;
        }
        submitButton.addEventListener('click', () => {
            if (honeyPotField !== null && honeyPotField.value === '') {
                honeyPotField.removeAttribute('required');
            }

            const invalidFields = this.getInvalidFields();

            if (invalidFields.length > 0) {
                const firstInvalidField = invalidFields[0];
                this.scrollToField(firstInvalidField);

                if (honeyPotField !== null) {
                    honeyPotField.setAttribute('required', 'required');
                }
            }
        });
    }

    get formSelector() {
        return this.getAttribute('form-name');
    }

    public getInvalidFields() : Array<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement> {
        const selector = 'input:invalid, textarea:invalid, select:invalid, checkbox:invalid';
        const fieldsWithErrors = Array.from(this.container.querySelectorAll(
            '.select_select--error, .checkbox_input--error, .text-field__input--error, .textarea--error',
        ));

        fieldsWithErrors.forEach((field: HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement) => {
            if (field.tagName.toLowerCase() === 'select') {
                field.classList.remove('select_select--error');
                if (field.parentElement.classList.contains('custom-select')) {
                    field.parentElement.classList.remove('custom-select--error');
                }
            } else if (field.tagName.toLowerCase() === 'textarea') {
                field.classList.remove('textarea--error');
            } else if (field.type === 'checkbox') {
                field.classList.remove('checkbox_input--error');
            } else if (field.type === 'text' || field.type === 'number') {
                field.classList.remove('text-field__input--error');
            }
        });

        return Array.from(this.container.querySelectorAll(selector));
    }

    private scrollToField(field : HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement) : void {
        let scrollTo = field.offsetTop - this.header.offsetHeight;
        if (scrollTo <= 0) {
            const parent = <HTMLElement> field.offsetParent;
            scrollTo = parent.offsetTop - this.header.offsetHeight;
        }

        window.scroll(0, scrollTo);
        try {
            field.reportValidity();

            if (field.tagName.toLowerCase() === 'select') {
                field.classList.add('select_select--error');

                if (field.parentElement.classList.contains('custom-select')) {
                    field.parentElement.classList.add('custom-select--error');
                }
            } else if (field.tagName.toLowerCase() === 'textarea') {
                field.classList.add('textarea--error');
            } else if (field.type === 'checkbox') {
                field.classList.add('checkbox_input--error');
            } else if (field.type === 'text' || field.type === 'number') {
                field.classList.add('text-field__input--error');
            }
        } catch {
            // We catch the error because of IE11, but reportValidity works there fine too
        }
    }
}
