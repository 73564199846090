import EventDataTransfer from '../event-data-transfer';
import Ga4EcommerceEventWithProductItemMapper
    from 'GA4EventTracking/types/event-mappers/ga4-ecommerce-event-with-product-item-mapper';
import Ga4EcommerceSelectItemEvent from 'GA4EventTracking/types/events/ecommerce-types/ga4-ecommerce-select-item-event';

export default class Ga4EcommerceSelectItemEventMapper extends Ga4EcommerceEventWithProductItemMapper {
    // eslint-disable-next-line class-methods-use-this
    /* eslint-disable camelcase */
    public map(transfer: EventDataTransfer): Ga4EcommerceSelectItemEvent {
        return <Ga4EcommerceSelectItemEvent>Object.assign(
            super.map(transfer),
            {
                click_location: transfer.data.click_location,
            }
        );
    }
    /* eslint-enable camelcase */
}
