import Component from 'ShopUi/models/component';

export default class AccordionElement extends Component {
    /**
     * The accordionHeadlines
     *
     * @protected
     */
    protected accordionHeadlines: HTMLElement[];

    /**
     * The selectedAccordionContent
     *
     * @protected
     */
    protected selectedAccordionContent: HTMLElement;

    /**
     * The readyCallback function
     *
     * @protected
     *
     * @return void
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * The initialize function
     *
     * @public
     *
     * @return void
     */
    public initialize(): void {
        this.accordionHeadlines = <HTMLElement[]> Array.from(
            this.querySelectorAll(`.${this.jsName}-row__headline-wrapper`),
        );

        this.mapEvents();
    }

    /**
     * The mapEvents function
     *
     * @protected
     *
     * @return void
     */
    protected mapEvents(): void {
        this.registerAccordionEvents();
    }

    /**
     * The registerAccordionEvents function
     *
     * @protected
     *
     * @return void
     */
    protected registerAccordionEvents(): void {
        this.accordionHeadlines.forEach((accordion: HTMLElement) => {
            accordion.addEventListener('click', event => this.onAccordionHeadlineClick(event, accordion));
        });
    }

    /**
     * Toggle the visibility of the accordion content
     *
     * @param event
     * @param accordion
     *
     * @protected
     *
     * @return void
     */
    protected onAccordionHeadlineClick(event, accordion): void {
        this.selectedAccordionContent = accordion.nextElementSibling;
        accordion.classList.toggle('active');
        this.selectedAccordionContent.classList.toggle('active');
    }
}
