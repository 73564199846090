import CustomSubtypeInterface from './custom-subtype-interface';
import MaxLengthStringValue from '../validator-types/max-length-string-value';

/* eslint-disable camelcase */
export interface SortingDataInterface {
    sorting_element: string;
}
export default class Sorting implements CustomSubtypeInterface {
    sorting_element: string;

    constructor(data: SortingDataInterface) {
        this.sorting_element = new MaxLengthStringValue(data.sorting_element).value;
    }
}
/* eslint-enable camelcase */
