import ModalWindow from 'src/BestIt/ShopUi/components/molecules/modal-window/modal-window';
import * as Cookies from 'es-cookie';
import UserCentrics from 'src/BestIt/ShopUi/components/molecules/user-centrics/user-centrics';

export default class MarketingModalWindow extends ModalWindow {
    public static DEACTIVATION_COOKIE_EXPIRE_DAYS: number = 365;

    protected static DEBOUNCE_FACTOR_FOR_MILLISECONDS: number = 1000;

    protected userCentricsHandler: UserCentrics;

    private displayControlElements: HTMLElement[];

    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    public initialize(): void {
        this.userCentricsHandler = <UserCentrics> document.querySelector('.user-centrics');

        if (typeof this.userCentricsHandler !== 'undefined' &&
            this.userCentricsHandler !== null
        ) {
            document.addEventListener('googleAnalyticsConsentStatusLoaded', () => {
                try {
                    let showModal = true;
                    if (!this.ignoreCookieCheck) {
                        showModal = this.getMarketingModalWindowDisplayInformationModal();
                    }

                    if (this.userCentricsHandler.getMarketingModalWindowConsentStatus() &&
                        showModal
                    ) {
                        setTimeout(() => {
                            if (this.displayControlTriggerSelector !== null) {
                                this.displayControlElements = <HTMLElement[]> Array.from(
                                    this.querySelectorAll(this.displayControlTriggerSelector),
                                );
                            }

                            super.initialize();
                        }, this.debounceTime * MarketingModalWindow.DEBOUNCE_FACTOR_FOR_MILLISECONDS);
                    }
                } catch (exception) {
                    // Do nothing
                }
            });
        }
    }

    protected mapEvents() {
        super.mapEvents();

        if (typeof this.displayControlElements !== 'undefined' && this.displayControlElements !== null) {
            this.displayControlElements.forEach((displayControl) => {
                displayControl.addEventListener('click', (event: Event) => this.deaktivateModalWindow(event));
            });
        }
    }

    protected deaktivateModalWindow(event) {
        if (!this.ignoreCookieCheck) {
            this.setMarketingModalWindowDisplayInformationModal();
        }
        this.closeModal(event);
    }

    /**
     * Get value of the session storage variable showConnectorModalWindow
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected getMarketingModalWindowDisplayInformationModal(): boolean {
        const displayInformationModal = Cookies.get('displayInformationModal');
        return typeof displayInformationModal === 'undefined' || displayInformationModal === 'true';
    }

    /**
     * Set value of the session storage variable showConnectorModalWindow
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected setMarketingModalWindowDisplayInformationModal(value?: string): void {
        let daysUntilExpire = MarketingModalWindow.DEACTIVATION_COOKIE_EXPIRE_DAYS;

        if (value !== null) {
            daysUntilExpire = parseInt(value, 10);
        }

        Cookies.set('displayInformationModal', 'false', {
            expires: isNaN(daysUntilExpire) ? MarketingModalWindow.DEACTIVATION_COOKIE_EXPIRE_DAYS : daysUntilExpire,
        });
    }

    get debounceTime(): number {
        const debounce = this.dataset.debounce || '0';

        return parseInt(debounce, 10);
    }

    get displayControlTriggerSelector() {
        return this.dataset.displayControlTrigger || null;
    }

    get ignoreCookieCheck() {
        return this.dataset.ignoreCheck === 'true';
    }
}
