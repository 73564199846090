import Component from 'ShopUi/models/component';

export default abstract class AbstractBasicForm extends Component {
    /**
     * Removes the events from the component and the child elements
     *
     * @public
     */
    public abstract removeEvents(): void;

    /**
     * Init function for the component
     *
     * @public
     */
    public abstract initialize(): void;
}
