import Component from 'ShopUi/models/component';
import { CheckoutOption } from 'GTMEventTracking/types/google-tag-events';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import LoadingIndicator from 'src/BestIt/ShopUi/components/molecules/loading-indicator/loading-indicator';

// eslint-disable-next-line no-var
declare var trakkenCheckoutData: any;

export default class AddressForm extends Component {
    static CUSTOM_ADDRESS = 'customAddress';

    static RADIO_SELECTOR = 'input[name="addressesForm[shippingAddress][addressChoiceType]"]';

    static CHECKED_RADIO_SELECTOR = `${AddressForm.RADIO_SELECTOR}:checked`;

    static OPTION_ADDRESS_BOOK = 'Select address from address book';

    static OPTION_CUSTOM_ADDRESS = 'Enter alternative delivery address';

    private select : HTMLElement;

    private customAddressForm : HTMLElement;

    private radios : HTMLInputElement[];

    private requiredFields : HTMLElement[];

    private form: HTMLFormElement;

    private trakkenCheckoutData: any;

    private googleEventTagHandler: GoogleTagEvents;

    private submitButton: HTMLButtonElement;

    private loadingIndicator: LoadingIndicator;

    protected readyCallback(): void {
        this.form = <HTMLFormElement> this.querySelector('[name=addressesForm]');
        this.submitButton = <HTMLButtonElement> this.form.querySelector('.button[type=submit]');
        this.select = <HTMLElement> this.querySelector(`.${this.name}__address-book-select`);
        this.radios = Array.from(this.querySelectorAll(AddressForm.RADIO_SELECTOR));
        this.customAddressForm = this.querySelector(`.${this.name}__custom-address-form`);
        this.requiredFields = Array.from(this.querySelectorAll('[required]'));
        this.trakkenCheckoutData = trakkenCheckoutData;
        this.googleEventTagHandler = document.querySelector('.google-tag-events');
        this.loadingIndicator = document.querySelector('loading-indicator') as LoadingIndicator;

        this.initAddressForm();
        this.mapEvents();
    }

    private initAddressForm() {
        const checkedRadio = <HTMLInputElement> this.querySelector(AddressForm.CHECKED_RADIO_SELECTOR);

        this.toggleAddressSelection(checkedRadio);

        if (this.googleEventTagHandler !== null) {
            const checkoutEvent = this.googleEventTagHandler.createCheckout(this.trakkenCheckoutData.ecommerce);
            this.pushTrakkenEvent(this.trakkenCheckoutData.event, checkoutEvent, null, 'load');
        }
    }

    private mapEvents() {
        this.radios.forEach((radio) => {
            radio.addEventListener('change', (event) => {
                const targetElement = <HTMLInputElement> event.target;

                this.toggleAddressSelection(targetElement);
            });
        });

        this.form.addEventListener('submit', (event: Event) => this.onFormSubmit(event));

        this.submitButton.addEventListener('click', () => {
            const invalidFields = this.getInvalidFields();

            if (invalidFields.length === 0) {
                this.loadingIndicator.openLoadingIndicator();
            }
        });
    }

    private toggleAddressSelection(selectedRadio: HTMLInputElement) {
        if (selectedRadio.value === AddressForm.CUSTOM_ADDRESS) {
            AddressForm.hideElement(this.select);
            this.showCustomForm();
            this.trakkenCheckoutData.ecommerce.checkout.actionField.option = AddressForm.OPTION_CUSTOM_ADDRESS;
        } else {
            AddressForm.showElement(this.select);
            this.hideCustomForm();
            this.trakkenCheckoutData.ecommerce.checkout.actionField.option = AddressForm.OPTION_ADDRESS_BOOK;
        }
    }

    private showCustomForm() {
        this.requiredFields.forEach(field => field.setAttribute('required', 'required'));
        AddressForm.showElement(this.customAddressForm);
    }

    private hideCustomForm() {
        this.requiredFields.forEach(field => field.removeAttribute('required'));
        AddressForm.hideElement(this.customAddressForm);
    }

    // eslint-disable-next-line class-methods-use-this
    private static hideElement(element : HTMLElement) {
        element.classList.add('is-hidden');
    }

    // eslint-disable-next-line class-methods-use-this
    private static showElement(element : HTMLElement) {
        element.classList.remove('is-hidden');
    }

    // eslint-disable-next-line max-params
    private pushTrakkenEvent(eventName: string, eventData: any, callback?: Function, eventType?: string) {
        if (this.eventTrackingIsActive) {
            const ecommerce = this.googleEventTagHandler.createEnhancedEcommerce(eventName, eventData, callback);
            ecommerce.cartType = this.trakkenCheckoutData.cartType;
            this.googleEventTagHandler.pushEvent(<any> ecommerce, eventType);
        }
    }

    private onFormSubmit(event: Event) {
        if (this.eventTrackingIsActive) {
            const actionField = this.googleEventTagHandler.createActionField(
                this.trakkenCheckoutData.ecommerce.checkout.actionField,
            );
            const checkoutOptionField = this.googleEventTagHandler.createCheckoutOptionField(actionField);
            const checkoutOption = new CheckoutOption(checkoutOptionField);
            const callback = () => {
                const triggerElement = <HTMLElement> event.target;
                triggerElement.closest('form').submit();
            };
            this.pushTrakkenEvent(GoogleTagEvents.EVENT_EEC_CHECKOUT_OPTION, checkoutOption, callback);
        } else {
            this.form.submit();
        }
    }

    get eventTrackingIsActive(): boolean {
        return this.googleEventTagHandler !== null && typeof this.googleEventTagHandler !== 'undefined';
    }

    private getInvalidFields() : Array<HTMLInputElement|HTMLSelectElement|HTMLTextAreaElement> {
        const selector = 'input:invalid, textarea:invalid, select:invalid, checkbox:invalid';
        return Array.from(this.form.querySelectorAll(selector));
    }
}
