import CustomSubtypeInterface from './custom-subtype-interface';
import MaxLengthStringValue from '../validator-types/max-length-string-value';

/* eslint-disable camelcase */
export interface SearchDealerDataInterface {
    click_location: 'PDP'|'Header';
    product_id: string|undefined;
    dealer_type: 'online'|'offline';
}
export default class SearchDealer implements CustomSubtypeInterface {
    click_location: string;
    product_id: string|undefined;
    dealer_type: 'online'|'offline';

    constructor(data: SearchDealerDataInterface) {
        this.click_location = new MaxLengthStringValue(data.click_location).value;
        this.dealer_type = data.dealer_type;
        this.product_id = data.product_id && new MaxLengthStringValue(data.product_id).value;
    }
}
/* eslint-enable camelcase */
