import CustomSubtypeInterface from './custom-subtype-interface';

/* eslint-disable camelcase */
export interface SelectDealerDataInterface {
    dealer_name: string;
    dealer_type: string;
}

export default class SelectDealer implements CustomSubtypeInterface {
    dealer_name: string;
    dealer_type: string;

    constructor(data: SelectDealerDataInterface) {
        this.dealer_name = data.dealer_name;
        this.dealer_type = data.dealer_type;
    }
}
/* eslint-enable camelcase */
