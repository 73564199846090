import AbstractTabsElement from 'src/BestIt/ShopUi/components/organisms/abstract-tabs-element/abstract-tabs-element';

export default class TabsElement extends AbstractTabsElement {
    /**
     * The initialize function
     *
     * @public
     *
     * @return void
     */
    public initialize(): void {
        super.initialize();
    }
}
