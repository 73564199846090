import Component from 'ShopUi/models/component';
import renderjson from 'renderjson';

export default class StoryblokStub extends Component {
    protected readyCallback(): void {
        // eslint-disable-next-line no-undef
        if (process.env.NODE_ENV === 'development') {
            const jsonContainer = document.createElement('div');
            jsonContainer.classList.add('json-container');

            const componentName = document.createElement('div');
            componentName.classList.add('component-name');

            componentName.textContent = this.json.component;
            jsonContainer.appendChild(componentName);
            jsonContainer.appendChild(renderjson(this.json));
            this.appendChild(jsonContainer);
        }
    }

    get json() {
        return JSON.parse(this.getAttribute('data-json'));
    }
}
