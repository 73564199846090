import Component from 'ShopUi/models/component';

export default class AbstractTabsElement extends Component {
    /**
     * An array of the tab elements
     *
     * @protected
     */
    protected tabs: HTMLElement[];

    /**
     * An array of the content elements of the  tab elements
     *
     * @protected
     */
    protected tabsContent: HTMLElement[];

    /**
     * The current active-tab element
     *
     * @protected
     */
    protected currentActiveTab: HTMLElement;

    /**
     * The current tab content
     *
     * @protected
     */
    protected currentTabContent: HTMLElement;

    /**
     * The selected tab content
     *
     * @protected
     */
    protected selectedTabContent: HTMLElement;

    /**
     * The [data-content] attr of the current active tab
     *
     * @protected
     */
    protected currentActiveTabDataContentAttr: string | null;

    /**
     * The [data-content] attr of the selected tab
     *
     * @protected
     */
    protected selectedTabDataContentAttr: string | null;

    /**
     * A variable to store if the selected tab is disabled
     *
     * @protected
     */
    protected selectedTabIsDisabled: boolean;

    /**
     * A variable to store if the click event is on the same tab
     *
     * @protected
     */
    protected isSameTab: boolean;

    /**
     * The readyCallback function
     *
     * @protected
     *
     * @return void
     */
    protected readyCallback(): void {
        this.initialize();
    }

    /**
     * The initialize function
     *
     * @public
     *
     * @return void
     */
    public initialize(): void {
        if (!this.isFunctionalityDisabled) {
            this.tabs = <HTMLElement[]>Array.from(
                this.querySelectorAll(`.${this.jsName}__nav-item`),
            );
            this.tabsContent = <HTMLElement[]>Array.from(
                this.querySelectorAll(`.${this.jsName}__content`),
            );

            this.mapEvents();
        }
    }

    /**
     * The mapEvents function
     *
     * @protected
     *
     * @return void
     */
    protected mapEvents(): void {
        this.setDefaultTab();
        this.registerTabEvents();
    }

    /**
     * A function to register the events
     *
     * @protected
     *
     * @return void
     */
    protected registerTabEvents(): void {
        this.tabs.forEach((selectedTab: HTMLElement) => {
            selectedTab.addEventListener('click', event => this.onTabClick(selectedTab, event));
        });

        this.tabsContent.forEach((selectedTabContent: HTMLElement) => {
            selectedTabContent.addEventListener('click', event => this.onTabContentClick(selectedTabContent, event));
        });
    }

    /**
     * A function to set a tab as default
     *
     * @protected
     *
     * @return void
     */
    protected setDefaultTab(): void {
        const initialActiveTab = <HTMLElement> this.querySelector(`[data-content='${this.initialActiveTabUid}']`);
        const initialActiveTabContent = <HTMLElement> this.querySelector(this.initialActiveTabUid);

        if (initialActiveTab && initialActiveTabContent) {
            initialActiveTab.classList.add('active');
            initialActiveTabContent.classList.add('active');
            initialActiveTab.classList.remove('is--disabled');
        }
    }

    /**
     * A function to handle the click event on a tab
     *
     * @param selectedTab
     * @param event
     *
     * @protected
     *
     * @return void
     */
    protected onTabClick(selectedTab: HTMLElement, event): void {
        this.currentActiveTab = <HTMLElement> this.querySelector(`.${this.jsName}__nav-item.active`);
        this.currentActiveTabDataContentAttr = <string | null> this.currentActiveTab.getAttribute('data-content');
        this.currentTabContent = <HTMLElement> this.querySelector(this.currentActiveTabDataContentAttr);

        this.selectedTabDataContentAttr = <string | null>selectedTab.getAttribute('data-content');
        this.selectedTabIsDisabled = <boolean>selectedTab.classList.contains('is--disabled');
        this.selectedTabContent = <HTMLElement> this.querySelector(this.selectedTabDataContentAttr);

        this.isSameTab = <boolean>(this.currentActiveTabDataContentAttr === this.selectedTabDataContentAttr);

        if (this.selectedTabIsDisabled) {
            event.stopPropagation();
        } else {
            this.setActiveTab(selectedTab);
            this.setActiveTabContent();
        }
    }

    /**
     * A function to handle the click event on a tab content
     * Note: this function is used for the mobile navigation
     *
     * @param selectedTabContent
     *
     * @protected
     *
     * @return void
     */
    protected onTabContentClick(selectedTabContent, event): void {
        const TabNav = <HTMLElement> this.querySelector(`.${this.jsName}__nav`);

        if (window.getComputedStyle(TabNav).display === 'none') {
            const selectedTabDataContent = selectedTabContent.getAttribute('id');
            const selectedTab = <HTMLElement> this.querySelector(`[data-content='#${selectedTabDataContent}']`);

            this.onTabClick(selectedTab, event);
        }
    }

    /**
     * A function to set the selected tab as active
     * Note: it also calls the functions to reset the selection and to check if the next button needs to be enabled
     *
     * @param selectedTab
     *
     * @protected
     *
     * @return void
     */
    protected setActiveTab(selectedTab: HTMLElement): void {
        if (this.isSameTab === false) {
            this.currentActiveTab.classList.remove('active');
            selectedTab.classList.add('active');
        }
    }

    /**
     * Set the content of the current active tab to visible
     *
     * @protected
     *
     * @return void
     */
    protected setActiveTabContent(): void {
        if (this.isSameTab === false) {
            this.currentTabContent.classList.remove('active');
            this.selectedTabContent.classList.add('active');
        }
    }

    /**
     * Getter to verify if the JS tab functionality should be initialized or not
     *
     * @public
     *
     * @return boolean
     */
    get isFunctionalityDisabled(): boolean {
        return this.dataset.disableFunctionality === 'true';
    }

    /**
     * Getter for the initialActiveTabUid
     *
     * @public
     *
     * @return string
     */
    get initialActiveTabUid(): string {
        return this.dataset.initialTab;
    }
}
