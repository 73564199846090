/* eslint-disable no-undef */
// eslint-disable-next-line spaced-comment
/// <reference types="@types/google.maps" />
import Component from 'ShopUi/models/component';

export default class GoogleMap extends Component {
    private map: google.maps.Map;

    public mapInitialized: boolean;

    private markerElements: google.maps.Marker[];

    private selectedMarker: google.maps.Marker;

    private markerInfoWindow: google.maps.InfoWindow;

    private currentUserMarker: google.maps.Marker;

    private markerImage = {
        url: '/assets/current/default/images/marker_pin_filled.png',
        // This marker is 39 pixels wide by 67 pixels high.
        size: new google.maps.Size(39, 67),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the pin in the middle at (19, 67).
        anchor: new google.maps.Point(19, 67),
    };

    private activeMarkerImage = {
        url: '/assets/current/default/images/marker_pin_filled_yellow.png',
        // This marker is 39 pixels wide by 67 pixels high.
        size: new google.maps.Size(39, 67),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the pin in the middle at (19, 67).
        anchor: new google.maps.Point(19, 67),
    };

    private userMarkerImage = {
        url: '/assets/current/default/images/marker_pin_user.png',
        // This marker is 39 pixels wide by 67 pixels high.
        size: new google.maps.Size(39, 67),
        // The origin for this image is (0, 0).
        origin: new google.maps.Point(0, 0),
        // The anchor for this image is the base of the pin in the middle at (19, 67).
        anchor: new google.maps.Point(19, 67),
    };

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapEvents(): void {
        // Placeholder if we want to add events later
    }

    /**
     * Initializing the Google Map and linking the DOM Element
     * specified in 'elementId to the Map.
     *
     * Method is is named with a Suffix since you are not able
     * to have multiple Instances of the Component otherwise
     */
    public initMap() {
        /**
         * Link Map-API to DOM Element
         */
        this.map = new google.maps.Map(this.mapContainer, this.mapSettings);
        this.mapInitialized = true;

        this.setCountryFocus(this.map);

        this.initMarkers();
    }

    private setCountryFocus(map: google.maps.Map) {
        /**
         * Set Boundries of the Map for the storeCountry
         * given by the mapping from the global store-variable.
         */
        const storeCountry = this.country;
        const geocoder = new google.maps.Geocoder();
        geocoder.geocode({
            address: storeCountry,
            componentRestrictions: {
                country: this.store,
            },
        }, (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
                map.setCenter(results[0].geometry.location);
                map.fitBounds(results[0].geometry.viewport);
            }
        });
    }

    /**
     * Drawing the Markers on the Google Map
     *
     * Method is is named with a Suffix since you are not able
     * to have multiple Instances of the Component otherwise
     *
     */
    private setMarkers() {
        this.markers.forEach((marker) => {
            marker = JSON.parse(marker);

            const mapMarker = this.createMarker(marker);

            this.markerElements.push(mapMarker);
        });

        this.addMarkerEventListener();

        this.setMapBounds();
    }

    get mapContainer(): HTMLElement {
        return <HTMLElement>document.querySelector(this.mapSelector);
    }

    get resultListContainer(): HTMLElement {
        return <HTMLElement>document.querySelector(this.resultListSelector);
    }

    get mapSelector(): string {
        return this.getAttribute('data-map-selector');
    }

    get resultListSelector(): string {
        return this.getAttribute('data-result-list-selector');
    }

    get mapSettings(): any {
        const mapConfig: string = this.getAttribute('data-map-settings');
        return JSON.parse(mapConfig);
    }

    get country(): string {
        return this.getAttribute('data-country');
    }

    get store(): string {
        return this.getAttribute('data-store');
    }

    get markers() {
        let markerSet = [];

        if (this.getAttribute('data-markers') !== '') {
            markerSet = Array.from(this.getAttribute('data-markers').split(';'));
        }

        return markerSet;
    }

    get userMarker(): string {
        return this.getAttribute('data-user-marker');
    }

    private addMarkerEventListener() {
        // eslint-disable-next-line consistent-this
        const self = this;

        if (this.markerElements.length === 0) {
            return;
        }

        this.markerElements.forEach((markerElement: google.maps.Marker) => {
            markerElement.addListener('click', () => {
                self.selectMarker(markerElement);
                self.setSelectedListItem(markerElement);
            });

            markerElement.addListener('mouseover', () => {
                self.showTooltip(markerElement);
            });

            markerElement.addListener('mouseout', () => {
                self.hideTooltip();
            });
        });
    }

    public unselectMarker() {
        if (this.selectedMarker) {
            this.selectedMarker.setIcon(this.markerImage);
            this.selectedMarker = null;
        }

        this.unselectListItem();
    }

    private selectMarker(marker: google.maps.Marker) {
        this.unselectMarker();

        this.map.setCenter(marker.getPosition());

        marker.setIcon(this.activeMarkerImage);

        this.selectedMarker = marker;
    }

    public initMarkers() {
        if (typeof this.markerElements !== 'undefined' &&
            this.markerElements !== null &&
            this.markerElements.length > 0
        ) {
            this.markerElements.forEach((marker: google.maps.Marker) => {
                marker.setMap(null);
            });
        }

        this.markerElements = [];

        this.setUserMarker();

        this.setMarkers();
    }

    public setMapBounds() {
        let maxLat: number;
        let maxLng: number;
        let minLat: number;
        let minLng: number;

        if (typeof this.currentUserMarker !== 'undefined') {
            maxLat = this.currentUserMarker.getPosition().lat();
            maxLng = this.currentUserMarker.getPosition().lng();
            minLat = this.currentUserMarker.getPosition().lat();
            minLng = this.currentUserMarker.getPosition().lng();
        }

        if (this.markerElements.length !== 0) {
            this.markerElements.forEach((markerElement: google.maps.Marker) => {
                maxLat = GoogleMap.getBiggerValue(maxLat, markerElement.getPosition().lat());
                maxLng = GoogleMap.getBiggerValue(maxLng, markerElement.getPosition().lng());
                minLat = GoogleMap.getSmallerValue(minLat, markerElement.getPosition().lat());
                minLng = GoogleMap.getSmallerValue(minLng, markerElement.getPosition().lng());
            });
        }

        if (this.markerElements.length === 0 && typeof this.currentUserMarker === 'undefined') {
            return;
        }

        const minPos = new google.maps.LatLng(minLat, minLng);
        const maxPos = new google.maps.LatLng(maxLat, maxLng);

        const bounds = new google.maps.LatLngBounds(minPos, maxPos);

        this.map.fitBounds(bounds);
    }

    private static getBiggerValue(currentMaxValue: number, testValue: number) {
        if (currentMaxValue === undefined) {
            return testValue;
        }

        if (currentMaxValue < testValue) {
            return testValue;
        }

        return currentMaxValue;
    }

    private static getSmallerValue(currentMinValue: number, testValue: number) {
        if (currentMinValue === undefined) {
            return testValue;
        }

        if (currentMinValue > testValue) {
            return testValue;
        }

        return currentMinValue;
    }

    private createMarker(marker: any) {
        return new google.maps.Marker({
            position: {
                lat: typeof marker.lat !== 'undefined' ? marker.lat : 0,
                lng: typeof marker.lng !== 'undefined' ? marker.lng : 0,
            },
            map: this.map,
            // eslint-disable-next-line dot-notation
            icon: typeof marker['currentLocation'] === 'undefined' ? this.markerImage : this.userMarkerImage,
            shape: {
                coords: [1, 18, 10, 1, 29, 3, 38, 13, 35, 27, 28, 44, 20, 65, 11, 43],
                type: 'poly',
            },
        });
    }

    private setUserMarker() {
        if (typeof this.currentUserMarker !== 'undefined') {
            this.currentUserMarker.setMap(null);
        }
        try {
            const marker = JSON.parse(this.userMarker);
            this.currentUserMarker = this.createMarker(marker);
        } catch (exception) {
            // Do nothing
        }
    }

    private setSelectedListItem(marker: google.maps.Marker) {
        const lat = marker.getPosition().lat();
        const lng = marker.getPosition().lng();
        const listItemSelector = `[data-lat="${lat}"][data-lng="${lng}"]`;
        const listItemElement = <HTMLElement> this.resultListContainer.querySelector(listItemSelector);

        this.unselectListItem();

        if (listItemElement) {
            listItemElement.scrollTo();

            this.dispatchCustomEvent('markerSelected', listItemElement);
        }
    }

    private unselectListItem() {
        const previousSelectedItem = this.resultListContainer.querySelector('.list-result-item.is-selected');

        if (previousSelectedItem) {
            previousSelectedItem.classList.remove('is-selected');
        }
    }

    public selectMarkerByPosition(lat: number, lng: number) {
        this.markerElements.forEach((marker: google.maps.Marker) => {
            if (marker.getPosition().lat() === lat && marker.getPosition().lng() === lng) {
                this.selectMarker(marker);
            }
        });
    }

    public zoomToMarker() {
        this.map.setZoom(15);
    }

    public zoomTo(zoom: number) {
        this.map.setZoom(zoom);
    }

    private showTooltip(marker: google.maps.Marker) {
        const lat = marker.getPosition().lat();
        const lng = marker.getPosition().lng();
        const listItemSelector = `[data-lat="${lat}"][data-lng="${lng}"]`;
        const listItemElement = <HTMLElement> this.resultListContainer.querySelector(listItemSelector);

        if (listItemElement) {
            const markerData = JSON.parse(listItemElement.getAttribute('data-json'));

            this.hideTooltip();

            let address = '';

            if (markerData.address_info) {
                const street = markerData.address_info.street;
                const postCode = markerData.address_info.postcode;
                const city = markerData.address_info.city;
                address = `${street}<br />${postCode} ${city}`;
            }

            this.markerInfoWindow = new google.maps.InfoWindow({
                content: `<h5>${markerData.name}</h5><p>${address}</p>`,
            });

            this.markerInfoWindow.open(this.map, marker);
        }
    }

    private hideTooltip() {
        if (this.markerInfoWindow) {
            this.markerInfoWindow.close();
        }
    }

    // eslint-disable-next-line class-methods-use-this
    protected dispatchCustomEvent(name: string, detail: any = {}): void {
        const customEvent = new CustomEvent(name, { detail });
        document.dispatchEvent(customEvent);
    }
}
