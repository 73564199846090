import AbstractGa4CustomEventWithDataMapper
    from 'GA4EventTracking/types/event-mappers/abstract-ga4-custom-event-with-data-mapper';
import ShowMore, { ShowMoreDataInterface } from 'GA4EventTracking/types/events/custom-subtypes/show-more';
import CustomSubtypeInterface from 'GA4EventTracking/types/events/custom-subtypes/custom-subtype-interface';

export default class Ga4CustomShowMoreEventMapper extends AbstractGa4CustomEventWithDataMapper {
    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(data: ShowMoreDataInterface): CustomSubtypeInterface {
        return new ShowMore(data);
    }
}
