import Component from 'ShopUi/models/component';
import KeenSliderCarousel from 'src/BestIt/ShopUi/components/molecules/keen-slider-carousel/keen-slider-carousel';

export default class ZoomOverlay extends Component {
    /**
     * The triggers for this zoom overlay
     *
     * @protected
     */
    protected overlayTriggers: HTMLElement[];

    /**
     * The image slider instance
     *
     * @protected
     */
    protected slider: KeenSliderCarousel;

    /**
     * The transparent background of the overlay (needed for the close behavior if you click outside the slider)
     *
     * @private
     */
    protected overlayBackground: HTMLElement;

    /**
     * The close button of the overlay
     *
     * @protected
     */
    protected closeButton: HTMLElement;

    /**
     * Flag if the image slider got already initialized or not
     */
    public sliderIsInitialized: boolean = false;

    /**
     * Base function for the components
     *
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Init function for the component
     *
     * @public
     */
    public initialize() {
        this.slider = <KeenSliderCarousel> this.querySelector(`.${this.name}__slider`);
        this.overlayBackground = <HTMLElement> this.querySelector(`.${this.name}__background`);
        this.closeButton = <HTMLElement> this.querySelector(`.${this.jsName}__close-button`);

        if (this.triggerSelector !== null && this.triggerSelector !== '') {
            this.overlayTriggers = <HTMLElement[]> Array.from(document.querySelectorAll(this.triggerSelector));
        }

        this.mapEvents();
    }

    /**
     * Adds the events for the images and the overlay
     *
     * @protected
     */
    protected mapEvents(): void {
        this.closeButton.addEventListener('click', () => this.close());
        this.overlayBackground.addEventListener('click', () => this.close());

        if (typeof this.overlayTriggers !== 'undefined' &&
            this.overlayTriggers !== null &&
            this.overlayTriggers.length > 0
        ) {
            this.overlayTriggers.forEach((trigger) => {
                trigger.addEventListener('click', () => this.open());
            });
        }
    }

    /**
     * Opens the overlay
     */
    public open(): void {
        this.classList.remove('is-hidden');
    }

    /**
     * Opens the overlay and moves the slider to the given position
     */
    public openSlideOnSelectedPosition(position: string|number): void {
        this.open();

        if (!this.sliderIsInitialized) {
            this.slider.initialize();
            this.sliderIsInitialized = true;
        }

        this.slider.sliderReinit();

        setTimeout(() => {
            this.slider.moveToSlide(position);
        }, 100);
    }

    /**
     * Closes the overlay
     */
    public close(): void {
        this.classList.add('is-hidden');
    }

    /**
     * Getter for the trigger selector for the open event
     */
    get triggerSelector(): string {
        return this.dataset.triggerSelector || '';
    }
}
