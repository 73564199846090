/* eslint-disable no-undef */
// eslint-disable-next-line spaced-comment
/// <reference types="@types/google.maps" />
import Component from 'ShopUi/models/component';
import AjaxProvider from 'ShopUi/components/molecules/ajax-provider/ajax-provider';
import debounce from 'lodash-es/debounce';
import GoogleMap from 'src/BestIt/ShopUi/components/molecules/google-map/google-map';
import LoadingIndicator from 'src/BestIt/ShopUi/components/molecules/loading-indicator/loading-indicator';
import UserCentrics from 'src/BestIt/ShopUi/components/molecules/user-centrics/user-centrics';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import { mount } from 'ShopUi/app';
import Ga4EventTracking from 'GA4EventTracking/components/molecules/ga4-event-tracking/ga4-event-tracking';

export default class LocalStores extends Component {
    private searchContainer: HTMLElement;

    private detailContainer: HTMLElement;

    private localStoreDetailsElementPrefix: string = 'local-store-detail';

    private form: HTMLFormElement;

    private resultsAjaxProvider: AjaxProvider;

    private addressContainer: HTMLElement;

    private addressResultContainer: HTMLElement;

    private noResultsContainer: HTMLElement;

    private resultContainer: HTMLElement;

    private resultItems: HTMLElement[];

    private inputField: HTMLInputElement;

    private selectField: HTMLSelectElement;

    private submitButton: HTMLButtonElement;

    private hiddenLat: HTMLInputElement;

    private hiddenLng: HTMLInputElement;

    private closeDetailButton: HTMLElement;

    private map: GoogleMap;

    private loadingIndicator: LoadingIndicator;

    private googleEventHandler: GoogleTagEvents;

    private userCentricsHandler: UserCentrics;

    protected ga4EventTracking: Ga4EventTracking = null;

    protected readyCallback(): void {
        if (LocalStores.modalWindows !== null) {
            LocalStores.modalWindows.forEach((modalWindow) => {
                modalWindow.addEventListener('opened', () => {
                    if (this.map.mapInitialized !== true) {
                        this.map.initMap();
                        this.submitButton.removeAttribute('disabled');
                    } else {
                        this.closeDetailButton.dispatchEvent(new Event('click'));
                        this.map.unselectMarker();
                        this.map.zoomTo(6);
                    }
                });
            });
        }

        this.searchContainer = <HTMLElement> this.querySelector(`.${this.name}__search`);
        this.detailContainer = <HTMLElement> this.querySelector(`.${this.name}__detail`);
        this.form = this.querySelector(`.${this.name}__search-form`);
        this.inputField = <HTMLInputElement> this.querySelector('input[name=address]');
        this.submitButton = <HTMLButtonElement> this.querySelector(`.${this.name}__find-button .button`);
        this.hiddenLat = <HTMLInputElement> this.querySelector('input[name=lat]');
        this.hiddenLng = <HTMLInputElement> this.querySelector('input[name=lng]');
        this.selectField = <HTMLSelectElement> this.querySelector('select[name=distance]');
        this.resultsAjaxProvider = <AjaxProvider>(
            this.querySelector(`.${this.name}__results-ajax-provider`)
        );
        this.resultContainer = <HTMLElement> this.querySelector(`.${this.name}__results`);
        this.addressContainer = <HTMLElement> this.querySelector(`.${this.name}__address-container`);
        this.addressResultContainer = <HTMLElement> this.querySelector(`.${this.name}__address-results-container`);
        this.noResultsContainer = <HTMLElement> this.querySelector(`.${this.name}__no-results-found`);
        if (this.detailContainer !== null) {
            this.closeDetailButton = this.querySelector(this.detailContainer.getAttribute('data-close-trigger'));
        }

        this.map = <GoogleMap>(
            this.querySelector(`.${this.name}__map .google-map`)
        );

        this.loadingIndicator = this.querySelector('loading-indicator') as LoadingIndicator;
        this.googleEventHandler = <GoogleTagEvents> document.querySelector('.google-tag-events');
        this.ga4EventTracking = <Ga4EventTracking> document.querySelector('.ga4-event-tracking');
        this.userCentricsHandler = <UserCentrics> document.querySelector('.user-centrics');

        document.addEventListener('consentManagementInitialized', () => {
            try {
                this.userCentricsHandler.checkGoogleMapStatus();
            } catch (exception) {
                // Do nothing
            }
        });

        document.addEventListener('googleMapConsentStatusLoaded', () => {
            if (LocalStores.findDealerPage !== null &&
                this.map !== null &&
                this.map.mapInitialized !== true &&
                this.getGoogleMapConsentStatus()
            ) {
                this.map.initMap();
                this.submitButton.removeAttribute('disabled');
            }

            if (this.inputField !== null) {
                this.mapEvents();
            }
        });
    }

    private static get modalWindows() {
        return document.querySelectorAll('.modal-window[data-trigger*=find-deal]');
    }

    private static get findDealerPage(): HTMLElement|null {
        return document.querySelector('.find-dealer-page');
    }

    protected mapEvents(): void {
        this.inputField.addEventListener(
            'keyup',
            debounce(
                () => this.onInputChange(),
                500,
            ),
        );
        this.form.addEventListener('submit', (event: Event) => this.onSubmit(event));
        this.addEventListener('click', (event: Event) => {
            if (event.target !== this.addressContainer && event.target !== this.inputField) {
                this.addressResultContainer.classList.remove('hasResults');
            } else if (event.target === this.inputField &&
                !this.addressResultContainer.classList.contains('hasResults')
            ) {
                this.addressResultContainer.classList.add('hasResults');
            }
        });

        if (this.closeDetailButton) {
            this.closeDetailButton.addEventListener('click', (event: Event) => this.closeStoreDetail(event));
        }

        if (typeof this.resultsAjaxProvider !== 'undefined' && this.resultsAjaxProvider !== null) {
            this.resultsAjaxProvider.addEventListener('fetching', () => this.loadingIndicator.openLoadingIndicator());
            this.resultsAjaxProvider.addEventListener('fetched', () => this.loadingIndicator.closeLoadingIndicator());
        }

        document.addEventListener('markerSelected', (event: CustomEvent) => {
            const listElement = event.detail;
            const storeInfo = JSON.parse(listElement.getAttribute('data-json'));

            this.unselectListItem();
            this.selectListItem(listElement);
            this.showStoreDetail(storeInfo);
        });
    }

    private async onSubmit(event: Event) {
        event.preventDefault();

        if (typeof this.googleEventHandler !== 'undefined' && this.googleEventHandler !== null) {
            let eventData = this.googleEventHandler.getGtmEventData(this.submitButton);
            eventData = {
                ...eventData,
                eventLabel: this.getFieldValue(this.selectField),
            };
            const gaEvent = this.googleEventHandler.createGaEvent(eventData);
            this.googleEventHandler.pushEvent(<any> gaEvent);
        }
        this.triggerGA4FindOfflineDealerEvent(this.selectField.value);

        await this.fetchResultData();

        this.resultItems = <HTMLElement[]>Array.from(this.resultContainer.querySelectorAll(`.${this.name}-list__item`));

        if (this.resultItems.length === 0) {
            document.querySelector(`.${this.name}-list__no-results-found`).classList.remove('is-hidden');
        }

        const geoData = [];
        const currentLocation = `{"lat": ${this.hiddenLat.value}, "lng": ${this.hiddenLng.value}` +
            `, "currentLocation": true}`;
        this.resultItems.forEach((item) => {
            geoData.push(item.getAttribute('data-geo-data'));

            item.addEventListener('click', () => {
                const markerLat = parseFloat(item.getAttribute('data-lat'));
                const markerLng = parseFloat(item.getAttribute('data-lng'));
                const storeInfo = JSON.parse(item.getAttribute('data-json'));

                this.unselectListItem();
                this.showStoreDetail(storeInfo);
                this.map.selectMarkerByPosition(markerLat, markerLng);
                this.selectListItem(item);
            });
        });

        this.map.setAttribute('data-markers', geoData.join(';'));
        this.map.setAttribute('data-user-marker', currentLocation);

        this.map.initMarkers();
    }

    private async fetchResultData() {
        const formData = new FormData(this.form);

        const latValue = this.getFieldValue(this.hiddenLat);
        const lngValue = this.getFieldValue(this.hiddenLng);

        this.resultsAjaxProvider.queryParams.set(this.selectField.name, this.getFieldValue(this.selectField));
        this.resultsAjaxProvider.queryParams.set(this.hiddenLat.name, latValue);
        this.resultsAjaxProvider.queryParams.set(this.hiddenLng.name, lngValue);

        this.resultContainer.innerHTML = await this.resultsAjaxProvider.fetch(formData);
        await mount();
    }

    // eslint-disable-next-line class-methods-use-this
    private getFieldValue(field: any): any {
        let value = null;

        if (!(field.value instanceof String)) {
            value = field.value;
        } else {
            value = field.value.trim;
        }

        return value;
    }

    private onInputChange(): void {
        const addressContainer = this.addressResultContainer;
        const { hiddenLat } = this;
        const { hiddenLng } = this;
        const { inputField } = this;

        const searchTerm = this.getFieldValue(this.inputField);

        if (searchTerm.length < 3) {
            return;
        }

        const geoCoder = new google.maps.Geocoder();
        geoCoder.geocode({
            address: searchTerm,
        }, (results, status) => {
            addressContainer.innerHTML = '';

            if (status === google.maps.GeocoderStatus.OK) {
                results.forEach((result) => {
                    const listElement = document.createElement('li');
                    const dataAddressAttr = document.createAttribute('data-address');
                    dataAddressAttr.value = <string>result.formatted_address;
                    const dataLatAttr = document.createAttribute('data-lat');
                    dataLatAttr.value = result.geometry.location.lat().toString();
                    const dataLngAttr = document.createAttribute('data-lng');
                    dataLngAttr.value = result.geometry.location.lng().toString();

                    listElement.setAttributeNode(dataAddressAttr);
                    listElement.setAttributeNode(dataLatAttr);
                    listElement.setAttributeNode(dataLngAttr);
                    listElement.innerText = <string>result.formatted_address;
                    addressContainer.appendChild(listElement);

                    listElement.addEventListener('click', () => {
                        hiddenLat.value = listElement.getAttribute('data-lat');
                        hiddenLng.value = listElement.getAttribute('data-lng');
                        inputField.value = listElement.getAttribute('data-address');
                        addressContainer.classList.remove('hasResults');
                    });
                });
                addressContainer.classList.add('hasResults');
            } else {
                const listElement = document.createElement('li');
                listElement.innerText = addressContainer.getAttribute('data-empty-text');
                addressContainer.innerHTML = '';
                addressContainer.appendChild(listElement);
                addressContainer.classList.remove('hasResults');
            }
        });
    }

    public showStoreDetail(storeInfo: any): void {
        this.triggerGA4SelectDealerEvent(storeInfo.name);
        this.populateStoreDetails(storeInfo);

        this.searchContainer.classList.add('is-hidden');
        this.detailContainer.classList.remove('is-hidden');

        this.map.zoomToMarker();
    }

    private closeStoreDetail(event: Event): void {
        event.preventDefault();
        this.searchContainer.classList.remove('is-hidden');
        this.detailContainer.classList.add('is-hidden');

        this.map.setMapBounds();
    }

    private populateStoreDetails(storeInfo: any): void {
        const storeDetailSelector = `.${this.localStoreDetailsElementPrefix}__content`;
        const storeDetailServicesContainer = `.${this.localStoreDetailsElementPrefix}__services-container`;
        const storeDetailBlock = this.detailContainer.querySelector(storeDetailSelector);
        const storeNameBlock = storeDetailBlock.querySelector('.store-name');
        const storeAddressBlock = storeDetailBlock.querySelector('.store-address');
        const storeOpeningHoursBlock = storeDetailBlock.querySelector('.store-openingHours');
        const storeWebsiteBlock = storeDetailBlock.querySelector('.store-website');
        const storeEmailBlock = storeDetailBlock.querySelector('.store-email');
        const storePhoneBlock = storeDetailBlock.querySelector('.store-phone');
        const storeLogoBlock = storeDetailBlock.querySelector('.store-logo');
        const storeLightplanningBlock = storeDetailBlock.querySelector('.store-lightplanningService');
        const storeInstallationBlock = storeDetailBlock.querySelector('.store-installationService');
        const storeShowroomBlock = storeDetailBlock.querySelector('.store-showroom');

        let address = '';
        let openingHours = '';

        if (storeInfo.address_info) {
            const street = storeInfo.address_info.street;
            const postCode = storeInfo.address_info.postcode;
            const city = storeInfo.address_info.city;
            address = `${street}<br />${postCode} ${city}`;
        }

        if (storeInfo.opening_hours) {
            // eslint-disable-next-line no-unused-vars
            Object.entries(storeInfo.opening_hours).forEach(([key, openingHourEntry]) => {
                /* eslint-disable dot-notation */
                openingHours += `
                    <div class="local-store-detail__opening-hours-container">
                        <span class="weekday-container">${openingHourEntry['weekday']} : &nbsp; </span>
                        <span class="opentime-container">${openingHourEntry['opentime']}</span> &nbsp; - &nbsp;
                        <span class="closetime-container">${openingHourEntry['closetime']}</span>
                    </div>
                `;
                /* eslint-enable dot-notation */
            });
        }

        const lightplanning = LocalStores.checkService(
            storeInfo.custom_fields.lightplanning_service,
            storeLightplanningBlock,
        );
        const installation = LocalStores.checkService(
            storeInfo.custom_fields.installation_service,
            storeInstallationBlock,
        );
        const showroom = LocalStores.checkService(storeInfo.custom_fields.s_lv_showroom, storeShowroomBlock);
        if (lightplanning === false && installation === false && showroom === false) {
            storeDetailBlock.querySelector(storeDetailServicesContainer).classList.add('is-hidden');
        }

        LocalStores.setDetailStoreBlockValue(storeNameBlock, storeInfo.name, 'h2');
        LocalStores.setDetailStoreBlockValue(storeAddressBlock, address);
        LocalStores.setDetailStoreBlockValue(storeOpeningHoursBlock, openingHours);
        LocalStores.setDetailStoreBlockValue(storeWebsiteBlock, storeInfo.contact_info.website, 'link');
        LocalStores.setDetailStoreBlockValue(storeEmailBlock, storeInfo.contact_info.email, 'email');
        LocalStores.setDetailStoreBlockValue(storePhoneBlock, storeInfo.contact_info.phone, 'phone');
        LocalStores.setDetailStoreBlockValue(storeLogoBlock, storeInfo.logos.logo_small, 'img');
    }

    private static setDetailStoreBlockValue(detailBlockElement: Element, value: string, type: string = 'text'): void {
        if (!detailBlockElement || !value) {
            detailBlockElement.classList.add('is-hidden');
            return;
        }

        if (type === 'email' || type === 'link' || type === 'phone') {
            const valueBlockElement = <HTMLAnchorElement> detailBlockElement.querySelector('.value');
            valueBlockElement.innerHTML = value;

            valueBlockElement.removeEventListener('click', () => {
                window.open(valueBlockElement.href, '_blank');
            });

            if (type === 'email') {
                valueBlockElement.href = `mailto:${value}`;
            }

            if (type === 'link') {
                let link = value;

                if (link.indexOf('http://') === -1 && link.indexOf('https://') === -1) {
                    link = `http://${link}`;
                }

                valueBlockElement.href = link;
            }

            if (type === 'phone') {
                valueBlockElement.href = `tel:${value}`;
            }

            valueBlockElement.addEventListener('click', () => {
                window.open(valueBlockElement.href, '_blank');
            });
        } else if (type === 'img') {
            const valueBlockElement = <HTMLImageElement> detailBlockElement.querySelector('.value');
            valueBlockElement.innerHTML = value;

            if (type === 'img') {
                valueBlockElement.src = value;
            }
        } else if (type === 'class') {
            const valueBlockElement = detailBlockElement.querySelector('.value');
            const classNames = value.split(' ');
            classNames.forEach((className) => {
                valueBlockElement.classList.add(className);
            });
        } else {
            const valueBlockElement = detailBlockElement.querySelector('.value');
            valueBlockElement.innerHTML = value;
        }

        detailBlockElement.classList.remove('is-hidden');
    }

    /**
     * @param field
     * @param block
     * @private
     */
    private static checkService(field, block): boolean {
        if (field === 'true') {
            LocalStores.setDetailStoreBlockValue(block, 'icon icon--checked', 'class');
            return true;
        }
        LocalStores.setDetailStoreBlockValue(block, '');
        return false;
    }

    private selectListItem(listItem: HTMLElement): void {
        listItem.classList.add('is-selected');

        if (typeof this.googleEventHandler !== 'undefined' && this.googleEventHandler !== null) {
            const eventData = this.googleEventHandler.getGtmEventData(listItem);
            const event = this.googleEventHandler.createGaEvent(eventData);
            this.googleEventHandler.pushEvent(<any> event);
        }
    }

    private unselectListItem() {
        const previousSelectedItem = this.addressResultContainer.querySelector('.list-result-item.is-selected');

        if (previousSelectedItem) {
            previousSelectedItem.classList.remove('is-selected');
        }
    }

    private getGoogleMapConsentStatus(): boolean {
        return this.userCentricsHandler.googleMapsConsentStatus;
    }

    /**
     * A function to push the Google Analytics 4 event - find offline dealer
     */
    private triggerGA4FindOfflineDealerEvent(radius: string): void {
        if (typeof this.ga4EventTracking !== 'undefined' && this.ga4EventTracking !== null) {
            const eventElement = document.createElement('div');
            eventElement.setAttribute('data-ga4-event-name', 'find_offline_dealer');
            eventElement.setAttribute(
                'data-ga4-event-data',
                `{"radius": "${radius}"}`
            );

            this.ga4EventTracking.createAndPushEvent(eventElement);
        }
    }

    /**
     * A function to push the Google Analytics 4 event - select dealer
     */
    private triggerGA4SelectDealerEvent(storeName: string): void {
        if (typeof this.ga4EventTracking !== 'undefined' && this.ga4EventTracking !== null) {
            const eventElement = document.createElement('div');
            eventElement.setAttribute('data-ga4-event-name', 'select_dealer');
            eventElement.setAttribute(
                'data-ga4-event-data',
                `{"dealer_name": "${storeName}", "dealer_type": "offline"}`
            );

            this.ga4EventTracking.createAndPushEvent(eventElement);
        }
    }
}
