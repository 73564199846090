import AbstractTabsElement from 'src/BestIt/ShopUi/components/organisms/abstract-tabs-element/abstract-tabs-element';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';

export default class ConfiguratorTabsElement extends AbstractTabsElement {
    /**
     * The hidden input fields
     *
     * @protected
     */
    protected hiddenInputFields: HTMLInputElement[];

    /**
     * The prev-button
     *
     * @protected
     */
    protected prevButton: HTMLElement;

    /**
     * The id of the prev-step (which is the id of the previous tab element)
     *
     * @protected
     */
    protected prevStepId: string;

    /**
     * A variable to store if the click event is on the same tab
     *
     * @protected
     */
    protected isSameTab: boolean;

    /**
     * The next button
     *
     * @protected
     */
    protected nextButton: HTMLElement;

    /**
     * The initialize function
     *
     * @public
     *
     * @return void
     */
    public initialize(): void {
        this.hiddenInputFields = <HTMLInputElement[]>Array.from(
            document.querySelectorAll('.js-system-step .input--hidden'),
        );

        this.prevButton = document.querySelector('.system-step__prev-step-button');
        this.nextButton = document.querySelector('.system-step__next-step-button');

        super.initialize();
    }

    /**
     * The mapEvents function
     *
     * @private
     *
     * @return void
     */
    protected mapEvents(): void {
        this.resetHiddenInputFields();
        this.registerGoogleTagManagerEvents();

        super.mapEvents();
    }

    /**
     * Register the google tag manager events
     *
     * @protected
     *
     * @return void
     */
    protected registerGoogleTagManagerEvents(): void {
        this.nextButton.addEventListener('click', () => {
            const gtmEvents = new GoogleTagEvents();
            const selectedPhaseType = <string | null> document.querySelector(
                'input[name=\'trackConfigurator[systemPhase]\']'
            ).getAttribute('data-name');
            const selectedMountingType = <string | null> document.querySelector(
                'input[name=\'trackConfigurator[systemMounting]\']'
            ).getAttribute('data-name');

            const data = {
                event: 'gaEvent',
                eventCategory: 'Track Configurator',
                eventAction: 'Select Technology',
                eventLabel: `${selectedPhaseType} - ${selectedMountingType}`,
                eventValue: undefined,
            };
            gtmEvents.pushEvent(data, 'gaEvent');
        });
    }

    /**
     * A function to handle the click event on a tab
     *
     * @param selectedTab
     * @param event
     *
     * @private
     *
     * @return void
     */
    protected onTabClick(selectedTab: HTMLElement, event): void {
        super.onTabClick(selectedTab, event);

        this.isSameTab = <boolean>(this.currentActiveTabDataContentAttr === this.selectedTabDataContentAttr);
        this.prevStepId = this.selectedTabContent
            .querySelector('.abstract-system-selection-tab')
            .getAttribute('data-prev-step');

        this.prevButton.setAttribute('data-prev-step', this.prevStepId);

        if (this.selectedTabIsDisabled) {
            event.stopPropagation();
        } else {
            this.setPrevButtonDisplayStatus();
        }
    }

    /**
     * A recursive function to reset the selection of the tabs, boxes and hidden fields
     *
     * @param currentStepSelector
     * @param nextStepSelector
     * @param nextHiddenFieldSelector
     *
     * @private
     *
     * @return void
     */
    private resetSelection(currentStepSelector, nextStepSelector, nextHiddenFieldSelector): void {
        const boxes = <HTMLElement[]>Array.from(
            document.querySelectorAll(`${currentStepSelector} .js-abstract-system-selection-box`)
        );
        const hiddenField = <HTMLInputElement>document.querySelector(`[name="${nextHiddenFieldSelector}"]`);

        hiddenField.value = '';
        boxes.forEach((box) => {
            box.classList.remove('is--selected');
        });

        if (nextStepSelector) {
            const nextTab = document.querySelector(`[data-content="${nextStepSelector}"]`);
            const nextStepAttr = document
                .querySelector(`${nextStepSelector} .abstract-system-selection-tab`)
                .getAttribute('data-next-step');
            // eslint-disable-next-line no-shadow
            const nextHiddenFieldSelector = document
                .querySelector(`${nextStepSelector} .js-abstract-system-selection-box`)
                .getAttribute('data-box-type');

            nextTab.classList.add('is--disabled');

            this.resetSelection(nextStepSelector, nextStepAttr, nextHiddenFieldSelector);
        }
    }

    /**
     * A function to set the selected tab as active
     * Note: it also calls the functions to reset the selection and to check if the next button needs to be enabled
     *
     * @param selectedTab
     *
     * @protected
     *
     * @return void
     */
    protected setActiveTab(selectedTab: HTMLElement): void {
        if (this.isSameTab === false) {
            const abstractSystemSelectionTab = <HTMLElement> this.selectedTabContent.querySelector(
                '.abstract-system-selection-tab'
            );
            const nextStepSelector = <string | null>abstractSystemSelectionTab.getAttribute('data-next-step');
            const currentHiddenFieldSelector = <string | null>document
                .querySelector(`${this.selectedTabDataContentAttr} .js-abstract-system-selection-box`)
                .getAttribute('data-box-type');

            if (this.selectedTabDataContentAttr === '#tabAssembly') {
                const boxes = <HTMLElement[]>Array.from(
                    document.querySelectorAll(`${this.selectedTabDataContentAttr} .js-abstract-system-selection-box`)
                );
                const selectedPhaseType = <string | null> document.querySelector(
                    'input[name=\'trackConfigurator[systemPhase]\']'
                ).getAttribute('value');

                boxes.forEach((box) => {
                    const image = box.querySelector('.image-wrapper__image');
                    const boxValueData = box.getAttribute('data-box-value');
                    const boxImageSrc = '/assets/current/default/images/track-configurator/' +
                        `${selectedPhaseType}-${boxValueData}.svg`;

                    image.setAttribute('src', boxImageSrc);
                });
            }

            this.currentActiveTab.classList.remove('active');
            selectedTab.classList.add('active');
            this.resetSelection(this.selectedTabDataContentAttr, nextStepSelector, currentHiddenFieldSelector);
            this.enableNextButton();
        }
    }

    /**
     * A function to enable the next button if all hidden fields are set
     * Note: the following hidden fields are automatically set on page load:
     * - trackConfigurator[_token]
     * - trackConfigurator[systemStepCompleted]
     *
     * @private
     *
     * @return void
     */
    // eslint-disable-next-line class-methods-use-this
    private enableNextButton(): void {
        const hiddenInputFields = <HTMLInputElement[]>Array.from(
            document.querySelectorAll('.js-system-step .input--hidden')
        );
        const nextStepButton = <HTMLElement>document.querySelector('.system-step__next-step-button');
        let enableButton = <boolean>true;

        if (hiddenInputFields !== undefined || hiddenInputFields.length !== 0) {
            hiddenInputFields.forEach((hiddenInputField: HTMLInputElement) => {
                const hiddenInputFieldValue = hiddenInputField.value;

                if (hiddenInputFieldValue === '') {
                    enableButton = false;
                }
            });
        }

        enableButton === true ?
            nextStepButton.classList.remove('is--disabled') :
            nextStepButton.classList.add('is--disabled');
    }

    /**
     * A function to set the visibility status of the prev-button
     *
     * @private
     *
     * @return void
     */
    private setPrevButtonDisplayStatus(): void {
        this.prevStepId ? this.prevButton.classList.remove('is-hidden') : this.prevButton.classList.add('is-hidden');
    }

    /**
     * A function to reset the hidden input fields
     * Note: the following hidden input fields are excluded:
     * - trackConfigurator[_token]
     * - trackConfigurator[systemStepCompleted]
     *
     * @private
     *
     * @return void
     */
    private resetHiddenInputFields(): void {
        this.hiddenInputFields.forEach((hiddenInputField) => {
            if (hiddenInputField.getAttribute('name') !== 'trackConfigurator[_token]' &&
                hiddenInputField.getAttribute('name') !== 'trackConfigurator[systemStepCompleted]') {
                hiddenInputField.value = '';
            }
        });
    }

    /**
     * A function to register the events
     *
     * @private
     *
     * @return void
     */
    protected registerTabEvents(): void {
        this.prevButton.addEventListener('click', () => this.onPrevButtonClick());

        super.registerTabEvents();
    }

    /**
     * A function to handle the prev button click
     *
     * @private
     *
     * @return void
     */
    private onPrevButtonClick(): void {
        const prevStepDataContentAttr = this.prevButton.getAttribute('data-prev-step');

        if (prevStepDataContentAttr !== null) {
            const prevTab = <HTMLElement> document.querySelector(`[data-content="${prevStepDataContentAttr}"]`);

            if (prevTab) {
                prevTab.click();
            }
        }
    }
}
