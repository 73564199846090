import Component from 'ShopUi/models/component';

// eslint-disable-next-line no-var
declare var storyblok: any;

export default class StoryblokPreview extends Component {
    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected init(): void {
        if (storyblok !== null && typeof storyblok !== 'undefined') {
            storyblok.on(['published', 'change'], (event) => {
                if (!event.slugChanged) {
                    window.location.reload(true);
                }
            });
        }
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }
}
