import Ga4EventInterface from './ga4-event-interface';

/* eslint-disable camelcase */
export interface Ga4CustomEventInterface extends Ga4EventInterface {
    event_name: string;
}

export default class Ga4CustomEvent implements Ga4EventInterface {
    event: string;
    event_name: string;

    constructor(data: Ga4CustomEventInterface) {
        this.event = 'custom_event';
        this.event_name = data.event_name;
    }
}
/* eslint-enable camelcase */
