import Component from 'ShopUi/models/component';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import Ga4EventTracking from 'GA4EventTracking/components/molecules/ga4-event-tracking/ga4-event-tracking';

export default class FilterSection extends Component {
    /**
     * The filter form of the product listing
     */
    private form: HTMLFormElement;

    /**
     * All hidden filter items
     */
    hiddenFilters: HTMLElement[];

    /**
     * The button to show all filter items
     */
    showMoreButton: HTMLElement;

    /**
     * The download filter for the search page
     */
    private downloadFilters: HTMLInputElement[];

    /**
     * The filter submit button of the product listing pages
     */
    private filterSubmitButton: HTMLButtonElement;

    private filterForm: HTMLFormElement;

    private googleEventHandler: GoogleTagEvents;

    private ga4EventHandler: Ga4EventTracking;

    /**
     * After this value, the download filter trigger an auto submit
     */
    private responsiveBreakpoint = 768;

    protected readyCallback(): void {
        this.hiddenFilters = Array.from(this.querySelectorAll(`.${this.name}__item.is-hidden-lg-xxl`));
        this.showMoreButton = this.querySelector(`.js-${this.name}__more-filters`);
        this.downloadFilters = <HTMLInputElement[]> Array.from(document.querySelectorAll('input[name="downloads[]"]'));
        this.filterSubmitButton = document.querySelector('.button--filter-submit');
        this.googleEventHandler = document.querySelector('.google-tag-events');
        this.ga4EventHandler = document.querySelector('.ga4-event-tracking');
        this.filterForm = <HTMLFormElement> document.querySelector('#catalog-page__form');

        if (window.location.search.includes('outletFilter=outlet')) {
            this.addHiddenOutletFilterElement();
        }

        if (window.location.search.includes('st=0')) {
            this.addHideHeadlineFilterElement();
        }

        if (this.showMoreButton !== null) {
            this.showMoreButton.addEventListener('click', () => this.showAllFilters());
        }

        if (this.downloadFilters !== null) {
            this.downloadFilters.forEach((downloadFilter: HTMLInputElement) => {
                downloadFilter.addEventListener('click', (event: Event) => this.changeDownloadFilter(event));
            });
        }

        if (this.filterSubmitButton !== null &&
            typeof this.googleEventHandler !== 'undefined' &&
            this.googleEventHandler !== null
        ) {
            this.filterSubmitButton.addEventListener('click', () => this.triggerEvents());
        }
    }

    protected showAllFilters() {
        this.hiddenFilters.forEach((filter) => {
            filter.classList.remove('is-hidden-lg-xxl');
        });

        this.showMoreButton.classList.add('is-hidden');
    }

    protected changeDownloadFilter(event: Event) {
        const filter = <HTMLInputElement> event.target;

        this.downloadFilters.forEach((downloadFilter) => {
            if (downloadFilter !== filter && downloadFilter.value === filter.value) {
                downloadFilter.setAttribute('checked', 'checked');

                if (!filter.checked) {
                    downloadFilter.removeAttribute('checked');
                }
            }
        });

        if (this.isAutoSubmitEnabled(filter) && this.isDesktop()) {
            this.filterSubmitButton.click();
        }
    }

    /* eslint-disable camelcase */
    protected triggerEvents() {
        const selectedFilters = this.getSelectedFilters();
        for (const filter in selectedFilters) {
            if (filter) {
                let eventData = this.googleEventHandler.getGtmEventData(this.filterSubmitButton);
                eventData = {
                    ...eventData,
                    eventLabel: `${filter}: ${selectedFilters[filter]}`,
                };
                const gaEvent = this.googleEventHandler.createGaEvent(eventData);
                this.googleEventHandler.pushEvent(<any> gaEvent);

                const eventElement = document.createElement('div');
                eventElement.setAttribute('data-ga4-event-name', 'filter');
                eventElement.setAttribute('data-ga4-event-data', JSON.stringify(
                    { filter_name: filter, filter_element: selectedFilters[filter] }
                ));

                this.ga4EventHandler.createAndPushEvent(eventElement);
            }
        }
    }
    /* eslint-enable camelcase */

    protected getSelectedFilters(): Object {
        const filters = {};
        new FormData(this.filterForm).forEach((value, name) => {
            if (value) {
                const filterItem = this.querySelector(`.filter-section__item [name="${name}"][value="${value}"]`);
                if (filterItem) {
                    const filterName = filterItem.getAttribute('data-tracking-name');
                    const filterValue = filterItem.hasAttribute('data-tracking-value') ?
                        filterItem.getAttribute('data-tracking-value') :
                        value;
                    // eslint-disable-next-line no-prototype-builtins
                    filters[filterName] = filters.hasOwnProperty(filterName) ?
                        `${filters[filterName]}, ${filterValue}` :
                        filterValue;
                }
            }
        });

        return filters;
    }

    // eslint-disable-next-line class-methods-use-this
    protected isAutoSubmitEnabled(downloadFilter: HTMLInputElement): boolean {
        const dataAutoSubmit = downloadFilter.getAttribute('data-auto-submit');
        return dataAutoSubmit !== '' && dataAutoSubmit !== 'false';
    }

    protected isDesktop() {
        return document.body.clientWidth >= this.responsiveBreakpoint;
    }

    protected addHiddenOutletFilterElement(): void {
        const outletFilter = document.createElement('input');
        outletFilter.setAttribute('type', 'hidden');
        outletFilter.setAttribute('name', 'outletFilter');
        outletFilter.setAttribute('value', 'outlet');
        this.filterForm.appendChild(outletFilter);
    }

    protected addHideHeadlineFilterElement(): void {
        const headlineFilter = document.createElement('input');
        headlineFilter.setAttribute('type', 'hidden');
        headlineFilter.setAttribute('name', 'st');
        headlineFilter.setAttribute('value', '0');
        this.filterForm.appendChild(headlineFilter);
    }
}
