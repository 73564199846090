import Component from 'ShopUi/models/component';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import TempParams from 'src/BestIt/ShopUi/components/molecules/temp-params/temp-params';

export default abstract class DefaultAbstractStep extends Component {
    protected header: HTMLElement;

    protected container: HTMLElement;

    // eslint-disable-next-line no-undef
    protected filters: NodeListOf<HTMLElement>;

    // eslint-disable-next-line no-undef
    protected hiddenFields: NodeListOf<HTMLElement>;

    protected form: HTMLFormElement;

    // eslint-disable-next-line no-undef
    protected nextStepButtons: NodeListOf<HTMLElement>;

    // eslint-disable-next-line no-undef
    protected tabElements: NodeListOf<HTMLElement>;

    // eslint-disable-next-line no-undef
    protected tabContainerElements: NodeListOf<HTMLElement>;

    protected optionsWithSpecialEvent: string[] = [];

    protected invalidFields: HTMLElement[] = [];

    protected scrollThreshold = 50;

    /* eslint-disable no-invalid-this */
    protected cssSelectors: any = {
        container: `.${this.jsName}[data-product-type]`,
        nextStepButton: `.${this.name}__next-step-button`,
        priceContainer: `.${this.name}__price-container`,
        tabElement: `.${this.jsName}__tab-element`,
        tabContainerElement: `.${this.jsName}__tab-container`,
        tabListContainer: `.${this.jsName}__tab-list-container`,
        currentStepName: '.js-product-configurator__step',
        active: '.active',
        header: '.header',
    };
    /* eslint-enable no-invalid-this */

    protected cssClasses: any = {
        selected: 'attribute-filter__selected',
        invisible: 'is-invisible',
        hidden: 'is-hidden',
        hiddenMobile: 'is-hidden-sm-lg',
        active: 'active',
    };

    protected googleEventHandler: GoogleTagEvents;

    protected tempParams: TempParams;

    protected steps = [
        'technical',
        'base-track-step',
        'base-luminaire-step',
        'detail-feeder-step',
        'detail-connector-step',
        'detail-endcap-step',
        'detail-adapter-step',
        'detail-bracket-step',
        'detail-cover-step',
    ];

    protected abstract initStepFunctionality(): void;

    protected initialize(): void {
        this.setHeader();
        this.setContainer();
        this.setFilters();
        this.setHiddenFields();
        this.setForm();

        this.initNextButton();
        this.initTabAreas();
        this.initFilters();

        this.initStepFunctionality();

        this.initGoogleTagEvents();
        this.initTempParams();
        this.fireEvent();
    }

    protected setHeader(): void {
        this.header = <HTMLElement> document.querySelector(this.cssSelectors.header);
    }

    protected setContainer(): void {
        this.container = <HTMLElement> this.querySelector(this.cssSelectors.container);
    }

    protected setFilters(): void {
        // eslint-disable-next-line no-undef
        this.filters = <NodeListOf<HTMLElement>> this.querySelectorAll(this.cssSelectors.filterValues);
    }

    protected initTabAreas(): void {
        this.setTabList();
        this.setTabContainer();

        if (this.steps.indexOf(this.getCurrentStep()) >= 0) {
            this.registerTabListEvent();
        }
    }

    protected setTabList(): void {
        // eslint-disable-next-line no-undef
        this.tabElements = <NodeListOf<HTMLElement>> this.querySelectorAll(this.cssSelectors.tabElement);
    }

    protected setTabContainer(): void {
        // eslint-disable-next-line no-undef
        this.tabContainerElements = <NodeListOf<HTMLElement>> this.querySelectorAll(
            this.cssSelectors.tabContainerElement,
        );
    }

    protected registerTabListEvent(): void {
        let isMobile = this.isMobileScreenWidth(window.innerWidth);

        if (isMobile) {
            this.setDefaultTabSettings();
        } else {
            this.enableAllTabContainers();
        }

        window.addEventListener('resize', () => {
            isMobile = this.isMobileScreenWidth(window.innerWidth);

            if (isMobile) {
                this.setDefaultTabSettings();
            } else {
                this.enableAllTabContainers();
            }
        });

        this.tabElements.forEach((tab) => {
            tab.addEventListener('click', (event: Event) => {
                this.changeActiveTab(event);
            });
        });
    }

    protected setDefaultTabSettings(): void {
        const activeTabElement = <HTMLElement> this.querySelector(
            this.cssSelectors.tabElement + this.cssSelectors.active,
        );

        // Check for existing tab settings
        if (activeTabElement !== null) {
            return;
        }

        this.resetTabSettings();

        // Set tab button active
        const tabButton = <HTMLElement> this.querySelector(
            `${this.cssSelectors.tabElement}[data-content=configuration]`,
        );
        tabButton.classList.add(this.cssClasses.active);

        // Enable correct container element for current tab
        const tabContainer = <HTMLElement> this.querySelector(
            `${this.cssSelectors.tabContainerElement}[data-content=configuration]`,
        );
        tabContainer.classList.remove(this.cssClasses.hiddenMobile);
    }

    protected changeActiveTab(event: Event): void {
        this.resetTabSettings();
        this.setActiveTab(event);
    }

    protected resetTabSettings(): void {
        const activeTabElement = <HTMLElement> this.querySelector(
            this.cssSelectors.tabElement + this.cssSelectors.active,
        );

        if (activeTabElement !== null) {
            activeTabElement.classList.remove(this.cssClasses.active);
        }

        this.tabContainerElements.forEach((container) => {
            if (!container.classList.contains(this.cssClasses.hiddenMobile)) {
                container.classList.add(this.cssClasses.hiddenMobile);
            }
        });
    }

    protected enableAllTabContainers(): void {
        const activeTabElement = <HTMLElement> this.querySelector(
            this.cssSelectors.tabElement + this.cssSelectors.active,
        );

        if (activeTabElement !== null) {
            activeTabElement.classList.remove(this.cssClasses.active);
        }

        this.tabContainerElements.forEach((container) => {
            if (container.classList.contains(this.cssClasses.hiddenMobile)) {
                container.classList.remove(this.cssClasses.hiddenMobile);
            }
        });
    }

    protected setActiveTab(event: Event): void {
        let eventTarget;
        const clickedEventTarget = <HTMLElement> event.target;

        if (clickedEventTarget.tagName.toLowerCase() === 'button') {
            eventTarget = clickedEventTarget.parentElement;
        } else {
            eventTarget = clickedEventTarget;
        }

        const eventTargetAttribute = <HTMLElement> eventTarget.getAttribute('data-content');

        const tabTargetSelector = this.querySelector(
            `${this.cssSelectors.tabElement}[data-content=${eventTargetAttribute}]`,
        );

        tabTargetSelector.classList.add(this.cssClasses.active);

        const tabContainer = this.querySelector(
            `${this.cssSelectors.tabContainerElement}[data-content=${eventTargetAttribute}]`,
        );
        tabContainer.classList.remove(this.cssClasses.hiddenMobile);
    }

    protected setHiddenFields(): void {
        // eslint-disable-next-line no-undef
        this.hiddenFields = <NodeListOf<HTMLElement>> this.querySelectorAll(this.cssSelectors.hiddenField);
    }

    protected setForm(): void {
        this.form = <HTMLFormElement> this.querySelector('form');
    }

    protected initNextButton(): void {
        this.setNextButton();

        this.registerNextStepButtonEvent();
    }

    protected setNextButton(): void {
        // eslint-disable-next-line no-undef
        this.nextStepButtons = <NodeListOf<HTMLElement>> this.querySelectorAll(this.cssSelectors.nextStepButton);
    }

    protected registerNextStepButtonEvent(): void {
        // eslint-disable-next-line consistent-this
        const self = this;

        self.nextStepButtons.forEach((button) => {
            button.addEventListener('click', (event) => {
                this.invalidFields = [];
                if (self.validateStep()) {
                    self.removeInvalidHiddenFields();
                } else {
                    if (this.invalidFields.length > 0) {
                        const firstInvalidField = this.invalidFields[0];
                        this.scrollToField(firstInvalidField);
                    }
                    event.preventDefault();
                }
            });
        });
    }

    // eslint-disable-next-line class-methods-use-this
    protected validateStep(): boolean {
        const errors = [];

        // Add validation

        return errors.length === 0;
    }

    protected getCurrentStep(): string {
        const stepContainer = <HTMLElement> document.querySelector(
            this.cssSelectors.currentStepName,
        );

        return stepContainer.getAttribute('data-step-name');
    }

    protected removeInvalidHiddenFields(): void {
        this.hiddenFields.forEach((hiddenField: HTMLElement) => {
            if (!hiddenField.hasAttribute('value')) {
                /**
                 * IE 11 can not use ChildNode.remove()
                 * (see https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove)
                 */
                const parentElement = hiddenField.parentElement;

                if (typeof parentElement !== 'undefined' && parentElement !== null) {
                    parentElement.removeChild(hiddenField);
                }
            }

            if (hiddenField.getAttribute('value') === 'null') {
                /**
                 * IE 11 can not use ChildNode.remove()
                 * (see https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove)
                 */
                const parentElement = hiddenField.parentElement;

                if (typeof parentElement !== 'undefined' && parentElement !== null) {
                    parentElement.removeChild(hiddenField);
                }
            }
        });
    }

    protected isMobileScreenWidth(screenWidth: Number): boolean {
        const tabListContainer = this.querySelector(this.cssSelectors.tabListContainer);

        if (tabListContainer !== null) {
            const viewport = <Number> tabListContainer.getAttribute('data-viewport');

            if (typeof screenWidth !== 'undefined' &&
                screenWidth !== null &&
                typeof viewport !== 'undefined' &&
                viewport !== null
            ) {
                return screenWidth < viewport;
            }
        }

        return false;
    }

    protected resetTabsOnMobile(): void {
        const isMobile = this.isMobileScreenWidth(window.innerWidth);

        if (isMobile) {
            this.resetTabSettings();
            this.setDefaultTabSettings();
        }
    }

    protected getViewData(optionKey: string): string {
        return this.container.getAttribute(
            `data-${optionKey}`,
        );
    }

    // eslint-disable-next-line class-methods-use-this
    protected initFilters(): void {
        /* eslint-disable no-empty-function */
    }

    /* eslint-disable-next-line class-methods-use-this, no-unused-vars */
    protected registerFilterButtonEvent(filter: HTMLElement): void {
        /* eslint-disable no-empty-function */
    }

    // eslint-disable-next-line no-undef
    protected setSelected(option: HTMLElement, options: NodeListOf<HTMLElement>): void {
        this.unselectAll(options);

        option.classList.add(this.cssClasses.selected);
    }

    protected unselectAll(options): void {
        options.forEach((option: HTMLElement) => {
            option.classList.remove(this.cssClasses.selected);
        });
    }

    // eslint-disable-next-line no-unused-vars
    protected changeValues(filterOption: HTMLElement): void {
        const changingOptions = [];

        this.updateDependencies(changingOptions);
    }

    protected updateDependencies(changingOptions): void {
        this.changeHiddenFields(changingOptions);
    }

    // eslint-disable-next-line
    protected changeHiddenFields(changingOptions): void {
        // Do something
    }

    protected scrollToField(field : HTMLElement) : void {
        let scrollTo = field.offsetTop - this.header.offsetHeight - this.scrollThreshold;
        if (scrollTo <= 0) {
            const parent = <HTMLElement> field.parentElement;
            scrollTo = parent.offsetTop - this.header.offsetHeight - this.scrollThreshold;
        }

        window.scroll(0, scrollTo);
    }

    protected initGoogleTagEvents(): void {
        this.googleEventHandler = <GoogleTagEvents> document.querySelector('.google-tag-events');
    }

    protected initTempParams() {
        this.tempParams = <TempParams> document.querySelector('.temp-params');
    }

    protected fireEvent(): void {
        const tabContainerElement = <HTMLElement> this.querySelector(
            `${this.cssSelectors.tabContainerElement}[data-content=graphics]`,
        );
        if (typeof this.googleEventHandler !== 'undefined' &&
            this.googleEventHandler !== null &&
            tabContainerElement !== null &&
            tabContainerElement.hasAttribute('data-event-data')
        ) {
            let eventData = this.googleEventHandler.getGtmEventData(tabContainerElement);
            if (typeof this.tempParams !== 'undefined' && this.tempParams !== null) {
                const param = this.tempParams.get('from');
                if (param) {
                    eventData = {
                        ...eventData,
                        eventLabel: param,
                    };
                }
            }
            const event = this.googleEventHandler.createGaEvent(eventData);
            this.googleEventHandler.pushEvent(<any> event, 'load');
        }
    }
}
