import Component from 'ShopUi/models/component';
import ZoomOverlay from '../../molecules/zoom-overlay/zoom-overlay';

export default class GalleryElement extends Component {
    /**
     * The images of the gallery
     *
     * @protected
     */
    protected images: HTMLElement[];

    /**
     * The overlay in which opens after a click on the gallery
     * @protected
     */
    protected overlay: ZoomOverlay;

    /**
     * The button to expand the grid for the mobile viewpor
     *
     * @protected
     */
    protected showMoreButton: HTMLElement;

    /**
     * The container for the gallery grid
     *
     * @protected
     */
    protected gridContainer: HTMLElement;

    /**
     * Base function for the components
     *
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Init function for the component
     *
     * @public
     */
    public initialize(): void {
        this.images = <HTMLElement[]> Array.from(this.querySelectorAll(`.${this.name}__box`));
        this.gridContainer = <HTMLElement> this.querySelector(`.${this.name}__container`);
        this.showMoreButton = <HTMLElement> this.querySelector(`.${this.jsName}__show-more-button`);
        this.overlay = <ZoomOverlay> this.querySelector(`.${this.name}__overlay`);

        this.mapEvents();
    }

    /**
     * Adds the events for the images and the overlay
     *
     * @protected
     */
    protected mapEvents(): void {
        this.images.forEach((image: HTMLElement) => {
            image.addEventListener('click', this.openSlideOnSelectedPosition.bind(this, image));
        });

        this.showMoreButton.addEventListener('click', () => this.expandGrid());
    }

    /**
     * Handler to open the overlay and initialize the slider if not already done
     * The slider will start on the image position which got clicked
     *
     * @param image
     *
     * @protected
     */
    protected openSlideOnSelectedPosition(image: HTMLElement): void {
        const position = image.dataset.imagePosition || '0';
        this.overlay.classList.remove('is-hidden');
        this.overlay.openSlideOnSelectedPosition(position);
    }

    /**
     * Expands the grid on mobile after the click on the show more button.
     *
     * @private
     */
    protected expandGrid() {
        if (!this.gridContainer.classList.contains('expand')) {
            this.gridContainer.classList.add('expand');
        }

        this.showMoreButton.classList.add('is-hidden');
    }
}
