import EventDataTransfer from '../event-data-transfer';
import Ga4EcommerceEvent from '../events/ecommerce-types/ga4-ecommerce-event';
import AbstractGa4EcommerceEventWithDataMapper from './abstract-ga4-ecommerce-event-with-data-mapper';
import Ecommerce, { EcommerceDataInterface } from '../events/ecommerce-subtypes/ecommerce';
import Product from '../events/ecommerce-subtypes/product';

export default class Ga4EcommerceEventWithProductItemMapper extends AbstractGa4EcommerceEventWithDataMapper {
    // eslint-disable-next-line class-methods-use-this
    /* eslint-disable camelcase */
    public map(transfer: EventDataTransfer): Ga4EcommerceEvent {
        return <Ga4EcommerceEvent>Object.assign(
            super.map(transfer),
            {
                event_name: transfer.eventName,
            }
        );
    }
    /* eslint-enable camelcase */

    // eslint-disable-next-line no-unused-vars,class-methods-use-this
    protected mapEcommerceSubtype(data: EcommerceDataInterface<Product>): Ecommerce<Product> {
        data.items = data.items.map((item): Product => new Product(item));

        return new Ecommerce<Product>(data);
    }
}
