import Component from 'ShopUi/models/component';

export default class EditableTextarea extends Component {
    private textarea: HTMLElement;

    private button: HTMLButtonElement;

    protected readyCallback(): void {
        this.textarea = <HTMLElement> this.querySelector(`.${this.name}__textarea`);
        this.button = <HTMLButtonElement> this.querySelector(`.${this.name}__button`);
        this.mapEvents();
    }

    private mapEvents() {
        this.textarea.addEventListener('focus', () => {
            this.button.classList.remove('is-hidden');
        });
    }
}
