import Component from 'ShopUi/models/component';
import CountrySelection from 'src/BestIt/ShopUi/components/molecules/country-selection/country-selection';
import Header from 'src/BestIt/ShopUi/components/organisms/header/header';

export default class StoreRedirectDialog extends Component {
    private continueButton: HTMLButtonElement;

    private actionSelect: HTMLSelectElement;

    private countrySelection: CountrySelection;

    private closeIcon: HTMLElement;

    private header: Header;

    protected readyCallback(): void {
        this.continueButton = <HTMLButtonElement> document.querySelector(`.${this.name}__button`);
        this.actionSelect = <HTMLSelectElement> document.querySelector(`.${this.name}__select .custom-select__select`);
        this.countrySelection = <CountrySelection> document.querySelector('.header__top .country-selection');
        this.closeIcon = <CountrySelection> document.querySelector(`.${this.name}__icon`);
        this.header = <Header> document.querySelector('.header');

        if (typeof sessionStorage.getItem('hideStoreRedirectDialog') === 'undefined' ||
            sessionStorage.getItem('hideStoreRedirectDialog') !== 'true'
        ) {
            this.classList.remove('is-hidden');
            this.header.resetHeader();
        }

        this.mapEvents();
    }

    private mapEvents() {
        this.continueButton.addEventListener('click', () => this.onButtonClick());
        this.closeIcon.addEventListener('click', () => this.onCloseIconClick());
    }

    private onButtonClick() {
        if (this.actionSelect.value === 'openCountryList') {
            if (this.isWidthMoreThanAvailableBreakpoint()) {
                this.countrySelection.openCountrySelection(
                    this.countrySelection.querySelector('.country-selection__container'),
                );
            } else {
                sessionStorage.setItem('hideStoreRedirectDialog', 'true');
                window.location.href = this.countryListUrl;
            }
        } else {
            sessionStorage.setItem('hideStoreRedirectDialog', 'true');
            window.location.href = this.actionSelect.value;
        }
    }

    protected isWidthMoreThanAvailableBreakpoint(): boolean {
        return window.innerWidth >= this.availableBreakpoint;
    }

    get availableBreakpoint(): number {
        return Number(this.getAttribute('available-breakpoint'));
    }

    get countryListUrl(): string {
        return this.getAttribute('country-list-url');
    }

    private onCloseIconClick() {
        sessionStorage.setItem('hideStoreRedirectDialog', 'true');
        this.classList.add('is-hidden');
        this.header.resetHeader();
    }
}
