import Ga4EcommerceEventWithProductItemMapper
    from 'GA4EventTracking/types/event-mappers/ga4-ecommerce-event-with-product-item-mapper';
import EventDataTransfer from 'GA4EventTracking/types/event-data-transfer';
import Ga4EcommerceAddPaymentInfoEvent, {
    Ga4EcommerceAddPaymentInfoEventDataInterface
} from 'GA4EventTracking/types/events/ecommerce-types/ga4-ecommerce-add-payment-info-event';
import Product from 'GA4EventTracking/types/events/ecommerce-subtypes/product';
import EcommerceWithPaymentInfo, {
    EcommerceWithPaymentInfoDataInterface
} from 'GA4EventTracking/types/events/ecommerce-subtypes/ecommerce-with-payment-info';

/* eslint-disable camelcase */
export default class Ga4EcommerceEventAddPaymentInfoMapper extends Ga4EcommerceEventWithProductItemMapper {
    public map(transfer: EventDataTransfer): Ga4EcommerceAddPaymentInfoEvent {
        return this.mapCustomSubtype({
            event_name: transfer.eventName,
            cart_type: transfer.data.cart_type,
            ecommerce: this.mapEcommerceSubtype(transfer.data.ecommerce)
        });
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(data: Ga4EcommerceAddPaymentInfoEventDataInterface): Ga4EcommerceAddPaymentInfoEvent {
        return new Ga4EcommerceAddPaymentInfoEvent(data);
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapEcommerceSubtype(data: EcommerceWithPaymentInfoDataInterface): EcommerceWithPaymentInfo {
        // eslint-disable-next-line complexity
        data.items = data.items.map((item): Product => new Product(item));

        return new EcommerceWithPaymentInfo(data);
    }
}
/* eslint-enable camelcase */
