import Component from 'ShopUi/models/component';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import TempParams from 'src/BestIt/ShopUi/components/molecules/temp-params/temp-params';

export default class VariantConfigurator extends Component {
    private eventSelectVariant: HTMLInputElement;

    private googleEventHandler: GoogleTagEvents;

    private tempParams: TempParams;

    protected readyCallback(): void {
        this.eventSelectVariant = <HTMLInputElement> this.querySelector(this.eventSelectVariantSelector);
        this.googleEventHandler = <GoogleTagEvents> document.querySelector('.google-tag-events');
        this.tempParams = <TempParams> document.querySelector('.temp-params');
        this.checkEvent();
    }

    private checkEvent(): void {
        if (typeof this.googleEventHandler !== 'undefined' &&
            this.googleEventHandler !== null &&
            this.eventSelectVariant !== null &&
            this.eventSelectVariant.hasAttribute('data-event-data') &&
            this.tempParams !== null &&
            this.tempParams.get('changed') === true
        ) {
            const eventData = this.googleEventHandler.getGtmEventData(this.eventSelectVariant);
            const event = this.googleEventHandler.createGaEvent(eventData);
            this.googleEventHandler.pushEvent(<any> event);
        }
    }

    get eventSelectVariantSelector(): string {
        return `.${this.name}__event_select_variant`;
    }
}
