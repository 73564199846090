import EcommerceWithPurchaseInfo from '../ecommerce-subtypes/ecommerce-with-purchase-info';
import Ga4EcommerceViewCartEvent, { Ga4EcommerceViewCartEventDataInterface } from './ga4-ecommerce-view-cart-event';

/* eslint-disable camelcase */
export interface Ga4EcommercePurchaseEventDataInterface extends Ga4EcommerceViewCartEventDataInterface {
    ecommerce: EcommerceWithPurchaseInfo;
}
export default class Ga4EcommercePurchaseEvent extends Ga4EcommerceViewCartEvent {
    ecommerce: EcommerceWithPurchaseInfo;

    constructor(data: Ga4EcommercePurchaseEventDataInterface) {
        super(data);

        this.ecommerce = data.ecommerce;
    }
}
/* eslint-enable camelcase */
