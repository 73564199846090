
import EventDataTransfer from 'GA4EventTracking/types/event-data-transfer';
import NewsletterSubscribeSoiDataEvent
    from 'GA4EventTracking/types/events/custom-subtypes/newsletter-subscribe-soi-event';
/* eslint-disable camelcase */
export default class Ga4NewsletterMapper {
    // eslint-disable-next-line class-methods-use-this
    public map(transfer: EventDataTransfer): NewsletterSubscribeSoiDataEvent {
        return new NewsletterSubscribeSoiDataEvent({
            event: transfer.event,
            event_name: transfer.eventName,
            newsletter_subscribe_soi: transfer.data,
        });
    }
}
/* eslint-enable camelcase */
