import AbstractBasicForm from 'FormPage/components/organisms/basic-form/basic-form';
import ScrollToInvalidFieldOnSubmit
    from 'FormPage/components/atoms/scroll-to-invalid-field-on-submit/scroll-to-invalid-field-on-submit';

export default class CatalogBasicForm extends AbstractBasicForm {
    /**
     * The submit button of the form
     *
     * @public
     */
    public submitButton: HTMLButtonElement;

    /**
     * The service component ScrollToInvalidFieldOnSubmit to get use the getInvalidFields function
     *
     * @protected
     */
    protected scrollToInvalidField: ScrollToInvalidFieldOnSubmit

    /**
     * @inheritDoc
     *
     * @protected
     */
    protected readyCallback(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     *
     * @protected
     */
    public initialize() {
        this.submitButton = <HTMLButtonElement> this.querySelector('.button[type=submit]');

        this.scrollToInvalidField = <ScrollToInvalidFieldOnSubmit> this.querySelector(
            '.scroll-to-invalid-field-on-submit',
        );

        this.mapEvents();
    }

    /**
     * @inheritDoc
     *
     * @protected
     */
    public removeEvents(): void {
        if (typeof this.submitButton !== 'undefined' && this.submitButton !== null) {
            this.submitButton.removeEventListener('click', (event: Event) => this.triggerSklikEvent(event));
        }
    }

    /**
     * Maps the event for the submit button to trigger the Sklik Event
     *
     * @protected
     */
    protected mapEvents() {
        this.submitButton.addEventListener('click', (event: Event) => this.triggerSklikEvent(event));
    }

    /**
     * Event handler function to trigger the Sklik conversion event
     *
     * @param event
     *
     * @protected
     */
    protected async triggerSklikEvent(event?: Event) {
        if (event !== null) {
            event.preventDefault();
        }

        if (this.sklikConversionId &&
            this.scrollToInvalidField.getInvalidFields().length === 0
        ) {
            await window.rc.conversionHit({
                id: this.sklikConversionId,
                seznam_value: null, // eslint-disable-line camelcase
            });
        }

        const ajaxFormWrapper = this.submitButton.closest('div.js-form-element__form-container');

        if (event !== null &&
            ajaxFormWrapper === null &&
            this.scrollToInvalidField.getInvalidFields().length === 0
        ) {
            this.submitButton.closest('form').submit();
        }
    }

    /**
     * Getter for the data attribute data-sklik-conversion-id
     *
     * @public
     */
    get sklikConversionId() {
        const sklikConversionId = this.dataset.sklikConversionId || null;
        return sklikConversionId !== null ? parseInt(sklikConversionId, 10) : null;
    }
}
