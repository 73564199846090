import Component from 'ShopUi/models/component';
import QuantityInputSelect from 'src/BestIt/ShopUi/components/molecules/quantity-input-select/quantity-input-select';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';

export default class ProductQuickAdd extends Component {
    private hiddenSkuInput: HTMLInputElement;

    private searchInput: HTMLInputElement;

    private submitButton: HTMLButtonElement;

    private quickAddForm: HTMLFormElement;

    private quantityInputSelect: QuantityInputSelect;

    private googleEventHandler: GoogleTagEvents;

    protected readyCallback(): void {
        this.hiddenSkuInput = this.querySelector('input[name=sku]');
        this.searchInput = this.querySelector('input.product-search-autocomplete-form__input');
        this.submitButton = this.querySelector('button[type=submit]');
        this.quickAddForm = this.querySelector(`.${this.name}-form__form`);
        this.submitButton = this.quickAddForm.querySelector('.button[type=submit]');
        this.quantityInputSelect = this.querySelector('.quantity-input-select');
        this.googleEventHandler = <GoogleTagEvents> document.querySelector('.google-tag-events');
        this.mapEvents();
    }

    private mapEvents(): void {
        this.hiddenSkuInput.addEventListener('change', (event) => {
            if (event) {
                const input = <HTMLInputElement>event.target;
                if (input.value && input.value.length > 0) {
                    this.setButtonDisabled(false);
                } else {
                    this.setButtonDisabled(true);
                }
            }
        });

        this.searchInput.addEventListener('input', () => {
            if (this.hiddenSkuInput.value && this.hiddenSkuInput.value.length > 0) {
                this.setButtonDisabled(true);
            }
        });

        this.submitButton.addEventListener('click', () => this.onFormSubmit());
    }

    private setButtonDisabled(disabled: boolean) {
        if (disabled) {
            this.submitButton.classList.add('button--disabled');
            this.submitButton.setAttribute('disabled', 'disabled');
        } else {
            this.submitButton.classList.remove('button--disabled');
            this.submitButton.removeAttribute('disabled');
        }
    }

    private onFormSubmit() {
        if (typeof this.quantityInputSelect !== 'undefined' && this.quantityInputSelect !== null) {
            let quantity = this.quantityInputSelect.getAttribute('data-new-value');
            if (quantity === '0' || quantity === '') {
                quantity = '1';
            }

            this.quantityInputSelect.pushGa4CartEvents(quantity);
            this.quantityInputSelect.pushCartEvents(quantity);
        }
        this.pushGaEvent();
    }

    private pushGaEvent(): void {
        let eventData = this.googleEventHandler.getGtmEventData(this.submitButton);
        if (eventData !== undefined) {
            eventData = {
                ...eventData,
                eventLabel: this.hiddenSkuInput.value,
            };
            const event = this.googleEventHandler.createGaEvent(eventData);
            this.googleEventHandler.pushEvent(<any>event);
        }
    }
}
