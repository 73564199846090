import DefaultAbstractStep
    from 'AbstractProductConfigurator/components/organisms/default-abstract-step/default-abstract-step';

export default class AbstractStep extends DefaultAbstractStep {
    protected itemCount: number = 0;

    /**
     * @inheritDoc
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    get selectedItemCount() {
        return parseInt(this.dataset.count, 10) || 0;
    }

    // eslint-disable-next-line class-methods-use-this
    protected initStepFunctionality(): void {
        /* eslint-disable no-empty-function */
    }
}
