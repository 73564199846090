import Product from './product';
import Return from './return';
import Promotion from './promotion';
import EventItemInterface from './ecommerce-item-interface';

/* eslint-disable camelcase */
type allowedTypes = Product|Return|Promotion;

export interface EcommerceDataInterface<T extends allowedTypes> {
    // eslint-disable-next-line no-use-before-define
    items: T[];
}

export default class Ecommerce<T extends allowedTypes> {
    items: T[];

    constructor(data: EcommerceDataInterface<T>) {
        data.items.forEach(item => {
            if (!this.isInstanceOfEventItemInterface(item)) {
                throw new Error('Items must be an instance of EcommerceItemInterface');
            }
        });
        this.items = data.items;
    }

    // eslint-disable-next-line class-methods-use-this
    private isInstanceOfEventItemInterface(object: any): object is EventItemInterface {
        return 'item_id' in object;
    }
}
/* eslint-enable camelcase */
