import CartType from '../ecommerce-subtypes/cart-type';
import Ecommerce from '../ecommerce-subtypes/ecommerce';
import EventNameConstants from '../../event-name-constants';
import Ga4EcommerceEvent from './ga4-ecommerce-event';
import Ga4EcommerceEventWithProductDataInterface from '../ga4-ecommerce-event-with-product-data-interface';
import Product from '../ecommerce-subtypes/product';

/* eslint-disable camelcase */
export interface Ga4EcommerceBeginCheckoutEventDataInterface extends Ga4EcommerceEventWithProductDataInterface {
    cart_type: CartType;
}

export default class Ga4EcommerceBeginCheckoutEvent extends Ga4EcommerceEvent {
    ecommerce: Ecommerce<Product>;
    cart_type: CartType;

    constructor(data: Ga4EcommerceBeginCheckoutEventDataInterface) {
        data.event_name = EventNameConstants.EVENT_NAME_ECOMMERCE_BEGIN_CHECKOUT;
        super(data);

        this.ecommerce = data.ecommerce;
        this.cart_type = data.cart_type;
    }
}
/* eslint-enable camelcase */
