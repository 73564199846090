export default class MaxLengthStringValue {
    readonly value: string;

    constructor(value: string, max = 100) {
        if (value.length > max) {
            throw new Error(`Value ${value} is too long. Max length is ${max}`);
        }

        this.value = value;
    }
}
