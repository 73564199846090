export default class EventNameConstants {
    static readonly EVENT_NAME_CUSTOM_ADD_COMMENT_TO_ORDER: string = 'add_comment_to_order';
    static readonly EVENT_NAME_CUSTOM_BACK_TO_TOP: string = 'back_to_top';
    static readonly EVENT_NAME_CUSTOM_BURGERMENU_CLOSE: string = 'burgermenu_close';
    static readonly EVENT_NAME_CUSTOM_BURGERMENU_OPEN: string = 'burgermenu_open';
    static readonly EVENT_NAME_CUSTOM_CATALOGUE_READ: string = 'catalogue_read';
    static readonly EVENT_NAME_CUSTOM_CHANGE_PASSWORD: string = 'change_password';
    static readonly EVENT_NAME_CUSTOM_CLOSE_FASTCALC_TOOL: string = 'close_fastcalc_tool';
    static readonly EVENT_NAME_CUSTOM_CREATE_NEW_SHOPPING_CART: string = 'create_new_shopping_cart';
    static readonly EVENT_NAME_CUSTOM_CREATE_NEW_WISHLIST: string = 'create_new_wishlist';
    static readonly EVENT_NAME_CUSTOM_DELETE_SHOPPING_CART: string = 'delete_shopping_cart';
    static readonly EVENT_NAME_CUSTOM_DELETE_WISHLIST: string = 'delete_wishlist';
    static readonly EVENT_NAME_CUSTOM_ENTER_COMMISSION_TEXT: string = 'enter_commission_text';
    static readonly EVENT_NAME_CUSTOM_FILTER: string = 'filter';
    static readonly EVENT_NAME_CUSTOM_FIND_OFFLINE_DEALER: string = 'find_offline_dealer';
    static readonly EVENT_NAME_CUSTOM_FORM_SUBMIT: string = 'form_submit';
    static readonly EVENT_NAME_CUSTOM_LOGIN: string = 'login';
    static readonly EVENT_NAME_CUSTOM_LOGIN_FAIL: string = 'login_fail';
    static readonly EVENT_NAME_CUSTOM_LOGOUT: string = 'logout';
    static readonly EVENT_NAME_CUSTOM_NAVIGATION_FOOTER: string = 'navigation_footer';
    static readonly EVENT_NAME_CUSTOM_NAVIGATION_HEADER: string = 'navigation_header';
    static readonly EVENT_NAME_CUSTOM_NEWSLETTER_SUBSCRIBE_FAIL: string = 'newsletter_subscribe_fail';
    static readonly EVENT_NAME_CUSTOM_NEWSLETTER_SUBSCRIBE_SOI: string = 'newsletter_subscribe_soi';
    static readonly EVENT_NAME_CUSTOM_OPEN_FASTCALC_TOOL: string = 'open_fastcalc_tool';
    static readonly EVENT_NAME_CUSTOM_PHOTO_CAROUSEL: string = 'photo_carousel';
    static readonly EVENT_NAME_CUSTOM_PRICE_CALCULATION: string = 'price_calculation';
    static readonly EVENT_NAME_CUSTOM_PRODUCT_VARIANT: string = 'product_variant';
    static readonly EVENT_NAME_CUSTOM_SEARCH: string = 'search';
    static readonly EVENT_NAME_CUSTOM_SEARCH_DEALER: string = 'search_dealer';
    static readonly EVENT_NAME_CUSTOM_SELECT_DEALER: string = 'select_dealer';
    static readonly EVENT_NAME_CUSTOM_SHARE: string = 'share';
    static readonly EVENT_NAME_CUSTOM_SHOW_MORE: string = 'show_more';
    static readonly EVENT_NAME_CUSTOM_SIGN_UP: string = 'sign_up';
    static readonly EVENT_NAME_CUSTOM_SIGN_UP_FAIL: string = 'sign_up_fail';
    static readonly EVENT_NAME_CUSTOM_SIMILAR_PRODUCTS: string = 'similar_products';
    static readonly EVENT_NAME_CUSTOM_SORTING: string = 'sorting';
    static readonly EVENT_NAME_CUSTOM_SUCCESSOR: string = 'successor';
    static readonly EVENT_NAME_CUSTOM_VIEW_ORDERS: string = 'view_orders';

    static readonly EVENT_NAME_ECOMMERCE_ADD_PAYMENT_INFO: string = 'add_payment_info';
    static readonly EVENT_NAME_ECOMMERCE_ADD_SHIPPING_INFO: string = 'add_shipping_info';
    static readonly EVENT_NAME_ECOMMERCE_ADD_TO_CART: string = 'add_to_cart';
    static readonly EVENT_NAME_ECOMMERCE_ADD_TO_WISHLIST: string = 'add_to_wishlist';
    static readonly EVENT_NAME_ECOMMERCE_BEGIN_CHECKOUT: string = 'begin_checkout';
    static readonly EVENT_NAME_ECOMMERCE_PURCHASE: string = 'purchase';
    static readonly EVENT_NAME_ECOMMERCE_REFUND:string = 'refund';
    static readonly EVENT_NAME_ECOMMERCE_REMOVE_FROM_CART = 'remove_from_cart';
    static readonly EVENT_NAME_ECOMMERCE_SELECT_ITEM = 'select_item';
    static readonly EVENT_NAME_ECOMMERCE_SELECT_PROMOTION = 'select_promotion';
    static readonly EVENT_NAME_ECOMMERCE_VIEW_CART = 'view_cart';
    static readonly EVENT_NAME_ECOMMERCE_VIEW_ITEM = 'view_item';
    static readonly EVENT_NAME_ECOMMERCE_VIEW_ITEM_LIST = 'view_item_list';
    static readonly EVENT_NAME_ECOMMERCE_VIEW_PROMOTION = 'view_promotion';

    static get impressionEventNames(): string[] {
        return [
            EventNameConstants.EVENT_NAME_ECOMMERCE_VIEW_ITEM_LIST,
        ];
    }
}
