import Component from 'ShopUi/models/component';

export default class DownloadDropdown extends Component {
    /**
     * The dropdown with the list of files or the whole file package
     * @private
     */
    private dropDown: HTMLSelectElement;

    /**
     * The download button
     * @private
     */
    private downloadButton: HTMLAnchorElement;

    /**
     * @inheritDoc
     * @protected
     */
    protected readyCallback(): void {
        this.dropDown = <HTMLSelectElement> this.querySelector(this.dropDownSelector);
        this.downloadButton = <HTMLAnchorElement> this.querySelector(this.downloadButtonSelector);
        this.mapEvents();
    }

    /**
     * Mapper for the events of the component
     * @protected
     */
    protected mapEvents(): void {
        if (this.dropDown !== null) {
            this.dropDown.addEventListener('change', this.handleDropDownChange.bind(this));
        }
    }

    /**
     * Event handler for the dropdown change event
     *
     * @private
     */
    private handleDropDownChange(): void {
        if (this.dropDown.selectedIndex > 0) {
            const eventData = JSON.parse(this.downloadButton.getAttribute('data-event-data'));
            const selected = JSON.parse(this.dropDown.value);
            this.downloadButton.href = selected.link;
            eventData.eventLabel = selected.link;
            eventData.eventAction = selected.translation;
            this.downloadButton.setAttribute('data-event-data', JSON.stringify(eventData));
        }
    }

    /**
     * Getter for the dropdown selector string
     *
     * @return string
     */
    // eslint-disable-next-line class-methods-use-this
    get dropDownSelector(): string {
        return '.custom-select__select';
    }

    /**
     * Getter for the download button selector string
     *
     * @return string
     */
    // eslint-disable-next-line class-methods-use-this
    get downloadButtonSelector(): string {
        return '.icon-button--download-file';
    }
}
