import AbstractGa4CustomEventWithDataMapper from './abstract-ga4-custom-event-with-data-mapper';
import SearchDealer, { SearchDealerDataInterface } from 'GA4EventTracking/types/events/custom-subtypes/search-dealer';
import CustomSubtypeInterface from 'GA4EventTracking/types/events/custom-subtypes/custom-subtype-interface';

export default class Ga4CustomSearchDealerEventMapper extends AbstractGa4CustomEventWithDataMapper {
    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(data: SearchDealerDataInterface): CustomSubtypeInterface {
        return new SearchDealer(data);
    }
}
