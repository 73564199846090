import Component from 'ShopUi/models/component';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';

export default class ModalWindow extends Component {
    triggerButtons: HTMLElement[];

    toggleButton: HTMLElement;

    closeButtons: HTMLElement[];

    overlay: HTMLElement;

    focusableElements: HTMLElement[];

    firstFocusableEl: HTMLElement;

    lastFocusableEl: HTMLElement;

    focusedElBeforeOpen: HTMLElement;

    randomModalId: string;

    googleEventHandler: GoogleTagEvents;

    public isInitialized: boolean = false;

    // eslint-disable-next-line class-methods-use-this
    get focusableElementsSelector() {
        return 'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), ' +
            'button:not([disabled]), [tabindex="0"]';
    }

    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    public initialize(): void {
        if (this.triggerSelector) {
            this.triggerButtons = <HTMLElement[]> Array.from(
                document.querySelectorAll(
                    this.triggerSelector,
                ),
            );
        }

        this.closeButtons = Array.from(this.querySelectorAll(this.getAttribute('data-close-trigger')));
        this.focusableElements = Array.from(this.querySelectorAll(this.focusableElementsSelector));
        this.firstFocusableEl = this.focusableElements[0];
        this.lastFocusableEl = this.focusableElements[this.focusableElements.length - 1];
        this.googleEventHandler = <GoogleTagEvents> document.querySelector('.google-tag-events');
        this.setRandomModalId();
        this.createOverlay();

        if (this.isInstantDisplayModal()) {
            this.openModal();
        }

        document.body.insertBefore(this, this.overlay);

        this.mapEvents();

        this.isInitialized = true;
    }

    protected mapEvents(): void {
        this.registerOpeningTrigger();
        this.registerClosingTriggers();
        this.registerKeyDownEvent();
    }

    public openModal = () => {
        /* eslint-disable no-invalid-this */
        if (this.shouldShowModal()) {
            this.dispatchOpenEvent();
            this.focusedElBeforeOpen = <HTMLElement>document.activeElement;
            this.classList.add(`${this.name}__active`);
            this.overlay.classList.add(`${this.name}__active`);
            if (this.firstFocusableEl) {
                this.firstFocusableEl.focus();
            }
            document.body.style.overflow = 'hidden';
            this.dispatchOpenedEvent();
        }
    }

    private setRandomModalId() {
        const randomNumber = Math.floor(Math.random() * 1000000);
        this.randomModalId = `${this.name}__${randomNumber}`;
    }

    private dispatchOpenEvent() {
        const event = new Event('open');
        this.dispatchEvent(event);
    }

    private dispatchOpenedEvent() {
        const event = new Event('opened');
        this.dispatchEvent(event);
    }

    private handleBackwardTab(event) {
        if (document.activeElement === this.firstFocusableEl) {
            event.preventDefault();
            this.lastFocusableEl.focus();
        }
    }

    private handleForwardTab(event) {
        if (document.activeElement === this.lastFocusableEl) {
            event.preventDefault();
            this.firstFocusableEl.focus();
        }
    }

    protected onTriggerKeydown(event: KeyboardEvent): void {
        if (event.key === 'Escape' && this.closingByTriggerIsPossible()) {
            this.closeModal(event);
        }

        if (event.key === 'Tab') {
            if (this.focusableElements.length === 1) {
                event.preventDefault();
            } else if (event.shiftKey) {
                this.handleBackwardTab(event);
            } else {
                this.handleForwardTab(event);
            }
        }
    }

    public closeModal(event): void {
        event.preventDefault();
        this.classList.remove(`${this.name}__active`);
        this.overlay.classList.remove(`${this.name}__active`);
        this.blur();
        if (this.focusedElBeforeOpen) {
            this.focusedElBeforeOpen.focus();
        }
        document.body.style.overflow = null;
        if (this.googleEventHandler) {
            this.pushGaEvent();
        }
        this.dispatchClosedEvent();
    }

    private pushGaEvent(): void {
        const eventData = this.googleEventHandler.getGtmEventData(this);
        if (eventData !== undefined) {
            const event = this.googleEventHandler.createGaEvent(eventData);
            this.googleEventHandler.pushEvent(<any>event);
        }
    }

    private dispatchClosedEvent() {
        const event = new Event('closed');
        this.dispatchEvent(event);
    }

    get availableBreakpointLower(): number {
        return Number(this.getAttribute('available-breakpoint-lower'));
    }

    get availableBreakpointGreater(): number {
        return Number(this.getAttribute('available-breakpoint-greater'));
    }

    protected shouldShowModal(): boolean {
        const breakpointLower = this.availableBreakpointLower;
        const breakpointGreater = this.availableBreakpointGreater;
        const windowWith = window.innerWidth;
        let showModal = false;

        if (breakpointGreater === 0 && breakpointLower === 0) {
            showModal = true;
        } else if (breakpointGreater < breakpointLower) {
            showModal = windowWith > breakpointGreater && windowWith < breakpointLower;
        }

        return showModal;
    }

    private isInstantDisplayModal(): boolean {
        const displayInstantly = this.getAttribute('data-instant-display');

        if (displayInstantly === '1') {
            return true;
        }

        return false;
    }

    private closingByTriggerIsPossible(): boolean {
        const disallowClosing = this.getAttribute('data-disallow-closing');

        if (disallowClosing === '1') {
            return false;
        }

        return true;
    }

    private shouldBlurBackground(): boolean {
        const blurBackground = this.getAttribute('data-blur-background');

        if (blurBackground === '1') {
            return true;
        }

        return false;
    }

    public registerOpeningTrigger(): void {
        if (this.triggerButtons) {
            this.triggerButtons.forEach((triggerButton) => {
                triggerButton.addEventListener('click', this.openModal, false);
            });
        }
    }

    public removeOpeningTrigger(): void {
        if (this.triggerButtons) {
            this.triggerButtons.forEach((triggerButton) => {
                triggerButton.removeEventListener('click', this.openModal, false);
            });
        }
    }

    private registerClosingTriggers(): void {
        if (!this.closingByTriggerIsPossible()) {
            return;
        }

        if (this.closeButtons) {
            this.closeButtons.forEach((button) => {
                button.addEventListener('click', (event: Event) => this.closeModal(event));
            });
        }

        this.overlay.addEventListener('click', (event: Event) => this.closeModal(event));
    }

    private registerKeyDownEvent(): void {
        this.addEventListener('keydown', (event: KeyboardEvent) => this.onTriggerKeydown(event));
    }

    private createOverlay(): void {
        this.overlay = document.createElement('div');
        this.overlay.setAttribute('tabindex', '-1');
        this.overlay.setAttribute('id', `${this.randomModalId}__overlay`);
        this.overlay.classList.add(`${this.name}__overlay`);

        if (this.shouldBlurBackground()) {
            this.overlay.classList.add(`${this.name}__overlay_blur`);
        }

        document.body.appendChild(this.overlay);
    }

    get triggerSelector() {
        const triggerSelector = this.getAttribute('data-trigger');
        return triggerSelector !== null && triggerSelector !== '' ? triggerSelector : null;
    }
}
