import ShoppingListAddItem from 'ShoppingListWidget/components/molecules/shopping-list-add-item/shopping-list-add-item';

export default class SessionShoppingListAddItem extends ShoppingListAddItem {
    protected readyCallback(): void {
        this.shoppingListSelect = this.querySelector('[name=idSessionShoppingList]');
        this.shoppingListForm = this.querySelector('.js-session-shopping-list__form');
        this.formSubmitButton = this.querySelector('.js-add-to-session-shopping-list');

        this.initShoppingListAddItem();
        this.mapEvents();
    }
}
