import Component from 'ShopUi/models/component';

export default class ProductDetail extends Component {
    private showMoreDetailsButton: HTMLElement;

    protected readyCallback() {
        this.showMoreDetailsButton = <HTMLElement> this.querySelector(this.showMoreDetailsButtonSelector);
        this.mapEvents();
    }

    protected mapEvents() {
        if (this.showMoreDetailsButton !== null) {
            this.showMoreDetailsButton.addEventListener('click', this.handleShowMoreDetailsButtonClick.bind(this));
        }
    }

    private handleShowMoreDetailsButtonClick() {
        this.querySelectorAll(this.showMoreDetailsTargetSelector).forEach((element) => {
            element.classList.remove(this.hiddenClass);
        });

        this.showMoreDetailsButton.classList.add(this.hiddenClass);
    }

    get showMoreDetailsButtonSelector() {
        return `.${this.name}__show-more-details-button`;
    }

    get showMoreDetailsTargetSelector() {
        return `.${this.name}__detail-list-col`;
    }

    // eslint-disable-next-line class-methods-use-this
    get hiddenClass() {
        return 'is-hidden-sm-lg';
    }
}
