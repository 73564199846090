import Component from 'ShopUi/models/component';
import ProductConfiguratorConfig from '../abstract-step/product-configurator-config';

export default class RoomView extends Component {
    private roomViewContainer: HTMLElement;

    private newImageContainer: HTMLElement;

    private oldImageContainer: HTMLElement;

    private detailModalContainer: HTMLElement;

    private productConfiguratorConfig: ProductConfiguratorConfig;

    private filepath: string = '';

    private series: string = 'numinos';

    private productFamily: string = 'downlight';

    private productType: string = 'downlight';

    private options: any;

    public setFilepath(value: string): void {
        this.filepath = value;
    }

    public setProductFamily(value: string): void {
        this.productFamily = value;
    }

    public setProductType(value: string): void {
        this.productType = value;
    }

    public setOptions(value: any): void {
        this.options = value;
    }

    public setSeries(value: string): void {
        this.series = value;
    }

    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /* eslint-disable no-empty-function */
    }

    public initGraphic(productFamily: string, productType: string, options: any): void {
        this.roomViewContainer = <HTMLElement> this.querySelector(`.${this.name}__container`);
        this.newImageContainer = <HTMLElement> this.querySelector(`.${this.name}__new-image-container`);
        this.oldImageContainer = <HTMLElement> this.querySelector(`.${this.name}__old-image-container`);
        this.detailModalContainer = <HTMLElement> document.querySelector(`.${this.name}__modal`);

        this.setProductFamily(productFamily);
        this.setProductType(productType);
        this.setOptions(options);

        this.productConfiguratorConfig = new ProductConfiguratorConfig();

        this.createImage();
    }

    protected createImage(): void {
        const img = this.generateImg();
        // eslint-disable-next-line consistent-this
        const self = this;

        img.onload = () => {
            self.oldImageContainer.innerHTML = self.newImageContainer.innerHTML;
            self.newImageContainer.innerText = '';
            self.newImageContainer.appendChild(img);

            self.setModalImage();
        };
    }

    private setModalImage() {
        const modalImage = this.detailModalContainer.querySelector('.modal-window__room-view-image');
        modalImage.setAttribute('src', this.getImage());
    }

    protected generateImg(): HTMLImageElement {
        // eslint-disable-next-line consistent-this
        const self = this;
        this.roomViewContainer.setAttribute('data-loaded', '0');

        const imgElement = document.createElement('img');
        const imgSrc = this.getImage();

        imgElement.setAttribute('src', imgSrc);
        imgElement.style.right = this.productConfiguratorConfig.getRightRoomViewOffset(this.options.abstract_sku);

        imgElement.onload = () => {
            self.roomViewContainer.setAttribute('data-loaded', '1');
        };

        imgElement.classList.add(`${this.name}__image`);

        return imgElement;
    }

    public change(options: {}) {
        Object.keys(options).forEach((optionKey) => {
            this.changeSingleProperty(optionKey, options[optionKey]);
        });
    }

    protected changeSingleProperty(optionKey: string, value: string): void {
        if (this.options[optionKey]) {
            this.options[optionKey] = value;
        }
        this.createImage();
    }

    protected preloadImage(): void {
        const preloadedImage = new Image();
        preloadedImage.src = this.getImage();
    }

    protected getImage(): string {
        const fileExtension = 'jpg';

        const abstractSku = this.getCleanStingValue(this.options.abstract_sku);
        const wattage = this.getCleanStingValue(this.options.variant_wattage);
        const lightColor = this.getCleanStingValue(this.options.variant_light_color);
        const beamAngle = this.getCleanStingValue(this.options.variant_beam_angle);

        return `${this.filepath}/${this.productFamily}/${this.productType}/roomview/` +
            `${abstractSku}_${wattage}_${lightColor}_${beamAngle}.${fileExtension}`;
    }

    // eslint-disable-next-line class-methods-use-this
    private getCleanStingValue(uncleanValue: string): string {
        return uncleanValue.split('.')[0];
    }
}
