import Component from 'ShopUi/models/component';

/**
 * This class makes sure that the navigation-dropdown-menu component has the correct hover functionality.
 */
export default class NavigationDropdownMenu extends Component {
    private hoverTriggers: Element[];

    private lastTouchedElement: HTMLElement;

    private isTouch: boolean;

    constructor() {
        super();
        this.isTouch = 'ontouchstart' in window;
    }

    /**
     * Callback method when component ready.
     */
    protected init(): void {
        this.hoverTriggers = Array.from(this.querySelectorAll(this.hoverTriggerSelector));
        this.setupListeners();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Sets the listeners on the hover triggers
     */
    private setupListeners() {
        if (this.isTouch) {
            this.hoverTriggers.forEach((trigger: HTMLElement) => {
                trigger.addEventListener('touchend', (event: Event) => this.onTouch(event, trigger));
            });
        } else {
            this.hoverTriggers.forEach((trigger: Element) => {
                trigger.addEventListener('mouseenter', () => this.onMouseEnter(trigger));
            });
        }
    }

    /**
     * Defines the behaviour when the mouse is entered on the trigger element.
     *
     * @param trigger The element that experiences the mouseenter event.
     */
    private onMouseEnter(trigger: Element) {
        // Mark menu item as selected
        this.selectMenuItem(trigger);

        // Hide all submenus first
        const submenus = this.querySelectorAll(this.hideSubmenuSelector);
        submenus.forEach(this.hideSubmenu.bind(this));

        // Show only selected Submenu
        const selectedSubmenu = this.querySelector(NavigationDropdownMenu.getShowOnHoverSelector(trigger));
        this.showSubmenu(selectedSubmenu);
    }

    private onTouch(event: Event, trigger: HTMLElement) {
        if (this.lastTouchedElement !== trigger) {
            event.preventDefault();
            this.onMouseEnter(trigger);
            this.lastTouchedElement = <HTMLElement>trigger;
        }
    }

    /**
     * Marks a given menu item element as selected
     *
     * @param element The element that should be marked as selected.
     */
    private selectMenuItem(element: Element | null) {
        if (element) {
            // Unselect all other submenus first
            const menuItems = this.querySelectorAll(this.menuItemSelector);
            menuItems.forEach(this.unselectMenuItem.bind(this));

            // Show item as selected
            element.classList.add(this.selectedMenuItemClass);
        }
    }

    /**
     * Unselects a given menu item element.
     *
     * @param element The element that should be unselected.
     */
    private unselectMenuItem(element: Element | null) {
        if (element) {
            element.classList.remove(this.selectedMenuItemClass);
        }
    }

    /**
     * Shows a given submenu element.
     *
     * @param element element that should be shown.
     */
    private showSubmenu(element: Element | null) {
        if (element) {
            element.classList.remove(this.hiddenClass);
        }
    }

    /**
     * Hides a given submenu element.
     *
     * @param element element that should be shown.
     */
    private hideSubmenu(element: Element | null) {
        if (element) {
            element.classList.add(this.hiddenClass);
        }
    }

    /**
     * Returns the show-on-hover-selector from a given element.
     *
     * @param element element that should be shown.
     */
    private static getShowOnHoverSelector(element: Element): string {
        return element.getAttribute('show-on-hover-selector');
    }

    // eslint-disable-next-line class-methods-use-this
    get hoverTriggerSelector(): string {
        return '.navigation-node-item__trigger';
    }

    // eslint-disable-next-line class-methods-use-this
    get hideSubmenuSelector(): string {
        return '.navigation-dropdown-submenu';
    }

    // eslint-disable-next-line class-methods-use-this
    get hiddenClass(): string {
        return 'is-hidden';
    }

    // eslint-disable-next-line class-methods-use-this
    get selectedMenuItemClass(): string {
        return 'navigation-node-item__trigger--selected';
    }

    // eslint-disable-next-line class-methods-use-this
    get menuItemSelector(): string {
        return '.navigation-node-item__trigger';
    }
}
