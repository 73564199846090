import Component from 'ShopUi/models/component';
import ProductListSlider from 'src/BestIt/ShopUi/components/molecules/product-list-slider/product-list-slider';

export default class ProductFamily extends Component {
    private tabNavItems: HTMLElement[];

    /* eslint-disable no-invalid-this */
    protected cssSelectors: any = {
        tab: `.${this.name}__nav-item`,
        activeTab: `.${this.name}__nav-item.active`,
    }
    /* eslint-enable no-invalid-this */

    protected cssClasses: any = {
        active: 'active',
        hidden: 'is-hidden',
    }

    /**
     * @inheritDoc
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /* eslint-disable no-empty-function */
    }

    protected init() {
        super.init();

        this.tabNavItems = <HTMLElement[]> Array.from(
            this.querySelectorAll(`.${this.name}__nav-item, .${this.name}__nav-item *`),
        );

        this.mapEvents();
    }

    /**
     * @return void
     */
    private mapEvents(): void {
        this.tabNavItems.forEach((tabNavItem: HTMLElement) => {
            tabNavItem.addEventListener('click', (event: Event) => this.switchTab(event));
        });
    }

    private switchTab(event: Event): void {
        let tab = <HTMLElement> event.target;

        if (!tab.hasAttribute('data-content') && !tab.classList.contains(this.cssSelectors.tab)) {
            do {
                tab = tab.parentElement;
            } while (!tab.hasAttribute('data-content') && !tab.classList.contains(this.cssSelectors.tab));
        }

        if (!tab.classList.contains(this.cssClasses.active)) {
            const tabContent = this.getTabContent(tab);
            const tabProductSlider = <ProductListSlider> tabContent.querySelector('.product-list-slider');
            const currentActiveTab = this.querySelector(this.cssSelectors.activeTab);
            const currentOpenedContent = this.getTabContent(currentActiveTab);

            currentActiveTab.classList.remove(this.cssClasses.active);
            currentOpenedContent.classList.add(this.cssClasses.hidden);

            tab.classList.add(this.cssClasses.active);
            tabContent.classList.remove(this.cssClasses.hidden);
            tabProductSlider.reinitProductListSlider();
        }
    }

    /**
     * @param triggerElement
     *
     * @return HTMLElement|null
     */
    private getTabContent(triggerElement: HTMLElement) {
        return <HTMLElement> this.querySelector(triggerElement.getAttribute('data-content')) || null;
    }
}
