import Component from 'ShopUi/models/component';
import SlickCarousel from 'src/BestIt/ShopUi/components/molecules/slick-carousel/slick-carousel';
import KeenSliderCarousel from 'src/BestIt/ShopUi/components/molecules/keen-slider-carousel/keen-slider-carousel';

export default class ProductListSlider extends Component {
    private productSlider: KeenSliderCarousel;

    private productBoxSliders: SlickCarousel[];

    protected readyCallback(): void {
        this.productSlider = <KeenSliderCarousel> this.querySelector('.keen-slider-carousel');

        this.productBoxSliders = <SlickCarousel[]> Array.from(
            this.productSlider.querySelectorAll('.slick-carousel--products'),
        );

        this.mapEvents();
    }

    private mapEvents() {
        window.addEventListener('resize', () => this.onWindowResize());
    }

    private onWindowResize() {
        this.productSlider.sliderReinit();

        this.productBoxSliders.forEach((slider) => {
            slider.sliderReinit();
        });
    }

    public initProductListSlider() {
        this.productSlider.sliderInit();

        this.productBoxSliders.forEach((slider: SlickCarousel) => {
            slider.sliderInit();
        });
    }

    public reinitProductListSlider() {
        this.productSlider.sliderReinit();

        this.productBoxSliders.forEach((slider: SlickCarousel) => {
            slider.sliderReinit();
        });
    }

    public terminateProductListSlider() {
        this.productSlider.sliderTerminate();
    }
}
