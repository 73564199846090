import Component from 'ShopUi/models/component';

export default class Video extends Component {
    private videoElement: HTMLIFrameElement;

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * The init function of the component
     * @protected
     */
    protected init(): void {
        this.videoElement = <HTMLIFrameElement> this.querySelector(`.${this.jsName}__youtube-iframe`);

        if (this.autoplayActivated) {
            this.activateAutoplayOnIntersection();

            this.mapAutoplayEvents();
        }

        if (this.loading === 'lazy') {
            this.mapLazyLoadingEvents();
        }
    }

    /**
     * Mapper for the autoplay events
     *
     * @protected
     */
    protected mapAutoplayEvents(): void {
        document.addEventListener('scroll', () => this.activateAutoplayOnIntersection());
        document.addEventListener('imageGalleryFullscreenOpened', () => this.activateAutoplayOnIntersection());
    }

    /**
     * Mapper for the lazy loading events
     * @protected
     */
    protected mapLazyLoadingEvents(): void {
        document.addEventListener('scroll', () => this.loadYoutubeVideo());
        document.addEventListener('imageGalleryFullscreenOpened', () => this.loadYoutubeVideo());
    }

    /**
     * Stops the video if it outside the visible area
     *
     * @param video
     *
     * @private
     */
    private static stopVideoInView(video: HTMLIFrameElement): void {
        if (video !== null) {
            video.contentWindow.postMessage(JSON.stringify({
                event: 'command',
                func: 'pauseVideo',
            }), '*');
        }
    }

    /**
     * Starts the video if it outside the visible area
     *
     * @param video
     *
     * @private
     */
    private static startVideoInView(video: HTMLIFrameElement): void {
        if (video !== null) {
            video.contentWindow.postMessage(JSON.stringify({
                event: 'command',
                func: 'playVideo',
            }), '*');
        }
    }

    /**
     * Checks if the video is in the visible area
     *
     * @private
     */
    private isElementInViewport(): boolean {
        const rect = this.getBoundingClientRect();

        return rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth);
    }

    /**
     * Getter to get the autoplay configuration
     */
    get autoplayActivated() {
        const autoplay = this.getAttribute('autoplay');

        return autoplay === '' || autoplay !== null;
    }

    /**
     * Getter to get the loading configuration
     */
    get loading(): string {
        return this.dataset.loading || 'eager';
    }

    /**
     * Event handler for the autoplay event
     */
    private activateAutoplayOnIntersection(): void {
        this.loadYoutubeVideo();

        if (this.isElementInViewport()
        ) {
            Video.startVideoInView(this.videoElement);
        } else {
            Video.stopVideoInView(this.videoElement);
        }
    }

    /**
     * Loads the Youtube video, if it is not loaded already
     *
     * @protected
     */
    protected loadYoutubeVideo(): void {
        if (this.loading === 'lazy' &&
            this.hasToBeLoaded()
        ) {
            this.videoElement.src = this.videoElement.dataset.videoSrc;
            this.videoElement.removeAttribute('data-video-src');
        }
    }

    /**
     * Returns if the Youtube video iFrame has to be loaded
     *
     * @protected
     */
    protected hasToBeLoaded(): boolean {
        return typeof this.videoElement.dataset.videoSrc !== 'undefined' &&
            this.videoElement.dataset.videoSrc !== null &&
            this.videoElement.dataset.videoSrc !== '';
    }
}
