import Component from 'ShopUi/models/component';
import QuantityInputSelect from 'src/BestIt/ShopUi/components/molecules/quantity-input-select/quantity-input-select';
import Ga4EventTracking from 'GA4EventTracking/components/molecules/ga4-event-tracking/ga4-event-tracking';

export default class ShoppingListProductQuickAdd extends Component {
    private quantityInputSelect: QuantityInputSelect;

    private submitButton: HTMLButtonElement;

    ga4EventHandler: Ga4EventTracking;

    selectedItem : HTMLInputElement;

    protected readyCallback(): void {
        this.quantityInputSelect = this.querySelector('.quantity-input-select');
        this.submitButton = this.querySelector('button[type=submit]');
        this.ga4EventHandler = document.querySelector('.ga4-event-tracking');
        this.selectedItem = this.querySelector('.js-product-search-autocomplete-form__input-hidden');
        this.mapEvents();
    }

    private mapEvents(): void {
        this.submitButton.addEventListener('click', () => this.onFormSubmit());
    }

    /* eslint-disable camelcase */
    private onFormSubmit() {
        if (typeof this.quantityInputSelect !== 'undefined' && this.quantityInputSelect !== null) {
            let quantity = this.quantityInputSelect.getAttribute('data-new-value');
            if (quantity === '0' || quantity === '') {
                quantity = '1';
            }

            const pushElement = document.createElement('input');

            const suggestedItems = this.querySelectorAll('li[data-ga4-event-data]');

            const selectedItemSku = this.selectedItem.attributes.getNamedItem('value').value;

            suggestedItems.forEach((item: Element) => {
                const itemData = JSON.parse(item.attributes.getNamedItem('data-ga4-event-data').value);

                if (itemData.items[0].item_id === selectedItemSku) {
                    itemData.items[0].quantity = parseInt(quantity, 10);
                    const data = {
                        event: 'Ecommerce - add_to_wishlist',
                        event_name: 'add_to_wishlist',
                        add_to_wishlist_type: 'Account',
                        items: itemData.items
                    };
                    pushElement.setAttribute('data-ga4-event-name', 'add_to_wishlist');
                    pushElement.setAttribute('data-ga4-event-data', JSON.stringify(data));
                    this.ga4EventHandler.createAndPushEvent(pushElement);
                }
            });
        }
    }
}
/* eslint-enable camelcase */
