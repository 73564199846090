import CustomSubtypeInterface from './custom-subtype-interface';

/* eslint-disable camelcase */
export interface SearchDataInterface {
    search_term: string;
    search_type: string;
    search_recommendation: string|null;
}

export default class Search implements CustomSubtypeInterface {
    search_term: string;
    search_type: string;
    search_recommendation: string|null;

    constructor(data: SearchDataInterface) {
        this.search_term = data.search_term;
        this.search_type = data.search_type;
        this.search_recommendation = data.search_recommendation;
    }
}
/* eslint-enable camelcase */
