import Component from 'ShopUi/models/component';

export default class scrollToAnchorField extends Component {
    private header: HTMLElement;

    private hash: string;

    private anchors: HTMLElement[];

    protected readyCallback(): void {
        this.header = <HTMLElement>document.querySelector('.header');
        this.anchors = Array.from(document.querySelectorAll('a[href^="#"]'));
        this.hash = window.location.hash;
        this.mapEvents();
    }

    protected mapEvents(): void {
        window.addEventListener('load', (event: Event) => {
            if (typeof this.hash !== 'undefined' && this.hash !== '') {
                // Timeout is necessary, because of the header.scss animationDuration
                setTimeout(() => {
                    this.scrollToAnchor(event, this.hash);
                }, 320);
            }
        });

        window.addEventListener('hashchange', (event: HashChangeEvent) => {
            this.scrollToAnchor(event, window.location.hash);
        });

        if (typeof this.anchors !== 'undefined' && this.anchors !== null) {
            this.anchors.forEach((anchor) => {
                if (anchor.getAttribute('href').indexOf('#') !== -1) {
                    anchor.addEventListener('click', (event: MouseEvent) => {
                        const anchorLink = anchor.getAttribute('href').substr(anchor.getAttribute('href').indexOf('#'));
                        this.scrollToAnchor(event, anchorLink);
                    });
                }
            });
        }
    }

    private scrollToAnchor(event: Event, anchor?: string) {
        event.preventDefault();

        if (anchor !== null && typeof anchor !== 'undefined') {
            const destination = <HTMLElement> document.querySelector(anchor);
            if (destination) {
                const scrollPosition = destination.offsetTop;

                if (event.type === 'click') {
                    window.location.hash = anchor;
                }

                const scrollTo = scrollPosition - this.header.offsetHeight;
                window.scroll(0, scrollTo);
            }
        }
    }
}
