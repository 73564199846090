import Component from 'ShopUi/models/component';

export default class BreakpointDependentBlockPlacer extends Component {
    protected data: Object[]

    protected blocks: HTMLElement[]

    protected init(): void {
        this.blocks = <HTMLElement[]>Array.from(document.querySelectorAll(this.blockSelector));

        // eslint-disable-next-line arrow-body-style
        this.data = this.blocks.map((block: HTMLElement) => {
            return {
                isMoved: false,
                node: block,
                parentNode: block.parentElement,
                breakpoint: Number(this.getDataAttribute(block, 'data-breackpoint')),
                selectorBlockToMove: this.getDataAttribute(block, 'data-block-to'),
            };
        });

        this.initBlockMoving();
        this.mapEvents();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    protected mapEvents(): void {
        window.addEventListener('resize', () => setTimeout(() => this.initBlockMoving(), 300));
    }

    protected initBlockMoving(): void {
        this.data.forEach((item: {
            breakpoint: number,
            selectorBlockToMove: string,
            node: HTMLElement,
            parentNode: HTMLElement,
            isMoved: boolean
        }) => {
            const { isMoved, breakpoint } = item;

            if (window.innerWidth < breakpoint && !isMoved) {
                const { selectorBlockToMove, node } = item;
                const blockToMove = document.querySelector(selectorBlockToMove);

                item.isMoved = true;
                blockToMove.appendChild(node);
            } else if (window.innerWidth >= breakpoint && isMoved) {
                const { parentNode, node } = item;

                item.isMoved = false;
                parentNode.appendChild(node);
            }
        });
    }

    get blockSelector(): string {
        return this.getAttribute('block-selector');
    }

    // eslint-disable-next-line class-methods-use-this
    protected getDataAttribute(block: HTMLElement, attr: string): string {
        return block.getAttribute(attr);
    }
}
