import AjaxProvider from 'src/BestIt/ShopUi/components/molecules/ajax-provider/ajax-provider';
import Component from 'ShopUi/models/component';
import PartnerLog from 'src/BestIt/ShopUi/components/molecules/partner-log/partner-log';

export default class SummaryStepSidebarNuminos extends Component {
    private EVENT_RESPONSE_FROM_PARTNER: string = 'slvAddToCartResponse';

    private addToPartnerCartButton: HTMLButtonElement;

    private messageContainer: HTMLElement;

    private ajaxProvider: AjaxProvider;

    private ajaxProviderPartner: AjaxProvider;

    private form: HTMLFormElement;

    protected readyCallback() {
        this.addToPartnerCartButton = this.querySelector(`.${this.jsName}__add-partner-cart-message`);
        this.messageContainer = this.querySelector(`.${this.name}__sidebar-partner-cart-message`);
        this.ajaxProvider = <AjaxProvider> this.querySelector(`.${this.jsName}__provider`);
        this.ajaxProviderPartner = <AjaxProvider> this.querySelector(`.${this.jsName}__provider-partner`);
        this.form = this.querySelector(`.${this.jsName}__form`);

        this.mapEvents();
    }

    private mapEvents(): void {
        if (this.addToPartnerCartButton !== null) {
            this.addToPartnerCartButton.addEventListener('click', (event: Event) => this.postAddToCartMessage(event));
            window.addEventListener('message', (event: MessageEvent) => this.handleResponseMessage(event));
        }

        if (this.form !== null) {
            this.form.addEventListener('submit', (event: Event) => this.onFormSubmit(event));
        }
    }

    private postAddToCartMessage(event: Event): void {
        if (this.ajaxProvider !== null) {
            event.preventDefault();
            new PartnerLog(this.ajaxProvider).addLog('add_to_cart').then(() => {
                this.executePostAddToCartMessage();
            });
        } else {
            this.executePostAddToCartMessage();
        }
    }

    private executePostAddToCartMessage(): void {
        let productsJson = JSON.parse(this.productsForPartnerCart);

        if (this.responseDataAsString) {
            productsJson = JSON.stringify(productsJson);
        }

        this.addToPartnerCartButton.setAttribute('disabled', 'disabled');
        window.parent.postMessage(
            productsJson,
            this.partnerWebsite,
        );
    }

    private async onFormSubmit(event: Event) {
        let submitFormAfterLogging = true;

        if ((typeof this.ajaxProviderPartner !== 'undefined' && this.ajaxProviderPartner !== null) ||
            (typeof this.ajaxProvider !== 'undefined' && this.ajaxProvider !== null)
        ) {
            event.preventDefault();
        }

        if (typeof this.ajaxProviderPartner !== 'undefined' && this.ajaxProviderPartner !== null) {
            const inputFields = <HTMLInputElement[]> Array.from(
                this.form.querySelectorAll('input[type="hidden"]'),
            );
            const honeyPotField = <HTMLInputElement> this.form.querySelector('input[name$="[check]"]');

            inputFields.forEach((input) => {
                if (input.name.indexOf('_token') === -1) {
                    const name = input.dataset.name || input.name;
                    this.ajaxProviderPartner.queryParams.set(name, this.getFieldValue(input));
                }
            });

            if (honeyPotField.value === '') {
                honeyPotField.removeAttribute('required');
            }

            await this.ajaxProviderPartner.fetch();

            submitFormAfterLogging = false;
        }

        if (typeof this.ajaxProvider !== 'undefined' && this.ajaxProvider !== null) {
            event.preventDefault();
            new PartnerLog(this.ajaxProvider).addLog('add_to_cart').then(() => {
                if (submitFormAfterLogging) {
                    this.form.submit();
                }
            });
        }
    }

    // eslint-disable-next-line class-methods-use-this
    private getFieldValue(field: any): any {
        let value = null;

        if (!(field.value instanceof String)) {
            value = field.value;
        } else {
            value = field.value.trim;
        }

        return value;
    }

    // eslint-disable-next-line require-await
    private async handleResponseMessage(event: MessageEvent): Promise<void> {
        if (event.origin !== window.location.origin) {
            return;
        }
        switch (event.data.event_id) {
            case this.EVENT_RESPONSE_FROM_PARTNER:
                this.addMessage(event.data);
                break;
            default:
                break;
        }
    }

    private addMessage(event: any) {
        const message = event.data.success ? this.successMessage : this.errorMessage;

        this.messageContainer.classList.remove('text--success');
        this.messageContainer.classList.remove('text--alert');
        this.messageContainer.classList.add(`text--${event.data.success ? 'success' : 'alert'}`);
        this.messageContainer.textContent = message;
        this.messageContainer.classList.remove('is-hidden');

        if (!event.data.success) {
            this.addToPartnerCartButton.removeAttribute('disabled');
        } else {
            const request = new Request(this.clearSessionRoute);

            fetch(request)
                .then((response) => {
                    window.sessionStorage.clear();
                    window.location.href = response.url;
                });
        }
    }

    get productsForPartnerCart(): string {
        return this.addToPartnerCartButton.getAttribute('data-products') || '';
    }

    get partnerWebsite(): string {
        return this.addToPartnerCartButton.getAttribute('data-website') || '';
    }

    get clearSessionRoute() {
        return this.addToPartnerCartButton.getAttribute('data-success-action') || '';
    }

    get responseDataAsString() {
        const stringify = this.addToPartnerCartButton.getAttribute('data-stringify') || 'false';

        return stringify === 'true' || false;
    }

    get successMessage() {
        return this.messageContainer.getAttribute('data-message-success') || '';
    }

    get errorMessage() {
        return this.messageContainer.getAttribute('data-message-error') || '';
    }
}
