import Component from 'ShopUi/models/component';

export default class TechnicalIcons extends Component {
    protected togglers: HTMLElement[];

    protected readyCallback(): void {
        this.initialize();
    }

    private initialize() {
        this.togglers = <HTMLElement[]> Array.from(this.querySelectorAll(`${this.toggleSelector}`));

        this.mapEvents();
    }

    get toggleSelector() {
        return this.dataset.toggleSelector;
    }

    get classToToggle() {
        return this.dataset.classToToggle;
    }

    private mapEvents() {
        if (typeof this.togglers !== 'undefined' && this.togglers !== null && this.togglers.length > 0) {
            this.togglers.forEach((toggler) => {
                toggler.addEventListener('click', () => {
                    this.classList.toggle(this.classToToggle);
                });
            });
        }
    }
}
