import Component from 'ShopUi/models/component';

export default class ToggleSwitch extends Component {
    /**
     * The form which should be submitted on checkbox change
     */
    private form: HTMLFormElement;

    /**
     * The checkbox which holds the data of the toggle switch
     */
    private checkbox: HTMLInputElement;

    protected init(): void {
        this.checkbox = <HTMLInputElement> this.querySelector(`.${this.jsName}__checkbox`);
        this.form = <HTMLFormElement> document.querySelector(this.formSelector);

        this.mapEvents();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    private mapEvents() {
        this.checkbox.addEventListener('change', () => this.submitFilter());
    }

    get formSelector(): string {
        return this.getAttribute('form-selector');
    }

    private submitFilter() {
        this.submitGaEvent();
        const submitEvent = new Event('submit');
        this.form.dispatchEvent(submitEvent);
    }

    private submitGaEvent() {
        let gaEventDataExists = false;
        const gaEventData = {};
        for (let i = 0; i < this.checkbox.getAttributeNames().length; ++i) {
            const attributeName = this.checkbox.getAttributeNames()[i];
            const match = attributeName.match(/^ga-event-(.*)$/);
            if (match) {
                gaEventDataExists = true;
                const gaEventKey = match[1]
                    // eslint-disable-next-line arrow-body-style
                    .replace(/-(.)/g, (str) => {
                        return str.toUpperCase();
                    })
                    .replace(/-/g, '');
                let eventValue = this.checkbox.getAttribute(attributeName)
                    .replace('%value%', this.checkbox.checked ? 'Yes' : 'No');

                if (eventValue === '' || eventValue === null) {
                    eventValue = undefined;
                }
                gaEventData[gaEventKey] = eventValue;
            }
        }
        if (gaEventDataExists) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(gaEventData);
        }
    }
}
