import CustomSubtypeInterface from '../events/custom-subtypes/custom-subtype-interface';
import EventDataTransfer from '../event-data-transfer';
import Ga4CustomEventWithData from '../events/ga4-custom-event-with-data';
import Ga4EventMapperInterface from '../event-mappers/ga4-event-mapper-interface';
import Ga4CustomEventMaker from './ga4-custom-event-maker';

export default class Ga4CustomEventWithDataMaker extends Ga4CustomEventMaker {
    protected customSubtype: typeof CustomSubtypeInterface;

    constructor(customSubtype: typeof CustomSubtypeInterface, mapper?: Ga4EventMapperInterface) {
        super(Ga4CustomEventWithData, mapper);

        this.customSubtype = customSubtype;
    }

    protected collectData(element: HTMLElement, transfer: EventDataTransfer): void {
        super.collectData(element, transfer);

        transfer.customSubtype = this.customSubtype;
    }
}
