import AbstractGa4CustomEventWithDataMapper from './abstract-ga4-custom-event-with-data-mapper';
import CustomSubtypeInterface from '../events/custom-subtypes/custom-subtype-interface';
import CatalogueRead, {
    CatalogueReadDataInterface
} from 'GA4EventTracking/types/events/custom-subtypes/catalogue-read';

export default class Ga4CustomCatalogueReadEventMapper extends AbstractGa4CustomEventWithDataMapper {
    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(data: CatalogueReadDataInterface): CustomSubtypeInterface {
        return new CatalogueRead(data);
    }
}
