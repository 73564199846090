import Component from 'ShopUi/models/component';
// eslint-disable-next-line no-unused-vars
import LoadingIndicator from 'src/BestIt/ShopUi/components/molecules/loading-indicator/loading-indicator';
import AjaxProvider from 'ShopUi/components/molecules/ajax-provider/ajax-provider';

export default class IframeLoader extends Component {
    /**
     * The ajax provider for the add to cart action
     */
    private ajaxProviderAddToCart: AjaxProvider;

    /**
     * The ajax provider for the add to shopping list action
     */
    private ajaxProviderAddToShoppingList: AjaxProvider;

    /**
     * The loading indicator for the modal
     */
    private loadingIndicator: LoadingIndicator;

    protected readyCallback(): void {
        const form = this.querySelector('form');
        const iframe = this.querySelector('iframe');
        this.loadingIndicator = this.querySelector('loading-indicator') as LoadingIndicator;
        this.ajaxProviderAddToCart = <AjaxProvider>(
            this.querySelector(`.${this.jsName}__ajax-provider--addToCart`)
        );

        this.ajaxProviderAddToShoppingList = <AjaxProvider>(
            this.querySelector(`.${this.jsName}__ajax-provider--addToShoppingList`)
        );

        iframe.addEventListener('load', () => {
            this.loadingIndicator.closeLoadingIndicator();
        });

        window.addEventListener('message', (event: MessageEvent) => this.handlePostMessage(event));

        if (typeof this.ajaxProviderAddToShoppingList !== 'undefined' && this.ajaxProviderAddToShoppingList !== null) {
            this.ajaxProviderAddToShoppingList.addEventListener('fetching', () => this.openLoadingIndicatorOnSubmit());
        }

        if (typeof this.ajaxProviderAddToCart !== 'undefined' && this.ajaxProviderAddToCart !== null) {
            this.ajaxProviderAddToCart.addEventListener('fetching', () => this.openLoadingIndicatorOnSubmit());
        }

        this.addEventListener('allProductsAdded', () => {
            window.location.reload();
        });

        this.modalWindow.addEventListener('opened', () => {
            this.loadingIndicator.openLoadingIndicator();
            if (form) {
                form.submit();
            }
        });
    }

    private get modalWindow() {
        return document.querySelector(this.getAttribute('modal-selector'));
    }

    private static get globalLoadingIndicator(): LoadingIndicator {
        return <LoadingIndicator> document.querySelector('.loading-indicator--fixed');
    }

    private get sku() {
        return this.getAttribute('data-sku');
    }

    private async handlePostMessage(event: MessageEvent) {
        const data = event.data;
        const products = data.productIds;

        if (typeof data.action !== 'undefined') {
            if (data.action === 'addToLeaflet' &&
                typeof this.ajaxProviderAddToShoppingList !== 'undefined' &&
                this.ajaxProviderAddToShoppingList !== null) {
                await this.sendAjaxCall(products, this.ajaxProviderAddToShoppingList);

                this.dispatchAllProductsAddedEvent();
            }

            if (data.action === 'addToShoppingBasket' &&
                typeof this.ajaxProviderAddToCart !== 'undefined' &&
                this.ajaxProviderAddToCart !== null) {
                await this.sendAjaxCall(products, this.ajaxProviderAddToCart);

                this.dispatchAllProductsAddedEvent();
            }
        }
    }

    private buildQueryParams(ajaxProvider: AjaxProvider, quantity?: string): AjaxProvider {
        ajaxProvider.queryParams.set('sku', this.sku);
        if (quantity !== null) {
            ajaxProvider.queryParams.set('quantity', quantity);
        }

        return ajaxProvider;
    }

    private async sendAjaxCall(products: any, ajaxProvider: AjaxProvider) {
        /* eslint-disable no-await-in-loop */
        for (let i = 0; i < products.length; i++) {
            const product = <string> products[i];
            const productData = product.split(':');

            const quantity = productData.length > 1 ? productData[1] : '1';

            ajaxProvider = this.buildQueryParams(ajaxProvider, quantity);
            await ajaxProvider.fetch();
        }
        /* eslint-enable no-await-in-loop */
    }

    private dispatchAllProductsAddedEvent() {
        const event = new Event('allProductsAdded');
        this.dispatchEvent(event);
    }

    private openLoadingIndicatorOnSubmit() {
        if (IframeLoader.globalLoadingIndicator !== null &&
            typeof IframeLoader.globalLoadingIndicator !== 'undefined') {
            IframeLoader.globalLoadingIndicator.openLoadingIndicator();
        } else {
            this.loadingIndicator.openLoadingIndicator();
        }
    }
}
