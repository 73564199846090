import Component from 'ShopUi/models/component';

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        rc: any;
    }
}

export default class CatalogElement extends Component {
    protected readCatalogButtons: HTMLElement[];

    /**
     * @inheritDoc
     *
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Init function for the component
     *
     * @public
     */
    public initialize(): void {
        // eslint-disable-next-line id-length
        window.rc = window.rc || {};
        this.readCatalogButtons = <HTMLElement[]> Array.from(this.querySelectorAll('.js-catalog-box__read-button'));

        this.mapEvents();
    }

    /**
     * Maps the event for the read catalog icon button to trigger the Sklik Event
     *
     * @protected
     */
    protected mapEvents() {
        window.addEventListener('load', () => {
            if (typeof window.rc !== 'undefined' && window.rc !== null &&
                typeof window.rc.conversionHit === 'function'
            ) {
                this.readCatalogButtons.forEach((readButton) => {
                    readButton.addEventListener('click', () => {
                        window.rc.conversionHit({
                            id: 100117259,
                            // eslint-disable-next-line camelcase
                            seznam_value: null,
                        });
                    });
                });
            }
        });
    }
}
