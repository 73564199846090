import Ga4EventInterface from '../events/ga4-event-interface';
import UserCentricsWrapper from '../user-centrics-wrapper';

export default class Ga4EventPusher {
    protected readonly STORAGE_KEY = 'ga4_event_collection';

    protected userCentricsHandler: UserCentricsWrapper;

    constructor(userCentricsHandler: UserCentricsWrapper) {
        this.userCentricsHandler = userCentricsHandler;
    }

    public push(event: Ga4EventInterface): void {
        const convertedData = this.prepareDataForDataLayer(event);

        if (Object.prototype.hasOwnProperty.call(event, 'ecommerce')) {
            window.dataLayer.push({ ecommerce: null });
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(convertedData);
    }

    protected static addToEventCollection(event: Ga4EventInterface): void {
        window.eventCollection = window.eventCollection || [];

        window.eventCollection.push(event);
    }

    protected prepareDataForDataLayer(data, key = null) {
        if (key !== null) {
            data = data[key];
        }

        if (data === null) {
            return undefined;
        }

        if (typeof data !== 'object') {
            return data;
        }

        if (Ga4EventPusher.areAllObjectKeysIntegers(data)) {
            return this.convertObjectToArray(data);
        }

        for (const dataKey in data) {
            if (!Object.prototype.hasOwnProperty.call(data, dataKey)) {
                continue;
            }

            data[dataKey] = this.prepareDataForDataLayer(data, dataKey);
        }

        return data;
    }

    protected static areAllObjectKeysIntegers(object: object): boolean {
        const keys = Object.keys(object);
        const isInteger = (key) => Number.isInteger(Number(key));

        return keys.filter(isInteger).length === keys.length;
    }

    protected convertObjectToArray(object: object) {
        const array = [];

        for (const key in object) {
            if (!Object.prototype.hasOwnProperty.call(object, key)) {
                continue;
            }

            const value = this.prepareDataForDataLayer(object, key);
            array.push(value);
        }

        return array;
    }

    public pushEventsFromStorage(): void {
        const events = this.getEventsFromStorage();

        events.forEach((event: Ga4EventInterface) => {
            this.push(event);
        });

        this.clearEventsFromStorage();
    }

    private getEventsFromStorage(): Ga4EventInterface[] {
        const events = localStorage.getItem(this.STORAGE_KEY);

        if (!events) {
            return [];
        }

        return JSON.parse(events);
    }

    private clearEventsFromStorage(): void {
        localStorage.removeItem(this.STORAGE_KEY);
    }

    public pushEventsToStorage(events: Ga4EventInterface[]): void {
        const eventsFromStorage = this.getEventsFromStorage();
        const allEvents = [...eventsFromStorage, ...events];

        localStorage.setItem(this.STORAGE_KEY, JSON.stringify(allEvents));
    }
}
