import Component from 'ShopUi/models/component';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import Ga4EventTracking from 'GA4EventTracking/components/molecules/ga4-event-tracking/ga4-event-tracking';

export default class SortList extends Component {
    protected DEFAULT_SORTING_NAME = 'Relevance';

    protected sortSelect: HTMLSelectElement;

    protected googleEventHandler: GoogleTagEvents;

    protected ga4EventHandler: Ga4EventTracking;

    protected readyCallback(): void {
        this.initialize();
    }

    protected initialize(): void {
        this.sortSelect = <HTMLSelectElement> this.querySelector(`.${this.name}__sort .custom-select__select`);
        this.googleEventHandler = <GoogleTagEvents> document.querySelector('.google-tag-events');
        this.ga4EventHandler = <Ga4EventTracking> document.querySelector('.ga4-event-tracking');

        this.mapEvents();
    }

    private mapEvents() {
        this.sortSelect.addEventListener('change', (event: Event) => this.changeSorting(event));
    }

    private changeSorting(event: Event) {
        const trigger = <HTMLSelectElement> event.target;

        let triggerValue = trigger.value !== '' ? trigger.value : this.DEFAULT_SORTING_NAME;
        triggerValue = triggerValue.replace('_', ' ');
        // eslint-disable-next-line id-length
        triggerValue = triggerValue.replace(/^\w/, c => c.toUpperCase());

        if (typeof this.googleEventHandler !== 'undefined' && this.googleEventHandler !== null) {
            let gtmEventData = this.googleEventHandler.getGtmEventData(trigger);

            gtmEventData = {
                ...gtmEventData,
                eventLabel: triggerValue,
            };
            const gaEvent = this.googleEventHandler.createGaEvent(gtmEventData);
            this.googleEventHandler.pushEvent(<any> gaEvent);
        }

        if (typeof this.ga4EventHandler !== 'undefined' && this.ga4EventHandler !== null) {
            const eventElement = document.createElement('div');
            eventElement.setAttribute('data-ga4-event-name', 'sorting');
            eventElement.setAttribute('data-ga4-event-data', `{"sorting_element": "${triggerValue}"}`);

            this.ga4EventHandler.createAndPushEvent(eventElement);
        }
    }
}
