import Ga4EcommerceViewCartEvent, { Ga4EcommerceViewCartEventDataInterface } from './ga4-ecommerce-view-cart-event';

/* eslint-disable camelcase */
export interface Ga4EcommerceAddShippingInfoEventDataInterface extends Ga4EcommerceViewCartEventDataInterface {
    shipping_tier: string|null;
}
export default class Ga4EcommerceAddShippingInfoEvent extends Ga4EcommerceViewCartEvent {
    shipping_tier: string|null;

    constructor(data: Ga4EcommerceAddShippingInfoEventDataInterface) {
        super(data);

        this.cart_type = data.cart_type;
        this.shipping_tier = data.shipping_tier;
    }
}
/* eslint-enable camelcase */
