import EcommerceWithCartSum, { EcommerceWithCartSumDataInterface } from './ecommerce-with-cart-sum';

/* eslint-disable camelcase */
export interface EcommerceWithCartSumAndCouponDataInterface extends EcommerceWithCartSumDataInterface {
    coupon: null;
}

export default class EcommerceWithCartSumAndCoupon extends EcommerceWithCartSum {
    coupon: null;

    constructor(data: EcommerceWithCartSumAndCouponDataInterface) {
        super(data);

        this.coupon = null;
    }
}
/* eslint-enable camelcase */
