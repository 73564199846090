import Component from 'ShopUi/models/component';

export default class AttributeFilterProductConcrete extends Component {
    // eslint-disable-next-line no-undef
    private eventTriggerElement: NodeListOf<HTMLElement>;

    // eslint-disable-next-line no-undef
    private productValueContainer: NodeListOf<HTMLButtonElement>;

    protected readyCallback(): void {
        // eslint-disable-next-line no-undef
        this.eventTriggerElement = <NodeListOf<HTMLElement>> this.querySelectorAll(
            `.${this.jsName}__attribute-filter-option`,
        );
        // eslint-disable-next-line no-undef
        this.productValueContainer = <NodeListOf<HTMLButtonElement>> this.querySelectorAll(
            `.${this.jsName}__product-value-container`,
        );
        this.mapEvents();
    }

    private mapEvents(): void {
        this.eventTriggerElement.forEach((trigger) => {
            trigger.addEventListener('click', () => {
                this.removeActiveContainer();
                this.addProductDetailInformation(trigger);
            });
        });
    }

    private removeActiveContainer(): void {
        this.productValueContainer.forEach((container) => {
            container.innerHTML = '';
            container.classList.add('is-hidden');
        });
    }

    private addProductDetailInformation(eventTarget: HTMLElement): void {
        // Get product data from event target
        const containerValue = <string> eventTarget.querySelector(`.${this.name}__outer-container`).innerHTML;

        // Convert product target data to HTML
        const productDetailsContainer = <DocumentFragment> document.createRange().createContextualFragment(
            containerValue,
        );

        const nthChildElement = <number> parseInt(eventTarget.getAttribute('data-filter-key'), 10);

        // Check position and add current value to the next product data container
        this.productValueContainer.forEach((container) => {
            if (parseInt(container.getAttribute('data-filter-key'), 10) === nthChildElement) {
                container.appendChild(productDetailsContainer);
                container.classList.remove('is-hidden');
            }
        });
    }
}
