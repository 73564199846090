import Component from 'ShopUi/models/component';

export default class AddressItemForm extends Component {
    private form: HTMLFormElement;

    private dataInputFields: HTMLElement[];

    private dataAddressIdSelect: HTMLSelectElement;

    private itemFormFields: HTMLInputElement[];

    private addressChoiceType: HTMLInputElement[];

    private itemAddressIdFields: HTMLInputElement[];

    private shipmentAddressFormIdField: HTMLInputElement;

    protected readyCallback(): void {
        this.form = <HTMLFormElement>document.querySelector('[name=addressesForm]');
        this.shipmentAddressFormIdField = <HTMLInputElement>document.querySelector(
            '.address-form__custom-address-form input[name="addressesForm[shippingAddress][idCompanyUnitAddress]"]'
        );

        this.dataInputFields = <HTMLElement[]>Array.from(
            document.querySelectorAll(
                // eslint-disable-next-line max-len
                '[name^="addressesForm[shippingAddress]"]:not([name="addressesForm[shippingAddress][addressChoiceType]"])',
            ),
        );

        this.dataAddressIdSelect = <HTMLSelectElement> document.querySelector(
            '[name="addressesForm[shippingAddress][idCompanyUnitAddress]"]',
        );

        this.itemFormFields = <HTMLInputElement[]> Array.from(
            document.querySelectorAll('[name^="addressesForm[multiShippingAddresses]"]'),
        );

        this.itemAddressIdFields = <HTMLInputElement[]> Array.from(
            document.querySelectorAll(
                '[name$="[idCompanyUnitAddress]"]:not(select)',
            ),
        );

        this.addressChoiceType = <HTMLInputElement[]> Array.from(
            document.querySelectorAll('input[name="addressesForm[shippingAddress][addressChoiceType]"]'),
        );

        this.initItemForms();

        this.mapEvents();
    }

    private mapEvents() {
        this.form.addEventListener('submit', () => this.syncFormData());
        this.dataAddressIdSelect.addEventListener(
            'change',
            (event:Event) => this.syncAddressIdData(<HTMLSelectElement> event.target)
        );

        this.dataInputFields.forEach((dataInputField) => {
            dataInputField.addEventListener(
                'change',
                (event:Event) => this.syncData(<HTMLInputElement|HTMLSelectElement>event.target),
            );
        });

        this.addressChoiceType.forEach((typeButton) => {
            typeButton.addEventListener(
                'click',
                () => this.initItemForms(),
            );
        });
    }

    // eslint-disable-next-line class-methods-use-this
    private syncData(formField: HTMLInputElement|HTMLSelectElement) {
        const syncFields = <HTMLInputElement[]>Array.from(
            document.querySelectorAll(`[data-value-source="${formField.getAttribute('name')}"`),
        );

        syncFields.forEach((field: HTMLInputElement) => {
            field.value = formField.value;
        });
    }

    private initItemForms() {
        this.addressChoiceType.forEach((typeButton) => {
            if (typeButton.checked && typeButton.value === 'addressBook') {
                this.itemFormFields.forEach((itemFormField: HTMLInputElement) => {
                    itemFormField.value = '';
                });

                this.itemAddressIdFields.forEach((itemAddressIdField: HTMLInputElement) => {
                    itemAddressIdField.value = this.dataAddressIdSelect.value;
                });
            } else if (typeButton.checked) {
                this.syncFormData();

                this.itemAddressIdFields.forEach((itemAddressIdField: HTMLInputElement) => {
                    itemAddressIdField.value = '';
                });
            }
        });
    }

    private syncFormData() {
        this.dataInputFields.forEach((inputField: HTMLInputElement|HTMLSelectElement) => {
            this.syncData(inputField);
        });
    }

    private syncAddressIdData(formField: HTMLSelectElement) {
        if (formField.getAttribute('name') === 'addressesForm[shippingAddress][idCompanyUnitAddress]') {
            this.shipmentAddressFormIdField.value = formField.value;
        }
    }
}
