import Component from 'ShopUi/models/component';

export default class AbstractSystemSelectionBox extends Component {
    /**
     * @inheritDoc
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    // eslint-disable-next-line class-methods-use-this
    public initialize() {
        /* eslint-disable no-empty-function */
    }
}
