import Ga4EventMaker from './ga4-event-maker';
import Ga4CustomEvent from '../events/ga4-custom-event';
import Ga4CustomEventMapper from '../event-mappers/ga4-custom-event-mapper';
import Ga4EventInterface from '../events/ga4-event-interface';
import Ga4EventMapperInterface from '../event-mappers/ga4-event-mapper-interface';

export default class Ga4CustomEventMaker extends Ga4EventMaker {
    constructor(dataClass?: typeof Ga4EventInterface, mapper?: Ga4EventMapperInterface) {
        if (dataClass && mapper) {
            super(dataClass, mapper);
        } else {
            super(Ga4CustomEvent, new Ga4CustomEventMapper());
        }
    }
}
