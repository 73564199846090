import Ecommerce from '../ecommerce-subtypes/ecommerce';
import Ga4EcommerceEvent from './ga4-ecommerce-event';
import Product from '../ecommerce-subtypes/product';
import Ga4EcommerceEventWithProductDataInterface from '../ga4-ecommerce-event-with-product-data-interface';

/* eslint-disable camelcase */
export interface Ga4EcommerceAddToWishlistEventDataInterface extends Ga4EcommerceEventWithProductDataInterface {
    add_to_wishlist_type: 'PDP'|'FastCalc'|'Account'|'Shopping Cart';
}
export default class Ga4EcommerceAddToWishlistEvent extends Ga4EcommerceEvent {
    ecommerce: Ecommerce<Product>;
    add_to_wishlist_type: 'PDP'|'FastCalc'|'Account'|'Shopping Cart';

    constructor(data: Ga4EcommerceAddToWishlistEventDataInterface) {
        super(data);

        this.ecommerce = data.ecommerce;
        this.add_to_wishlist_type = data.add_to_wishlist_type;
    }
}
/* eslint-enable camelcase */
