import CustomSubtypeInterface from './custom-subtype-interface';
import MaxLengthStringValue from '../validator-types/max-length-string-value';

/* eslint-disable camelcase */
export interface PriceCalculationDataInterface {
    product_id: string;
}

export default class PriceCalculation implements CustomSubtypeInterface {
    product_id: string;

    constructor(data: PriceCalculationDataInterface) {
        this.product_id = new MaxLengthStringValue(data.product_id).value;
    }
}
/* eslint-enable camelcase */
