import CustomSubtypeInterface from './custom-subtype-interface';
/* eslint-disable camelcase */

export interface CatalogueReadDataInterface {
    catalogue_name: string;
}

export default class CatalogueRead implements CustomSubtypeInterface {
    catalogue_name: string;

    constructor(data: CatalogueReadDataInterface) {
        this.catalogue_name = data.catalogue_name;
    }
}
/* eslint-enable camelcase */
