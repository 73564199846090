import Component from 'ShopUi/models/component';
import CustomSelect from 'src/BestIt/ShopUi/components/molecules/custom-select/custom-select';
import TextField from 'src/BestIt/ShopUi/components/molecules/text-field/text-field';
import DatePicker from 'src/BestIt/ShopUi/components/molecules/date-picker/date-picker';
import Icon from 'src/BestIt/ShopUi/components/atoms/icon/icon';

export default class ProductReturnForm extends Component {
    private addPositionButton: HTMLButtonElement;

    private formCollectionPositionIndex: number;

    private positionsCounter: number;

    private selectAllForms: HTMLElement;

    private additionalUploadFieldsCounter: any;

    private htmlInputs: any;

    inputValidationClass: string = `text-field__input--error`;

    protected readyCallback(): void {
        this.positionsCounter = 1;
        this.additionalUploadFieldsCounter = [];

        this.addPositionButton = <HTMLButtonElement>document.querySelector(`.${this.jsName}__add-position`);
        this.addPositionButton.addEventListener('click', () => this.addNewPosition());

        this.initForm();
    }

    protected initForm(): void {
        this.selectAllForms = document.querySelector('#product_return_form_collection_positions');
        this.formCollectionPositionIndex = this.selectAllForms.children.length;
        this.getFormSelection();

        this.htmlInputs = document.querySelectorAll('.product-return-form input');
        for (let i = 0; i < this.htmlInputs.length; i++) {
            const currentInput = <HTMLInputElement> this.htmlInputs[i];
            currentInput.addEventListener('change', (event: Event) => this.validateField(event, currentInput));
        }
    }

    private getFormSelection(): void {
        if (this.selectAllForms !== null) {
            for (let i = 0; i < this.selectAllForms.children.length; i++) {
                const currentForm = <HTMLElement> this.selectAllForms.children[i];
                ProductReturnForm.removePrototypePlaceholder(currentForm);
                const selectedElement = <HTMLSelectElement> currentForm.querySelector(
                    '.product-return-form__request-type .js-custom-select',
                );

                if (selectedElement.value === '') {
                    ProductReturnForm.hideAllContainers(currentForm);
                }

                this.addSelectionHandler(currentForm);
                this.addWarrantyHandler(currentForm);

                if (i >= 1) {
                    currentForm.classList.add('addedCollectionElement');
                    const removeFormButton = document.createElement('button');
                    removeFormButton.classList.add('product-return-form__close-button-container');
                    const icon = document.createElement('icon-item');
                    icon.classList.add('icon');
                    icon.classList.add('icon--close');
                    icon.setAttribute('symbol', 'close');
                    icon.setAttribute('namespace', 'assets');
                    icon.setAttribute('pack', 'icons');
                    icon.setAttribute('loading', 'eager');
                    removeFormButton.appendChild(icon);

                    this.initCloseIcon(removeFormButton);

                    currentForm.prepend(removeFormButton);
                }

                this.addEventsForNewForm(currentForm);
            }
        }
    }

    private validateField(event: Event, currentInput: HTMLInputElement): void {
        if (currentInput.checkValidity()) {
            currentInput.classList.remove(this.inputValidationClass);
        } else {
            currentInput.classList.add(this.inputValidationClass);
        }
    }

    private addNewPosition(): void {
        const newPosition = this.addCollectionElement(
            'product_return_form_collection_positions',
            '__name__',
            'product-return-form__close-button-container',
            this.formCollectionPositionIndex,
        );

        ProductReturnForm.initializeComponents(newPosition);

        this.addEventsForNewForm(newPosition);
        ProductReturnForm.hideAllContainers(newPosition);

        this.formCollectionPositionIndex++;
        this.positionsCounter++;
        this.addPositionButton.blur();

        /*
         * We disable the button because it is defined, that the user can only have 10 positions in the form,
         * see story SLV-1593
         */
        if (this.positionsCounter > 9) {
            this.addPositionButton.setAttribute('disabled', 'disabled');
        }
    }

    private removePosition(event: Event): void {
        const removePositionButton = <HTMLElement>event.target;
        let form = removePositionButton.parentElement;
        if (!form.classList.contains('addedCollectionElement')) {
            do {
                form = form.parentElement;
            } while (!form.classList.contains('addedCollectionElement'));
        }

        this.removeAdditionalUploadFieldsCounterForPosition(form);
        this.removeEventListenersFromFormElements(form);
        // IE 11 can not use ChildNode.remove() (see https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove)
        form.parentElement.removeChild(form);

        this.positionsCounter--;

        if (this.positionsCounter < 9) {
            this.addPositionButton.disabled = false;
        }
    }

    private addNewUploadField(event: Event, form: HTMLElement): void {
        let uploadButton = <HTMLElement>event.target;
        if (!uploadButton.classList.contains(`${this.jsName}__add-upload`)) {
            do {
                uploadButton = uploadButton.parentElement;
            } while (!uploadButton.classList.contains(`${this.jsName}__add-upload`));
        }
        const uploadCollectionHolderId = ProductReturnForm.getUplaodCollectionHolderId(
            <HTMLButtonElement> uploadButton,
        );

        const newUploadField = this.addCollectionElement(
            uploadCollectionHolderId,
            '__upload_name__',
            `${this.name}__remove-upload-container`,
        );

        const removeUploadButton = <HTMLElement> newUploadField.querySelector(
            `.${this.name}__remove-upload-container`,
        );
        if (removeUploadButton !== null) {
            removeUploadButton.addEventListener(
                'click',
                (clickEvent: Event) => this.removeUploadField(clickEvent, form),
            );
        }

        this.countUploadButtonsInCollection(uploadCollectionHolderId, uploadButton);
    }

    private removeUploadField(event: Event, form: HTMLElement) {
        let removePositionButton = <HTMLElement>event.target;
        if (!removePositionButton.classList.contains(`${this.name}__remove-upload-container`)) {
            do {
                removePositionButton = removePositionButton.parentElement;
            } while (!removePositionButton.classList.contains(`${this.name}__remove-upload-container`));
        }
        const additionalUploadField = removePositionButton.parentElement;
        const additionalUploadContainer = <HTMLElement> form.querySelector(`.${this.name}__upload-items`);
        const collectionHolderId = removePositionButton.getAttribute('data-collection-holder');
        const addUploadButton = <HTMLButtonElement> form.querySelector(`.${this.jsName}__add-upload`);

        // IE 11 can not use ChildNode.remove() (see https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove)
        additionalUploadContainer.removeChild(additionalUploadField);
        this.reduceCountUploadButtonsInCollection(collectionHolderId, addUploadButton);
    }

    private addSelectionHandler(form: HTMLElement, event?: Event): void {
        const regulationType = <HTMLElement> form.querySelector(`.${this.name}__regulation-type`);
        const regulationTypeSelect = <HTMLSelectElement> regulationType.querySelector(
            '.custom-select .custom-select__select',
        );
        const sampleOrder = <HTMLElement> form.querySelector(`.${this.name}__sample-order`);
        const sampleOrderRadios = <HTMLInputElement[]> Array.from(sampleOrder.querySelectorAll(
            `.${this.name}__original-packaging .radio__input`,
        ));
        const defect = <HTMLElement> form.querySelector(`.${this.name}__defect`);
        const defectErrorInformation = <HTMLSelectElement> defect.querySelector(
            '.custom-select .custom-select__select'
        );
        const warrantyRadioButtons = <HTMLInputElement[]> Array.from(defect.querySelectorAll(
            `.${this.name}__warranty-guarantee .radio__input`,
        ));
        let selectedElement = <HTMLSelectElement> form.querySelector(
            '.product-return-form__request-type .js-custom-select',
        );

        if (event !== null && typeof event !== 'undefined') {
            selectedElement = <HTMLSelectElement> event.target;
        }

        ProductReturnForm.hideAllContainers(form);

        if (selectedElement !== null && selectedElement.value !== '') {
            warrantyRadioButtons.forEach((radio: HTMLInputElement) => {
                radio.removeAttribute('required');
            });
            defectErrorInformation.removeAttribute('required');
            sampleOrderRadios.forEach((input: HTMLInputElement) => {
                input.removeAttribute('required');
            });

            if (selectedElement.value !== 'sample_order') {
                regulationType.classList.remove('is-hidden');
                regulationTypeSelect.setAttribute('required', 'required');
            }

            if (selectedElement.value === 'defect') {
                defect.classList.remove('is-hidden');
                warrantyRadioButtons.forEach((radio: HTMLInputElement) => {
                    radio.setAttribute('required', 'required');
                });
                defectErrorInformation.setAttribute('required', 'required');
            } else if (selectedElement.value === 'sample_order') {
                regulationTypeSelect.removeAttribute('required');
                sampleOrder.classList.remove('is-hidden');
                sampleOrderRadios.forEach((radio: HTMLInputElement) => {
                    radio.setAttribute('required', 'required');
                });
            }
        }
    }

    // eslint-disable-next-line class-methods-use-this
    private addWarrantyHandler(form: HTMLElement, event?: Event): void {
        const warranty = <HTMLElement> form.querySelector('.product-return-form__warranty');
        const dateFirstErrorOccuredField = <HTMLInputElement[]> Array.from(warranty.querySelectorAll(
            '.product-return-form__date_first_error_occured input',
        ));
        let warrantyRadioButton = <HTMLInputElement> form.querySelector(
            '.product-return-form__warranty-guarantee input[type=radio]:checked',
        );

        if (event !== null && typeof event !== 'undefined') {
            warrantyRadioButton = <HTMLInputElement> event.target;
        }

        if (warrantyRadioButton !== null && warrantyRadioButton.value === 'warranty') {
            warranty.classList.remove('is-hidden');
            dateFirstErrorOccuredField.forEach((input: HTMLInputElement) => {
                input.setAttribute('required', 'required');
            });
        } else {
            warranty.classList.add('is-hidden');
        }
    }

    // eslint-disable-next-line max-params
    private addCollectionElement(
        collectionHolderId,
        prototypeName,
        closeButtonClassName = null,
        formCollectionIndex = null,
    ): HTMLElement {
        const collectionHolder = <HTMLElement>document.querySelector(`#${collectionHolderId}`);
        const prototype = collectionHolder.getAttribute('data-prototype');
        const form = document.createElement('div');
        let closeButton = '';

        if (closeButtonClassName !== null) {
            /* eslint-disable max-len */
            closeButton = `<button class="${closeButtonClassName}" data-collection-holder="${collectionHolderId}">
                <icon-item class="icon icon--close" symbol="close" namespace="static" pack="icons" loading="eager"></icon-item>
            </button>`;
            /* eslint-enable max-len */

            if (collectionHolderId === 'product_return_form_collection_positions') {
                form.classList.add('addedCollectionElement');
            }
        }

        if (formCollectionIndex !== null) {
            form.innerHTML = closeButton +
                prototype.replace(
                    new RegExp(prototypeName, 'g'),
                    formCollectionIndex,
                );
        } else {
            form.innerHTML = closeButton +
                prototype.replace(
                    new RegExp(prototypeName, 'g'),
                    collectionHolder.children.length.toString(),
                );
        }

        this.initCloseIcon(form);

        ProductReturnForm.removePrototypePlaceholder(form);

        collectionHolder.appendChild(form);

        return form;
    }

    private countUploadButtonsInCollection(uploadCollectionHolderId: string, uploadButton: HTMLElement): void {
        const uploadCollection = this.additionalUploadFieldsCounter.find(
            ({ name }) => name === uploadCollectionHolderId
        );

        if (typeof uploadCollection !== 'undefined') {
            this.additionalUploadFieldsCounter.forEach((counter) => {
                if (counter.name === uploadCollection.name) {
                    counter.count += 1;

                    /*
                     * We disable the button because it is defined, that the user can only add six additional files,
                     * see story SLV-1593
                     */
                    if (counter.count === 6) {
                        uploadButton.setAttribute('disabled', 'disabled');
                    }
                }
            });
        } else {
            this.additionalUploadFieldsCounter.push({
                name: uploadCollectionHolderId,
                count: 1,
            });
        }
    }

    private reduceCountUploadButtonsInCollection(uploadCollectionHolderId: string, uploadButton: Element): void {
        const uploadCollection = this.additionalUploadFieldsCounter.find(
            ({ name }) => name === uploadCollectionHolderId
        );

        if (typeof uploadCollection !== 'undefined') {
            this.additionalUploadFieldsCounter.forEach((counter) => {
                if (counter.name === uploadCollection.name) {
                    counter.count -= 1;

                    /*
                     * We disable the button because it is defined, that the user can only add six additional files,
                     * see story SLV-1593
                     */
                    if (counter.count < 6) {
                        uploadButton.removeAttribute('disabled');
                    }
                }
            });
        }
    }

    private addEventsForNewForm(form: HTMLElement): void {
        const removeFormButton = <HTMLElement> form.querySelector('.product-return-form__close-button-container');
        const removeUploadButton = <HTMLElement> form.querySelector(`.${this.name}__remove-upload-container`);
        const addUploadButton = <HTMLButtonElement> form.querySelector(`.${this.jsName}__add-upload`);

        const selectedElement = <HTMLSelectElement> form.querySelector(
            '.product-return-form__request-type .js-custom-select',
        );
        const warrantyRadioButtons = <HTMLInputElement[]> Array.from(
            form.querySelectorAll('.product-return-form__warranty-guarantee input[type=radio]'),
        );

        if (removeFormButton !== null) {
            removeFormButton.addEventListener(
                'click',
                (event: Event) => this.removePosition(event),
            );
        }

        if (removeUploadButton !== null) {
            removeUploadButton.addEventListener(
                'click',
                (event: Event) => this.removeUploadField(event, form),
            );
        }

        addUploadButton.addEventListener(
            'click',
            (event: Event) => this.addNewUploadField(event, form),
        );

        selectedElement.addEventListener('change', (event: Event) => this.addSelectionHandler(form, event));

        warrantyRadioButtons.forEach((warrantyRadioButton) => {
            warrantyRadioButton.addEventListener('change', (event: Event) => this.addWarrantyHandler(form, event));
        });
    }

    private removeEventListenersFromFormElements(form: HTMLElement): void {
        const removeFormButton = <HTMLElement> form.querySelector('.product-return-form__close-button-container');
        const removeUploadButton = <HTMLElement> form.querySelector(`.${this.name}__remove-upload-container`);
        const addUploadButton = <HTMLButtonElement> form.querySelector(`.${this.jsName}__add-upload`);
        const selectedElement = <HTMLSelectElement> form.querySelector(
            '.product-return-form__request-type .js-custom-select',
        );
        const warrantyRadioButtons = <HTMLInputElement[]> Array.from(
            form.querySelectorAll('.product-return-form__warranty-guarantee input[type=radio]'),
        );

        if (removeFormButton !== null) {
            removeFormButton.removeEventListener(
                'click',
                (event: Event) => this.removePosition(event),
            );
        }

        if (removeUploadButton !== null) {
            removeUploadButton.removeEventListener(
                'click',
                (event: Event) => this.removeUploadField(event, form),
            );
        }

        addUploadButton.removeEventListener(
            'click',
            (event: Event) => this.addNewUploadField(event, form),
        );

        selectedElement.removeEventListener('change', (event: Event) => this.addSelectionHandler(form, event));

        warrantyRadioButtons.forEach((warrantyRadioButton) => {
            warrantyRadioButton.removeEventListener('change', (event: Event) => this.addWarrantyHandler(form, event));
        });
    }

    private static hideAllContainers(form: HTMLElement): void {
        const regulationType = <HTMLElement> form.querySelector('.product-return-form__regulation-type');
        const sampleOrder = <HTMLElement> form.querySelector('.product-return-form__sample-order');
        const defect = <HTMLElement> form.querySelector('.product-return-form__defect');
        const warranty = <HTMLElement> form.querySelector('.product-return-form__warranty');

        if (!regulationType.classList.contains('is-hidden')) {
            regulationType.classList.add('is-hidden');
        }
        if (!sampleOrder.classList.contains('is-hidden')) {
            sampleOrder.classList.add('is-hidden');
        }
        if (!defect.classList.contains('is-hidden')) {
            defect.classList.add('is-hidden');
        }
        if (!warranty.classList.contains('is-hidden')) {
            warranty.classList.add('is-hidden');
        }
    }

    private static removePrototypePlaceholder(form: HTMLElement): void {
        const placeholders = <HTMLElement[]> Array.from(form.querySelectorAll('span[id$="_placeholder"]'));

        placeholders.forEach((placeholder) => {
            const listItem = placeholder.parentElement;

            /*
             * IE 11 can not use ChildNode.remove()
             * (see https://developer.mozilla.org/en-US/docs/Web/API/ChildNode/remove)
             */
            listItem.parentElement.removeChild(listItem);
        });
    }

    private removeAdditionalUploadFieldsCounterForPosition(form: HTMLElement): void {
        const addUploadButton = <HTMLButtonElement> form.querySelector(`.${this.jsName}__add-upload`);
        const uploadCollectionHolderId = ProductReturnForm.getUplaodCollectionHolderId(addUploadButton);
        this.additionalUploadFieldsCounter = this.additionalUploadFieldsCounter.filter(
            ({ name }) => name !== uploadCollectionHolderId
        );
    }

    private static getUplaodCollectionHolderId(addUploadButton: HTMLButtonElement): string {
        const addUploadButtonId = addUploadButton.getAttribute('id');

        return addUploadButtonId.substring(0, addUploadButtonId.length - 3);
    }

    private static initializeComponents(form: HTMLElement): void {
        const customSelects = <CustomSelect[]> Array.from(form.querySelectorAll('.custom-select'));
        const textFields = <TextField[]> Array.from(form.querySelectorAll('.text-field'));
        const datePickers = <DatePicker[]> Array.from(form.querySelectorAll('.date-picker'));

        textFields.forEach((textField: TextField) => {
            textField.initialize();
        });

        customSelects.forEach((customSelect: CustomSelect) => {
            customSelect.initialize();
        });

        datePickers.forEach((datePicker: DatePicker) => {
            datePicker.initialize();
        });
    }

    // eslint-disable-next-line class-methods-use-this
    private initCloseIcon(container: HTMLElement): void {
        const closeIcon = <Icon> container.querySelector('.icon--close');
        closeIcon.initializeCustomElement('icon-item', {
            fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
            createIntersectionObserver: false,
        }, {}, false);
    }
}
