import Component from 'ShopUi/models/component';

export default class ActionSingleClickEnforcer extends Component {
    /**
     * Elements on which the single action check is enforced.
     */
    targets: HTMLElement[]

    protected readyCallback(): void {
        this.targets = <HTMLElement[]>Array.from(document.querySelectorAll(this.targetSelector));
        this.mapEvents();
    }

    protected mapEvents(): void {
        this.targets.forEach((element: HTMLElement) => {
            element.addEventListener('click', (event: Event) => this.onTargetClick(event));
        });
    }

    // eslint-disable-next-line class-methods-use-this
    protected onTargetClick(event: Event): void {
        const targetElement = <HTMLElement> event.currentTarget;
        const isDisabled: boolean = targetElement.hasAttribute('disabled');
        // eslint-disable-next-line no-extra-parens
        const isSubmit: boolean = (<HTMLInputElement>targetElement).type === 'submit';
        const form: HTMLFormElement = isSubmit ? targetElement.closest('form') : null;

        const isLink : boolean = targetElement.tagName.toLowerCase() === 'a';
        let href;

        event.preventDefault();

        if (isLink) {
            // eslint-disable-next-line prefer-destructuring,no-extra-parens
            href = (targetElement as HTMLAnchorElement).href;
        }

        if (isDisabled) {
            return;
        }

        if (isSubmit && form) {
            form.submit();
        }

        targetElement.setAttribute('disabled', 'disabled');

        if (isLink) {
            window.location.href = href;
        }
    }

    /**
     * Gets a querySelector name of the target element.
     */
    get targetSelector(): string {
        return this.getAttribute('target-selector') || '';
    }
}
