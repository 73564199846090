import Component from 'ShopUi/models/component';
import TempParams from 'src/BestIt/ShopUi/components/molecules/temp-params/temp-params';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';

export default class SystemStep extends Component {
    protected googleEventHandler: GoogleTagEvents;

    protected tempParams: TempParams;

    /**
     * @inheritDoc
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    public initialize() {
        this.initGoogleTagEvents();
        this.initTempParams();
        this.removeShowBaseTrackModalWindowSessionValue();
        this.fireEvent();
    }

    protected initGoogleTagEvents(): void {
        this.googleEventHandler = <GoogleTagEvents> document.querySelector('.google-tag-events');
    }

    protected initTempParams() {
        this.tempParams = <TempParams> document.querySelector('.temp-params');
    }

    protected fireEvent(): void {
        if (typeof this.googleEventHandler !== 'undefined' &&
            this.googleEventHandler !== null &&
            this.hasAttribute('data-event-data')
        ) {
            let eventData = this.googleEventHandler.getGtmEventData(this);

            if (typeof this.tempParams !== 'undefined' && this.tempParams !== null) {
                const param = this.tempParams.get('from');

                if (param) {
                    eventData = {
                        ...eventData,
                        eventLabel: param,
                    };
                }
            }

            const event = this.googleEventHandler.createGaEvent(eventData);
            this.googleEventHandler.pushEvent(<any> event, 'load');
        }
    }

    // eslint-disable-next-line class-methods-use-this
    private removeShowBaseTrackModalWindowSessionValue() {
        if (sessionStorage.getItem('showBaseTrackModalWindow') !== null &&
            sessionStorage.getItem('baseTrackModalWindowDeactivated') !== 'true'
        ) {
            sessionStorage.removeItem('showBaseTrackModalWindow');
        }
    }
}
