import Component from 'ShopUi/models/component';
import KeenSliderCarousel from 'src/BestIt/ShopUi/components/molecules/keen-slider-carousel/keen-slider-carousel';
import { ResizeObserver } from 'resize-observer';
import { ResizeObserverEntry } from 'resize-observer/lib/ResizeObserverEntry';

export default class ContentSlider extends Component {
    /**
     * The slider
     *
     * @protected
     */
    protected sliderCarousel: KeenSliderCarousel;

    /**
     * The container for the hero slider on tablet and mobile viewports
     *
     * @protected
     */
    protected heroTeaserContainer: HTMLElement;

    /**
     * The hero teaser of the slider
     *
     * @protected
     */
    protected heroTeaser: HTMLElement;

    /**
     * The placeholder for the hero teaser in the slider
     *
     * @protected
     */
    protected heroTeaserPlaceholder: HTMLElement;

    /**
     * To prevent the slider reinit on page load, will be set to true after the page load
     *
     * @protected
     */
    protected isResize: boolean = false;

    /**
     * Base function for the components
     *
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Init function for the component
     *
     * @private
     */
    public initialize() {
        this.sliderCarousel = <KeenSliderCarousel> this.querySelector('.keen-slider-carousel');
        this.heroTeaserContainer = <HTMLElement> this.querySelector(`.${this.jsName}__hero-container`);
        this.heroTeaser = <HTMLElement> this.querySelector('.keen-slider__slide--is-hero');
        this.heroTeaserPlaceholder = <HTMLElement> this.querySelector('.keen-slider__slide--placeholder');

        this.mapEvents();
    }

    /**
     * Mapper for the events
     *
     * @private
     */
    private mapEvents() {
        const resizeObserver = new ResizeObserver(
            (entries: ResizeObserverEntry[]) => this.moveHeroTeaserOnResize(entries),
        );
        resizeObserver.observe(this.sliderCarousel);
    }

    /**
     * Event handler for the load and resize event in which the hero slider moves from the slider to the hero teaser
     * container
     *
     * @private
     */
    private moveHeroTeaserOnResize(entries: ResizeObserverEntry[]) {
        entries.forEach(() => {
            if (window.innerWidth <= this.viewportBreakpoint) {
                this.heroTeaserContainer.appendChild(this.heroTeaser);
                this.heroTeaserContainer.appendChild(this.heroTeaserPlaceholder);
                this.sliderCarousel.sliderReinit();
            } else if (window.innerWidth > this.viewportBreakpoint && this.isResize) {
                this.sliderCarousel.sliderContainer.prepend(this.heroTeaserPlaceholder);
                this.sliderCarousel.sliderContainer.prepend(this.heroTeaser);
                this.sliderCarousel.sliderReinit();
            }

            this.isResize = true;
        });
    }

    /**
     * Getter for the breakpoint on which the hero teaser should be removed from the slider
     *
     * @public
     */
    get viewportBreakpoint(): number {
        return parseInt(this.dataset.breakpoint || '0', 10);
    }
}
