import Component from 'ShopUi/models/component';
import $ from 'jquery/dist/jquery';
import 'slick-carousel';
import Icon from 'src/BestIt/ShopUi/components/atoms/icon/icon';
import SvgIcon from 'src/BestIt/ShopUi/components/atoms/svg-icon/svg-icon';
import MagnifierSLV from '../../../resources/scripts/magnifier/MagnifierSLV';

export default class ImageGallery extends Component {
    private galleryItems: HTMLElement[];

    private thumbnailItems: HTMLElement[];

    private thumbnailDots: HTMLElement[];

    private quantityImages: number;

    private pageWrapper: HTMLElement;

    private thumbnailSlider: $;

    private fullscreenButton: $;

    private fullscreenCloseButton: $;

    private images: $;

    private videos: HTMLElement[];

    private videoThumbnails: HTMLElement[];

    readyCallback(): void {
        this.galleryItems = <HTMLElement[]> Array.from(this.querySelectorAll(`.${this.jsName}__item`));
        this.thumbnailItems = <HTMLElement[]> Array.from(this.querySelectorAll(`.${this.jsName}__thumbnail-item`));
        this.thumbnailDots = <HTMLElement[]> Array.from(this.querySelectorAll('.slick-dots li'));
        this.quantityImages = this.galleryItems.length;
        this.pageWrapper = document.querySelector('.page-layout-main');
        this.thumbnailSlider = $(`.${this.jsName}__thumbnails`);
        this.fullscreenButton = $('.button.button--gallery-fullscreen');
        this.fullscreenCloseButton = $('.icon.icon--gallery-close');
        this.images = <HTMLElement[]> Array.from(this.querySelectorAll(`.${this.name}__image`));
        this.videos = <HTMLElement[]> Array.from(this.querySelectorAll(`.${this.name}__video`));
        this.videoThumbnails = <HTMLElement[]> Array.from(
            this.querySelectorAll(`.${this.name}__thumbnail-item--video`),
        );

        this.initializationSlider();
        this.initializationMagnifier();
        this.mapEvents();
    }

    protected mapEvents(): void {
        this.thumbnailSlider.on('afterChange', (event: Event, slider: $) => this.onAfterChange(event, slider));
        this.thumbnailSlider.on('init', () => this.onSliderInit());
        this.fullscreenButton.on('click', () => this.toggleFullscreen());
        this.fullscreenCloseButton.on('click', () => this.toggleFullscreen());
        document.addEventListener('keydown', (event: KeyboardEvent) => this.onTriggerKeydown(event));
        window.addEventListener('resize', () => this.initializationMagnifier());
        window.addEventListener('load', () => {
            this.videoThumbnails = <HTMLElement[]> Array.from(
                this.querySelectorAll(`.${this.name}__thumbnail-item--video`),
            );
            this.videoThumbnails.forEach((videoThumbnail) => {
                videoThumbnail.addEventListener('click', () => {
                    this.openVideo(videoThumbnail);
                });
            });
        });
        this.videos.forEach((video) => {
            video.addEventListener('click', () => {
                this.openVideo(video);
            });
        });
    }

    protected initializationSlider(): void {
        if (this.quantityImages > 1) {
            this.thumbnailSlider.slick(
                this.thumbnailSliderConfig,
            );
            this.thumbnailSlider.find(`.${this.jsName}__thumbnail-item`).show();
        }
    }

    protected initializationMagnifier(): void {
        if (window.innerWidth >= this.magnifierBreakpoint) {
            const isSliderElement = this.images.length > 1;

            this.magnifierCleanUp();

            this.images.forEach((image) => {
                new MagnifierSLV(image)
                    .borderRadius(0)
                    .width(300)
                    .height(300)
                    .isSliderElement(isSliderElement);
            });
        } else {
            this.magnifierCleanUp();
        }
    }

    protected magnifierCleanUp(): void {
        this.magnifies.forEach((magnifier) => {
            magnifier.replaceWith();
        });
    }

    protected onAfterChange(event: Event, slider: $): void {
        const index = slider.currentSlide;
        this.changeImage(index);
    }

    private reinitializeSlider() {
        this.thumbnailSlider.slick('unslick');
        this.initializationSlider();
        const currentSlide = this.thumbnailSlider.slick('slickCurrentSlide');
        this.changeImage(currentSlide, true);
    }

    protected toggleFullscreen(): void {
        let hideOnFullscreenElements : HTMLElement[] = [];
        const pdpHeader = document.querySelector<HTMLElement>('.container--pdp-header');

        if (this.hideOnFullscreenSelector) {
            hideOnFullscreenElements = Array.from<HTMLElement>(
                document.querySelectorAll(this.hideOnFullscreenSelector),
            );
        }

        if (!this.pageWrapper.classList.contains(this.fullscreenClass)) {
            this.openFullscreen(hideOnFullscreenElements, pdpHeader);
        } else {
            this.pageWrapper.classList.remove(this.fullscreenClass);
            document.body.classList.remove('overflow-hidden');
            document.documentElement.classList.remove('overflow-hidden');
            hideOnFullscreenElements.forEach(element => element.classList.remove('is-hidden'));
            if (pdpHeader) {
                pdpHeader.style.height = 'auto';
            }
            if (this.videos.length > 0) {
                this.switchVideoVisibility();
            }
            this.reinitializeSlider();
            this.fullscreenButton.show();
            this.fullscreenCloseButton.hide();
            this.initializationMagnifier();
        }
    }

    protected onTriggerKeydown(event: KeyboardEvent): void {
        if (event.key === 'Escape' && this.pageWrapper.classList.contains(this.fullscreenClass)) {
            this.toggleFullscreen();
        }
    }

    protected changeImage(activeItemIndex: number, setActiveThumbnail: boolean = false): void {
        this.galleryItems.forEach((galleryItem, index) => {
            if (galleryItem.classList.contains(this.activeClass) && activeItemIndex !== index) {
                galleryItem.classList.remove(this.activeClass);
            }
            if (activeItemIndex === index) {
                galleryItem.classList.add(this.activeClass);
            }
        });

        if (setActiveThumbnail) {
            if (window.innerWidth >= this.magnifierBreakpoint) {
                this.thumbnailItems.forEach((thumbnailItem, index) => {
                    const slickSlide = thumbnailItem.closest('.slick-slide');
                    if (slickSlide.classList.contains('slick-current') && activeItemIndex !== index) {
                        slickSlide.classList.remove('slick-current');
                    }
                    if (activeItemIndex === index) {
                        slickSlide.classList.add('slick-current');
                    }
                });
            } else {
                this.thumbnailSlider.slick('slickGoTo', activeItemIndex, true);
            }
        }
    }

    get activeClass(): string {
        return this.getAttribute('active-class');
    }

    get fullscreenClass(): string {
        return this.getAttribute('fullscreen-class');
    }

    get thumbnailSliderConfig(): object {
        const sliderConfig: string = this.getAttribute('config-thumbnail-slider');
        return JSON.parse(sliderConfig);
    }

    get magnifierBreakpoint(): number {
        const magnifierBreakpoint = parseInt(this.getAttribute('magnifier-breakpoint'), 10);
        return magnifierBreakpoint >= 0 ? magnifierBreakpoint : 0;
    }

    get magnifies(): any {
        return <HTMLElement[]>Array.from(this.querySelectorAll('.magnifier'));
    }

    get hideOnFullscreenSelector() : string {
        return this.dataset.hideOnFullscreenSelector;
    }

    protected openVideo(video: HTMLElement) {
        let hideOnFullscreenElements : HTMLElement[] = [];
        const pdpHeader = document.querySelector<HTMLElement>('.container--pdp-header');

        if (this.hideOnFullscreenSelector) {
            hideOnFullscreenElements = Array.from<HTMLElement>(
                document.querySelectorAll(this.hideOnFullscreenSelector),
            );
        }
        if (!this.pageWrapper.classList.contains(this.fullscreenClass)) {
            this.openFullscreen(hideOnFullscreenElements, pdpHeader);

            this.changeImage(parseInt(video.dataset.slidePosition, 10), true);
        }
    }

    private switchVideoVisibility() {
        this.videos.forEach((video) => {
            const player = <HTMLElement> this.querySelector(video.dataset.videoPlayer);

            video.parentElement.classList.toggle('is-hidden');
            player.classList.toggle('is-hidden');
        });
    }

    protected openFullscreen(hideOnFullscreenElements: any, pdpHeader: HTMLElement) {
        this.pageWrapper.classList.add(this.fullscreenClass);
        document.body.classList.add('overflow-hidden');
        document.documentElement.classList.add('overflow-hidden');
        hideOnFullscreenElements.forEach(element => element.classList.add('is-hidden'));

        if (pdpHeader) {
            // eslint-disable-next-line no-param-reassign
            pdpHeader.style.height = '100%';
        }

        if (this.videos.length > 0) {
            this.switchVideoVisibility();
        }

        this.reinitializeSlider();
        this.fullscreenCloseButton.show();
        this.fullscreenButton.hide();
        this.magnifierCleanUp();

        this.dispatchCustomEvent('imageGalleryFullscreenOpened');
    }

    private onSliderInit() {
        this.thumbnailSlider = $(`.${this.jsName}__thumbnails`);
        const icons = <Icon[]> Array.from(
            this.thumbnailSlider.find('.icon'),
        );
        const svgIconHandler = <SvgIcon> document.querySelector('.svg-icon');

        if (typeof icons !== 'undefined' &&
            icons !== null &&
            typeof svgIconHandler !== 'undefined' &&
            svgIconHandler !== null
        ) {
            const { iconElementSelector } = svgIconHandler;

            if (iconElementSelector !== '') {
                icons.forEach((icon) => {
                    icon.initializeCustomElement(iconElementSelector, {
                        fetchPattern: '/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
                        createIntersectionObserver: false,
                    }, {}, false);
                });
            }
        }
    }

    /**
     * Function to dispatch an event, when the user opens the fullscreen mode
     *
     * @param name
     * @param detail
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected dispatchCustomEvent(name: string, detail: any = {}): void {
        const customEvent = new CustomEvent(name, { detail });
        document.dispatchEvent(customEvent);
    }
}
