import Sorting, { SortingDataInterface } from 'GA4EventTracking/types/events/custom-subtypes/sorting';
import AbstractGa4CustomEventWithDataMapper
    from 'GA4EventTracking/types/event-mappers/abstract-ga4-custom-event-with-data-mapper';

export default class Ga4CustomSortingEventMapper extends AbstractGa4CustomEventWithDataMapper {
    // eslint-disable-next-line class-methods-use-this
    mapCustomSubtype(data: SortingDataInterface) {
        return new Sorting(data);
    }
}
