import Component from 'ShopUi/models/component';

export default class NavigationListMobile extends Component {
    itemTriggers: HTMLElement[]

    goBackTriggers: HTMLElement[]

    protected readyCallback(): void {
        this.itemTriggers = <HTMLElement[]>Array.from(this.querySelectorAll(this.itemTriggerSelector));
        this.goBackTriggers = <HTMLElement[]>Array.from(this.querySelectorAll(this.goBackTriggerSelector));
        this.mapEvents();
    }

    protected mapEvents(): void {
        this.itemTriggers.forEach((trigger: HTMLElement) => {
            trigger.addEventListener('click', () => this.onItemClick(trigger));
        });

        this.goBackTriggers.forEach((trigger: HTMLElement) => {
            trigger.addEventListener('click', () => this.onGoBackClick(trigger));
        });
    }

    private onGoBackClick(element: Element) {
        const toggleClass = element.getAttribute('toggle-class');
        const hideSelector = element.getAttribute('hide-selector');
        const hideElement = this.querySelector(hideSelector);
        if (hideElement) {
            hideElement.classList.add(toggleClass);
        }
    }

    private onItemClick(element: Element) {
        const toggleClass = element.getAttribute('toggle-class');
        const showSelector = element.getAttribute('show-selector');
        const showElement = this.querySelector(showSelector);
        if (showElement) {
            showElement.classList.remove(toggleClass);
        }
    }

    get itemTriggerSelector(): string {
        return `.${this.name}__item`;
    }

    get goBackTriggerSelector(): string {
        return `.${this.name}__back-button`;
    }
}
