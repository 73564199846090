import Component from 'ShopUi/models/component';
import AjaxProvider from 'src/BestIt/ShopUi/components/molecules/ajax-provider/ajax-provider';
import QuantityInputSelect from 'src/BestIt/ShopUi/components/molecules/quantity-input-select/quantity-input-select';
import TogglerAccordion from 'src/BestIt/ShopUi/components/molecules/toggler-accordion/toggler-accordion';
import ConfiguratorProductItem from '../../molecules/configurator-product-item/configurator-product-item';
import SelectedProductItem from '../../molecules/selected-product-item/selected-product-item';
import { mount } from 'ShopUi/app';

export default class ConfiguratorFilterSection extends Component {
    /**
     * The product list
     * @private
     */
    private productListContainer: HTMLElement;

    /**
     * The filter form
     * @private
     */
    private form: HTMLFormElement;

    /**
     * Ajax provider to get the filtered product list
     * @private
     */
    private ajaxProvider: AjaxProvider;

    /**
     * The text input fields of e.g. the range filter
     * @private
     */
    private inputFields: HTMLInputElement[];

    /**
     * The checkboxes of the product filter (multi select)
     * @private
     */
    private checkboxFields: HTMLInputElement[];

    /**
     * The checkboxes of the product filter (multi select)
     * @private
     */
    private productItems: ConfiguratorProductItem[];

    /**
     * The selected filter boxes
     * @private
     */
    private activeFilters: HTMLElement[];

    /**
     * The selected product list box
     *
     * @private
     */
    private selectedProductList: Element;

    /**
     * The selected product items
     *
     * @private
     */
    private selectedProductItems: SelectedProductItem[];

    /**
     * The toggler for the selected product list box
     *
     * @private
     */
    private selectedProductToggler: TogglerAccordion;

    /**
     * The toggler for the selected product list box
     *
     * @private
     */
    private overlayCloseButton: HTMLElement;

    /**
     * @inheritDoc
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Init function for the component
     *
     * @private
     */
    private initialize(): void {
        if (this.loadAsync && this.productListSelector !== '') {
            this.setProductListAndItems();
            this.setSelectedProductList();
            this.setFilterForm();
            this.ajaxProvider = this.querySelector(`.${this.jsName}__provider--add`);
            this.overlayCloseButton = this.querySelector(`.${this.name}__close`);

            if (this.activeFilterSelector) {
                this.setActiveFilter();
            }

            this.mapEvents();
        }
    }

    /**
     * Set form and filter input fields for the request
     *
     * @private
     */
    private setFilterForm(): void {
        this.form = this.querySelector(`.${this.jsName}__form`);
        this.inputFields = <HTMLInputElement[]> Array.from(
            this.form.querySelectorAll('input[type="text"], input[type="number"]'),
        );
        this.checkboxFields = <HTMLInputElement[]> Array.from(
            this.form.querySelectorAll('input[type="checkbox"]'),
        );
    }

    private resetFilterBoxes(): void {
        const activeFilterHeadlines = <HTMLElement[]> Array.from(
            this.querySelectorAll(`.${this.jsName}__trigger.active`),
        );
        const activeFilterContents = <HTMLElement[]> Array.from(
            this.querySelectorAll(`.${this.jsName}__item-content:not(.is-hidden)`),
        );

        activeFilterHeadlines.forEach((headline) => {
            headline.classList.remove('active');
        });

        activeFilterContents.forEach((content) => {
            content.classList.add('is-hidden');
        });
    }

    /**
     * Reset filter boxes except the clicked one
     *
     * @param event
     * @private
     */
    private resetActiveFilterBoxes(event: Event): void {
        const target = <HTMLElement> event.target;
        const itemElement = target.closest(`.${this.name}__item`);
        const activeFilterHeadlines = <HTMLElement[]> Array.from(
            this.querySelectorAll(`.${this.jsName}__trigger.active`),
        );
        const activeFilterContents = <HTMLElement[]> Array.from(
            this.querySelectorAll(`.${this.jsName}__item-content:not(.is-hidden)`),
        );

        activeFilterHeadlines.forEach((headline) => {
            if (itemElement !== headline.closest(`.${this.name}__item`)) {
                headline.classList.remove('active');
            }
        });

        activeFilterContents.forEach((content) => {
            if (itemElement !== content.closest(`.${this.name}__item`)) {
                content.classList.add('is-hidden');
            }
        });
    }

    /**
     * Set product list and product items
     * @private
     */
    private setProductListAndItems(showLoadingSpinner: boolean = false): void {
        this.productListContainer = document.querySelector(this.productListSelector);
        if (showLoadingSpinner) {
            this.productListContainer.classList.add('loading');
        }
        this.productItems = <ConfiguratorProductItem[]> Array.from(
            this.productListContainer.querySelectorAll(this.productItemSelector),
        );
    }

    /**
     * Reset product list and product items
     * @private
     */
    private resetProductListAndItems(): void {
        this.productListContainer.classList.remove('loading');
        this.productListContainer.remove();
        this.setProductListAndItems(true);
        this.productItems.forEach((productItem: ConfiguratorProductItem) => {
            const quantityInputSelect = <QuantityInputSelect> productItem.querySelector(
                `#${productItem.jsName}-quantity-input-select-${productItem.sku}`,
            );
            if (typeof quantityInputSelect !== 'undefined' && quantityInputSelect !== null) {
                quantityInputSelect.initialize();
            }
            try {
                productItem.initialize();
            } catch (event) {
                console.error(event.message);
            }
        });
        this.productListContainer.classList.remove('loading');
    }

    /**
     * Reset the selected product list
     *
     * @private
     */
    private resetSelectedProductList() {
        this.setSelectedProductList();
        this.selectedProductItems.forEach((item: SelectedProductItem) => {
            item.initialize();
        });
        this.selectedProductToggler.initialize();
    }

    /**
     * Getter if the products should get loaded asynchronously
     */
    get loadAsync(): boolean {
        return this.dataset.async === 'true' || false;
    }

    /**
     * Getter for the product list selector
     */
    get productListSelector(): string {
        return this.dataset.productListSelector || '';
    }

    /**
     * Getter for the product list selector
     */
    get productItemSelector(): string {
        return this.dataset.productItemSelector || '';
    }

    /**
     * Getter for the product list selector
     */
    get activeFilterSelector() {
        return this.dataset.activeFilterSelector || null;
    }

    /**
     * Getter for the selected product list selector
     */
    get selectedProductListSelector() {
        return this.dataset.selectedProductList || null;
    }

    /**
     * Getter for the selected product item selector
     */
    get selectedProductItemSelector() {
        return this.dataset.selectedProductItem || null;
    }

    /**
     * Event mapper function
     *
     * @private
     */
    private mapEvents(): void {
        this.form.addEventListener('submit', (event:Event) => this.loadProducts(event));

        const filterHeadlines = <HTMLElement[]> Array.from(
            this.querySelectorAll(`.${this.jsName}__trigger`),
        );
        filterHeadlines.forEach((headline) => {
            headline.addEventListener('click', (event: Event) => this.resetActiveFilterBoxes(event));
        });
        document.addEventListener('click', (event: Event) => this.resetActiveFilterBoxes(event));
    }

    /**
     * A asynchronous function to load the filtered product list
     * @param event
     * @private
     */
    private async loadProducts(event: Event) {
        event.preventDefault();
        const formData = new FormData(this.form);

        this.inputFields.forEach((input) => {
            if (input.type === 'number' && (input.value !== input.min && input.value !== input.max)) {
                this.ajaxProvider.queryParams.set(input.name, this.getFieldValue(input));
            } else if (input.type !== 'number') {
                this.ajaxProvider.queryParams.set(input.name, this.getFieldValue(input));
            }
        });

        const checkboxValues = {};
        this.checkboxFields.forEach((input) => {
            if (input.checked) {
                if (input.name in checkboxValues) {
                    checkboxValues[input.name].push(this.getFieldValue(input));
                } else {
                    checkboxValues[input.name] = [
                        this.getFieldValue(input),
                    ];
                }
            }
        });

        Object.keys(checkboxValues).forEach((key: string) => {
            this.ajaxProvider.queryParams.set(key, checkboxValues[key]);
        });

        const urlWithoutAsyncParameter = this.ajaxProvider.url;

        this.ajaxProvider.queryParams.set('async', 'true');
        this.productListContainer.classList.add('loading');

        if (!this.classList.contains('is-hidden-sm-md')) {
            this.overlayCloseButton.click();
        }

        const response = await this.ajaxProvider.fetch(formData);
        this.processResponse(response);
        this.resetFilterBoxes();
        this.setActiveFilter();

        window.history.pushState({}, '', urlWithoutAsyncParameter);
        this.ajaxProvider.queryParams.clear();

        this.dispatchCustomEvent('filterChanged', {});
    }

    /**
     * A function to get the input field values of the filters
     *
     * @param field
     * @private
     */
    // eslint-disable-next-line class-methods-use-this
    private getFieldValue(field: any): any {
        return !(field.value instanceof String) ? field.value : field.value.trim;
    }

    /**
     * A function to process the response from the ajax provider and retruns the new href for the trigger
     */
    private async processResponse(response): Promise<void> {
        this.removeEventsOfExistingItems();
        this.removeActiveFilterEvents();
        this.removeSelectedProductListEvents();

        this.productListContainer.insertAdjacentHTML('beforebegin', response);
        await mount();
        this.resetProductListAndItems();
        this.resetSelectedProductList();
        this.setFilterForm();
        this.setActiveFilter();
    }

    /**
     * Remove Events of the previously loaded products
     * @private
     */
    private removeEventsOfExistingItems(): void {
        this.productItems.forEach((productItem: ConfiguratorProductItem) => {
            productItem.removeEvents();
            productItem.removeQuantitySelectEvents();
        });
    }

    /**
     * Set the active filter boxes and maps the events of the boxes
     *
     * @private
     */
    private setActiveFilter(): void {
        this.activeFilters = <HTMLElement[]> Array.from(document.querySelectorAll(this.activeFilterSelector));
        this.mapActiveFilterEvents();
    }

    /**
     * Register the events of the active filter boxes
     *
     * @private
     */
    private mapActiveFilterEvents(): void {
        this.activeFilters.forEach((activeFilter) => {
            activeFilter.addEventListener('click', (event: Event) => {
                event.preventDefault();
                let target = <HTMLAnchorElement> event.target;

                if (!target.hasAttribute('href')) {
                    do {
                        target = <HTMLAnchorElement> target.parentElement;
                    } while (!target.hasAttribute('href'));
                }
                this.removeFilter(target);
            });
        });
    }

    /**
     * Remove the events of the previously active filter boxes
     *
     * @private
     */
    private removeActiveFilterEvents(): void {
        this.activeFilters.forEach((activeFilter) => {
            activeFilter.removeEventListener('click', (event: Event) => {
                event.preventDefault();
                let target = <HTMLAnchorElement> event.target;

                if (!target.hasAttribute('href')) {
                    do {
                        target = <HTMLAnchorElement> target.parentElement;
                    } while (!target.hasAttribute('href'));
                }
                this.removeFilter(target);
            });
        });
    }

    /**
     * Removes the set filter
     *
     * @param trigger
     *
     * @private
     */
    private async removeFilter(trigger: HTMLAnchorElement) {
        this.ajaxProvider.queryParams.set('async', 'true');

        const activeFiltersAfterRemove = trigger.href.split('?');
        const attributeName = trigger.dataset.filter;
        const filters = activeFiltersAfterRemove.length > 1 ? activeFiltersAfterRemove[1].split('&') : [];

        filters.forEach((filter) => {
            const queryParam = filter.split('=');
            this.ajaxProvider.queryParams.set(queryParam[0], queryParam[1]);
        });

        this.productListContainer.classList.add('loading');
        const response = await this.ajaxProvider.fetch();
        this.processResponse(response);
        this.removeFilterSelection(attributeName);

        this.ajaxProvider.queryParams.clear();
        window.history.pushState({}, '', trigger.href);

        this.dispatchCustomEvent('filterChanged', {});
    }

    /**
     * Removes the filter selection (checkboxes and text input fields)
     *
     * @param attributeName
     *
     * @private
     */
    // eslint-disable-next-line class-methods-use-this
    private removeFilterSelection(attributeName: string): void {
        const filtersToRemove = <HTMLInputElement[]> Array.from(
            document.querySelectorAll(`input[name^="${attributeName}"]`),
        );

        filtersToRemove.forEach((filterToRemove) => {
            if (filterToRemove.getAttribute('type') === 'checkbox' && filterToRemove.checked === true) {
                filterToRemove.checked = false;
            } else if (filterToRemove.getAttribute('name').indexOf('[min]') !== -1) {
                filterToRemove.value = filterToRemove.min;
            } else if (filterToRemove.getAttribute('name').indexOf('[max]') !== -1) {
                filterToRemove.value = filterToRemove.max;
            }
        });
    }

    private setSelectedProductList() {
        this.selectedProductList = document.querySelector(
            this.selectedProductListSelector,
        );

        this.selectedProductToggler = <TogglerAccordion> this.selectedProductList.querySelector(
            '.toggler-accordion',
        );

        this.selectedProductItems = <SelectedProductItem[]> Array.from(this.selectedProductList.querySelectorAll(
            this.selectedProductItemSelector,
        ));
    }

    private removeSelectedProductListEvents() {
        this.selectedProductItems.forEach((item) => {
            item.removeEvents();
        });

        this.selectedProductToggler.removeEvents();
    }

    /**
     * Function to dispatch the filter change event
     *
     * @param name
     * @param detail
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected dispatchCustomEvent(name: string, detail: any = {}): void {
        const customEvent = new CustomEvent(name, { detail });
        document.dispatchEvent(customEvent);
    }
}
