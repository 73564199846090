import Component from 'ShopUi/models/component';

export default class TempParams extends Component {
    private tempParams: HTMLElement[];

    /**
     * @inheritDoc
     * @protected
     */
    protected init(): void {
        this.tempParams = <HTMLElement[]> Array.from(
            document.querySelectorAll(this.tempParamsSelector),
        );

        this.mapEvents(this.tempParams);
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Event mapper of this component
     *
     * @param tempParams
     */
    public mapEvents(tempParams: HTMLElement[]): void {
        tempParams.forEach((trigger: HTMLElement) => {
            trigger.addEventListener('click', (event: Event) => this.onEventTrigger(event));
        });
    }

    /**
     * Getter for the temp params selector
     */
    // eslint-disable-next-line class-methods-use-this
    get tempParamsSelector() {
        return '[data-temp-params]';
    }

    /**
     * Event handler to set the temporary params for the next Google Tag Manager event
     *
     * @param event
     *
     * @protected
     */
    protected onEventTrigger(event: Event): void {
        const triggerElement = <HTMLElement> event.target;
        let tempParams = JSON.parse(triggerElement.getAttribute('data-temp-params'));
        if (!tempParams) {
            let element = triggerElement;
            do {
                element = element.parentElement;
                tempParams = JSON.parse(element.getAttribute('data-temp-params'));
            } while (!tempParams);
        }
        this.set(tempParams);
    }

    /**
     * Setter for the temporary saved params in the local storage
     * @param value
     */
    // eslint-disable-next-line class-methods-use-this
    public set(value: object): void {
        localStorage.setItem('tempParams', JSON.stringify(value));
    }

    /**
     * Getter for the temporary saved params in the local storage
     *
     * @param key
     */
    // eslint-disable-next-line class-methods-use-this
    public get(key: string): any {
        if (localStorage.getItem('tempParams')) {
            const tempParams = JSON.parse(localStorage.getItem('tempParams'));

            if (tempParams) {
                const paramValue = tempParams[key];
                delete tempParams[key];
                localStorage.setItem('tempParams', JSON.stringify(tempParams));

                return paramValue;
            }
        }
        return null;
    }
}
