import EventDataTransfer from '../event-data-transfer';
import Ga4EcommerceEventWithProductItemMapper from './ga4-ecommerce-event-with-product-item-mapper';
import Ga4EcommerceAddToCartEvent, {
    Ga4EcommerceAddToCartEventDataInterface
} from 'GA4EventTracking/types/events/ecommerce-types/ga4-ecommerce-add-to-cart-event';
import Product from 'GA4EventTracking/types/events/ecommerce-subtypes/product';
import Ecommerce, { EcommerceDataInterface } from 'GA4EventTracking/types/events/ecommerce-subtypes/ecommerce';

/* eslint-disable camelcase */
export default class Ga4EcommerceEventAddToCartMapper extends Ga4EcommerceEventWithProductItemMapper {
    public map(transfer: EventDataTransfer): Ga4EcommerceAddToCartEvent {
        return this.mapCustomSubtype({
            event_name: transfer.eventName,
            cart_type: transfer.data.cart_type,
            add_to_cart_type: transfer.data.add_to_cart_type,
            product_selection: transfer.data.product_selection,
            ecommerce: this.mapEcommerceSubtype(transfer.data),
        });
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapEcommerceSubtype(data: EcommerceDataInterface<Product>): Ecommerce<Product> {
        data.items = data.items.map((item): Product => new Product(item));

        return new Ecommerce<Product>(data);
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(data: Ga4EcommerceAddToCartEventDataInterface): Ga4EcommerceAddToCartEvent {
        return new Ga4EcommerceAddToCartEvent(data);
    }
}
/* eslint-enable camelcase */
