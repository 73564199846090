import Component from 'ShopUi/models/component';
import AjaxProvider from 'src/BestIt/ShopUi/components/molecules/ajax-provider/ajax-provider';
import LoadingIndicator from 'src/BestIt/ShopUi/components/molecules/loading-indicator/loading-indicator';

export default class AjaxLoader extends Component {
    protected providers: AjaxProvider[];

    protected loadingIndicator: LoadingIndicator = null;

    protected readyCallback(): void {
        /* eslint:disable: deprecation */
        this.providers = <AjaxProvider[]>Array.from(
            this.providerClassName ?
                document.getElementsByClassName(this.providerClassName) :
                document.querySelectorAll(this.providerSelector),
        );
        /* eslint:enable: deprecation */

        if (this.useLoadingIndicator) {
            this.loadingIndicator = document.querySelector('loading-indicator') as LoadingIndicator;
        }

        this.mapEvents();
    }

    protected mapEvents(): void {
        this.providers.forEach((provider: AjaxProvider) => {
            provider.addEventListener('fetching', () => this.onFetching());
            provider.addEventListener('fetched', () => this.onFetched());
        });
    }

    protected onFetching(): void {
        if (this.useLoadingIndicator && this.loadingIndicator !== null) {
            this.loadingIndicator.openLoadingIndicator();
        } else {
            this.classList.remove('is-invisible');
        }
    }

    protected onFetched(): void {
        if (this.useLoadingIndicator && this.loadingIndicator !== null) {
            this.loadingIndicator.closeLoadingIndicator();
        } else {
            this.classList.add('is-invisible');
        }
    }

    /**
     * Gets a querySelector name of the provider element.
     *
     * @deprecated Use providerClassName() instead.
     */
    get providerSelector(): string {
        return this.getAttribute('provider-selector');
    }

    protected get providerClassName(): string {
        return this.getAttribute('provider-class-name');
    }

    /**
     * Gets a querySelector name of the provider element.
     */
    get useLoadingIndicator(): boolean {
        return this.getAttribute('use-loading-indicator') === 'true';
    }
}
