import Component from 'ShopUi/models/component';
import { ResizeObserver } from 'resize-observer';
import { ResizeObserverEntry } from 'resize-observer/lib/ResizeObserverEntry';

export default class ImageTeaserFullSize extends Component {
    /**
     * The content container of the image teaser full size
     *
     * @protected
     */
    protected textBox: HTMLElement;

    /**
     * The content container of the image teaser full size
     *
     * @protected
     */
    protected image: HTMLImageElement;

    /**
     * The initial height of the image teaser full size box
     *
     * @protected
     */
    protected initialHeight: number;

    /**
     * A flag to define that the resizing is in process right now and that it should not be triggered again.
     *
     * @protected
     */
    protected resizeInProcess: boolean;

    /**
     * Base function for the components
     *
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    private initialize() {
        this.textBox = this.querySelector(`.${this.name}__text-box`);
        this.image = this.querySelector('.image__image');

        if (typeof this.textBox !== 'undefined' && this.textBox !== null) {
            this.initialHeight = this.offsetHeight;
            this.mapEvents();
        }
    }

    private mapEvents() {
        const resizeObserver = new ResizeObserver(
            (entries: ResizeObserverEntry[]) => this.adjustSizes(entries),
        );
        resizeObserver.observe(document.body);
    }

    /**
     * Adjusts the
     * @param entries
     * @private
     */
    private adjustSizes(entries: ResizeObserverEntry[]) {
        if (!this.resizeInProcess) {
            this.resizeInProcess = true;
            setTimeout(() => this.prepareResize(entries), 500);
        }
    }

    protected resize() {
        const newMaxHeight = (this.textBox.offsetHeight + 30) / 15;

        this.style.maxHeight = `${newMaxHeight.toString()}rem`;
        this.image.style.maxHeight = `${newMaxHeight.toString()}rem`;

        let containerSize = 1200;
        if (window.innerWidth < 1200) {
            containerSize = window.innerWidth - 15;
        }

        if (this.textBox.offsetHeight + 30 >= this.image.offsetHeight) {
            while (this.textBox.offsetHeight + 30 >= this.image.offsetHeight &&
                (this.textBox.offsetWidth + 15) < containerSize
            ) {
                const textBoxWidth = this.textBox.offsetWidth + 15;
                const newMaxWidth = textBoxWidth < containerSize ? textBoxWidth : containerSize;

                this.textBox.style.maxWidth = `${newMaxWidth / 15}rem`;
            }
        }
    }

    protected isResizeNeeded(textBoxHeight: number): boolean {
        return textBoxHeight > this.offsetHeight ||
            textBoxHeight > window.outerHeight * 0.53 ||
            textBoxHeight + 30 >= this.image.offsetHeight;
    }

    /**
     * Validate the screen width
     *
     * @protected
     */
    protected isDisplayWidthMoreThanAvailableBreakpoint(): boolean {
        return window.innerWidth >= this.breakpoint;
    }

    /**
     * Getter for the breakpoint where we want to resize
     *
     * @public
     */
    get breakpoint(): number {
        return Number(this.dataset.breakpoint);
    }

    protected prepareResize(entries: ResizeObserverEntry[]): void {
        if (this.image.offsetHeight > 0) {
            entries.forEach(() => {
                if (this.isDisplayWidthMoreThanAvailableBreakpoint()) {
                    const windowHeight = window.outerHeight;
                    const textBoxHeight = this.textBox.offsetHeight;
                    const imageHeight = this.image.offsetHeight;

                    if (this.isResizeNeeded(textBoxHeight)) {
                        this.resize();
                    } else if (textBoxHeight < this.offsetHeight - 30 &&
                        textBoxHeight < windowHeight * 0.53 &&
                        textBoxHeight + 30 < imageHeight
                    ) {
                        this.style.maxHeight = '53vh';
                        this.image.style.maxHeight = '53vh';
                        this.textBox.style.maxWidth = '22rem';

                        if (this.isResizeNeeded(this.textBox.offsetHeight)) {
                            this.resize();
                        }
                    }
                } else {
                    this.style.maxHeight = null;
                    this.image.style.maxHeight = null;
                    this.textBox.style.maxWidth = null;
                }
            });
        }

        this.resizeInProcess = false;
    }
}
