import CartType from '../ecommerce-subtypes/cart-type';
import Ga4EcommerceEventWithProductDataInterface
    from 'GA4EventTracking/types/events/ga4-ecommerce-event-with-product-data-interface';
import Ga4EcommerceEvent from 'GA4EventTracking/types/events/ecommerce-types/ga4-ecommerce-event';

/* eslint-disable camelcase */
export interface Ga4EcommerceViewCartEventDataInterface extends Ga4EcommerceEventWithProductDataInterface {
    cart_type: CartType;
}
export default class Ga4EcommerceViewCartEvent extends Ga4EcommerceEvent {
    cart_type: CartType;

    constructor(data: Ga4EcommerceViewCartEventDataInterface) {
        super(data);

        this.cart_type = data.cart_type;
    }
}
/* eslint-enable camelcase */
