import Component from 'ShopUi/models/component';
import {
    GaEvent,
    EnhancedEcommerce,
    Impression,
    ProductImpression,
    Promotion,
    PromoView,
    PromotionView,
    ActionField,
    CheckoutOption,
    Product,
    Checkout,
    CheckoutStep,
    OrderItem,
    Order,
    Purchase,
    ProductCollection,
    AddToCart,
    CartProduct,
    RemoveFromCart,
    ProductDetail,
    Detail,
    CheckoutOptionField,
    ProductClick,
    EnhancedEcommerceExtended,
} from 'GTMEventTracking/types/google-tag-events';
import TempParams from 'src/BestIt/ShopUi/components/molecules/temp-params/temp-params';
import UserCentrics from 'src/BestIt/ShopUi/components/molecules/user-centrics/user-centrics';

export default class GoogleTagEvents extends Component {
    /**
     * A constant for the event type "EECproductImpression"
     *
     * @static
     */
    static EVENT_EEC_PRODUCT_IMPRESSION = 'EECproductImpression';

    /**
     * A constant for the event type "EECproductImpressionNuminos"
     *
     * @static
     */
    static EVENT_EEC_PRODUCT_IMPRESSION_NUMINOS = 'EECproductImpressionNuminos';

    /**
     * A constant for the event type "EECpromotionImpression"
     *
     * @static
     */
    static EVENT_EEC_PROMOTION_IMPRESSION = 'EECpromotionImpression';

    /**
     * A constant for the event type "EECcheckout"
     *
     * @static
     */
    static EVENT_EEC_CHECKOUT = 'EECcheckout';

    /**
     * A constant for the event type "EECcheckoutOption"
     *
     * @static
     */
    static EVENT_EEC_CHECKOUT_OPTION = 'EECcheckoutOption';

    /**
     * A constant for the event type "EECpurchase"
     *
     * @static
     */
    static EVENT_EEC_PURCHASE = 'EECpurchase';

    /**
     * A constant for the event type "EECproductDetailView"
     *
     * @static
     */
    static EVENT_EEC_PRODUCT_DETAIL_VIEW = 'EECproductDetailView';

    /**
     * A constant for the event type "EECproductClick"
     *
     * @static
     */
    static EVENT_EEC_PRODUCT_CLICK = 'EECproductClick';

    /**
     * A constant for the event type "EECproductClickNuminos"
     *
     * @static
     */
    static EVENT_EEC_PRODUCT_CLICK_NUMINOS = 'EECproductClickNuminos';

    /**
     * A constant for the event type "EECaddToCart"
     *
     * @static
     */
    static EVENT_EEC_PRODUCT_ADDTOCART = 'EECaddToCart';

    /**
     * A constant for the event type "EECaddToCartConfigurator"
     *
     * @static
     */
    static EVENT_EEC_PRODUCT_ADDTOCART_CONFIGURATOR = 'EECaddToCartConfigurator';

    /**
     * A constant for the cart type "sample cart"
     *
     * @static
     */
    static EVENT_EEC_PRODUCT_ADDTOCART_CART_TYPE_SAMPLE = 'sample cart';

    /**
     * A list of the Google tag event triggers
     *
     * @private
     */
    private eventTriggers: HTMLElement[];

    /**
     * A list of the impression event triggers
     *
     * @private
     */
    private impressionEventTriggers: HTMLElement[];

    /**
     * A list of the already tracked product impression events
     *
     * @private
     */
    private trackedProductImpressions: string[];

    /**
     * A list of the already tracked promotion impression events
     *
     * @private
     */
    private trackedPromotionImpressions: string[];

    /**
     * A Handler for temporary params for the Google tag events
     *
     * @private
     */
    private tempParams: TempParams;

    /**
     * The UserCentrics consent management handler component
     * @private
     */
    private userCentricsHandler: UserCentrics;

    /**
     * @inheritDoc
     *
     * @protected
     *
     * @return void
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * The initialize function
     *
     * @public
     *
     * @return void
     */
    protected initialize(): void {
        window.dataLayer = window.dataLayer || [];
        this.trackedProductImpressions = [];
        this.trackedPromotionImpressions = [];
        this.tempParams = <TempParams> document.querySelector('.temp-params');
        this.userCentricsHandler = <UserCentrics> document.querySelector('.user-centrics');

        this.eventTriggers = <HTMLElement[]> Array.from(
            document.querySelectorAll(this.eventTriggerSelector),
        );

        this.impressionEventTriggers = <HTMLElement[]> Array.from(
            document.querySelectorAll(this.impressionEventTriggerSelector),
        );

        this.mapEvents(this.eventTriggers);
        if (this.impressionEventTriggers) {
            this.mapImpressionEvents(this.impressionEventTriggers);
        }
    }

    /**
     * Getter for the event triggers
     */
    // eslint-disable-next-line class-methods-use-this
    get eventTriggerSelector() {
        return '[data-event-name][data-event-data]:not([data-event-type="impression"])';
    }

    /**
     * Getter for the impression event triggers
     */
    // eslint-disable-next-line class-methods-use-this
    get impressionEventTriggerSelector() {
        return '[data-event-name][data-event-data][data-event-type="impression"]';
    }

    /**
     * Event mapper function
     *
     * @param eventTriggers
     */
    public mapEvents(eventTriggers: HTMLElement[]) {
        eventTriggers.forEach((trigger: HTMLElement) => {
            const eventType = this.getGtmEventType(trigger);
            if (eventType === 'load') {
                this.pushLoadEvent(trigger, eventType);
            } else {
                trigger.addEventListener(`${eventType}`, (event: Event) => this.onEventTrigger(event));
            }
        });
        window.addEventListener('load', () => {
            if (typeof this.userCentricsHandler !== 'undefined' &&
                this.userCentricsHandler !== null &&
                this.userCentricsHandler.getGoogleAnalyticsConsentStatus()
            ) {
                this.pushDataLayerCollection();
            } else if (typeof this.userCentricsHandler === 'undefined' ||
                this.userCentricsHandler === null
            ) {
                this.pushDataLayerCollection();
            }
        });
    }

    /**
     * Event mapper function for the impression events
     *
     * @param impressionEventTriggers
     */
    public mapImpressionEvents(impressionEventTriggers: HTMLElement[]) {
        const options = {
            root: null,
            threshold: 0.5,
        };

        if (typeof IntersectionObserver !== 'undefined') {
            const observer = new IntersectionObserver(this.onImpressionEventTrigger.bind(this), options);

            impressionEventTriggers.forEach((element) => {
                observer.observe(element);
            });
        }
    }

    /**
     * This functions pushes the events which get triggerd on load
     *
     * @param trigger
     * @param eventType
     *
     * @private
     */
    private pushLoadEvent(trigger: HTMLElement, eventType: string) {
        const gtmEvent = this.getEventData(trigger);
        this.pushEvent(gtmEvent, eventType);
    }

    /**
     * Event handler for the event trigger events
     * @param event
     * @private
     */
    private onEventTrigger(event: Event) {
        const triggerElement = <HTMLElement> event.target;
        const gtmEvent = this.getEventData(triggerElement);

        if (event.type === 'submit') {
            gtmEvent.eventCallback = () => {
                triggerElement.closest('form').submit();
            };
            gtmEvent.eventTimeout = 2000;
        }
        this.pushEvent(gtmEvent);
    }

    /**
     * Getter for the Google Tag event cart type
     * @param triggerElement
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected getGtmCartType(triggerElement: HTMLElement) {
        return triggerElement.getAttribute('data-event-cartType');
    }

    /**
     * Handler for the impression events
     * @param entries
     * @private
     */
    private onImpressionEventTrigger(entries) {
        const trackableProducts = [];
        const trackablePromotions = [];
        let gtmProductImpressionEvent;
        let gtmPromotionImpressionEvent;
        let basicGtmEventData = {
            event: GoogleTagEvents.EVENT_EEC_PRODUCT_IMPRESSION,
            ecommerce: {
                currencyCode: 'EUR',
            },
        };

        entries.forEach((entry) => {
            const triggerElement = <HTMLElement> entry.target;
            const gtmEventName = this.getGtmEventName(triggerElement);
            const gtmEventData = this.getGtmEventData(triggerElement);

            if (gtmEventName === GoogleTagEvents.EVENT_EEC_PRODUCT_IMPRESSION ||
                gtmEventName === GoogleTagEvents.EVENT_EEC_PRODUCT_IMPRESSION_NUMINOS
            ) {
                gtmEventData.ecommerce.impressions.forEach((impression) => {
                    const product = new Impression(impression);
                    const shouldBeTracked = !this.trackedProductImpressions.includes(product.id);

                    if (entry.intersectionRatio > 0.5 && shouldBeTracked) {
                        this.trackedProductImpressions.push(product.id);
                        trackableProducts.push(product);
                        basicGtmEventData = gtmEventData;
                    }
                });
            } else if (gtmEventName === GoogleTagEvents.EVENT_EEC_PROMOTION_IMPRESSION) {
                const promotion = new Promotion(gtmEventData);
                const shouldBeTracked = !this.trackedPromotionImpressions.includes(promotion.id);

                if (entry.intersectionRatio > 0.5 && shouldBeTracked) {
                    this.trackedPromotionImpressions.push(promotion.id);
                    trackablePromotions.push(promotion);
                }
            }
        });

        if (trackableProducts.length > 0) {
            const productImpression = new ProductImpression(
                basicGtmEventData.ecommerce.currencyCode,
                trackableProducts,
            );
            if (basicGtmEventData.event === GoogleTagEvents.EVENT_EEC_PRODUCT_IMPRESSION_NUMINOS) {
                gtmProductImpressionEvent = this.createEnhancedEcommerceConfigurator(
                    GoogleTagEvents.EVENT_EEC_PRODUCT_IMPRESSION,
                    basicGtmEventData,
                    productImpression,
                );
            } else {
                gtmProductImpressionEvent = this.createEnhancedEcommerce(
                    GoogleTagEvents.EVENT_EEC_PRODUCT_IMPRESSION,
                    productImpression,
                );
            }

            this.pushEvent(gtmProductImpressionEvent);
        }

        if (trackablePromotions.length > 0) {
            const promoView = new PromoView(trackablePromotions);
            const promotionView = new PromotionView(promoView);
            gtmPromotionImpressionEvent = this.createEnhancedEcommerce(
                GoogleTagEvents.EVENT_EEC_PROMOTION_IMPRESSION,
                promotionView,
            );

            this.pushEvent(gtmPromotionImpressionEvent);
        }
    }

    /**
     * Creates a GaEvent event
     *
     * @param gtmEventData
     */
    // eslint-disable-next-line class-methods-use-this
    public createGaEvent(gtmEventData: any): GaEvent {
        return new GaEvent(gtmEventData);
    }

    /**
     *
     * Creates a EnhancedEcommerce event
     *
     * @param gtmEventName
     * @param gtmEventData
     * @param callback
     * @param cartType
     */
    /* eslint-disable class-methods-use-this */
    /* eslint-disable max-params */
    public createEnhancedEcommerce(
        gtmEventName: string,
        gtmEventData: any,
        callback?: Function,
        cartType?: string
    ): EnhancedEcommerce {
        return new EnhancedEcommerce(gtmEventName, gtmEventData, callback, cartType);
    }
    /* eslint-enable class-methods-use-this */
    /* eslint-enable max-params */

    /**
     * Creates a EnhancedEcommerceSampleCart event
     *
     * @param gtmEventName
     * @param gtmEventData
     * @param productData
     * @param callback
     */
    /* eslint-disable class-methods-use-this */
    /* eslint-disable max-params */
    public createEnhancedEcommerceSampleCart(
        gtmEventName: string,
        gtmEventData: any,
        productData: any,
        callback?: Function,
    ): EnhancedEcommerceExtended {
        return new EnhancedEcommerceExtended(
            gtmEventName,
            gtmEventData,
            productData,
            callback,
            GoogleTagEvents.EVENT_EEC_PRODUCT_ADDTOCART_CART_TYPE_SAMPLE,
        );
    }
    /* eslint-enable class-methods-use-this */
    /* eslint-enable max-params */

    /**
     * Creates a EnhancedEcommerceConfigurator event
     *
     * @param gtmEventName
     * @param gtmEventData
     * @param productData
     * @param callback
     * @param cartType
     */
    /* eslint-disable class-methods-use-this */
    /* eslint-disable max-params */
    public createEnhancedEcommerceConfigurator(
        gtmEventName: string,
        gtmEventData: any,
        productData: any,
        callback?: Function,
        cartType?: string,
    ): EnhancedEcommerceExtended {
        return new EnhancedEcommerceExtended(gtmEventName, gtmEventData, productData, callback, cartType);
    }
    /* eslint-enable class-methods-use-this */
    /* eslint-enable max-params */

    /**
     * Creates a ActionField for different events
     *
     * @param gtmEventData
     */
    public createActionField(actionFieldData: any) {
        const actionfield = new ActionField();

        // eslint-disable-next-line no-restricted-syntax
        for (const [key, value] of Object.entries(actionFieldData)) {
            if (typeof value !== 'undefined' && value !== null) {
                actionfield[key] = value;
            } else if (value === null) {
                actionfield[key] = undefined;
                if (typeof this.tempParams !== 'undefined' && this.tempParams !== null) {
                    const paramValue = this.tempParams.get(key);
                    if (paramValue) {
                        actionfield[key] = paramValue;
                    }
                }
            }
        }

        return actionfield;
    }

    /**
     * Creates a CheckoutOption event
     *
     * @param gtmEventData
     */
    public createCheckoutOption(gtmEventData: any): CheckoutOption {
        const actionFieldData = <any> gtmEventData.ecommerce.checkout_option.actionField;
        const actionfield = this.createActionField(actionFieldData);
        const checkoutOptionField = this.createCheckoutOptionField(actionfield);

        return new CheckoutOption(checkoutOptionField);
    }

    /**
     * Creates a CheckoutOptionField event
     *
     * @param gtmEventData
     */
    // eslint-disable-next-line class-methods-use-this
    public createCheckoutOptionField(actionfield: ActionField) {
        return new CheckoutOptionField(actionfield);
    }

    /**
     * Creates a Checkout event
     *
     * @param gtmEventData
     */
    public createCheckout(gtmEventData: any): Checkout {
        const currencyCode = <string> gtmEventData.currencyCode;
        const productData = gtmEventData.checkout.products;
        const actionFieldData = <any> gtmEventData.checkout.actionField;
        const actionfield = this.createActionField(actionFieldData);

        const products = [];
        productData.forEach((product) => {
            products.push(new CartProduct(product));
        });
        const checkoutStep = new CheckoutStep(actionfield, products);

        return new Checkout(currencyCode, checkoutStep);
    }

    /**
     * Creates a Purchase event
     *
     * @param gtmEventData
     */
    public createPurchase(gtmEventData: any): Purchase {
        const currencyCode = <string> gtmEventData.currencyCode;
        const productData = gtmEventData.purchase.products;
        const actionFieldData = <any> gtmEventData.purchase.actionField;
        const actionfield = this.createActionField(actionFieldData);

        const orderItems = [];
        productData.forEach((product) => {
            orderItems.push(new OrderItem(product));
        });
        const order = new Order(actionfield, orderItems);

        return new Purchase(currencyCode, order);
    }

    /**
     * Creates a ProductDetail event
     *
     * @param gtmEventData
     */
    public createProductDetail(gtmEventData: any): ProductDetail {
        const currencyCode = <string> gtmEventData.currencyCode;
        const productData = gtmEventData.detail.products;
        const actionFieldData = <any> gtmEventData.detail.actionField;
        const actionfield = this.createActionField(actionFieldData);

        const products = [];
        productData.forEach((product) => {
            products.push(new Product(product));
        });
        const detail = new Detail(actionfield, products);

        return new ProductDetail(currencyCode, detail);
    }

    /**
     * Creates a ProductClick event
     *
     * @param gtmEventData
     */
    public createProductClick(gtmEventData: any): ProductClick {
        const currencyCode = <string> gtmEventData.ecommerce.currencyCode;
        const productData = gtmEventData.ecommerce.click.products;
        const actionFieldData = <any> gtmEventData.ecommerce.click.actionField;
        const actionfield = this.createActionField(actionFieldData);

        const products = [];
        productData.forEach((product) => {
            products.push(new Product(product));
        });
        const click = new Detail(actionfield, products);

        return new ProductClick(currencyCode, click);
    }

    /**
     * Creates an AddToCart event
     *
     * @param gtmEventData
     * @param currency
     * @param difference
     */
    public createAddToCart(gtmEventData: any, currency: string, difference?: number) {
        const products = [];
        const productData = <any> gtmEventData.products;
        let actionfield;
        // eslint-disable-next-line no-prototype-builtins
        if (gtmEventData.hasOwnProperty('actionField')) {
            actionfield = this.createActionField(<any>gtmEventData.actionField);
        }

        productData.forEach((product) => {
            const cartProduct = new CartProduct(product);
            if (difference) {
                cartProduct.setQuantity(difference);
            }
            products.push(cartProduct);
        });
        const add = new ProductCollection(actionfield, products);
        return new AddToCart(currency, add);
    }

    /**
     * Creates a RemoveFromCart event
     *
     * @param gtmEventData
     * @param currency
     * @param difference
     */
    public createRemoveFromCart(gtmEventData: any, currency: string, difference?: number) {
        const products = [];
        const productData = <any> gtmEventData.products;
        let actionfield;
        // eslint-disable-next-line no-prototype-builtins
        if (gtmEventData.hasOwnProperty('actionField')) {
            actionfield = this.createActionField(<any>gtmEventData.actionField);
        }

        productData.forEach((product) => {
            const cartProduct = new CartProduct(product);
            if (difference) {
                cartProduct.setQuantity(difference);
            }
            products.push(cartProduct);
        });
        const remove = new ProductCollection(actionfield, products);
        return new RemoveFromCart(currency, remove);
    }

    /**
     * This function pushes the event data into the dataLayer
     *
     * @param data
     * @param eventType
     */
    public pushEvent(data, eventType?) {
        if (typeof data !== 'undefined') {
            if (typeof this.userCentricsHandler !== 'undefined' &&
                this.userCentricsHandler !== null &&
                !this.userCentricsHandler.getGoogleAnalyticsConsentStatus()
            ) {
                if (!this.alreadyContainsEvent(data)) {
                    window.dataLayerCollection.push(data);
                }
            } else if (typeof eventType !== 'undefined' && eventType === 'load') {
                if (!this.alreadyContainsEvent(data)) {
                    window.dataLayerCollection.push(data);
                }
            } else {
                const convertedData = this.convertObjects(data);
                window.dataLayer.push(convertedData);
            }
        }
    }

    /**
     * Getter for the event data
     *
     * @param triggerElement
     *
     * @private
     */
    private getEventData(triggerElement: HTMLElement) {
        let gtmEventName = this.getGtmEventName(triggerElement);
        let gtmEventData = this.getGtmEventData(triggerElement);
        let gtmCartType = this.getGtmCartType(triggerElement);
        let gtmEvent;

        if (typeof gtmEventData === 'undefined' && typeof gtmEventName === 'undefined') {
            let element = triggerElement;
            do {
                element = element.parentElement;
                gtmEventName = this.getGtmEventName(element);
                gtmEventData = this.getGtmEventData(element);
                gtmCartType = this.getGtmCartType(element);
            } while (typeof gtmEventData === 'undefined' && typeof gtmEventName === 'undefined');
        }

        if (typeof gtmEventName !== 'undefined') {
            if (gtmEventName === 'gaEvent') {
                gtmEvent = this.createGaEvent(gtmEventData);
            } else if (gtmEventName === GoogleTagEvents.EVENT_EEC_PRODUCT_CLICK_NUMINOS) {
                gtmEvent = this.createEnhancedEcommerceConfigurator(
                    GoogleTagEvents.EVENT_EEC_PRODUCT_CLICK,
                    gtmEventData,
                    this.createProductClick(gtmEventData),
                    undefined,
                    gtmCartType,
                );
            } else if (gtmEventName === GoogleTagEvents.EVENT_EEC_PRODUCT_ADDTOCART_CONFIGURATOR) {
                gtmEvent = this.createEnhancedEcommerceConfigurator(
                    GoogleTagEvents.EVENT_EEC_PRODUCT_ADDTOCART,
                    gtmEventData,
                    this.createAddToCart(gtmEventData.ecommerce.add, gtmEventData.ecommerce.currencyCode),
                    undefined,
                    gtmCartType,
                );
            } else if (gtmCartType === GoogleTagEvents.EVENT_EEC_PRODUCT_ADDTOCART_CART_TYPE_SAMPLE) {
                gtmEvent = this.createEnhancedEcommerceSampleCart(
                    GoogleTagEvents.EVENT_EEC_PRODUCT_ADDTOCART,
                    gtmEventData,
                    this.createAddToCart(gtmEventData.ecommerce.add, gtmEventData.ecommerce.currencyCode),
                    undefined,
                );
            } else {
                switch (gtmEventName) {
                    case GoogleTagEvents.EVENT_EEC_CHECKOUT:
                        gtmEventData = this.createCheckout(gtmEventData);
                        break;
                    case GoogleTagEvents.EVENT_EEC_CHECKOUT_OPTION:
                        gtmEventData = this.createCheckoutOption(gtmEventData);
                        break;
                    case GoogleTagEvents.EVENT_EEC_PURCHASE:
                        gtmEventData = this.createPurchase(gtmEventData);
                        break;
                    case GoogleTagEvents.EVENT_EEC_PRODUCT_DETAIL_VIEW:
                        gtmEventData = this.createProductDetail(gtmEventData);
                        break;
                    case GoogleTagEvents.EVENT_EEC_PRODUCT_CLICK:
                        gtmEventData = this.createProductClick(gtmEventData);
                        break;
                    default:
                        break;
                }

                gtmEvent = this.createEnhancedEcommerce(gtmEventName, gtmEventData, undefined, gtmCartType);
            }
        }
        return gtmEvent;
    }

    /**
     * Getter for the Google tag event type
     *
     * @param triggerElement
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected getGtmEventType(triggerElement: HTMLElement) {
        return triggerElement.getAttribute('data-event-type') || 'click';
    }

    /**
     * Getter for the Google Tag event name
     *
     * @param triggerElement
     */
    // eslint-disable-next-line class-methods-use-this
    public getGtmEventName(triggerElement: HTMLElement) {
        return triggerElement.getAttribute('data-event-name') || undefined;
    }

    /**
     * Getter for the Google tag event data
     *
     * @param triggerElement
     */
    // eslint-disable-next-line class-methods-use-this
    public getGtmEventData(triggerElement: HTMLElement) {
        let gtmEventData;

        if (triggerElement.getAttribute('data-event-data') !== null) {
            gtmEventData = JSON.parse(triggerElement.getAttribute('data-event-data'));
        }
        return gtmEventData || undefined;
    }

    /**
     * Converts data to an object
     *
     * @param data
     *
     * @protected
     */
    protected convertObjects(data) {
        const convertedData = {};
        for (const key in data) {
            // eslint-disable-next-line no-prototype-builtins
            if (data.hasOwnProperty(key)) {
                convertedData[key] = this.objectCheck(data, key);
            }
        }
        return convertedData;
    }

    /**
     * Validates if the given Data
     *
     * @param data
     * @param key
     *
     * @protected
     */
    protected objectCheck(data, key) {
        if (data[key] === null) {
            return undefined;
        }
        if (typeof data[key] === 'object') {
            const obj = {};
            const arr = [];
            for (const objKey in data[key]) {
                // eslint-disable-next-line no-prototype-builtins
                if (data[key].hasOwnProperty(objKey)) {
                    if (Number.isInteger(Number(objKey))) {
                        arr.push(this.objectCheck(data[key], objKey));
                    } else {
                        obj[objKey] = this.objectCheck(data[key], objKey);
                    }
                }
            }
            if (arr.length) {
                return arr;
            }
            return obj;
        }
        return data[key];
    }

    /**
     * Push the events from the dataLayer collection
     *
     * @public
     */
    public pushDataLayerCollection(): void {
        window.dataLayerCollection.forEach((data) => {
            this.pushEvent(data);
        });

        window.dataLayerCollection = [];
    }

    /**
     * Compares the already pushed event data in the dataLayerCollection with the given event data, to determain if we
     * got a duplicate. This can happen, because we do not get the consent status on page load.
     *
     * @param data
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected alreadyContainsEvent(data: any): boolean {
        let contains = false;

        window.dataLayerCollection.forEach((event) => {
            if (data === event) {
                contains = true;
            }
        });

        return contains;
    }
}
