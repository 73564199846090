export default class IntegerValue {
    readonly value: number;

    constructor(value: number) {
        if (!Number.isInteger(value)) {
            throw new Error(`Value ${value} is not an integer`);
        }

        this.value = value;
    }
}
