import Component from 'ShopUi/models/component';

export default class TextField extends Component {
    input: HTMLInputElement;

    /* eslint-disable no-invalid-this */
    focussedModifierClass: string = `${this.name}--focussed`;

    hasValueModifierClass: string = `${this.name}--has-value`;
    /* eslint-enable no-invalid-this */

    /**
     * @inheritDoc
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    public initialize(): void {
        this.input = <HTMLInputElement> this.querySelector(`.${this.name}__input`);
        this.addListeners();
    }

    private addListeners() {
        this.input.addEventListener('focus', this.onFocus.bind(this), true);
        this.input.addEventListener('blur', this.onBlur.bind(this), true);
        window.addEventListener('load', this.onLoad.bind(this), true);
    }

    private onFocus() {
        this.classList.add(this.focussedModifierClass);
    }

    private onBlur() {
        this.setModifierClasses();
    }

    private onLoad() {
        this.setModifierClasses();
    }

    public setModifierClasses() {
        if (this.input.value) {
            this.classList.add(this.hasValueModifierClass);
        } else {
            this.classList.remove(this.hasValueModifierClass);
        }

        this.classList.remove(this.focussedModifierClass);
    }

    get inputValue() {
        return this.input.value;
    }
}
