import Component from 'ShopUi/models/component';
import AjaxProvider from 'ShopUi/components/molecules/ajax-provider/ajax-provider';

export default class MiniCartList extends Component {
    protected ajaxProvider: AjaxProvider;

    protected miniCartListContainer: HTMLElement;

    protected readyCallback(): void {
        this.initialize();
    }

    public initialize() {
        this.ajaxProvider = <AjaxProvider> this.querySelector(`.${this.jsName}__provider`);
        if (this.ajaxProvider !== null) {
            this.miniCartListContainer = <HTMLElement> document.querySelector(
                '.js-user-navigation__sub-nav-cart .user-navigation__sub-nav-inner',
            );
            this.miniCartListContainer.classList.add('loading');

            this.mapEvents();
        }
    }

    private mapEvents() {
        window.addEventListener('load', () => this.onWindowLoaded(), { once: true });
    }

    private async onWindowLoaded() {
        try {
            const response = await this.ajaxProvider.fetch();
            const priceData = JSON.parse(response);

            if (priceData.success === true && priceData.error === null) {
                if (typeof priceData.data !== 'undefined' &&
                    typeof priceData.data.pricesByQuoteId !== 'undefined'
                ) {
                    Object.entries(priceData.data.pricesByQuoteId).forEach(([idQuote, price]) => {
                        const miniCartDetailAmount = <HTMLElement> this.querySelector(
                            `.mini-cart-detail[data-quote-id="${idQuote}"] .mini-cart-detail__amount`,
                        );
                        miniCartDetailAmount.innerText = price.toString();
                        miniCartDetailAmount.classList.remove('is-hidden');
                    });
                }
            } else {
                throw new Error(priceData.error);
            }
        } catch (error) {
            const miniCartDetailsAmount = <HTMLElement[]> Array.from(this.querySelectorAll(
                '.mini-cart-detail .mini-cart-detail__amount',
            ));
            miniCartDetailsAmount.forEach((miniCartDetailAmount) => {
                miniCartDetailAmount.innerText = miniCartDetailAmount.getAttribute('data-error-message');
                miniCartDetailAmount.classList.remove('is-hidden');
            });
        } finally {
            this.miniCartListContainer.classList.remove('loading');
        }
    }
}
