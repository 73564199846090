import Component from 'ShopUi/models/component';

export default class Header extends Component {
    /**
     * Prefix for the modifier classes, needed because we had to rename the registerd component to slv-header
     */
    private modifierPrefix = 'header';

    /**
     * Modifier for the header if it should be fixed on the top of the window
     */
    private modifierIsFixed = 'is-fixed';

    /**
     * Modifier for the header if it should not be fixed on the top of the window
     */
    private modifierIsRelative = 'is-relative';

    /**
     * Modifier for the header if it should be in a collapsed state
     */
    private modifierIsCollapsed = 'is-collapsed';

    /**
     * Modifier for the desktop styling of the header
     */
    private modifierDesktop = 'desktop';

    /**
     * Placeholder element that replaces the header when page is scrolled
     */
    private placeholder: HTMLElement;

    /**
     * Search button that is used in the collapsed state of the header
     */
    private collapsedSearchButton: HTMLElement;

    /**
     * The input used for search
     */
    private searchInput: HTMLInputElement;

    /**
     * Represents the progress bar on the checkout page;
     */
    private breadcrumbProgressElement: HTMLElement;

    /**
     * Represents the threshold until the breadcrumbProgressElement should be faded out
     */
    private bredcrumbProgressElementFadeThreshold = 40;

    /**
     * The calculated height of the Header.
     */
    private headerHeight: number;

    /**
     * A threshold for when the header should set to fixed
     */
    private threshold = 10;

    /**
     * We cache the lastScrollPosition to work with our rAF debouncing mechanism
     */
    private lastScrollPosition = 0;

    /**
     * Helper variable for debouncing with rAF
     */
    private tick = false;

    /**
     * Collapsed-state of the header
     */
    private isCollapsed = false;

    /**
     * Search-Open-state of the header
     */
    private isSearchOpen = false;

    /**
     * After this value, the placeholder gets the full header height
     */
    private headerResponsiveBreakpoint = 1024;

    /**
     * Register event listeneres
     */
    protected readyCallback(): void {
        if (!Header.getHeader().classList.contains(this.getModifier(this.modifierIsRelative))) {
            this.initializePlaceholder();
        }

        this.breadcrumbProgressElement = Header.getBreadcrumbProgressElement();

        this.addScrollListener();
        this.addWindowResizeListener();

        this.collapsedSearchButton = this.getCollapsedSearchButton();

        // On some pages like checkout, the collapsedSearchButton might not exist
        if (this.collapsedSearchButton) {
            this.addCollapsedSearchButtonClickListener();
        }

        this.searchInput = this.getSearchInput();

        // On some pages like checkout, the search Input might not exist
        if (this.searchInput) {
            this.addSearchInputFocusListener();
            this.addSearchInputBlurListener();
        }

        // We need to initialize the header if start in a scrolled position
        if (window.pageYOffset > 0) {
            this.handleScroll(window.pageYOffset);
        }
    }

    private addScrollListener() {
        document.addEventListener('scroll', this.onScroll.bind(this));
    }

    private addWindowResizeListener() {
        window.addEventListener('resize', () => {
            /**
             * If we are at the top scrolling position,
             * we should reset the header so that the
             * height of the placeholder fits again
             */
            if (window.pageYOffset === 0) {
                this.resetHeader();
            }
        });
    }

    private addCollapsedSearchButtonClickListener() {
        this.collapsedSearchButton.addEventListener('click', this.openSearch.bind(this));
    }

    private addSearchInputFocusListener() {
        // When the search input is focussed, make sure it is marked as open
        this.searchInput.addEventListener('focus', () => {
            this.isSearchOpen = true;
        });
    }

    private addSearchInputBlurListener() {
        this.searchInput.addEventListener('blur', () => {
            // The search should always close on blur,
            this.closeSearch();

            const nothingWasTyped = this.searchInput.value === '';
            const hasScrolledBelowThreshold = window.pageYOffset >= this.threshold;

            /**
             * We only want the collapsing to happen if nothing was typed in the search box and if we
             * are below the scroll threshold
             */
            const shouldCollapse = nothingWasTyped && hasScrolledBelowThreshold;

            if (shouldCollapse) {
                this.collapseHeader();
            }
        });
    }

    private openSearch() {
        this.isSearchOpen = true;
        this.openHeader();

        /**
         * To be sure, that no scroll happens when the focus is set, we pass preventScroll
         */
        this.searchInput.focus({ preventScroll: true });
    }

    private closeSearch() {
        this.isSearchOpen = false;
    }

    private handleScroll(scrollPosition) {
        // Check if the header should be treated responsively or not
        if (this.isDesktop()) {
            if (scrollPosition >= this.headerHeight) {
                this.setHeaderToScrollingStateOnDesktop();
            } else {
                this.setHeaderToTopStateOnDesktop();
            }
        }

        // If page is scrolled to the top, reinitilize everything.
        if (scrollPosition === 0) {
            this.resetHeader();
        } else {
            const isNotCollapsed = !this.isCollapsed;
            const isScrolledOverThreshold = scrollPosition >= this.threshold;
            const shouldCollapse = isNotCollapsed && isScrolledOverThreshold;
            const isMobile = !this.isDesktop();

            if (isMobile) {
                const searchIsNotOpen = !this.isSearchOpen;
                const searchInputIsEmpty = this.searchInput ? this.searchInput.value === '' : true;

                // On mobile devices, it should only collapse if the searchInput is not used in any way
                if (shouldCollapse && searchIsNotOpen && searchInputIsEmpty) {
                    this.collapseHeader();
                }
            } else if (shouldCollapse) {
                this.collapseHeader();
            }
        }

        /**
         * On the checkout page, we fade out the breadcrumbProgressElement onScroll
         * Further, we move it down accordingly so it looks better
         */
        if (this.breadcrumbProgressElement) {
            const progress = scrollPosition / this.bredcrumbProgressElementFadeThreshold;
            this.breadcrumbProgressElement.style.transform = `translate3d(0,${scrollPosition}px,0)`;

            const scrollableWay = document.documentElement.scrollHeight - window.innerHeight;

            if (scrollableWay > 100) {
                this.breadcrumbProgressElement.style.opacity = String(1 - progress);
                if (1 - progress <= 0.5) {
                    this.breadcrumbProgressElement.style.pointerEvents = 'none';
                } else {
                    this.breadcrumbProgressElement.style.pointerEvents = 'all';
                }
            } else {
                this.breadcrumbProgressElement.style.opacity = '1';
                this.breadcrumbProgressElement.style.pointerEvents = 'all';
            }
        }
    }

    /**
     * Event handler for the on scroll event
     * Based on https://www.afasterweb.com/2017/09/26/performance-basics-throttling/
     */
    private onScroll = () => {
        /* eslint-disable no-invalid-this */
        /**
         * Cache the current scroll position
         * NOTE: It is IMPORTANT to use pageYOffset for cross browser compatibility
         */
        this.lastScrollPosition = window.pageYOffset;

        if (!this.tick) {
            /**
             * With this, we essentially tell the browser to handle the scroll
             * in the next possible frame, which is up to the browser
             * It will only request a new frame when the old one is done
             * rAF polyfilled by src/Pyz/Yves/ShopUi/Theme/default/requestAnimationFramePolyfill.ts
             */
            window.requestAnimationFrame(() => {
                this.handleScroll(this.lastScrollPosition);

                // Scroll is handled, release tick lock
                this.tick = false;
            });

            // Mark as ticked
            this.tick = true;
        }
    };

    private isDesktop() {
        return document.body.clientWidth >= this.headerResponsiveBreakpoint;
    }

    private collapseHeader() {
        if (!Header.getHeader().classList.contains(this.getModifier(this.modifierIsRelative))) {
            this.classList.add(this.getModifier(this.modifierIsCollapsed));
            this.isCollapsed = true;
        }
    }

    private openHeader() {
        this.classList.remove(this.getModifier(this.modifierIsCollapsed));
        this.isCollapsed = false;
    }

    private setHeaderToScrollingStateOnDesktop() {
        this.classList.add(this.getModifier(this.modifierDesktop));
    }

    private setHeaderToTopStateOnDesktop() {
        this.classList.remove(this.getModifier(this.modifierDesktop));
    }

    public resetHeader() {
        /**
         * The header should be opened again,
         * so that we can measure it correctly
         */
        this.openHeader();

        /**
         * The timeout makes sure that we measure
         * it after the animation is done
         */
        if (!Header.getHeader().classList.contains(this.getModifier(this.modifierIsRelative))) {
            setTimeout(() => {
                this.initializePlaceholder();
            }, 350);
        }
    }

    private initializePlaceholder() {
        // This caches the current header height
        this.headerHeight = this.offsetHeight;

        const shouldCreatePlaceholder = !this.placeholder;

        if (shouldCreatePlaceholder) {
            this.createPlaceholder();
        }

        // We size the placeholder accordingly
        this.placeholder.style.minHeight = `${this.headerHeight}px`;

        /**
         * We can safely set the header to be fixed now
         * because we have a correctly sized placeholder
         */
        this.classList.add(this.getModifier(this.modifierIsFixed));
    }

    private createPlaceholder() {
        this.placeholder = document.createElement('div');
        this.placeholder.style.width = '100%';
        this.parentNode.insertBefore(this.placeholder, this.nextSibling);
    }

    /**
     * Getter for the header modifier classes
     *
     * @param modifier
     */
    // eslint-disable-next-line arrow-body-style
    private getModifier = (modifier) => {
        /* eslint-disable no-invalid-this */
        return `${this.modifierPrefix}--${modifier}`;
    };

    /**
     * Returns the search input in the header
     */
    private getSearchInput() {
        return this.querySelector('.search-form input') as HTMLInputElement;
    }

    /**
     * Returns the search button that is displayed in the collapsed state
     */
    private getCollapsedSearchButton() {
        return this.querySelector('.user-navigation__collapsed-search-button') as HTMLElement;
    }

    /**
     * Returns the breadcrumb progress element on the checkout page
     */
    private static getBreadcrumbProgressElement() {
        return document.querySelector('.container--checkout .breadcrumb--progress') as HTMLElement;
    }

    /**
     * Returns the header element
     */
    private static getHeader() {
        return document.querySelector('.header') as HTMLElement;
    }
}
