import Component from 'ShopUi/models/component';
import AjaxProvider from 'ShopUi/components/molecules/ajax-provider/ajax-provider';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import Icon from 'src/BestIt/ShopUi/components/atoms/icon/icon';
import SvgIcon from 'src/BestIt/ShopUi/components/atoms/svg-icon/svg-icon';
import Ga4EventTracking from 'GA4EventTracking/components/molecules/ga4-event-tracking/ga4-event-tracking';

export default class SubscriptionForm extends Component {
    /**
     * The form element
     */
    private form: HTMLFormElement;

    /**
     * The ajax provider to set the request data
     */
    private ajaxProvider: AjaxProvider;

    /**
     * The input elements of the form
     */
    private inputFields: HTMLInputElement[];

    /**
     * The input elements of the form
     */
    private honeyPotField: HTMLInputElement;

    /**
     * The select elements of the form
     */
    private selectFields: HTMLSelectElement[];

    /**
     * The textarea elements of the form
     */
    private textAreaFields: HTMLTextAreaElement[];

    /**
     * The form response message container element
     */
    private formResponseContainer: HTMLElement;

    /**
     * The form messages
     */
    private formResponseMessages: HTMLElement;

    /**
     * The handler for the google tag manager events
     * @private
     */
    private googleEventHandler: GoogleTagEvents;

    /**
     * The status icon of the form which symbolize the error or success of the request
     */
    private statusIcon: Icon;

    /**
     * The SVG icon handler
     *
     * @protected
     */
    protected svgIconHandler: SvgIcon;

    /**
     * The GA4 event tracking
     *
     * @protected
     */
    protected ga4EventTracking: Ga4EventTracking = null;

    readyCallback(): void {
        this.form = document.querySelector(`form[name=${this.formSelector}]`);
        this.inputFields = <HTMLInputElement[]>Array.from(
            document.querySelectorAll(`form[name=${this.formSelector}] input`)
        );
        this.honeyPotField = document.querySelector(`input[name="${this.formSelector}[check]"]`);
        this.selectFields = <HTMLSelectElement[]>Array.from(
            document.querySelectorAll(`form[name=${this.formSelector}] select`)
        );
        this.textAreaFields = <HTMLTextAreaElement[]>Array.from(
            document.querySelectorAll(`form[name=${this.formSelector}] textarea`)
        );
        this.formResponseContainer = document.querySelector(`form[name=${this.formSelector}] .form-response`);
        this.formResponseMessages = document.querySelector(`form[name=${this.formSelector}] .form-response > div`);
        this.statusIcon = <Icon> document.querySelector(`form[name=${this.formSelector}] .form-response .icon`);
        this.svgIconHandler = <SvgIcon> document.querySelector('.svg-icon');

        this.ajaxProvider = <AjaxProvider>(
            document.querySelector(`.${this.formSelector}__ajax-provider`)
        );

        this.googleEventHandler = <GoogleTagEvents> document.querySelector('.google-tag-events');
        this.ga4EventTracking = <Ga4EventTracking> document.querySelector('.ga4-event-tracking');

        this.mapEvents();
    }

    private mapEvents() {
        const submitButton = <HTMLButtonElement> this.form.querySelector('button[type="submit"]');

        if (submitButton !== null) {
            submitButton.addEventListener('click', (event: Event) => this.onSubmit(event));
        }
    }

    private async onSubmit(event: Event) {
        event.preventDefault();
        const formData = new FormData(this.form);

        if (this.honeyPotField.value === '') {
            this.honeyPotField.removeAttribute('required');
        }

        this.inputFields.forEach((input) => {
            this.ajaxProvider.queryParams.set(input.name, SubscriptionForm.getFieldValue(input));
        });

        this.selectFields.forEach((select) => {
            this.ajaxProvider.queryParams.set(select.name, SubscriptionForm.getFieldValue(select));
        });

        this.textAreaFields.forEach((textArea) => {
            this.ajaxProvider.queryParams.set(textArea.name, SubscriptionForm.getFieldValue(textArea));
        });

        const response = await this.ajaxProvider.fetch(formData);
        const jsonResponse = JSON.parse(response);

        if (jsonResponse.errorId === undefined &&
            typeof this.googleEventHandler !== 'undefined' &&
            this.googleEventHandler !== null
        ) {
            this.fireEvent(jsonResponse.submittedData);
        }

        this.setStatusIcon(jsonResponse);
        this.clearFormMessage();
        this.setFormMessage(jsonResponse);

        this.formResponseContainer.classList.remove('is-hidden');
        this.triggerGa4NewsletterSubscribeFailEvent(jsonResponse.message);

        if (!this.honeyPotField.hasAttribute('required')) {
            this.honeyPotField.setAttribute('required', 'required');
        }
    }

    private static getFieldValue(field: any): any {
        let value = null;

        if (!(field.value instanceof String)) {
            value = field.value;
        } else {
            value = field.value.trim;
        }

        return value;
    }

    get formSelector() {
        return this.getAttribute('form-name');
    }

    private setStatusIcon(jsonResponse: any) {
        if (this.statusIcon !== null) {
            this.statusIcon.innerHTML = '';

            if (jsonResponse.errorId === undefined) {
                this.statusIcon.classList.remove('icon--close');
                this.statusIcon.classList.add('icon--checked');
                this.statusIcon.setAttribute('symbol', 'checked');
            } else {
                this.statusIcon.classList.remove('icon--checked');
                this.statusIcon.classList.add('icon--close');
                this.statusIcon.setAttribute('symbol', 'close');
            }

            this.statusIcon.initializeCustomElement(this.statusIcon.tagName, {
                fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
                createIntersectionObserver: false,
            }, {}, false);
        }
    }

    private clearFormMessage() {
        const message = document.querySelector(`form[name=${this.formSelector}] .form-response .list`);

        if (message !== null) {
            message.remove();
        }
    }

    private setFormMessage(jsonResponse: any) {
        if (jsonResponse.message !== undefined) {
            const messageList = SubscriptionForm.createFormMessageList(jsonResponse.message);
            this.formResponseMessages.appendChild(messageList);
        }
    }

    private static createFormMessageList(message: string) {
        const listElement = document.createElement('li');
        listElement.classList.add('list__item');
        listElement.innerText = message;

        const list = document.createElement('ul');
        list.classList.add('list', 'list--bullet', 'list--alert');
        list.appendChild(listElement);

        return list;
    }

    private fireEvent(submittedData): void {
        const eventData = {
            eventCategory: 'Newsletter',
            eventAction: 'Subscribe to Newsletter',
            eventLabel: 'no topic selected',
            userInfo: submittedData.branchLabel !== 'undefined' ? submittedData.branchLabel : 'no branch selected',
        };
        const event = this.googleEventHandler.createGaEvent(eventData);
        this.googleEventHandler.pushEvent(<any> event);
    }

    // eslint-disable-next-line class-methods-use-this
    private triggerGa4NewsletterSubscribeFailEvent(errorMessage: string): void {
        if (typeof this.ga4EventTracking !== 'undefined' && this.ga4EventTracking !== null) {
            const eventElement = document.createElement('div');
            eventElement.setAttribute('data-ga4-event-name', 'newsletter_subscribe_fail');
            eventElement.setAttribute(
                'data-ga4-event-data',
                `{"error_type": "${errorMessage}"}`
            );

            this.ga4EventTracking.createAndPushEvent(eventElement);
        }
    }
}
