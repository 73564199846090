import Component from 'ShopUi/models/component';
import AjaxProvider from 'ShopUi/components/molecules/ajax-provider/ajax-provider';
import Ga4EventTracking
    from 'GA4EventTracking/components/molecules/ga4-event-tracking/ga4-event-tracking';
import Ga4ImpressionEventTracking
    from 'GA4EventTracking/components/molecules/ga4-impression-event-tracking/ga4-impression-event-tracking';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import AjaxModalWindow from 'src/BestIt/ShopUi/components/molecules/ajax-modal-window/ajax-modal-window';
import ModalWindow from 'src/BestIt/ShopUi/components/molecules/modal-window/modal-window';
import SlickCarousel from 'src/BestIt/ShopUi/components/molecules/slick-carousel/slick-carousel';
import QuantityInputSelect from 'src/BestIt/ShopUi/components/molecules/quantity-input-select/quantity-input-select';
import TempParams from 'src/BestIt/ShopUi/components/molecules/temp-params/temp-params';
import Icon from 'src/BestIt/ShopUi/components/atoms/icon/icon';
import { Iconly } from '@best-it/iconly';
import SvgIcon from 'src/BestIt/ShopUi/components/atoms/svg-icon/svg-icon';
import { mount } from 'ShopUi/app';

export default class InfiniteScrolling extends Component {
    /**
     * Constant to define the number of pages which should get automatically loaded
     */
    protected AUTO_LOAD_PAGES: number = 1;

    /**
     * Constant to define the trigger type 'manually' for the Google Analytics event data
     */
    protected TRIGGER_TYPE_MANUALLY: string = 'manually';

    /**
     * Constant to define the trigger type 'automatically' for the Google Analytics event data
     */
    protected TRIGGER_TYPE_AUTOMATICALLY: string = 'automatically';

    /**
     * A counter of the automatically loaded pages
     */
    protected autoLoadPagesCounter: number = 0;

    /**
     * The container where we visualize the loaded items counter
     */
    protected loadedItemsCounterContainer: HTMLElement;

    /**
     * A counter of the loaded product boxes to visualize it at the end of the product list
     */
    protected loadedItemsCounter: number;

    /**
     * The trigger button to load the previous page
     */
    protected loadPrevButton: HTMLAnchorElement;

    /**
     * The trigger button to load the next page
     */
    protected loadNextButton: HTMLAnchorElement;

    /**
     * The container where we append the loaded product list
     */
    protected productListsContainer: HTMLElement;

    /**
     * The tabs of the search result listing page
     */
    protected listingTabs: HTMLAnchorElement[];

    /**
     * The ajax provider we use to load the products
     */
    protected ajaxProvider: AjaxProvider;

    /**
     * The header to calculate the scroll position
     */
    protected header: HTMLElement;

    protected ga4EventTracking: Ga4EventTracking = null;

    protected ga4ImpressionEventTracking: Ga4ImpressionEventTracking = null;

    /**
     * The Google event handler to push the event
     */
    protected googleEventHandler: GoogleTagEvents;

    /**
     * Getting variables from local storage
     */
    protected tempParams: TempParams;

    /**
     * @inheritDoc
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Initialize method
     */
    public initialize(): void {
        this.header = <HTMLElement>document.querySelector('.header');
        this.loadedItemsCounterContainer = this.querySelector(`.${this.name}__loaded-items`);
        this.loadedItemsCounter = parseInt(this.loadedItemsCounterContainer.getAttribute('data-initially-loaded'), 10);
        this.loadPrevButton = document.querySelector(`.${this.jsName}__load-prev`);
        this.loadNextButton = this.querySelector(`.${this.jsName}__load-next`);
        this.productListsContainer = <HTMLElement> document.querySelector(this.containerTargetSelector);
        this.ajaxProvider = <AjaxProvider> this.querySelector(`.${this.jsName}__provider`);
        this.ga4EventTracking =
            <Ga4EventTracking>document.querySelector('.ga4-event-tracking');
        this.ga4ImpressionEventTracking =
            <Ga4ImpressionEventTracking>document.querySelector('.ga4-impression-event-tracking');
        this.googleEventHandler = <GoogleTagEvents> document.querySelector('.google-tag-events');
        this.tempParams = <TempParams> document.querySelector('.temp-params');

        if (this.updateListingTabs && this.listingTabsSelector !== null) {
            this.listingTabs = <HTMLAnchorElement[]> Array.from(document.querySelectorAll(this.listingTabsSelector));
        }

        this.mapEvents();
    }

    /**
     * A function to add the event listeners of the component
     */
    private mapEvents(): void {
        if (this.loadPrevButton !== null) {
            this.loadPrevButton.addEventListener('click', (event: MouseEvent) => this.loadProducts(event), false);
        }
        if (this.loadNextButton !== null) {
            this.loadNextButton.addEventListener('click', (event: MouseEvent) => this.loadProducts(event), false);
            this.mapImpressionEvents([this.loadNextButton]);
        }
    }

    /**
     * A function to add the event listener for the automatic loading of the product list on scroll
     */
    public mapImpressionEvents(impressionEventTriggers: HTMLElement[]): void {
        const options = {
            root: null,
            threshold: 0.5,
        };

        if (typeof IntersectionObserver !== 'undefined') {
            const observer = new IntersectionObserver(this.handleIntersection.bind(this), options);

            impressionEventTriggers.forEach((element) => {
                observer.observe(element);
            });
        }
    }

    /**
     * A getter for the selector of the product list container
     */
    get containerTargetSelector(): string {
        return this.getAttribute('data-target-selector');
    }

    /**
     * A getter for the selector of the loaded product list
     */
    get loadedHTMLTargetSelector(): string {
        return this.getAttribute('data-loaded-html-selector');
    }

    /**
     * A getter for if the history should be modified
     */
    get historyPushState() {
        return this.getAttribute('data-history-push-state');
    }

    /**
     * A getter for the button trigger url
     */
    get buttonTriggerUrl() {
        return this.getAttribute('data-button-trigger-url') || window.location.pathname;
    }

    /**
     * A getter for scrolling to window position
     */
    get scrollToPosition() {
        return this.getAttribute('data-scroll-to-position');
    }

    /**
     * A getter for the data attribute, which returns the max page number
     */
    get maxPage(): number {
        return parseInt(this.getAttribute('data-max-page'), 10);
    }

    /**
     * A getter for the data attribute, which returns the current page number
     */
    get currentPage(): number {
        return parseInt(this.getAttribute('data-current-page'), 10);
    }

    /**
     * A setter for the data attribute, which returns the current page number
     */
    set currentPage(currentPage: number) {
        this.setAttribute('data-current-page', currentPage.toString());
    }

    /**
     * A getter for the data attribute, whether the tabs on the search page should get updated or not
     */
    get updateListingTabs(): boolean {
        return this.getAttribute('data-update-listing-tabs') === 'true';
    }

    /**
     * A getter for the selector of the loaded product list
     */
    get listingTabsSelector(): string {
        return this.getAttribute('data-listing-tabs-selector') || null;
    }

    /**
     * A getter for the data attribute of the trigger, which returns the page number that get loaded
     */
    // eslint-disable-next-line class-methods-use-this
    protected getLoadPage(trigger: HTMLElement): number {
        return parseInt(trigger.getAttribute('data-load-page'), 10);
    }

    /**
     * A setter for the data attribute of the trigger, which returns the page number that get loaded
     */
    // eslint-disable-next-line class-methods-use-this
    protected setLoadPage(trigger: HTMLElement, page: number): void {
        return trigger.setAttribute('data-load-page', page.toString());
    }

    /**
     * A function to configure the url attribute of the ajax provider
     */
    protected configureAjaxProvider(catalogPageUrl: string): void {
        const queryParamSeparator = catalogPageUrl.indexOf('?') !== -1 ? '&' : '?';
        this.ajaxProvider.setAttribute('url', `${catalogPageUrl}${queryParamSeparator}ajax=true`);
    }

    /**
     * A function to load the product list
     */
    private async loadProducts(event?: MouseEvent) {
        let trigger = null;
        let triggerdType = '';

        if (typeof event !== 'undefined' && event !== null) {
            event.preventDefault();
            trigger = <HTMLAnchorElement>event.target;
            triggerdType = this.TRIGGER_TYPE_MANUALLY;
        }

        if (trigger === null) {
            trigger = this.loadNextButton;
            triggerdType = this.TRIGGER_TYPE_AUTOMATICALLY;
        }

        if (!trigger.hasAttribute('href')) {
            do {
                trigger = trigger.parentElement;
            } while (!trigger.hasAttribute('href'));
        }

        trigger.classList.add('loading');
        trigger.setAttribute('disabled', 'disabled');
        const scrollPosition = trigger.offsetTop - this.header.offsetHeight;
        this.configureAjaxProvider(trigger.getAttribute('href'));
        const keepCurrentPage = this.currentPage;
        const response = await this.ajaxProvider.fetch();
        const newTriggerUrl = this.processResponse(trigger, response);

        this.processAfterFetchTasks(trigger, newTriggerUrl, triggerdType, keepCurrentPage);
        if (this.scrollToPosition) {
            window.scroll(0, scrollPosition);
        }
    }

    /**
     * A function to process the response from the ajax provider and retruns the new href for the trigger
     */
    private processResponse(trigger: HTMLAnchorElement, response): string {
        const loadedPage = this.getLoadPage(trigger);
        let { search } = window.location;
        let newPage = null;

        search = search.replace('?', '');
        let searchParams = search.split('&');

        if (loadedPage > this.currentPage) {
            // eslint-disable-next-line no-undef
            this.insertResponse(<InsertPosition> 'beforeend', response);

            if (loadedPage < this.maxPage) {
                newPage = loadedPage + 1;
            } else {
                trigger.classList.add('is-hidden');
            }
        } else {
            // eslint-disable-next-line no-undef
            this.insertResponse(<InsertPosition> 'afterbegin', response);

            if (loadedPage > 1) {
                newPage = loadedPage - 1;
            } else {
                trigger.classList.add('is-hidden');
            }
        }

        if (newPage !== null) {
            this.setLoadPage(trigger, newPage);

            searchParams = this.updateHref(searchParams, newPage);
        }

        const loadedHTML = <HTMLElement> document.querySelector(
            `${this.loadedHTMLTargetSelector}[data-page="${loadedPage}"]`,
        );
        this.registerEventsOfLoadedItems(loadedHTML);
        this.updateItemsCountLabel(loadedHTML);
        this.currentPage = loadedPage;

        if (this.updateListingTabs && this.listingTabs !== null) {
            this.updateListingTabUrls(loadedPage);
        }

        return `${this.buttonTriggerUrl}?${searchParams.join('&')}`;
    }

    /**
     * A function to update the loaded items counter
     */
    private updateItemsCountLabel(loadedHTML: HTMLElement): void {
        const loadedItemsCount = loadedHTML !== null ? parseInt(loadedHTML.getAttribute('data-items-count'), 10) : 0;
        this.loadedItemsCounter += loadedItemsCount;
        this.loadedItemsCounterContainer.innerText = this.loadedItemsCounter.toString();
    }

    /**
     * A function to process some after fetch tasks
     */
    // eslint-disable-next-line max-params
    protected processAfterFetchTasks(
        trigger: HTMLAnchorElement,
        href: string,
        triggerdType: string,
        currentPage: number
    ): void {
        if (this.historyPushState) {
            window.history.pushState({}, '', trigger.getAttribute('href'));
        }
        trigger.setAttribute('href', href);
        trigger.classList.remove('loading');
        trigger.removeAttribute('disabled');
        trigger.blur();
        this.triggerGoogleTagEvents(trigger, triggerdType, currentPage);
        this.triggerGA4Events(triggerdType, currentPage);
    }

    /**
     * A function to insert the product list response into the DOM
     */
    // eslint-disable-next-line no-undef
    protected insertResponse(position: InsertPosition, response: string): void {
        if (typeof response !== 'undefined' && response !== null) {
            if (this.productListsContainer !== null) {
                this.productListsContainer.insertAdjacentHTML(position, response);
            } else {
                this.insertAdjacentHTML(position, response);
            }
        }
    }

    /**
     * A function to handle the intersection events for the automatic loading of a product list
     */
    protected handleIntersection(entries): void {
        entries.forEach((entry) => {
            if (entry.isIntersecting && this.autoLoadPagesCounter < this.AUTO_LOAD_PAGES) {
                this.loadProducts();
                this.autoLoadPagesCounter++;
            }
        });
    }

    /**
     * A function to push the Google Analytics event
     */
    private triggerGoogleTagEvents(trigger: any, triggerType: string, currentPage: number): void {
        if (typeof this.googleEventHandler !== 'undefined' && this.googleEventHandler !== null) {
            let eventData = this.googleEventHandler.getGtmEventData(trigger);
            if (typeof eventData !== 'undefined' && eventData !== null) {
                eventData = {
                    ...eventData,
                    eventLabel: `${currentPage} - ${triggerType}`,
                };
                const event = this.googleEventHandler.createGaEvent(eventData);
                this.googleEventHandler.pushEvent(<any>event);
            }
        }
    }

    /**
     * A function to push the Google Analytics 4 event
     */
    private triggerGA4Events(triggerType: string, currentPage: number): void {
        if (typeof this.ga4EventTracking !== 'undefined' && this.ga4EventTracking !== null) {
            const eventElement = document.createElement('div');
            eventElement.setAttribute('data-ga4-event-name', 'show_more');
            eventElement.setAttribute(
                'data-ga4-event-data',
                `{"page_number": "${currentPage}", "trigger": "${triggerType}"}`
            );

            this.ga4EventTracking.createAndPushEvent(eventElement);
        }
    }

    /**
     * A function to register the events of the loaded product boxes
     */
    // eslint-disable-next-line complexity
    private async registerEventsOfLoadedItems(loadedHTML: HTMLElement): Promise<void> {
        if (loadedHTML !== null) {
            await mount();

            const ajaxModalWindows = <AjaxModalWindow[]> Array.from(
                loadedHTML.querySelectorAll('.ajax-modal-window'),
            );
            const modalWindows = <ModalWindow[]> Array.from(
                loadedHTML.querySelectorAll('.modal-window'),
            );
            const slickCarousels = <SlickCarousel[]> Array.from(
                loadedHTML.querySelectorAll('.slick-carousel'),
            );
            const quantityInputSelects = <QuantityInputSelect[]> Array.from(
                loadedHTML.querySelectorAll('.quantity-input-select'),
            );
            const icons = <Icon[]> Array.from(
                loadedHTML.querySelectorAll('.icon'),
            );
            const svgIconHandler = <SvgIcon> document.querySelector('.svg-icon');
            let productGoogleEventTriggers = null;
            let productImpressionGoogleEventTriggers = null;
            if (typeof this.googleEventHandler !== 'undefined' && this.googleEventHandler !== null) {
                productGoogleEventTriggers = <HTMLElement[]>Array.from(
                    loadedHTML.querySelectorAll(this.googleEventHandler.eventTriggerSelector),
                );
                productImpressionGoogleEventTriggers = <HTMLElement[]>Array.from(
                    loadedHTML.querySelectorAll(this.googleEventHandler.impressionEventTriggerSelector),
                );
            }
            const tempParamElements = <HTMLElement[]> Array.from(
                document.querySelectorAll(this.tempParams.tempParamsSelector),
            );

            if (typeof ajaxModalWindows !== 'undefined' && ajaxModalWindows !== null) {
                ajaxModalWindows.forEach((ajaxModalWindow: AjaxModalWindow) => {
                    ajaxModalWindow.isInitialized ?? ajaxModalWindow.initialize();
                });
            }

            if (typeof modalWindows !== 'undefined' && modalWindows !== null) {
                modalWindows.forEach((modalWindow: ModalWindow) => {
                    modalWindow.isInitialized ?? modalWindow.initialize();
                });
            }

            if (typeof slickCarousels !== 'undefined' && slickCarousels !== null) {
                slickCarousels.forEach((slickCarousel: SlickCarousel) => {
                    slickCarousel.isInitialized ?? slickCarousel.initialize();
                });
            }

            if (typeof quantityInputSelects !== 'undefined' && quantityInputSelects !== null) {
                quantityInputSelects.forEach((quantityInputSelect: QuantityInputSelect) => {
                    quantityInputSelect.isInitialized ?? quantityInputSelect.initialize();
                });
            }

            if (typeof icons !== 'undefined' &&
                icons !== null &&
                typeof svgIconHandler !== 'undefined' &&
                svgIconHandler !== null
            ) {
                const { iconElementSelector } = svgIconHandler;

                if (iconElementSelector !== '') {
                    icons.forEach((icon) => {
                        icon.initializeCustomElement(iconElementSelector, {
                            fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
                            createIntersectionObserver: false,
                        }, {}, false);
                    });

                    Iconly.registerIntersectionObserver(iconElementSelector, {
                        fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
                    });
                }
            }

            this.registerGa4Events(loadedHTML);
            this.registerGa4ImpressionEvents(loadedHTML);

            if (typeof productGoogleEventTriggers !== 'undefined' && productGoogleEventTriggers !== null) {
                this.googleEventHandler.mapEvents(productGoogleEventTriggers);
            }

            if (typeof productImpressionGoogleEventTriggers !== 'undefined' &&
                productImpressionGoogleEventTriggers !== null
            ) {
                this.googleEventHandler.mapImpressionEvents(productImpressionGoogleEventTriggers);
            }

            if (typeof tempParamElements !== 'undefined' && tempParamElements !== null) {
                this.tempParams.mapEvents(tempParamElements);
            }
        }
    }

    protected registerGa4Events(container: HTMLElement): void {
        if (this.ga4EventTracking === null) {
            return;
        }

        this.ga4EventTracking.createEvents(container);
    }

    protected registerGa4ImpressionEvents(container: HTMLElement): void {
        if (this.ga4ImpressionEventTracking === null) {
            return;
        }

        this.ga4ImpressionEventTracking.createEvents(container);
    }

    protected updateListingTabUrls(loadedPage?: number) {
        if (loadedPage !== null) {
            this.listingTabs.forEach((tab: HTMLAnchorElement) => {
                const tabLink = tab;
                const href = tabLink.search.replace('?', '');
                let tabSearchParams = href.split('&');

                tabSearchParams = this.updateHref(tabSearchParams, loadedPage.toString());

                tabLink.href = `?${tabSearchParams.join('&')}`;
            });
        }
    }

    // eslint-disable-next-line class-methods-use-this
    protected updateHref(searchParams: Array<string>, newPage: string): Array<string> {
        let pageParameterFound = false;
        const newSearchParams = searchParams;

        searchParams.forEach((param: string, index: number) => {
            if (param.indexOf('page') !== -1) {
                newSearchParams[index] = `page=${newPage}`;
                pageParameterFound = true;
            }
        });

        if (!pageParameterFound) {
            newSearchParams.push(`page=${newPage}`);
        }

        return newSearchParams;
    }
}
