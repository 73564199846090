import Component from 'ShopUi/models/component';

export default class SummaryStep extends Component {
    /**
     * @inheritDoc
     * @protected
     */
    protected init(): void {
        super.init();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
        // ToDo: add functionality
    }
}
