import AbstractTabsElement from 'src/BestIt/ShopUi/components/organisms/abstract-tabs-element/abstract-tabs-element';
import { mount } from 'ShopUi/app';

export default class BiteKarriere extends AbstractTabsElement {
    /**
     * The readyCallback function
     *
     * @protected
     *
     * @return void
     */
    protected init(): void {
        this.waitForElementToDisplay('.bite-karriere .tabs-element', () => {
            this.mountTabElement();
        }, 500, 10000);
    }

    protected async mountTabElement() {
        await mount();

        this.classList.remove('is-hidden');
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Wait for an element to load with a set checkFrequency.
     * Once the timeout is reached or the element is loaded, the function executes the code and terminates.
     *
     * @param selector
     * @param callback
     * @param checkFrequencyInMs
     * @param timeoutInMs
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this,max-params
    protected waitForElementToDisplay(selector, callback, checkFrequencyInMs, timeoutInMs) {
        const startTimeInMs = Date.now();
        (function loopSearch() {
            if (document.querySelector(selector) !== null) {
                callback();
            } else {
                setTimeout(() => {
                    if (timeoutInMs && Date.now() - startTimeInMs > timeoutInMs) {
                        return;
                    }
                    loopSearch();
                }, checkFrequencyInMs);
            }
        }());
    }
}
