import AbstractGa4CustomEventWithDataMapper
    from 'GA4EventTracking/types/event-mappers/abstract-ga4-custom-event-with-data-mapper';
import CustomSubtypeInterface from 'GA4EventTracking/types/events/custom-subtypes/custom-subtype-interface';
import FindOfflineDealer,
{ FindOfflineDealerDataInterface } from 'GA4EventTracking/types/events/custom-subtypes/find-offline-dealer';

export default class Ga4CustomFindOfflineDealerEventMapper extends AbstractGa4CustomEventWithDataMapper {
    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(data: FindOfflineDealerDataInterface): CustomSubtypeInterface {
        return new FindOfflineDealer(data);
    }
}
