import Component from 'ShopUi/models/component';

export default class ServiceSidebars extends Component {
    /**
     * The sidebar marker on the page
     *
     * @protected
     */
    protected marker: HTMLElement;

    /**
     * The close button
     *
     * @private
     */
    private closeButton: HTMLElement;

    /**
     * The sidebar container
     *
     * @private
     */
    private sidebarContainer: HTMLElement;

    /**
     * Base function for the components
     *
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Init function for the component
     *
     * @private
     */
    public initialize() {
        this.sidebarContainer = this.querySelector('.service-sidebars__container');
        this.marker = <HTMLElement> this.querySelector('.service-sidebars__marker');
        this.closeButton = this.querySelector('.js-service-sidebars__close-button');

        this.mapEvents();
    }

    /**
     * Mapper for the events
     *
     * @private
     */
    protected mapEvents() {
        this.marker.addEventListener(
            'click',
            () => this.openSidebar(),
        );

        if (typeof this.closeButton !== 'undefined' && this.closeButton !== null) {
            this.closeButton.addEventListener(
                'click',
                () => this.closeSidebar(),
            );
        }

        if (this.displayOnLoad) {
            window.addEventListener('load', () => {
                this.closeButton.classList.remove('is-hidden');

                setTimeout(() => {
                    this.closeSidebar();
                }, 5000);
            });
        }
    }

    /**
     * Function to open the sidebar
     *
     * @private
     */
    private openSidebar() {
        if (window.innerWidth >= this.viewportBreakpoint) {
            if (!this.classList.contains('show')) {
                this.classList.add('show');
            }
        }
    }

    /**
     * Function to close the sidebar
     *
     * @private
     */
    private closeSidebar() {
        if (this.classList.contains('show')) {
            this.classList.remove('show');
        }
    }

    /**
     * Getter for the breakpoint on which the hero teaser should be removed from the slider
     *
     * @public
     */
    get viewportBreakpoint(): number {
        return parseInt(this.dataset.breakpoint || '0', 10);
    }

    /**
     * Getter for the configuration if the sidebars are initially open or closed
     */
    get displayOnLoad(): boolean {
        return this.dataset.displayOnLoad === 'true' || false;
    }
}
