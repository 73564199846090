import Component from 'ShopUi/models/component';
import Ga4EventInterface from '../../../types/events/ga4-event-interface';
import Ga4EventMakerInterface from '../../../types/event-makers/ga4-event-maker-interface';
import Ga4EventPusher from '../../../types/event-pusher/ga4-event-pusher';
import UserCentricsWrapper from '../../../types/user-centrics-wrapper';
import Ga4CustomEventWithDataMaker from '../../../types/event-makers/ga4-custom-event-with-data-maker';
import PriceCalculation from '../../../types/events/custom-subtypes/price-calculation';
import Ga4CustomPriceCalculationEventMapper
    from '../../../types/event-mappers/ga4-custom-price-calculation-event-mapper';
import EventNameConstants from '../../../types/event-name-constants';
import EventTypeConstants from '../../../types/event-type-constants';
import Ga4EcommerceEvent from '../../../types/events/ecommerce-types/ga4-ecommerce-event';
import Ga4EcommerceEventWithProductItemMapper
    from '../../../types/event-mappers/ga4-ecommerce-event-with-product-item-mapper';
import Ga4EventMaker from '../../../types/event-makers/ga4-event-maker';
import Ga4EcommerceEventBeginCheckoutMapper
    from '../../../types/event-mappers/ga4-ecommerce-event-begin-checkout-mapper';
import Ga4EcommerceBeginCheckoutEvent from '../../../types/events/ecommerce-types/ga4-ecommerce-begin-checkout-event';
import Ga4EcommerceSelectItemEvent from 'GA4EventTracking/types/events/ecommerce-types/ga4-ecommerce-select-item-event';
import Ga4EcommerceSelectItemEventMapper
    from 'GA4EventTracking/types/event-mappers/ga4-ecommerce-select-item-event-mapper';
import Ga4EcommerceEventAddPaymentInfoMapper
    from 'GA4EventTracking/types/event-mappers/ga4-ecommerce-event-add-payment-info-mapper';
import Ga4CustomSearchEventMapper from '../../../types/event-mappers/ga4-custom-search-event-mapper';
import Search from '../../../types/events/custom-subtypes/search';
import Ga4EcommerceAddShippingInfoEvent
    from 'GA4EventTracking/types/events/ecommerce-types/ga4-ecommerce-add-shipping-info-event';
import Ga4EcommerceEventAddShippingInfoMapper
    from 'GA4EventTracking/types/event-mappers/ga4-ecommerce-event-add-shipping-info-mapper';
import Ga4EcommerceAddPaymentInfoEvent
    from 'GA4EventTracking/types/events/ecommerce-types/ga4-ecommerce-add-payment-info-event';
import NewsletterSubscribeSoiDataEvent
    from 'GA4EventTracking/types/events/custom-subtypes/newsletter-subscribe-soi-event';
import Ga4NewsletterMapper
    from 'GA4EventTracking/types/event-mappers/ga4-newsletter-mapper';
import Ga4EcommercePurchaseEvent from 'GA4EventTracking/types/events/ecommerce-types/ga4-ecommerce-purchase-event';
import Ga4EcommerceEventPurchaseMapper from 'GA4EventTracking/types/event-mappers/ga4-ecommerce-event-purchase-mapper';
import Ga4EcommerceViewCartEvent from '../../../types/events/ecommerce-types/ga4-ecommerce-view-cart-event';
import Ga4EcommerceViewCartEventMapper from '../../../types/event-mappers/ga4-ecommerce-view-cart-event-mapper';
import Sorting from 'GA4EventTracking/types/events/custom-subtypes/sorting';
import Ga4CustomSortingEventMapper from 'GA4EventTracking/types/event-mappers/ga4-custom-sorting-event-mapper';
import Filter from 'GA4EventTracking/types/events/custom-subtypes/filter';
import Ga4CustomFilterEventMapper from 'GA4EventTracking/types/event-mappers/ga4-custom-filter-event-mapper';
import ProductVariant from 'GA4EventTracking/types/events/custom-subtypes/product-variant';
import Ga4CustomProductVariantEventMapper
    from 'GA4EventTracking/types/event-mappers/ga4-custom-product-variant-event-mapper';
import Ga4EcommerceAddToCartEvent from 'GA4EventTracking/types/events/ecommerce-types/ga4-ecommerce-add-to-cart-event';
import Ga4EcommerceEventAddToCartMapper
    from 'GA4EventTracking/types/event-mappers/ga4-ecommerce-event-add-to-cart-mapper';
import FormSubmit from 'GA4EventTracking/types/events/custom-subtypes/form-submit';
import Ga4CustomFormSubmitEventMapper from 'GA4EventTracking/types/event-mappers/ga4-custom-form-submit-event-mapper';
import Ga4EcommerceAddToWishlistEvent
    from 'GA4EventTracking/types/events/ecommerce-types/ga4-ecommerce-add-to-wishlist-event';
import Ga4EcommerceEventAddToWishlistMapper
    from 'GA4EventTracking/types/event-mappers/ga4-ecommerce-event-add-to-wishlist-mapper';
import Ga4CustomEventMaker from 'GA4EventTracking/types/event-makers/ga4-custom-event-maker';
import ViewOrders from 'GA4EventTracking/types/events/custom-subtypes/view-orders';
import CreateNewShoppingCart from 'GA4EventTracking/types/events/custom-subtypes/create-new-shopping-cart';
import FindOfflineDealer from 'GA4EventTracking/types/events/custom-subtypes/find-offline-dealer';
import Ga4CustomFindOfflineDealerEventMapper
    from 'GA4EventTracking/types/event-mappers/ga4-custom-find-offline-dealer-event-mapper';
import CatalogueRead from 'GA4EventTracking/types/events/custom-subtypes/catalogue-read';
import Ga4CustomCatalogueReadEventMapper
    from 'GA4EventTracking/types/event-mappers/ga4-custom-catalogue-read-event-mapper';
import ShowMore from 'GA4EventTracking/types/events/custom-subtypes/show-more';
import Ga4CustomShowMoreEventMapper from 'GA4EventTracking/types/event-mappers/ga4-custom-show-more-event-mapper';
import SearchDealer from 'GA4EventTracking/types/events/custom-subtypes/search-dealer';
import Ga4CustomSearchDealerEventMapper
    from 'GA4EventTracking/types/event-mappers/ga4-custom-search-dealer-event-mapper';
import OpenFastcalcTool from 'GA4EventTracking/types/events/custom-subtypes/open-fastcalc-tool';
import Ga4CustomOpenFastcalcEventMapper
    from 'GA4EventTracking/types/event-mappers/ga4-custom-event-open-fastcalc-mapper';
import Login from 'GA4EventTracking/types/events/custom-subtypes/login';
import SignUp from 'GA4EventTracking/types/events/custom-subtypes/sign-up';
import SelectDealer from 'GA4EventTracking/types/events/custom-subtypes/select-dealer';
import Ga4CustomSelectDealerEventMapper
    from 'GA4EventTracking/types/event-mappers/ga4-custom-select-dealer-event-mapper';
import NewsletterSubscribeFail from 'GA4EventTracking/types/events/custom-subtypes/newsletter-subscribe-fail';
import Ga4CustomNewsletterSubscribeFailEventMapper
    from 'GA4EventTracking/types/event-mappers/ga4-custom-newsletter-subscribe-fail-event-mapper';
import Ga4EcommerceRefundEvent from 'GA4EventTracking/types/events/ecommerce-types/ga4-ecommerce-refund-event';

export default class Ga4EventTracking extends Component {
    protected userCentricsHandler: UserCentricsWrapper;
    protected eventPusher: Ga4EventPusher;

    public static get eventTriggerSelector(): string {
        return '[data-ga4-event-name]';
    }

    protected static get eventNamesToSkip(): string[] {
        return EventNameConstants.impressionEventNames;
    }

    // eslint-disable-next-line class-methods-use-this, no-empty-function
    protected readyCallback(): void {}

    protected init(): void {
        window.eventCollection = window.eventCollection || [];
        this.userCentricsHandler = new UserCentricsWrapper();
        this.eventPusher = new Ga4EventPusher(this.userCentricsHandler);

        document.addEventListener(
            this.userCentricsHandler.EVENT_GOOGLE_ANALYTICS_CONSENT_STATUS_LOADED,
            () => this.onConsentStatusLoaded(),
        );

        this.eventPusher.pushEventsFromStorage();

        this.createEvents();
    }

    protected onConsentStatusLoaded() {
        this.pushEventCollection();
    }

    public pushEventCollection(): void {
        window.eventCollection.forEach((event: Ga4EventInterface) => {
            this.eventPusher.push(event);
        });

        window.eventCollection = [];
    }

    public createEvents(container: HTMLElement|Document = document): void {
        const elements = <HTMLElement[]> Array.from(
            container.querySelectorAll(Ga4EventTracking.eventTriggerSelector),
        ).filter((element: HTMLElement) => {
            const eventName = element.dataset.ga4EventName;

            return !Ga4EventTracking.eventNamesToSkip.includes(eventName);
        });

        elements.forEach((element: HTMLElement) => {
            const eventType = Ga4EventTracking.getEventTypeFromElement(element);
            if (eventType !== EventTypeConstants.EVENT_TYPE_LOAD) {
                element.addEventListener(
                    eventType === EventTypeConstants.EVENT_TYPE_STORAGE_LOAD ?
                        EventTypeConstants.EVENT_TYPE_CLICK :
                        `${eventType}`,
                    () => this.createAndPushEvent(element),
                );

                return;
            }

            this.createAndPushEvent(element);
        });
    }

    private static getEventTypeFromElement(
        element: HTMLElement,
        defaultEventType: string = EventTypeConstants.EVENT_TYPE_CLICK
    ): string {
        return element.dataset.ga4EventType ?? defaultEventType;
    }

    public createAndPushEvent(element: HTMLElement): void {
        const eventName = element.dataset.ga4EventName;

        if (!eventName) {
            throw new Error('GA4 event name is missing.');
        }

        const eventMaker = this.createEventMaker(eventName);
        const event = eventMaker.createEventFromElement(element);

        if (Ga4EventTracking.getEventTypeFromElement(element) === EventTypeConstants.EVENT_TYPE_STORAGE_LOAD) {
            this.eventPusher.pushEventsToStorage([event]);
            return;
        }

        this.eventPusher.push(event);
    }

    // eslint-disable-next-line class-methods-use-this, complexity, max-lines-per-function
    protected createEventMaker(name: string): Ga4EventMakerInterface {
        switch (name) {
            case EventNameConstants.EVENT_NAME_CUSTOM_SEARCH:
                return new Ga4CustomEventWithDataMaker(
                    Search,
                    new Ga4CustomSearchEventMapper(Search),
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_PRICE_CALCULATION:
                return new Ga4CustomEventWithDataMaker(
                    PriceCalculation,
                    new Ga4CustomPriceCalculationEventMapper(PriceCalculation)
                );
            case EventNameConstants.EVENT_NAME_ECOMMERCE_BEGIN_CHECKOUT:
                return new Ga4EventMaker(
                    Ga4EcommerceBeginCheckoutEvent,
                    new Ga4EcommerceEventBeginCheckoutMapper()
                );
            case EventNameConstants.EVENT_NAME_ECOMMERCE_SELECT_ITEM:
                return new Ga4EventMaker(
                    Ga4EcommerceSelectItemEvent,
                    new Ga4EcommerceSelectItemEventMapper(),
                );
            case EventNameConstants.EVENT_NAME_ECOMMERCE_VIEW_ITEM:
                return new Ga4EventMaker(
                    Ga4EcommerceEvent,
                    new Ga4EcommerceEventWithProductItemMapper()
                );
            case EventNameConstants.EVENT_NAME_ECOMMERCE_REMOVE_FROM_CART:
                return new Ga4EventMaker(
                    Ga4EcommerceEvent,
                    new Ga4EcommerceEventWithProductItemMapper()
                );
            case EventNameConstants.EVENT_NAME_ECOMMERCE_VIEW_CART:
                return new Ga4EventMaker(
                    Ga4EcommerceViewCartEvent,
                    new Ga4EcommerceViewCartEventMapper()
                );
            case EventNameConstants.EVENT_NAME_ECOMMERCE_ADD_SHIPPING_INFO:
                return new Ga4EventMaker(
                    Ga4EcommerceAddShippingInfoEvent,
                    new Ga4EcommerceEventAddShippingInfoMapper()
                );
            case EventNameConstants.EVENT_NAME_ECOMMERCE_ADD_PAYMENT_INFO:
                return new Ga4EventMaker(
                    Ga4EcommerceAddPaymentInfoEvent,
                    new Ga4EcommerceEventAddPaymentInfoMapper()
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_NEWSLETTER_SUBSCRIBE_SOI:
                return new Ga4EventMaker(
                    NewsletterSubscribeSoiDataEvent,
                    new Ga4NewsletterMapper()
                );
            case EventNameConstants.EVENT_NAME_ECOMMERCE_PURCHASE:
                return new Ga4EventMaker(
                    Ga4EcommercePurchaseEvent,
                    new Ga4EcommerceEventPurchaseMapper()
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_SORTING:
                return new Ga4CustomEventWithDataMaker(
                    Sorting,
                    new Ga4CustomSortingEventMapper(Sorting)
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_FILTER:
                return new Ga4CustomEventWithDataMaker(
                    Filter,
                    new Ga4CustomFilterEventMapper(Filter)
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_PRODUCT_VARIANT:
                return new Ga4CustomEventWithDataMaker(
                    ProductVariant,
                    new Ga4CustomProductVariantEventMapper(ProductVariant)
                );
            case EventNameConstants.EVENT_NAME_ECOMMERCE_ADD_TO_CART:
                return new Ga4EventMaker(
                    Ga4EcommerceAddToCartEvent,
                    new Ga4EcommerceEventAddToCartMapper()
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_FORM_SUBMIT:
                return new Ga4CustomEventWithDataMaker(
                    FormSubmit,
                    new Ga4CustomFormSubmitEventMapper(FormSubmit)
                );
            case EventNameConstants.EVENT_NAME_ECOMMERCE_ADD_TO_WISHLIST:
                return new Ga4EventMaker(
                    Ga4EcommerceAddToWishlistEvent,
                    new Ga4EcommerceEventAddToWishlistMapper()
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_VIEW_ORDERS:
                return new Ga4CustomEventMaker(
                    ViewOrders
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_CREATE_NEW_SHOPPING_CART:
                return new Ga4CustomEventMaker(
                    CreateNewShoppingCart
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_CATALOGUE_READ:
                return new Ga4CustomEventWithDataMaker(
                    CatalogueRead,
                    new Ga4CustomCatalogueReadEventMapper(CatalogueRead)
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_FIND_OFFLINE_DEALER:
                return new Ga4CustomEventWithDataMaker(
                    FindOfflineDealer,
                    new Ga4CustomFindOfflineDealerEventMapper(FindOfflineDealer)
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_SHOW_MORE:
                return new Ga4CustomEventWithDataMaker(
                    ShowMore,
                    new Ga4CustomShowMoreEventMapper(ShowMore)
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_SEARCH_DEALER:
                return new Ga4CustomEventWithDataMaker(
                    SearchDealer,
                    new Ga4CustomSearchDealerEventMapper(SearchDealer)
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_OPEN_FASTCALC_TOOL:
                return new Ga4CustomEventWithDataMaker(
                    OpenFastcalcTool,
                    new Ga4CustomOpenFastcalcEventMapper(OpenFastcalcTool)
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_LOGIN:
                return new Ga4CustomEventMaker(
                    Login
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_SIGN_UP:
                return new Ga4CustomEventMaker(
                    SignUp
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_SELECT_DEALER:
                return new Ga4CustomEventWithDataMaker(
                    SelectDealer,
                    new Ga4CustomSelectDealerEventMapper(SelectDealer)
                );
            case EventNameConstants.EVENT_NAME_CUSTOM_NEWSLETTER_SUBSCRIBE_FAIL:
                return new Ga4CustomEventWithDataMaker(
                    NewsletterSubscribeFail,
                    new Ga4CustomNewsletterSubscribeFailEventMapper(NewsletterSubscribeFail)
                );
            case EventNameConstants.EVENT_NAME_ECOMMERCE_REFUND:
                return new Ga4EventMaker(
                    Ga4EcommerceRefundEvent,
                    new Ga4EcommerceEventWithProductItemMapper()
                );
            default:
                throw new Error(`GA4 event ${name} cannot be created.`);
        }
    }
}
