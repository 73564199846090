import Component from 'ShopUi/models/component';

export default class SelectedProductItem extends Component {
    private deleteIcon: HTMLElement;

    /**
     * @inheritDoc
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Init function for the selected product item
     *
     * @public
     */
    public initialize() {
        this.deleteIcon = this.querySelector(`.${this.jsName}__delete`);

        this.mapEvents();
    }

    /**
     * Register the events for the delete icon
     *
     * @private
     */
    private mapEvents() {
        this.deleteIcon.addEventListener('click', () => this.removeProduct());
    }

    /**
     * Register the events for the delete icon
     *
     * @private
     */
    public removeEvents() {
        this.deleteIcon.removeEventListener('click', () => this.removeProduct());
    }

    /**
     * Function to dispatch the remove product event
     *
     * @private
     */
    private removeProduct(): void {
        this.dispatchCustomEvent('removeProduct', {
            sku: this.sku,
        });
    }

    /**
     * Getter for the sku of the product item
     *
     * @public
     */
    get sku(): string {
        return this.dataset.sku;
    }

    /**
     * Function to dispatch the remove product event
     *
     * @param name
     * @param detail
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected dispatchCustomEvent(name: string, detail: any = {}): void {
        const customEvent = new CustomEvent(name, { detail });
        document.dispatchEvent(customEvent);
    }
}
