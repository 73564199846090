import Component from 'ShopUi/models/component';
import debounce from 'lodash-es/debounce';

export default class LoadingIndicator extends Component {
    triggers: HTMLElement[];

    protected init(): void {
        this.triggers = <HTMLElement[]>Array.from(document.querySelectorAll('[trigger-loading]'));

        this.mapEvents();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    protected mapEvents(): void {
        this.triggers.forEach((trigger: HTMLElement) => {
            trigger.addEventListener(
                'click',
                debounce(
                    () => this.openLoadingIndicator(),
                    this.getloadingIndicatorDebounce(trigger),
                ),
            );
        });
    }

    public openLoadingIndicator(setOverflowAttribute?: boolean): void {
        this.classList.add(`${this.name}__active`);

        if (setOverflowAttribute !== false) {
            document.body.style.overflow = 'hidden';
        }
    }

    public closeLoadingIndicator(): void {
        this.classList.remove(`${this.name}__active`);
        document.body.style.overflow = null;
    }

    // eslint-disable-next-line class-methods-use-this
    protected getloadingIndicatorDebounce(trigger: HTMLElement) {
        return trigger.getAttribute('loading-indicator-debounce');
    }
}
