import AjaxProvider from 'ShopUi/components/molecules/ajax-provider/ajax-provider';
import debounce from 'lodash-es/debounce';
import QuantityInputSelect from 'src/BestIt/ShopUi/components/molecules/quantity-input-select/quantity-input-select';
import SprykerAutoCompleteForm from 'ShopUi/components/molecules/autocomplete-form/autocomplete-form';
import OverlayBlock from 'src/BestIt/ShopUi/components/atoms/overlay-block/overlay-block';

// eslint-disable-next-line no-unused-vars,no-shadow
export enum Events {FETCHING = 'fetching', FETCHED = 'fetched', CHANGE = 'change', SET = 'set', UNSET = 'unset'}

export default class AutocompleteForm extends SprykerAutoCompleteForm {
    public inputElement: HTMLInputElement;

    public hiddenInputElement: HTMLInputElement;

    overlay: OverlayBlock;

    quantityInputSelect: QuantityInputSelect;

    protected readyCallback(): void {
        this.ajaxProvider = <AjaxProvider> this.querySelector(`.${this.jsName}__provider`);
        this.suggestionsContainer = <HTMLElement> this.querySelector(`.${this.jsName}__container`);
        this.inputElement = <HTMLInputElement> this.querySelector(`.${this.jsName}__input`);
        this.hiddenInputElement = <HTMLInputElement> this.querySelector(`.${this.jsName}__input-hidden`);
        this.cleanButton = <HTMLButtonElement> this.querySelector(`.${this.jsName}__clean-button`);
        this.overlay = <OverlayBlock>document.querySelector(this.overlaySelector);
        this.quantityInputSelect = <QuantityInputSelect>document.querySelector(
            '.select-button--quick-add .quantity-input-select',
        );

        if (this.autoInitEnabled) {
            this.autoLoadInit();
        }

        this.mapEvents();
    }

    protected mapEvents(): void {
        this.inputElement.addEventListener('input', debounce(() => this.onInput(), this.debounceDelay));
        this.inputElement.addEventListener('blur', debounce(() => this.onBlur(), this.debounceDelay));
        this.inputElement.addEventListener('focus', () => this.onFocus());
        if (this.showCleanButton) {
            this.cleanButton.addEventListener('click', () => this.onCleanButtonClick());
        }
    }

    protected onCleanButtonClick(): void {
        this.cleanFields();
        this.dispatchCustomEvent(Events.UNSET);
    }

    protected onBlur(): void {
        this.overlay.hideOverlay('no-agent-user', 'no-agent-user');
        this.hideSuggestions();
    }

    protected onFocus(): void {
        this.overlay.showOverlay('no-agent-user', 'no-agent-user');
        if (this.inputValue.length >= this.minLetters) {
            this.showSuggestions();
        }
    }

    async loadSuggestions(): Promise<void> {
        this.dispatchCustomEvent(Events.FETCHING);
        this.showSuggestions();
        this.ajaxProvider.queryParams.set(this.queryParamName, this.inputValue);

        await this.ajaxProvider.fetch();
        this.mapSuggestionItemsEvents();
        this.dispatchCustomEvent(Events.FETCHED);
    }

    protected mapSuggestionItemsEvents(): void {
        /* eslint-disable consistent-this */
        const _self = this;
        const items = Array.from(this.suggestionsContainer.querySelectorAll(this.itemSelector));
        items.forEach((item: HTMLElement) => item.addEventListener('click', () => _self.onItemClick(item)));
    }

    protected onItemClick(item: HTMLElement): void {
        let data = item.getAttribute(this.valueDataAttribute);
        let text = item.textContent.trim();

        if (this.textDataAttribute !== null) {
            text = item.getAttribute(this.textDataAttribute);
            if (this.isAttributeDefined(text)) {
                let element = item;
                do {
                    element = element.parentElement;
                    data = element.getAttribute(this.valueDataAttribute);
                    text = element.getAttribute(this.textDataAttribute);
                } while (this.isAttributeDefined(text) && element.parentElement !== null);
            }
        }

        if (this.quantityDataAttribute !== null &&
            (this.eventDataDataAttribute !== null || this.ga4EventDataDataAttribute !== null)
        ) {
            let quantity = item.getAttribute(this.quantityDataAttribute);
            let eventData = item.getAttribute(this.eventDataDataAttribute);
            let ga4EventData = item.getAttribute(this.ga4EventDataDataAttribute);

            if (this.isAttributeDefined(quantity) &&
                this.isAttributeDefined(eventData)
            ) {
                let eventElement = item;
                do {
                    eventElement = eventElement.parentElement;
                    quantity = eventElement.getAttribute(this.quantityDataAttribute);
                    eventData = eventElement.getAttribute(this.eventDataDataAttribute);
                    ga4EventData = eventElement.getAttribute(this.ga4EventDataDataAttribute);
                } while (this.isAttributeDefined(quantity) &&
                (this.isAttributeDefined(eventData) || this.isAttributeDefined(ga4EventData)) &&
                    eventElement.parentElement !== null
                );
            }

            this.setAttributesOnQuantityInputSelect(quantity, eventData, ga4EventData);
        }

        this.setInputs(data, text);
    }

    setInputs(data: string, text: string): void {
        this.inputText = text;
        this.inputValue = data;

        this.dispatchCustomEvent(Events.SET, {
            text: this.inputText,
            value: this.inputValue,
        });
    }

    cleanFields(): void {
        this.setInputs('', '');
    }

    get inputText(): string {
        return this.inputElement.value.trim();
    }

    set inputText(value: string) {
        this.inputElement.value = value;
    }

    get inputValue(): string {
        return this.hiddenInputElement.value.trim();
    }

    /**
     * Sets the input value.
     */
    set inputValue(value: string) {
        this.hiddenInputElement.value = value;
    }

    get queryParamName(): string {
        return this.getAttribute('query-param-name');
    }

    get valueDataAttribute(): string {
        return this.getAttribute('value-data-attribute');
    }

    get textDataAttribute(): string {
        return this.getAttribute('text-data-attribute') || null;
    }

    get quantityDataAttribute(): string {
        return this.getAttribute('quantity-data-attribute') || null;
    }

    get eventDataDataAttribute(): string {
        return this.getAttribute('event-data-data-attribute') || null;
    }

    get ga4EventDataDataAttribute(): string {
        return this.getAttribute('ga4-event-data-data-attribute') || null;
    }

    get itemSelector(): string {
        return this.getAttribute('item-selector');
    }

    get showCleanButton(): boolean {
        return this.hasAttribute('show-clean-button');
    }

    // eslint-disable-next-line class-methods-use-this
    get overlaySelector(): string {
        return '.js-overlay-block';
    }

    // eslint-disable-next-line class-methods-use-this
    protected isAttributeDefined($attribute: string) {
        return typeof $attribute === 'undefined' || $attribute === null;
    }

    private setAttributesOnQuantityInputSelect(quantity?: string, eventData?: string, ga4EventData?: string) {
        if (typeof this.quantityInputSelect !== 'undefined' && this.quantityInputSelect !== null) {
            this.quantityInputSelect.setAttribute('current-value', quantity);
            this.quantityInputSelect.setAttribute('data-new-value', '1');
            this.quantityInputSelect.setAttribute('data-event-data', eventData);
            this.quantityInputSelect.setAttribute('data-ga4-event-data', ga4EventData);
        }
    }
}
