import Component from 'ShopUi/models/component';
import QuantityInputSelect from 'src/BestIt/ShopUi/components/molecules/quantity-input-select/quantity-input-select';
import debounce from 'lodash-es/debounce';

export default class ConfiguratorProductItem extends Component {
    private overlay: HTMLElement;

    public quantitySelect: QuantityInputSelect;

    protected isQuantitySelectAlreadyInitialized: boolean = false;

    /**
     * @inheritDoc
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Init function for the component
     *
     * @private
     */
    public initialize(): void {
        this.overlay = <HTMLElement> this.querySelector(`#${this.name}__quantity-overlay-${this.sku}`);
        this.quantitySelect = <QuantityInputSelect> this.querySelector(
            `#${this.jsName}-quantity-input-select-${this.sku}`,
        );

        this.registerEvents();
    }

    public registerEvents(): void {
        this.mapEvents();
        this.mapQuantitySelectEvents();
    }

    /**
     * Register the events for the quantity select overlays
     *
     * @private
     */
    private mapEvents(): void {
        this.overlay.addEventListener('click', () => {
            this.overlay.classList.add('is-hidden');
            this.addProduct();
            this.classList.add('is-selected');

            if (this.isQuantitySelectAlreadyInitialized === false) {
                this.mapQuantitySelectEvents();
            }
        });
    }

    /**
     * Removes the events for the quantity select overlays
     *
     * @public
     */
    public removeEvents(): void {
        this.overlay.removeEventListener('click', () => {
            this.overlay.classList.add('is-hidden');
            this.addProduct();
            this.classList.add('is-selected');
        });
    }

    /**
     * Register the events for the quantity input selects
     *
     * @private
     */
    private mapQuantitySelectEvents(): void {
        this.quantitySelect.addEventListener('change', debounce(
            () => this.onQuantityChange(),
            200,
        ));

        if (this.isQuantitySelectAlreadyInitialized === false) {
            this.isQuantitySelectAlreadyInitialized = true;
        }
    }

    /**
     * Removes the events for the quantity input selects
     *
     * @public
     */
    public removeQuantitySelectEvents(): void {
        this.quantitySelect.removeEventListener('change', debounce(
            () => this.onQuantityChange(),
            200,
        ));

        this.isQuantitySelectAlreadyInitialized = false;
    }

    /**
     * Quantity change handler for the product items
     *
     * @private
     */
    private onQuantityChange(): void {
        if (this.quantityValue > 0) {
            this.addProduct(true);
        } else {
            this.removeProduct();
        }
    }

    /**
     * Function to reset the quantity input select and the overlay of the quantity selection
     */
    public resetConfiguratorProductItem() {
        this.overlay.classList.remove('is-hidden');
        this.classList.remove('is-selected');
        this.resetQuantitySelect();
    }

    /**
     * Function to dispatch the add product event
     *
     * @param isUpdate
     *
     * @private
     */
    private addProduct(isUpdate: boolean = false): void {
        this.dispatchCustomEvent('addProduct', {
            sku: this.sku,
            step: this.step,
            quantity: this.quantityValue || 1,
            isUpdate: isUpdate,
        });
    }

    /**
     * Function to dispatch the remove product event
     *
     * @private
     */
    private removeProduct(): void {
        this.dispatchCustomEvent('removeProduct', {
            sku: this.sku,
        });
    }

    /**
     * Getter for the current value of the quantity input select
     *
     * @public
     */
    get quantityValue(): Number {
        return parseInt(this.quantitySelect.quantityInputField.value, 10);
    }

    /**
     * Getter for the current step name
     *
     * @public
     */
    get step(): string {
        return this.dataset.step;
    }

    /**
     * Getter for the sku of the product item
     *
     * @public
     */
    get sku(): string {
        return this.dataset.sku;
    }

    /**
     * Function to reset the quantity input select after the product got deselected
     *
     * @public
     */
    private resetQuantitySelect(): void {
        this.quantitySelect.quantityInputField.setAttribute('current-value', '1');
        this.quantitySelect.quantityInputField.value = '1';
        this.quantitySelect.currentValue = 1;
        this.quantitySelect.quantitySelectField.value = '1';
    }

    /**
     * Function to dispatch the add and remove product events
     *
     * @param name
     * @param detail
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected dispatchCustomEvent(name: string, detail: any = {}): void {
        const customEvent = new CustomEvent(name, { detail });
        document.dispatchEvent(customEvent);
    }
}
