import Component from 'ShopUi/models/component';
import AjaxProvider from 'ShopUi/components/molecules/ajax-provider/ajax-provider';
import debounce from 'lodash-es/debounce';
import throttle from 'lodash-es/throttle';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import OverlayBlock from '../../atoms/overlay-block/overlay-block';
// eslint-disable-next-line max-len
import Ga4EventTracking from '../../../../../../GA4EventTracking/Theme/default/components/molecules/ga4-event-tracking/ga4-event-tracking';

interface keyCodes {
    [keyCode: number]: string;
}

export default class SuggestSearch extends Component {
    readonly keyboardCodes: keyCodes;

    readonly overlay: OverlayBlock;

    searchInput: HTMLInputElement;

    suggestionsContainer: HTMLElement;

    ajaxProvider: AjaxProvider;

    currentSearchValue: string;

    hint: string;

    navigation: HTMLElement[];

    activeItemIndex: number;

    navigationActiveClass: string;

    googleEventTagHandler: GoogleTagEvents;

    ga4EventTracking: Ga4EventTracking;

    constructor() {
        super();

        this.keyboardCodes = {
            9: 'tab',
            13: 'enter',
            37: 'arrowLeft',
            38: 'arrowUp',
            39: 'arrowRight',
            40: 'arrowDown',
        };
        this.activeItemIndex = 0;
        this.overlay = <OverlayBlock>(
            document.querySelector(this.overlaySelector)
        );
    }

    protected init(): void {
        this.ajaxProvider = <AjaxProvider>(
            this.querySelector(`.${this.jsName}__ajax-provider`)
        );
        this.suggestionsContainer = <HTMLElement>(
            this.querySelector(`.${this.jsName}__container`)
        );
        this.searchInput = <HTMLInputElement>(
            document.querySelector(this.searchInputSelector)
        );
        this.navigationActiveClass = `${this.name}__item--active`;
        this.googleEventTagHandler = document.querySelector('.google-tag-events');
        this.ga4EventTracking = document.querySelector('.ga4-event-tracking');
        this.mapEvents();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    protected mapEvents(): void {
        this.searchInput.addEventListener(
            'keyup',
            debounce(
                () => this.onInputKeyUp(),
                this.debounceDelay,
            ),
        );
        this.searchInput.addEventListener(
            'keydown',
            throttle(
                (event: Event) => this.onInputKeyDown(<KeyboardEvent>event),
                this.throttleDelay,
            ),
        );
        this.searchInput.addEventListener(
            'blur',
            debounce(
                () => this.onInputFocusOut(),
                this.debounceDelay,
            ),
        );
        this.searchInput.addEventListener('focus', () => this.onInputFocusIn());
        this.searchInput.addEventListener('click', () => this.onInputClick());
    }

    protected async onInputKeyUp(): Promise<void> {
        const suggestQuery = this.getSearchValue();

        if (
            suggestQuery !== this.currentSearchValue &&
            suggestQuery.length >= this.lettersTrashold
        ) {
            this.saveCurrentSearchValue(suggestQuery);

            await this.getSuggestions();
            const elements = <HTMLElement[]> Array.from(
                document.querySelectorAll(Ga4EventTracking.eventTriggerSelector),
            );
            elements.forEach((element) => {
                element.addEventListener('click', () => {
                    this.ga4EventTracking.createAndPushEvent(element);
                });
            });
        }

        this.saveCurrentSearchValue(suggestQuery);

        if (suggestQuery.length < this.lettersTrashold) {
            this.hideSugestions();
        }
    }

    protected onInputKeyDown(event: KeyboardEvent): void {
        const { keyCode } = event;

        switch (this.keyboardCodes[keyCode]) {
            case 'enter':
                this.onEnter(event);
                break;
            case 'tab':
                this.onTab(event);
                break;
            case 'arrowUp':
                this.onArrowUp();
                break;
            case 'arrowDown':
                this.onArrowDown();
                break;
            case 'arrowLeft':
                this.onArrowLeft();
                break;
            case 'arrowRight':
                this.onArrowRight();
                break;
            default:
                break;
        }
    }

    protected onInputClick(): void {
        this.activeItemIndex = 0;
        if (this.isNavigationExist()) {
            this.updateNavigation();
            this.showSugestions();
        }
    }

    protected onTab(event: KeyboardEvent): boolean {
        this.searchInput.value = this.hint;
        event.preventDefault();
        return false;
    }

    protected onArrowUp() {
        this.activeItemIndex = this.activeItemIndex > 0 ? this.activeItemIndex - 1 : 0;
        this.updateNavigation();
    }

    protected onArrowDown() {
        this.activeItemIndex = this.activeItemIndex < this.navigation.length ?
            this.activeItemIndex + 1 :
            0;
        this.updateNavigation();
    }

    protected onArrowLeft() {
        this.activeItemIndex = 1;
        this.updateNavigation();
    }

    protected onArrowRight(): void {
        this.activeItemIndex = this.getFirstProductNavigationIndex() + 1;
        this.updateNavigation();
    }

    protected onEnter(event: KeyboardEvent): void {
        let activeItem = null;
        if (this.isNavigationExist()) {
            activeItem = this.getActiveNavigationItem();
        }

        if (activeItem) {
            activeItem.click();
            event.preventDefault();
        }
    }

    protected onInputFocusIn(): void {
        this.activeItemIndex = 0;
    }

    protected onInputFocusOut(): void {
        this.hideSugestions();
    }

    protected getActiveNavigationItem(): HTMLElement {
        return this.navigation[this.activeItemIndex - 1];
    }

    protected getFirstProductNavigationIndex(): number {
        return this.navigation.findIndex(
            // eslint-disable-next-line arrow-body-style
            (element: HTMLElement): boolean => {
                return element.classList.contains(
                    `${this.jsName}__product-item--navigable`,
                );
            },
        );
    }

    protected getNavigation(): HTMLElement[] {
        return <HTMLElement[]>(
            Array.from(
                this.getElementsByClassName(`${this.jsName}__item--navigable`),
            )
        );
    }

    protected updateNavigation(): void {
        if (this.isNavigationExist()) {
            this.navigation.forEach((element) => {
                element.classList.remove(this.navigationActiveClass);
            });
            if (this.activeItemIndex > this.navigation.length) {
                this.activeItemIndex = 0;
                this.searchInput.focus();
                return;
            }
            if (this.activeItemIndex > 0) {
                this.navigation[this.activeItemIndex - 1].classList.add(
                    this.navigationActiveClass,
                );
            }
        }
    }

    protected isNavigationExist(): boolean {
        return this.navigation && Boolean(this.navigation.length);
    }

    protected getSearchValue(): string {
        return this.searchInput.value.trim();
    }

    protected initGoogleTagEvents(container: HTMLElement): void {
        if (this.googleEventTagHandler !== null) {
            this.googleEventTagHandler.mapEvents(
                Array.from(
                    container.querySelectorAll(
                        '[data-event-name][data-event-data]:not([data-event-type="impression"])'
                    )
                )
            );
        }
    }

    protected async getSuggestions(): Promise<void> {
        if (window.innerWidth >= this.overlayBreakpoint) {
            const suggestQuery = this.getSearchValue();

            this.ajaxProvider.queryParams.set('q', suggestQuery);

            const response = await this.ajaxProvider.fetch(suggestQuery);

            const suggestions = JSON.parse(response).suggestion;
            this.suggestionsContainer.innerHTML = suggestions;
            const container = this.suggestionsContainer;
            this.initGoogleTagEvents(container);

            this.hint = JSON.parse(response).completion;

            if (suggestions) {
                this.showSugestions();
            }

            this.navigation = this.getNavigation();

            this.updateNavigation();
        }
    }

    showSugestions(): void {
        this.suggestionsContainer.classList.remove('is-hidden');
        this.searchInput.classList.add(`${this.name}__input--active`);

        if (window.innerWidth >= this.overlayBreakpoint) {
            this.overlay.showOverlay('no-search', 'no-search');
        }
    }

    hideSugestions(): void {
        this.suggestionsContainer.classList.add('is-hidden');
        this.searchInput.classList.remove(`${this.name}__input--active`);

        if (window.innerWidth >= this.overlayBreakpoint) {
            this.overlay.hideOverlay('no-search', 'no-search');
        }
    }

    protected saveCurrentSearchValue(suggestQuery: string): void {
        this.currentSearchValue = suggestQuery;
    }

    get debounceDelay(): number {
        return Number(this.getAttribute('debounce-delay'));
    }

    get throttleDelay(): number {
        return Number(this.getAttribute('throttle-delay'));
    }

    get lettersTrashold(): number {
        return Number(this.getAttribute('letters-trashold'));
    }

    get searchInputSelector(): string {
        return <string> this.getAttribute('input-selector');
    }

    // eslint-disable-next-line class-methods-use-this
    get overlaySelector(): string {
        return '.js-overlay-block';
    }

    get overlayBreakpoint(): number {
        return Number(this.getAttribute('overlay-breakpoint'));
    }
}
