import EcommerceWithCartSumAndCoupon, {
    EcommerceWithCartSumAndCouponDataInterface
} from './ecommerce-with-cart-sum-and-coupon';

/* eslint-disable camelcase */

export interface EcommerceWithPurchaseInfoDataInterface extends EcommerceWithCartSumAndCouponDataInterface {
    transaction_id: string;
    affiliation: string|null;
    tax: string;
    shipping: string;
}

export default class EcommerceWithPurchaseInfo extends EcommerceWithCartSumAndCoupon {
    transaction_id: string;
    affiliation: string|null;
    tax: string;
    shipping: string;

    constructor(data: EcommerceWithPurchaseInfoDataInterface) {
        super(data);

        this.transaction_id = data.transaction_id;
        this.affiliation = data.affiliation;
        this.tax = data.tax;
        this.shipping = data.shipping;
    }
}
/* eslint-enable camelcase */
