import Component from 'ShopUi/models/component';

export default class TouchChecker extends Component {
    protected readyCallback(): void {
        this.touchInspectionInit();
    }

    // eslint-disable-next-line class-methods-use-this
    protected touchInspectionInit(): void {
        const isTouch = 'ontouchstart' in window;

        if (isTouch) {
            document.body.classList.add('is-touch');
        }
    }
}
