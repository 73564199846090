import EventDataTransfer from '../event-data-transfer';
import Ga4EcommerceEvent from '../events/ecommerce-types/ga4-ecommerce-event';
import Ga4EcommerceEventWithProductItemMapper from './ga4-ecommerce-event-with-product-item-mapper';
import Ga4EcommerceBeginCheckoutEvent, {
    Ga4EcommerceBeginCheckoutEventDataInterface
} from '../events/ecommerce-types/ga4-ecommerce-begin-checkout-event';

/* eslint-disable camelcase */
export default class Ga4EcommerceEventBeginCheckoutMapper extends Ga4EcommerceEventWithProductItemMapper {
    public map(transfer: EventDataTransfer): Ga4EcommerceEvent {
        return this.mapCustomSubtype({
            event_name: transfer.eventName,
            cart_type: transfer.data.cart_type,
            ecommerce: this.mapEcommerceSubtype(transfer.data.ecommerce)
        });
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(data: Ga4EcommerceBeginCheckoutEventDataInterface): Ga4EcommerceEvent {
        return new Ga4EcommerceBeginCheckoutEvent(data);
    }
}
/* eslint-enable camelcase */
