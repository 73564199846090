import Component from 'ShopUi/models/component';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';

export default class OverviewForm extends Component {
    private checkbox: HTMLInputElement;

    private gtmEventData: JSON;

    private gtmEventHandler: GoogleTagEvents;

    /**
     * @inheritDoc
     *
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /* eslint-disable no-empty-function */
    }

    protected init(): void {
        this.checkbox = <HTMLInputElement> this.querySelector(
            '.myData__settings-form .checkbox__input[data-event-data]',
        );
        this.gtmEventHandler = this.getGoogleTagEventHandler;
        if (this.checkbox === null || typeof this.gtmEventHandler === 'undefined' || this.gtmEventHandler === null) {
            return;
        }
        this.gtmEventData = <JSON> this.gtmEventHandler.getGtmEventData(this.checkbox);
        this.mapEvents();
    }

    private mapEvents(): void {
        this.checkbox.addEventListener('change', () => this.fireEvents());
    }

    private fireEvents(): void {
        if (typeof this.gtmEventHandler !== 'undefined' && this.gtmEventHandler !== null) {
            const eventData = this.gtmEventData;

            /* eslint-disable dot-notation */
            if (this.checkbox.checked) {
                eventData['eventLabel'] = this.gtmEventData['eventLabel']
                    .replace('%state%', 'on')
                    .replace('off', 'on');
            } else {
                eventData['eventLabel'] = this.gtmEventData['eventLabel']
                    .replace('%state%', 'off')
                    .replace('on', 'off');
            }
            /* eslint-enable dot-notation */

            const event = this.gtmEventHandler.createGaEvent(eventData);
            this.gtmEventHandler.pushEvent(<any> event);
        }
    }

    // eslint-disable-next-line class-methods-use-this
    get getGoogleTagEventHandler(): GoogleTagEvents {
        return <GoogleTagEvents> document.querySelector('.google-tag-events');
    }
}
