import Component from 'ShopUi/models/component';
import $ from 'jquery/dist/jquery';
import 'slick-carousel';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import Icon from 'src/BestIt/ShopUi/components/atoms/icon/icon';
import SvgIcon from 'src/BestIt/ShopUi/components/atoms/svg-icon/svg-icon';

export default class SlickCarousel extends Component {
    slider: HTMLElement

    prevArrow: HTMLElement

    nextArrow: HTMLElement

    gtmEventHandler: GoogleTagEvents;

    private icons: Icon[];

    private svgIconHandler: SvgIcon;

    public isInitialized: boolean = false;

    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    public initialize(): void {
        this.slider = <HTMLElement> this.querySelector(`.${this.jsName}__container`);
        this.gtmEventHandler = this.getGoogleTagEventHandler;
        this.mapEvents();
        if (this.initSliderOnWindowLoad) {
            this.sliderInit();
        }
        this.prevArrow = <HTMLElement> this.querySelector('.nav-arrow--prev');
        this.nextArrow = <HTMLElement> this.querySelector('.nav-arrow--next');
        this.gtmEvents();

        this.isInitialized = true;
    }

    protected mapEvents(): void {
        $(this.slider).on('init', () => this.showSlider());

        $(window).on('resize', () => this.resizeSlider());
    }

    private gtmEvents(): void {
        if (typeof this.gtmEventHandler !== 'undefined' &&
            this.gtmEventHandler !== null &&
            typeof this.prevArrow !== 'undefined' &&
            this.prevArrow !== null &&
            typeof this.nextArrow !== 'undefined' &&
            this.nextArrow !== null
        ) {
            $(this.prevArrow).on('click', () => this.triggerGaEvent());

            $(this.nextArrow).on('click', () => this.triggerGaEvent());
        }
    }

    protected showSlider(): void {
        this.slider.classList.add(`${this.name}__container--is-inited`);
    }

    public resizeSlider(): void {
        if (!$(this.slider).hasClass('slick-initialized')) {
            $(this.slider).slick(
                this.sliderConfig,
            );
        }
    }

    private triggerGaEvent(): void {
        if (typeof this.getSlickSliderGtmEventData() !== 'undefined' &&
            this.getSlickSliderGtmEventData() !== null
        ) {
            const slickCarouselGaEvent = this.gtmEventHandler.createGaEvent(this.getSlickSliderGtmEventData());
            this.gtmEventHandler.pushEvent(slickCarouselGaEvent);
        }
    }

    public sliderInit(): void {
        if (this.slider !== null) {
            $(this.slider).slick(
                this.sliderConfig,
            );

            this.icons = <Icon[]> Array.from(
                this.slider.querySelectorAll('.icon'),
            );
            this.svgIconHandler = <SvgIcon> document.querySelector('.svg-icon');

            if (typeof this.icons !== 'undefined' &&
                this.icons !== null &&
                typeof this.svgIconHandler !== 'undefined' &&
                this.svgIconHandler !== null
            ) {
                const { iconElementSelector } = this.svgIconHandler;

                if (iconElementSelector !== '') {
                    this.icons.forEach((icon: Icon) => {
                        icon.initializeCustomElement(iconElementSelector, {
                            fetchPattern: '/assets/%NAMESPACE%/%PACK%/%SYMBOL%.svg',
                            createIntersectionObserver: false,
                        }, {}, false);
                    });
                }
            }
        }
    }

    public sliderReinit(): void {
        this.sliderTerminate();
        this.sliderInit();
    }

    public sliderTerminate(): void {
        $(this.slider).slick('unslick');
    }

    get sliderConfig(): object {
        return JSON.parse(this.getAttribute('slider-config'));
    }

    get initSliderOnWindowLoad(): boolean {
        return this.getAttribute('data-init-slider') === '1' || true;
    }

    // eslint-disable-next-line class-methods-use-this
    get getGoogleTagEventHandler(): GoogleTagEvents {
        return <GoogleTagEvents> document.querySelector('.google-tag-events');
    }

    private getSlickSliderGtmEventData(): object {
        const gtmEventData = JSON.parse(this.getAttribute('sick-slider-ga-event-data'));

        return gtmEventData || undefined;
    }
}
