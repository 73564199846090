import Component from 'ShopUi/models/component';
import ModalWindow from 'src/BestIt/ShopUi/components/molecules/modal-window/modal-window';

export default class TrackView extends Component {
    /**
     * The modal window where the track is edited
     *
     * @protected
     */
    protected editTrackModalWindow: ModalWindow;

    /**
     * All active tooltip modal windows
     *
     * @protected
     */
    protected tooltipModalWindows: ModalWindow[];

    /**
     * @inheritDoc
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * @public
     *
     * @return void
     */
    public initialize(): void {
        this.editTrackModalWindow = <ModalWindow> document.querySelector(`.${this.jsName}__modal-edit-track`);
        if (typeof this.editTrackModalWindow !== 'undefined' && this.editTrackModalWindow !== null) {
            this.editTrackModalWindow.addEventListener('closed', (event) => {
                this.onEditTrackClosed(event);
            });
        }
    }

    /**
     * Is called when the edit track modal window is closed
     *
     * @param event
     * @private
     *
     * @return void
     */
    private onEditTrackClosed(event: Event): void {
        this.tooltipModalWindows = <ModalWindow[]>Array.from(document.querySelectorAll(
            '.modal-window--track-configurator.tooltip-button__modal-window.modal-window__active',
        ));
        this.tooltipModalWindows.forEach((modal: ModalWindow) => {
            modal.closeModal(event);
        });
    }
}
