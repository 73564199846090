import Component from 'ShopUi/models/component';
import debounce from 'lodash-es/debounce';

export default class ProductConfigurator extends Component {
    private sidebars: HTMLElement[];

    protected readyCallback(): void {
        const sidebarWrapper = this.querySelector(`.${this.name}__sidebar`);

        if (typeof sidebarWrapper !== 'undefined' && sidebarWrapper !== null) {
            this.sidebars = <HTMLElement[]> Array.from(sidebarWrapper.children);
        }

        this.mapEvents();
    }

    private mapEvents(): void {
        if (typeof this.sidebars !== 'undefined' && this.sidebars !== null) {
            window.addEventListener(
                'load',
                debounce(
                    () => this.hideSidebarOnLoad(),
                    this.getFadeOutDebounce(),
                ),
            );
            this.sidebars.forEach((element) => {
                this.addSidebarElementEvent(element);
            });
        }
    }

    // eslint-disable-next-line class-methods-use-this
    private addSidebarElementEvent(sidebarElement: HTMLElement): void {
        sidebarElement.addEventListener('mouseenter', (event: Event) => ProductConfigurator.triggerAnimation(
            sidebarElement,
            event.type,
        ));

        sidebarElement.addEventListener('mouseleave', (event: Event) => ProductConfigurator.triggerAnimation(
            sidebarElement,
            event.type,
        ));

        sidebarElement.addEventListener('click', (event: Event) => ProductConfigurator.triggerAnimation(
            sidebarElement,
            event.type,
        ));
    }

    private hideSidebarOnLoad(): void {
        this.sidebars.forEach((element) => {
            ProductConfigurator.triggerAnimation(element);
        });
    }

    private static triggerAnimation(target: HTMLElement, eventType: string = ''): void {
        if (target.classList.contains('show')) {
            if (eventType === 'click' && ProductConfigurator.getTriggeredWith(target) === 'click') {
                target.setAttribute('data-triggered-with', 'mouseenter');
                return;
            }

            if (eventType === 'click' || ProductConfigurator.getTriggeredWith(target) === 'click') {
                target.setAttribute('data-triggered-with', 'click');
                return;
            }

            target.classList.remove('show');
            return;
        }

        target.classList.add('show');
        target.setAttribute('data-triggered-with', eventType);
    }

    private getFadeOutDebounce(): number {
        return parseInt(this.getAttribute('fade-out-debounce'), 10);
    }

    private static getTriggeredWith(target: HTMLElement): string {
        return target.getAttribute('data-triggered-with') || '';
    }
}
