import CustomSubtypeInterface from './custom-subtype-interface';
import MaxLengthStringValue from '../validator-types/max-length-string-value';

/* eslint-disable camelcase */
export interface FindOfflineDealerDataInterface {
    radius: string;
}

export default class FindOfflineDealer implements CustomSubtypeInterface {
    radius: string;

    constructor(data: FindOfflineDealerDataInterface) {
        this.radius = new MaxLengthStringValue(data.radius).value;
    }
}
/* eslint-enable camelcase */
