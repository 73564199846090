/* eslint-disable no-use-before-define */
interface Part {
    type: string,
    name: string,
    transparent?: boolean,
    options?: any,
    active?: boolean,
    chip?: Chip,
    lightcone?: Lightcone,
}
/* eslint-enable no-use-before-define */

interface Lightcone {
    type: string,
    active?: boolean,
    options?: any,
}

interface Chip {
    type: string,
    active?: boolean,
    options?: any,
}

export interface ConfiguratorOption {
    'abstract_sku'?: string,
    'diameter_cm_sp_dimension'?: string,
    'cut_out_diameter_cm_sp_dimension'?: string,
    'variant_wattage'?: string,
    'variant_light_color'?: string,
    'variant_beam_angle'?: string,
    'dimm_options_sp_fixture_data_new'?: string,
    'sku_accessory_driver'?: string,
    'sku_accessory_diffusor'?: string,
    'sku_accessory_mounting_frame'?: string,
    'optical_frontring'?: string,
}

export default class ProductConfiguratorConfig {
    private filepath: string = '';

    /* eslint-disable camelcase */
    private options = {
        downlight: {
            MOD_NUMINOS_DL: {
                abstract_sku: 'MOD_NUMINOS_DL',
                cut_out_diameter_cm_sp_dimension: '5.5',
                variant_wattage: '7',
                variant_light_color: '2700',
                variant_beam_angle: '20',
                concrete_sku: 'null',
                sku_accessory_driver: 'null',
                sku_accessory_diffusor: 'null',
                sku_accessory_mounting_frame: 'null',
            },
            MOD_NUMINOS_MOVE_DL: {
                abstract_sku: 'MOD_NUMINOS_MOVE_DL',
                cut_out_diameter_cm_sp_dimension: '6.8',
                variant_wattage: '7',
                variant_light_color: '2700',
                variant_beam_angle: '20',
                concrete_sku: 'null',
                sku_accessory_driver: 'null',
                sku_accessory_diffusor: 'null',
                sku_accessory_mounting_frame: 'null',
            },
            MOD_NUMINOS_GIMBLE: {
                abstract_sku: 'MOD_NUMINOS_GIMBLE',
                cut_out_diameter_cm_sp_dimension: '6.8',
                variant_wattage: '7',
                variant_light_color: '2700',
                variant_beam_angle: '20',
                concrete_sku: 'null',
                sku_accessory_driver: 'null',
                sku_accessory_diffusor: 'null',
                sku_accessory_mounting_frame: 'null',
            },
            MOD_NUMINOS_PROJECTOR_CL: {
                abstract_sku: 'MOD_NUMINOS_PROJECTOR_CL',
                diameter_cm_sp_dimension: '4.9',
                variant_wattage: '7',
                variant_light_color: '2700',
                variant_beam_angle: '20',
                concrete_sku: 'null',
                sku_accessory_driver: 'null',
                sku_accessory_diffusor: 'null',
                sku_accessory_mounting_frame: 'null',
            },
        },
        spotlight: {
            MOD_NUMINOS_CL: {
                abstract_sku: 'MOD_NUMINOS_CL',
                diameter_cm_sp_dimension: '6.5',
                variant_wattage: '10',
                variant_light_color: '2700',
                variant_beam_angle: '24',
                dimm_options_sp_fixture_data_new: 'Dali',
                concrete_sku: 'null',
                optical_frontring: '0',
            },
            MOD_NUMINOS_PD: {
                abstract_sku: 'MOD_NUMINOS_PD',
                diameter_cm_sp_dimension: '6.5',
                variant_wattage: '10',
                variant_light_color: '2700',
                variant_beam_angle: '24',
                dimm_options_sp_fixture_data_new: 'Dali',
                concrete_sku: 'null',
                optical_frontring: '0',
            },
            MOD_NUMINOS_TRACK: {
                abstract_sku: 'MOD_NUMINOS_TRACK',
                diameter_cm_sp_dimension: '6.5',
                variant_wattage: '10',
                variant_light_color: '2700',
                variant_beam_angle: '24',
                dimm_options_sp_fixture_data_new: 'Dali',
                concrete_sku: 'null',
                optical_frontring: '0',
            },
            MOD_NUMINOS_SPOT: {
                abstract_sku: 'MOD_NUMINOS_SPOT',
                diameter_cm_sp_dimension: '6.5',
                variant_wattage: '10',
                variant_light_color: '2700',
                variant_beam_angle: '24',
                dimm_options_sp_fixture_data_new: 'Dali',
                concrete_sku: 'null',
                optical_frontring: '0',
            },
            MOD_NUMINOS_ZOOM: {
                abstract_sku: 'MOD_NUMINOS_ZOOM',
                diameter_cm_sp_dimension: '8.5',
                variant_wattage: '20',
                variant_light_color: '2700',
                variant_beam_angle: '24',
                dimm_options_sp_fixture_data_new: 'Dali',
                concrete_sku: 'null',
                optical_frontring: '0',
            },
            MOD_NUMINOS_48V_TRACK_DALI_SP: {
                abstract_sku: 'MOD_NUMINOS_48V_TRACK_DALI_SP',
                diameter_cm_sp_dimension: '5',
                variant_wattage: '8.7',
                variant_light_color: '2700',
                variant_beam_angle: '20',
                dimm_options_sp_fixture_data_new: 'Dali',
                concrete_sku: 'null',
                optical_frontring: '0',
                sku_accessory_diffusor: 'null',
            },
            MOD_NUMINOS_48V_TRACK_DALI_PD: {
                abstract_sku: 'MOD_NUMINOS_48V_TRACK_DALI_PD',
                diameter_cm_sp_dimension: '5',
                variant_wattage: '8.7',
                variant_light_color: '2700',
                variant_beam_angle: '20',
                dimm_options_sp_fixture_data_new: 'Dali',
                concrete_sku: 'null',
                optical_frontring: '0',
                sku_accessory_diffusor: 'null',
            },
        },
    };
    /* eslint-enable camelcase */

    private schema = {
        downlight: [
            {
                type: 'housing',
                name: 'Numinos Gehäuse',
                options: ['abstract_sku'],
                chip: {
                    type: 'chip',
                    optionsForSku: {
                        MOD_NUMINOS_PROJECTOR_CL: ['abstract_sku'],
                    },
                    active: true,
                },
            },
            {
                type: 'reflector',
                name: 'Reflektor',
                transparent: true,
                lightcone: {
                    type: 'lightcone',
                    options: ['variant_wattage', 'variant_light_color', 'variant_beam_angle'],
                },
            },
            {
                type: 'ring',
                name: 'Decoration Ring',
                transparent: true,
            },
        ],
        spotlight: [
            {
                type: 'housing',
                name: 'Numinos Gehäuse',
                options: ['abstract_sku'],
                chip: {
                    type: 'chip',
                    active: true,
                },
            },
            {
                type: 'lens',
                name: 'Lens',
                options: ['abstract_sku'],
                transparent: true,
                lightcone: {
                    type: 'lightcone',
                    options: ['variant_wattage', 'variant_light_color', 'variant_beam_angle'],
                },
            },
            {
                type: 'ring',
                name: 'Decoration Ring',
                options: ['abstract_sku'],
                transparent: true,
            },
        ],
    };

    private ratios = {
        MOD_NUMINOS_DL: '73.4',
        MOD_NUMINOS_MOVE_DL: '85.7',
        MOD_NUMINOS_GIMBLE: '85.7',
        MOD_NUMINOS_CL: '42.9',
        MOD_NUMINOS_PD: '42.9',
        MOD_NUMINOS_TRACK: '42.9',
        MOD_NUMINOS_SPOT: '42.9',
        MOD_NUMINOS_ZOOM: '42.9',
        MOD_NUMINOS_PROJECTOR_CL: '42.9',
        MOD_NUMINOS_48V_TRACK_DALI_SP: '42.9',
        MOD_NUMINOS_48V_TRACK_DALI_PD: '42.9',
    };

    private rightRoomViewOffset = {
        MOD_NUMINOS_DL: '0',
        MOD_NUMINOS_MOVE_DL: '0',
        MOD_NUMINOS_GIMBLE: '0',
        MOD_NUMINOS_CL: '0',
        MOD_NUMINOS_PD: '0',
        MOD_NUMINOS_TRACK: '0',
        MOD_NUMINOS_SPOT: '0',
        MOD_NUMINOS_ZOOM: '0',
        MOD_NUMINOS_PROJECTOR_CL: '0',
        MOD_NUMINOS_48V_TRACK_DALI_SP: '0',
        MOD_NUMINOS_48V_TRACK_DALI_PD: '0',
    };

    private steps = {
        downlight: [
            {
                name: 'technical',
                requiredFields: [
                    'abstract_sku',
                    'variant_wattage',
                    'variant_light_color',
                    'variant_beam_angle',
                ],
                activeParts: [
                    'housing',
                    'chip',
                    'reflector',
                    'lightcone',
                ],
            },
            {
                name: 'optical',
                requiredFields: [
                    'concrete_sku',
                ],
                activeParts: [
                    'housing',
                    'ring',
                ],
            },
            {
                name: 'accessories',
                requiredFields: [],
            },
            {
                name: 'summary',
                requiredFields: [],
            },
        ],
        spotlight: [
            {
                name: 'technical',
                requiredFields: [
                    'abstract_sku',
                    'variant_wattage',
                    'variant_light_color',
                    'variant_beam_angle',
                    'dimm_options_sp_fixture_data_new',
                ],
                requiredFieldsException: [
                    {
                        field: 'variant_beam_angle',
                        abstractSku: 'MOD_NUMINOS_ZOOM',
                    },
                    {
                        field: 'dimm_options_sp_fixture_data_new',
                        abstractSku: 'MOD_NUMINOS_48V_TRACK_DALI_SP',
                    },
                    {
                        field: 'dimm_options_sp_fixture_data_new',
                        abstractSku: 'MOD_NUMINOS_48V_TRACK_DALI_PD',
                    }
                ],
                activeParts: [
                    'housing',
                    'chip',
                    'lens',
                    'lightcone',
                ],
            },
            {
                name: 'optical',
                requiredFields: [
                    'concrete_sku',
                ],
                activeParts: [
                    'housing',
                ],
            },
            {
                name: 'summary',
                requiredFields: [
                ],
            },
        ],
    };

    public getFilepath(): string {
        return this.filepath;
    }

    public getActiveParts(type: string, currentStep: string): string[] {
        let activeParts = [];

        if (!this.steps[type]) {
            console.warn('No configuration found for given type.');
            return activeParts;
        }

        this.steps[type].forEach((step) => {
            if (step.name === currentStep && step.activeParts) {
                activeParts = step.activeParts;
            }
        });

        return activeParts;
    }

    public getNextStep(type: string, currentStep: string): string {
        if (!this.steps[type]) {
            console.warn('No configuration found for given type.');
            return '';
        }

        let isCurrentStep = false;
        let nextStep = currentStep;

        this.steps[type].forEach((step) => {
            // Return the name of the next Step
            if (isCurrentStep) {
                nextStep = step.name;
                isCurrentStep = false;
            }

            if (step.name === currentStep) {
                isCurrentStep = true;
            }
        });

        // If there is no next Step -> Return current Step
        return nextStep;
    }

    public getRequiredFields(type: string, currentStep: string, abstractSku: string): string[] {
        let requiredFields = [];

        if (!this.steps[type]) {
            console.warn('No configuration found for given type.');
            return requiredFields;
        }
        this.steps[type].forEach((step) => {
            if (step.name === currentStep) {
                if (step.requiredFieldsException) {
                    step.requiredFieldsException.forEach((fieldException) => {
                        if (fieldException.abstractSku === abstractSku) {
                            // eslint-disable-next-line arrow-body-style
                            step.requiredFields = step.requiredFields.filter((value) => {
                                return value !== fieldException.field;
                            });
                        }
                    });
                }
                requiredFields = step.requiredFields;
            }
        });

        return requiredFields;
    }

    public getOptions(type: string, abstractSku?: string): ConfiguratorOption {
        if (!this.options[type]) {
            console.warn('No configuration found for given series.');
            return {};
        }

        if (!abstractSku || !this.options[type][abstractSku]) {
            console.warn('No configuration found for given series. Basic Configuration for Type returned');
            return Object.values(this.options[type])[0];
        }

        return this.options[type][abstractSku];
    }

    public getDefaultOptions(type: string): ConfiguratorOption {
        return this.getOptions(type);
    }

    public getRatio(sku: string): string {
        if (!this.ratios[sku]) {
            console.warn('No ratio found for given product.');
            return '100';
        }

        return this.ratios[sku];
    }

    public getSchema(type: string): Array<Part> {
        if (!this.schema[type]) {
            console.warn('No schema found for given type.');
            return [];
        }

        return this.schema[type];
    }

    public getRightRoomViewOffset(abstractSku: string): string {
        if (!this.rightRoomViewOffset[abstractSku]) {
            console.warn('No schema found for given type.');
            return '0px';
        }

        return `${this.rightRoomViewOffset[abstractSku]}px`;
    }
}
