import Component from 'ShopUi/models/component';

export default class FormHandler extends Component {
    readonly event: string;

    readonly triggers: HTMLElement[];

    readonly form: HTMLFormElement;

    constructor() {
        super();
        this.event = <string> this.getAttribute('event');
        this.triggers = <HTMLElement[]>Array.from(document.querySelectorAll(this.triggerSelector));
        this.form = <HTMLFormElement>document.querySelector(this.formSelector);
    }

    protected init(): void {
        this.mapEvents();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    protected mapEvents(): void {
        this.triggers.forEach((trigger: HTMLElement) => trigger.addEventListener(
            this.event, (event: Event) => this.onTriggerEvent(event)
        ));
    }

    protected onTriggerEvent(event: Event): void {
        const trigger = <HTMLElement>event.currentTarget;
        if (this.shouldChangeAction) {
            const newActionName = this.getDataAttribute(trigger, 'data-change-action-to');
            this.form.action = newActionName;
        }
        if (this.shouldSubmitForm) {
            event.preventDefault();
            if (this.shouldDispatchEvent) {
                const submitEvent = new Event('submit');
                this.form.dispatchEvent(submitEvent);
            } else {
                this.form.submit();
            }
        }
    }

    get triggerSelector(): string {
        return this.getAttribute('trigger-selector');
    }

    get formSelector(): string {
        return this.getAttribute('form-selector');
    }

    get shouldSubmitForm(): boolean {
        return this.submitForm === 'true';
    }

    get submitForm(): string {
        return this.getAttribute('submit-form');
    }

    get shouldChangeAction(): boolean {
        return this.changeAction === 'true';
    }

    get changeAction(): string {
        return this.getAttribute('change-action');
    }

    get shouldDispatchEvent(): boolean {
        return this.useDispatchEvent === 'true';
    }

    get useDispatchEvent(): string {
        return this.getAttribute('use-dispatch-event');
    }

    // eslint-disable-next-line class-methods-use-this
    protected getDataAttribute(block: HTMLElement, attr: string): string {
        return block.getAttribute(attr);
    }
}
