import EventNameConstants from '../../../types/event-name-constants';
import Ga4EcommerceEvent from '../../../types/events/ecommerce-types/ga4-ecommerce-event';
import Ga4EcommerceEventWithProductItemMapper
    from '../../../types/event-mappers/ga4-ecommerce-event-with-product-item-mapper';
import Ga4EventMaker from '../../../types/event-makers/ga4-event-maker';
import Ga4EventMakerInterface from '../../../types/event-makers/ga4-event-maker-interface';
import Ga4EventTracking from '../ga4-event-tracking/ga4-event-tracking';

export default class Ga4ImpressionEventTracking extends Ga4EventTracking {
    private trackedProductSkus: string[] = [];

    public createEvents(container: HTMLElement|Document = document): void {
        if (typeof IntersectionObserver === 'undefined') {
            return;
        }

        const elements = <HTMLElement[]> Array.from(
            container.querySelectorAll(Ga4ImpressionEventTracking.eventTriggerSelector),
        ).filter((element: HTMLElement) => {
            const eventName = element.dataset.ga4EventName;

            return EventNameConstants.impressionEventNames.includes(eventName);
        });

        const observer = new IntersectionObserver(
            this.onImpressionEventTrigger.bind(this),
            {
                root: null,
                threshold: 0.5,
            },
        );

        elements.forEach((element) => {
            observer.observe(element);
        });
    }

    protected onImpressionEventTrigger(entries: IntersectionObserverEntry[]): void {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const element = <HTMLElement> entry.target;
                const eventData = Ga4ImpressionEventTracking.getEventData(element);
                const productSku = eventData.items[0].item_id;

                if (this.trackedProductSkus.includes(productSku)) {
                    return;
                }

                this.trackedProductSkus.push(productSku);
                this.createAndPushEvent(element);
            }
        });
    }

    protected static getEventData(element: HTMLElement) {
        return JSON.parse(element.dataset.ga4EventData);
    }

    // eslint-disable-next-line class-methods-use-this
    protected createEventMaker(name: string): Ga4EventMakerInterface {
        switch (name) {
            case EventNameConstants.EVENT_NAME_ECOMMERCE_VIEW_ITEM_LIST:
                return new Ga4EventMaker(
                    Ga4EcommerceEvent,
                    new Ga4EcommerceEventWithProductItemMapper(),
                );
            default:
                throw new Error(`GA4 event ${name} cannot be created.`);
        }
    }
}
