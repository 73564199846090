import Component from 'ShopUi/models/component';
import Vue from 'vue';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import Ga4EventTracking
    from '../../../../../../GA4EventTracking/Theme/default/components/molecules/ga4-event-tracking/ga4-event-tracking';

export default class QuantityInputSelect extends Component {
    vueComponent: Vue;

    input: HTMLInputElement;

    select: HTMLSelectElement;

    gtmEventHandler: GoogleTagEvents;

    ga4EventHandler: Ga4EventTracking;

    public currentValue: number;

    public isInitialized: boolean = false;

    protected getCartType(): string {
        return this.getAttribute('data-event-cartType') || 'regular cart';
    }

    public initialize(): void {
        this.input = <HTMLInputElement> this.querySelector(`.${this.jsName}__input`);
        this.select = <HTMLSelectElement> this.querySelector(`.${this.jsName}__select .select__select`);
        this.gtmEventHandler = this.getGoogleTagEventHandler;
        this.ga4EventHandler = this.getGA4EventHandler;
        this.currentValue = this.getInitialValue;

        if (this.getForm !== null) {
            this.getForm.addEventListener('submit', (event:Event) => this.onFormSubmit(event));
        }

        if (this.getUpdateTarget !== null) {
            this.getUpdateTarget.addEventListener('click', () => this.onUpdateTargetClick());
        }

        this.vueComponent = new Vue({
            el: this,
            name: `${this.name}-${Math.floor(Math.random() * (1000000 + 1))}`,
            data: {
                value: this.getInitialValue,
                initialValue: this.getInitialValue,
                inputChanged: false,
                autoUpdateEnabled: this.autoUpdateEnabled,
                form: this.getForm,
                updateTarget: this.getUpdateTarget,
                component: this,
                googleTagEventHandler: this.gtmEventHandler,
                ga4EventHandler: this.ga4EventHandler,
                currencyCode: this.getCurrencyCode,
            },
            created: function() {
                this.component.markAsMounted();
            },
            computed: {
                isInputVisible() {
                    return this.value >= 10 || this.inputChanged;
                },
                hasInputChanged() {
                    return {
                        visible: this.inputChanged,
                    };
                },
            },
            methods: {
                changeSelectValue(event) {
                    const newValue = event.target.value === 'custom' ? 10 : Number(event.target.value);
                    this.value = newValue;
                    this.component.currentValue = newValue;
                    this.component.dispatchEvent(new Event('change'));
                    this.component.setAttribute('data-new-value', newValue.toString());

                    if (this.updateTarget !== null) {
                        const href = this.updateTarget.href.split('?quantity=');
                        this.updateTarget.setAttribute('href', `${href[0]}?quantity=${this.value}`);
                    }

                    if (this.value >= 10) {
                        this.inputChanged = true;
                    } else if (this.autoUpdateEnabled) {
                        this.$nextTick(() => {
                            if (this.form !== null) {
                                const callback = () => {
                                    const triggerElement = <HTMLElement> event.target;
                                    triggerElement.closest('form').submit();
                                };
                                this.component.pushCartEvents(newValue, callback);
                                this.component.pushGa4CartEvents(newValue);
                            }
                        });
                    }
                },
                changeInputValue(event) {
                    const newValue = Number(event.target.value);
                    this.value = newValue;
                    this.component.currentValue = newValue;
                    this.component.dispatchEvent(new Event('change'));
                    this.component.setAttribute('data-new-value', newValue.toString());

                    if (this.updateTarget !== null) {
                        const href = this.updateTarget.href.split('?quantity=');
                        this.updateTarget.setAttribute('href', `${href[0]}?quantity=${this.value}`);
                    }

                    this.inputChanged = true;
                },
                submitForm(event) {
                    const saveButton = <HTMLButtonElement> event.target;
                    saveButton.closest('form').submit();
                },
                triggerChangeEvent() {
                    this.component.dispatchEvent(new Event('change'));
                },
            },
        });

        this.isInitialized = true;
    }

    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    public pushCartEvents(newValue, callback?) {
        if (this.gtmEventHandler === null || typeof this.gtmEventHandler === 'undefined') {
            this.gtmEventHandler = this.getGoogleTagEventHandler;
        }

        if (this.gtmEventHandler !== null && typeof this.gtmEventHandler !== 'undefined') {
            const googleTagEventData = this.gtmEventHandler.getGtmEventData(this);

            if (typeof googleTagEventData !== 'undefined' && googleTagEventData !== null && googleTagEventData !== '') {
                const eventName = this.getEventName(this.getInitialValue, newValue);
                let cartEvent = null;

                if (eventName === 'EECaddToCart') {
                    cartEvent = this.gtmEventHandler.createAddToCart(
                        googleTagEventData, this.getCurrencyCode, this.getDifference(this.getInitialValue, newValue),
                    );
                } else if (eventName === 'EECremoveFromCart') {
                    cartEvent = this.gtmEventHandler.createRemoveFromCart(
                        googleTagEventData, this.getCurrencyCode, this.getDifference(this.getInitialValue, newValue),
                    );
                }
                if (cartEvent !== null) {
                    const enhancedEcommerceEvent = this.gtmEventHandler.createEnhancedEcommerce(
                        eventName,
                        cartEvent,
                        callback,
                    );

                    enhancedEcommerceEvent.cartType = this.getCartType();

                    this.gtmEventHandler.pushEvent(<any>enhancedEcommerceEvent);
                }
            }
        }
        if (typeof callback === 'function') {
            callback();
        }
    }

    public pushGa4CartEvents(newValue, callback?): void {
        if (this.canPushGa4CartEvents()) {
            this.prepareGa4EventData(this.getInitialValue, newValue);

            this.ga4EventHandler.createAndPushEvent(this);
        }

        if (typeof callback === 'function') {
            callback();
        }
    }

    // eslint-disable-next-line class-methods-use-this
    public prepareGa4EventData(initialValue, newValue): void {
        let ga4Event = '';
        let ga4EventName = '';
        const ga4EventData = JSON.parse(this.dataset.ga4EventData);

        if (initialValue < newValue) {
            ga4Event = 'Ecommerce - add_to_cart';
            ga4EventName = 'add_to_cart';
        } else if (initialValue > newValue) {
            ga4Event = 'Ecommerce - remove_from_cart';
            ga4EventName = 'remove_from_cart';
        }

        this.dataset.ga4Event = ga4Event;
        this.dataset.ga4EventName = ga4EventName;
        ga4EventData.items[0].quantity = this.getDifference(this.getInitialValue, newValue);
        this.dataset.ga4EventData = JSON.stringify(ga4EventData);
    }

    // eslint-disable-next-line class-methods-use-this
    public getDifference(initialValue, newValue) {
        let difference = initialValue - newValue;

        if (difference < 0) {
            difference *= -1;
        }
        return difference;
    }

    // eslint-disable-next-line class-methods-use-this
    public getEventName(initialValue, newValue) {
        let eventName = '';

        if (initialValue < newValue) {
            eventName = 'EECaddToCart';
        } else if (initialValue > newValue) {
            eventName = 'EECremoveFromCart';
        }
        return eventName;
    }

    public onFormSubmit(event: Event) {
        event.preventDefault();
        const newValue = this.getAttribute('data-new-value');

        const callback = () => {
            const target = <HTMLFormElement> event.target;
            target.submit();
        };

        this.pushCartEvents(newValue, callback);
        this.pushGa4CartEvents(newValue);
    }

    public onUpdateTargetClick() {
        const newValue = this.getAttribute('data-new-value');

        this.pushCartEvents(newValue);
        this.pushGa4CartEvents(newValue);
    }

    private canPushGa4CartEvents(): boolean {
        if (this.getGA4EventHandler === null || typeof this.getGA4EventHandler === 'undefined') {
            this.ga4EventHandler = this.getGA4EventHandler;
        }

        if (this.ga4EventHandler === null || typeof this.ga4EventHandler === 'undefined') {
            return false;
        }

        const ga4EventData = JSON.parse(this.dataset.ga4EventData);
        if (typeof ga4EventData === 'undefined' || ga4EventData === null || ga4EventData === '') {
            return false;
        }

        return true;
    }

    get getForm(): HTMLFormElement {
        return <HTMLFormElement> document.querySelector(this.getAttribute('data-form'));
    }

    // eslint-disable-next-line class-methods-use-this
    get getGoogleTagEventHandler(): GoogleTagEvents {
        return <GoogleTagEvents> document.querySelector('.google-tag-events');
    }

    // eslint-disable-next-line class-methods-use-this
    get getGA4EventHandler(): Ga4EventTracking {
        return <Ga4EventTracking> document.querySelector('.ga4-event-tracking');
    }

    get getInitialValue(): number {
        if (this.input === null || typeof this.input === 'undefined') {
            this.input = this.quantityInputField;
        }

        return Number(this.input.getAttribute('current-value')) || 0;
    }

    get getCurrencyCode(): string {
        return this.getAttribute('data-currency') || undefined;
    }

    get autoUpdateEnabled(): boolean {
        if (this.select === null || typeof this.select === 'undefined') {
            this.select = this.quantitySelectField;
        }

        return this.select.dataset.autoUpdate === 'true';
    }

    get getUpdateTarget(): HTMLAnchorElement {
        return <HTMLAnchorElement> document.querySelector(this.input.getAttribute('update-href-selector'));
    }

    get quantityInputField(): HTMLInputElement {
        return <HTMLInputElement> this.querySelector(`.${this.jsName}__input`);
    }

    get quantitySelectField(): HTMLSelectElement {
        return <HTMLSelectElement> this.querySelector(`.${this.jsName}__select .select__select`);
    }

    get uid(): string {
        return this.dataset.uid;
    }
}
