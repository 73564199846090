import AjaxProvider from 'src/BestIt/ShopUi/components/molecules/ajax-provider/ajax-provider';

export default class PartnerLog {
    ajaxProvider: AjaxProvider;

    constructor(ajaxProvider: AjaxProvider) {
        this.ajaxProvider = ajaxProvider;
    }

    public async addLog(logKey: string): Promise<Object> {
        this.ajaxProvider.queryParams.set('logKey', logKey);
        const response = await this.ajaxProvider.fetch();
        return JSON.parse(response);
    }
}
