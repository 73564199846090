import AbstractGa4EventMapper from './abstract-ga4-event-mapper';
import EventDataTransfer from '../event-data-transfer';
import Ga4CustomEvent from '../events/ga4-custom-event';

export default class Ga4CustomEventMapper extends AbstractGa4EventMapper {
    // eslint-disable-next-line class-methods-use-this
    /* eslint-disable camelcase */
    public map(transfer: EventDataTransfer): Ga4CustomEvent {
        const event: Ga4CustomEvent = <Ga4CustomEvent>Object.assign(
            super.map(transfer),
            {
                event_name: transfer.eventName,
            }
        );

        return event;
    }
    /* eslint-enable camelcase */
}
