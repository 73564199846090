import Component from 'ShopUi/models/component';
import TextField from 'src/BestIt/ShopUi/components/molecules/text-field/text-field';

export default class PdfDownloadSidebar extends Component {
    /**
     * The input field for the shopping list name
     * @private
     */
    private fileNameInput: TextField;

    /**
     * The button to download the PDF file
     * @private
     */
    private downloadButton: HTMLAnchorElement;

    /**
     * The base download url
     *
     * @private
     */
    private initialDownloadUrl: string = '';

    /**
     * @inheritDoc
     * @protected
     */
    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    /**
     * Init function for the component
     *
     * @private
     */
    private initialize() {
        this.fileNameInput = this.querySelector(`.${this.jsName}__file-name`);
        this.downloadButton = this.querySelector(`.${this.jsName}__download-button`);

        if (this.downloadButton !== null) {
            this.initialDownloadUrl = this.downloadButton.href;
        }

        this.mapEvents();
    }

    /**
     * Event mapper function
     *
     * @private
     */
    private mapEvents() {
        this.fileNameInput.input.addEventListener('input', (event: Event) => this.onFileNameChange(event));
        this.downloadButton.addEventListener('click', () => this.onDownloadButtonClick());
    }

    /**
     * Event handler for value change of the input field
     *
     * @param event
     * @private
     */
    private onFileNameChange(event: Event) {
        const target = <HTMLInputElement> event.target;

        if (target.value !== null && target.value !== '') {
            this.downloadButton.removeAttribute('disabled');
            this.downloadButton.classList.remove('button--disabled');
        } else {
            this.downloadButton.setAttribute('disabled', 'disabled');
            this.downloadButton.classList.add('button--disabled');
        }
    }

    /**
     * Event handler for the click event of the button
     *
     * @private
     */
    private onDownloadButtonClick() {
        const fileNameParam = `?shoppingListName=${encodeURI(this.fileNameInput.inputValue)}`;
        this.downloadButton.href = `${this.initialDownloadUrl}${fileNameParam}`;
    }
}
