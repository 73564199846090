import Ga4CustomEvent, { Ga4CustomEventInterface } from './ga4-custom-event';

/* eslint-disable camelcase */
export default class Ga4CustomEventWithData extends Ga4CustomEvent {
    constructor(data: Ga4CustomEventInterface) {
        super(data);

        if (!Object.prototype.hasOwnProperty.call(data, this.event_name)) {
            throw new Error(`${this.event_name} is missing in data.`);
        }

        if (data[this.event_name].constructor.name.toLowerCase() !== this.event_name.replace(/_/g, '')) {
            throw new Error(
                `${this.event_name} property of data is not an instance of ${this.event_name}.`
            );
        }

        this[this.event_name] = data[this.event_name];
    }
}
/* eslint-enable camelcase */
