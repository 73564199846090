import CustomSubtypeInterface from './custom-subtype-interface';

/* eslint-disable camelcase */
export interface FormSubmitDataInterface {
    branch: string;
    form_type: string;
}

export default class FormSubmit implements CustomSubtypeInterface {
    branch: string;
    form_type: string;

    constructor(data: FormSubmitDataInterface) {
        this.branch = data.branch;
        this.form_type = data.form_type;
    }
}
/* eslint-enable camelcase */
