import EventNameConstants from '../../event-name-constants';
import Ga4EcommerceEvent from './ga4-ecommerce-event';
import Ga4EcommerceEventWithProductDataInterface from '../ga4-ecommerce-event-with-product-data-interface';

/* eslint-disable camelcase */
export default class Ga4EcommerceRefundEvent extends Ga4EcommerceEvent {
    constructor(data: Ga4EcommerceEventWithProductDataInterface) {
        data.event_name = EventNameConstants.EVENT_NAME_ECOMMERCE_REFUND;
        super(data);
        this.event = 'Ecommerce - refund_partial';
    }
}
/* eslint-enable camelcase */
