import AbstractStep from '../abstract-step/abstract-step';

export default class OpticalStep extends AbstractStep {
    // eslint-disable-next-line no-undef
    protected priceBoxContainer: NodeListOf<HTMLElement>;

    protected readyCallback(): void {
        this.initialize();
    }

    protected initStepFunctionality(): void {
        this.setPriceBoxContainer();

        this.registerConcreteSkuFilterEvent();
        this.registerFilterEvents();
    }

    protected setPriceBoxContainer() {
        // eslint-disable-next-line no-undef
        this.priceBoxContainer = <NodeListOf<HTMLElement>> this.querySelectorAll(this.cssSelectors.priceContainer);
    }

    protected registerConcreteSkuFilterEvent() {
        // eslint-disable-next-line no-undef
        const concreteSkuFilterOptions = <NodeListOf<HTMLElement>> this.querySelectorAll(
            this.cssSelectors.concreteSkuFilter
        );

        if (concreteSkuFilterOptions === null || concreteSkuFilterOptions.length === 0) {
            return;
        }

        concreteSkuFilterOptions.forEach((filterOption: HTMLElement) => {
            filterOption.addEventListener('click', (event) => {
                event.preventDefault();
                this.updatePriceBox();
            });
        });
    }

    protected registerFilterEvents(): void {
        // eslint-disable-next-line no-undef
        const filterOptions = <NodeListOf<HTMLElement>> this.querySelectorAll(this.cssSelectors.filterOption);

        filterOptions.forEach((filterOption: HTMLElement) => {
            filterOption.addEventListener('click', (event) => {
                event.preventDefault();

                this.showSelectedProductBox(filterOption);
                this.updatePriceBox();
            });
        });
    }

    protected updatePriceBox() {
        this.priceBoxContainer.forEach((container) => {
            if (container !== null) {
                const priceContainer = container.querySelector(`.${this.name}--selected-price`);
                const price = this.convertPriceToMoney();

                if (!price) {
                    return;
                }

                container.classList.remove(this.cssClasses.hidden);

                if (priceContainer) {
                    priceContainer.innerHTML = price;
                }
            }
        });
    }

    protected convertPriceToMoney(): string {
        const currency = this.getCurrency('.attribute-filter-product-concrete__price');
        const priceString = this.querySelector('.optical-step--selected-price').innerHTML;
        let price = this.calculatePrice().toFixed(2);

        if (!currency) {
            return price;
        }

        if (priceString.includes(',')) {
            price = price.replace('.', ',');
        }

        return priceString.replace(/\d+([,.]\d+)?/, price);
    }

    protected calculatePrice(): number {
        return this.getProductPrice() + (this.getPriceOfAccessories() / 100);
    }

    // eslint-disable-next-line class-methods-use-this
    protected getProductPrice(): number {
        const concreteProductFilter = this.getElementsByTagName('attribute-filter-product-concrete').item(0);
        const filterOption = concreteProductFilter.querySelectorAll(this.cssSelectors.selectedFilterOption).item(0);

        if (!filterOption || !filterOption.hasAttribute('data-price')) {
            return 0;
        }

        if (filterOption.getAttribute('data-price') === '') {
            return 0;
        }

        const priceAttribute = filterOption.getAttribute('data-price');
        const trimmedPrice = priceAttribute
            .replace(/^\D+|\D+$/g, '')
            .replace(/\s/g, '');
        return parseInt(trimmedPrice, 10);
    }

    protected getPriceOfAccessories(): number {
        const accessories = this.getElementsByTagName('attribute-filter-accessory');
        let cumulatedPrice = 0;

        // @ts-ignore
        for (const accessory of accessories) {
            cumulatedPrice += this.getPriceOfSingleAccessory(accessory);
        }

        return cumulatedPrice;
    }
}
