import AbstractGa4EventMapper from './abstract-ga4-event-mapper';
import Ecommerce from '../events/ecommerce-subtypes/ecommerce';
import EventDataTransfer from '../event-data-transfer';
import Ga4EcommerceEvent from '../events/ecommerce-types/ga4-ecommerce-event';

export default abstract class AbstractGa4EcommerceEventWithDataMapper extends AbstractGa4EventMapper {
    /* eslint-disable camelcase */
    public map(transfer: EventDataTransfer): Ga4EcommerceEvent {
        return <Ga4EcommerceEvent>Object.assign(
            super.map(transfer),
            {
                event_name: transfer.eventName,
                ecommerce: this.mapEcommerceSubtype(transfer.data),
            }
        );
    }

    /* eslint-enable camelcase */
    // eslint-disable-next-line no-unused-vars
    protected abstract mapEcommerceSubtype(data: object): Ecommerce<any>;
}
