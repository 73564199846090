import Ga4EcommerceEventWithProductItemMapper
    from 'GA4EventTracking/types/event-mappers/ga4-ecommerce-event-with-product-item-mapper';
import EventDataTransfer from 'GA4EventTracking/types/event-data-transfer';
import Ga4EcommercePurchaseEvent, {
    Ga4EcommercePurchaseEventDataInterface
} from 'GA4EventTracking/types/events/ecommerce-types/ga4-ecommerce-purchase-event';
import EcommerceWithPurchaseInfo, {
    EcommerceWithPurchaseInfoDataInterface
} from 'GA4EventTracking/types/events/ecommerce-subtypes/ecommerce-with-purchase-info';
import Product from 'GA4EventTracking/types/events/ecommerce-subtypes/product';

/* eslint-disable camelcase */
export default class Ga4EcommerceEventPurchaseMapper extends Ga4EcommerceEventWithProductItemMapper {
    public map(transfer: EventDataTransfer): Ga4EcommercePurchaseEvent {
        return this.mapCustomSubtype({
            event_name: transfer.eventName,
            cart_type: transfer.data.cart_type,
            ecommerce: this.mapEcommerceSubtype(transfer.data.ecommerce)
        });
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapCustomSubtype(data: Ga4EcommercePurchaseEventDataInterface): Ga4EcommercePurchaseEvent {
        return new Ga4EcommercePurchaseEvent(data);
    }

    // eslint-disable-next-line class-methods-use-this
    protected mapEcommerceSubtype(data: EcommerceWithPurchaseInfoDataInterface): EcommerceWithPurchaseInfo {
        data.items = data.items.map((item): Product => new Product(item));

        return new EcommerceWithPurchaseInfo(data);
    }
}
