import Component from 'ShopUi/models/component';

export default class TogglerAccordion extends Component {
    wrap: HTMLElement;

    triggers: HTMLElement[];

    closeTriggers: HTMLElement[];

    isTouch: boolean;

    protected init(): void {
        this.initialize();
    }

    /**
     * @inheritDoc
     * @protected
     */
    // eslint-disable-next-line class-methods-use-this
    protected readyCallback(): void {
        /**
         * We do nothing, because this function is deprecated.
         * It is only here, because it is defined as abstract function in the Component class
         */
    }

    public initialize() {
        this.wrap = <HTMLElement>document.querySelector(this.wrapSelector);
        this.closeTriggers = <HTMLElement[]>Array.from(document.querySelectorAll(this.closeTriggerSelector));
        this.triggers = <HTMLElement[]>Array.from(document.querySelectorAll(this.triggerSelector));
        this.isTouch = 'ontouchstart' in window;

        this.mapEvents();
    }

    protected mapEvents(): void {
        if (typeof this.wrap !== 'undefined' && this.wrap !== null) {
            this.wrap.addEventListener('click', (event: Event) => this.onTriggerClick(event));
        }
    }

    public removeEvents(): void {
        if (typeof this.wrap !== 'undefined' && this.wrap !== null) {
            this.wrap.removeEventListener('click', (event: Event) => this.onTriggerClick(event));
        }
    }

    protected onTriggerClick(event: Event): void {
        this.closeItems();

        if (this.isTouchScreen) {
            if (this.isTouch) {
                this.initializeClick(event);
            }
        } else {
            this.initializeClick(event);
        }
    }

    protected initializeClick(event: Event): void {
        this.triggers.forEach((trigger: HTMLElement) => {
            let target = <any>event.target;
            while (target !== this.wrap) {
                if (target === trigger) {
                    event.preventDefault();
                    this.toggle(trigger);
                    return;
                }
                target = target.parentNode;
            }
        });
    }

    protected toggle(activeTrigger: HTMLElement): void {
        const isTriggerActive = activeTrigger.classList.contains(this.triggerActiveClass);
        activeTrigger.classList.toggle(this.triggerActiveClass, !isTriggerActive);
        this.targetToggle(activeTrigger);
    }

    protected targetToggle(trigger: HTMLElement): void {
        const targets = <HTMLElement[]>Array.from(document.querySelectorAll(trigger.dataset.toggleTarget));
        targets.forEach((target: HTMLElement) => {
            const isTargetActive = !target.classList.contains(this.classToToggle);
            target.classList.toggle(this.classToToggle, isTargetActive);
        });
    }

    private closeItems(): void {
        this.closeTriggers.forEach((closeTrigger: HTMLElement) => {
            const isCurrentElement = closeTrigger.matches(this.triggerSelector);

            if (!isCurrentElement) {
                closeTrigger.classList.remove(this.triggerActiveClass);
                const targets = <HTMLElement[]>Array.from(document.querySelectorAll(closeTrigger.dataset.toggleTarget));
                targets.forEach((target: HTMLElement) => {
                    target.classList.add(this.classToToggle);
                });
            }
        });
    }

    get wrapSelector(): string {
        return this.getAttribute('wrap-selector');
    }

    get triggerSelector(): string {
        return this.getAttribute('trigger-selector');
    }

    get closeTriggerSelector(): string {
        return this.getAttribute('close-trigger-selector');
    }

    get classToToggle(): string {
        return this.getAttribute('class-to-toggle');
    }

    get triggerActiveClass(): string {
        return this.getAttribute('active-class');
    }

    get isTouchScreen(): boolean {
        return this.touchRules === 'true';
    }

    get touchRules(): string {
        return this.getAttribute('active-on-touch');
    }
}
