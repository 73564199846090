import Component from 'ShopUi/models/component';
import GoogleTagEvents from 'GTMEventTracking/components/molecules/google-tag-events/google-tag-events';
import TempParams from 'src/BestIt/ShopUi/components/molecules/temp-params/temp-params';

export default class Variant extends Component {
    private variantSelect: HTMLSelectElement;

    private googleEventHandler: GoogleTagEvents;

    private tempParams: TempParams;

    protected readyCallback(): void {
        this.variantSelect = <HTMLSelectElement> this.querySelector(this.variantSelector);
        this.googleEventHandler = <GoogleTagEvents> document.querySelector('.google-tag-events');
        this.tempParams = <TempParams> document.querySelector('.temp-params');
        this.mapEvents();
    }

    protected mapEvents(): void {
        if (this.variantSelect !== null) {
            this.variantSelect.addEventListener('change', (event: Event) => this.handleVariantSelectChange(event));
        }
    }

    private handleVariantSelectChange(event): void {
        const target = <HTMLSelectElement> event.target;

        if (typeof this.googleEventHandler !== 'undefined' &&
            this.googleEventHandler !== null &&
            this.variantSelect.hasAttribute('data-event-data')
        ) {
            let eventData = this.googleEventHandler.getGtmEventData(this.variantSelect);
            // eslint-disable-next-line eqeqeq
            if (target.selectedIndex == 0) {
                eventData = {
                    ...eventData,
                    eventAction: 'Reset Variant Selection',
                };
                const gaEvent = this.googleEventHandler.createGaEvent(eventData);
                this.googleEventHandler.pushEvent(<any> gaEvent);
            } else if (typeof this.tempParams !== 'undefined' && this.tempParams !== null) {
                this.tempParams.set({
                    changed: true,
                });
            }
        }
    }

    // eslint-disable-next-line class-methods-use-this
    get variantSelector(): string {
        return '.custom-select__select';
    }
}
