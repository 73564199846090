import Component from 'ShopUi/models/component';

export default class InfoBox extends Component {
    private subject: HTMLButtonElement

    protected readyCallback(): void {
        this.subject = <HTMLButtonElement> this.querySelector('.js-info-box__close-button .icon-button');

        this.mapEvents();
    }

    private mapEvents(): void {
        if (typeof this.subject !== 'undefined' && this.subject !== null) {
            this.subject.addEventListener('click', () => this.closeInfoContainer());
        }
    }

    private closeInfoContainer(): void {
        this.remove();
    }
}
