export default class NumberValue {
    readonly value: number;

    constructor(value: number) {
        if (Number.isNaN(value)) {
            throw new Error(`Value ${value} is not a float number`);
        }

        this.value = value;
    }
}
